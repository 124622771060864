import { Button } from '@packages/uiKit';
import { RadioGroup } from '@packages/uiKit/RadioGroup';
import styled from 'styled-components';

export const StyledCitiesModal = styled.div``;

export const StyledButton = styled(Button)`
  margin-top: 50px;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  margin-top: 50px;
`;
