import { useSearchParam } from '@hooks/useSearchParam';
import {
  StyledPageButton,
  StyledPagesContainer,
  StyledPagination,
  StyledPrevButton,
} from './PaginationOffset.styles';

import { Button } from '@packages/uiKit/Button';
import { FC, memo, useEffect } from 'react';

const getAroundPages = (pageIndex: number, totalElementsCount: number) => {
  const pages = [];

  const start = Math.max(pageIndex - 1, 1);
  const end = Math.min(pageIndex + 1, totalElementsCount);

  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
};

const calcPageIndex = function (offset: number, perPage: number) {
  return offset / perPage + 1;
};

const calcNewOffset = function (pageIndex: number, perPage: number) {
  if (pageIndex === 1) return 0;

  let newOffset = 0;
  let counter = 0;

  while (counter < pageIndex - 1) {
    newOffset = newOffset + perPage;
    counter++;
  }

  return newOffset;
};

export interface PaginationLimitOffsetAdapterProps {
  totalElementsCount: number;
  onChangeOffset: (pageIndex: number) => void;

  perPage: number;
  offset: number;
}

const _PaginationLimitOffsetAdapter: FC<PaginationLimitOffsetAdapterProps> = (
  props,
) => {
  const {
    offset,
    totalElementsCount,
    onChangeOffset,
    perPage = 10,

    ...restProps
  } = props;

  const [pageIndex, setPageIndex] = useSearchParam<string | '1'>({
    paramName: 'page',
    defaultValue: '1',
  });

  useEffect(() => {
    setPageIndex(calcPageIndex(offset, perPage).toString());
  }, [offset, perPage, setPageIndex]);

  //const pageIndex = calcPageIndex(offset, perPage);

  const canNextPage = Number(pageIndex) < totalElementsCount / perPage;
  const canPrevPage = Number(pageIndex) > 1 && totalElementsCount > 1;

  const onNextPage = () => {
    onChangeOffset(offset + perPage);
  };

  const onPrevPage = () => {
    onChangeOffset(offset - perPage);
  };

  const aroundPages = getAroundPages(
    Number(pageIndex),
    totalElementsCount / perPage,
  );

  return (
    <StyledPagination {...restProps}>
      {canPrevPage && <StyledPrevButton onClick={onPrevPage} />}
      <StyledPagesContainer>
        {aroundPages.map((page) => {
          const isCurrent = page === Number(pageIndex);

          const onClickHandler = !isCurrent
            ? () => {
                const newOffset = calcNewOffset(page, perPage);

                onChangeOffset(newOffset);
              }
            : undefined;

          return (
            <StyledPageButton
              key={page}
              onClick={onClickHandler}
              variant={isCurrent ? 'filled' : 'outlined'}>
              {page.toLocaleString('ru', {
                minimumIntegerDigits: 2,
              })}
            </StyledPageButton>
          );
        })}
      </StyledPagesContainer>

      {canNextPage && (
        <Button onClick={onNextPage} variant="filled">
          Далее
        </Button>
      )}
    </StyledPagination>
  );
};

export const PaginationLimitOffsetAdapter = memo(_PaginationLimitOffsetAdapter);
