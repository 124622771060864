import { PaginationLimitOffsetAdapter } from '@packages/uiKit/PaginationOffset';
import { scrollbarStylesLightVariant } from '@utils/scrollBarStyles';
import styled from 'styled-components';

export const StyledOrdersSection = styled.div``;

export const StyledPaginationLimitOffsetAdapter = styled(
  PaginationLimitOffsetAdapter,
)`
  margin-top: 26px;
`;

export const StyledTablesScrollX = styled.div`
  overflow: auto;
  /* overflow-y: visible; */
  /* overflow-y: visible; */

  margin-bottom: 30px;

  ${scrollbarStylesLightVariant}
`;
