import {
  ApiDateString,
  ApiImageUrl,
  ApiPaginationMainResponse,
  ApiPaginationParams,
  ApiRecordId,
} from '@features/api';
import { PeriodMonths } from '@utils/types';

export enum HalalEdaOrderStatus {
  Paid = 'paid',
  UnPaid = 'unpaid',
  InProgress = 'preparing',
  Completed = 'prepared',
  Cancelled = 'canceled',
}

export type HalalEdaOrderCartItem = {
  id: ApiRecordId;
  cost: number;
  name: string;
  quantity: number;
  photo: ApiImageUrl;
};

export type HalalEdaOrderRecord = {
  id: ApiRecordId;
  status: HalalEdaOrderStatus;
  cost: number;
  cart: HalalEdaOrderCartItem[];
  created: ApiDateString;
  address: string;
  when: ApiDateString;
  paid_card: boolean;
  persons_number: number | null;
  change_sum: number | null;

  client_type: 'temp' | 'account';
  client: {
    full_name: string;
    tel: string;
    email: string | null;
    balance?: number;
  };

  useBalance?: boolean;
};

export type GetHalalEdaStatsPageResponse = {
  id: number;
  total_hits: number; // просмотры заведения
  orders_count: number; // кол-во заказов оформили
  orders_delivered_count: number; // кол-во доставок
  orders_sum: number; // сумма всех заказов
  commission_sum: number; // сумма всех комиссий
  top_menu_items: {
    id: number;
    name: string;
    count: number;
    sum: number;
  }[];
  orders_courier: {
    // структура доставка
    count: number;
    sum: number;
    ratio: number;
  };
  orders_pickup: {
    count: number;
    sum: number;
    ratio: number;
  };
  orders_cancelled: {
    // структура доставка отмененные
    count: number;
    sum: number;
    ratio: number;
  };
  orders_draft: {
    // структура доставка черновики
    count: number;
    sum: number;
    ratio: number;
  };

  orders_not_delivered: {
    count: number;
    sum: number;
    ratio: number;
  };
  bar_chart_data: {
    chart: {
      month: number;
      sum: number;
      count: number;
    }[];
    average: number;
    best_month: { month: number; sum: number };
  };
  currency: {
    id: number;
    short: string;
    sign: string;
    code: string;
  };
};

export type GetHalalEdaStatsPageRequest = {
  period?: PeriodMonths | string;
};

type PutHalalEdaOrderClientRecord = {
  full_name: string;
  tel: string;
  email: string | null;
};
export type PutHalalEdaOrderRecord = Omit<
  HalalEdaOrderRecord,
  'id' | 'client'
> & { client: PutHalalEdaOrderClientRecord };

export type PatchHalalEdaOrderRecord = Partial<
  Omit<PutHalalEdaOrderRecord, 'client'>
> & { client?: Partial<PutHalalEdaOrderRecord['client']> };

export type GetHalalEdaOrderPageRequest = ApiPaginationParams;

export type GetHalalEdaOrderPageResponse =
  ApiPaginationMainResponse<HalalEdaOrderRecord>;

export type GetHalalEdaOrderDetailRequest = { id: ApiRecordId };
export type GetHalalEdaOrderDetailResponse = HalalEdaOrderRecord;

export type PatchHalalEdaOrderRequest = {
  id: ApiRecordId;
  patch: PatchHalalEdaOrderRecord;
};
