import { selectIsAuth } from '@features/auth';
import { useAppSelector } from '@features/store';
import { IdentityLayout } from '@layouts/IdentityLayout';
import { MainLayout } from '@layouts/MainLayout';
import { AccountingPage } from '@pages/AccountingPage';
import { AnalyticsPage } from '@pages/AnalyticsPage';
import { BookingPage } from '@pages/BookingPage';
import { CompanyPage } from '@pages/CompanyPage';
import { EstablishmentPage } from '@pages/EstablishmentPage';
import { HalalBonusClientDetailPage } from '@pages/HalalBonusClientDetailPage';
import { HalalBonusEmployeeDetailPage } from '@pages/HalalBonusEmployeeDetailPage/HalalBonusEmployeeDetailPage';

import { HalalBonusPage } from '@pages/HalalBonusPage';
import { HalalBonusPageTab } from '@pages/HalalBonusPage/types';
import { HalalEdaOrderDetailPage } from '@pages/HalalEdaOrderDetailPage';
import { HalalEdaPage } from '@pages/HalalEdaPage';
import { HalalEdaPageSection } from '@pages/HalalEdaPage/types';
import { HalalShopOrderDetail } from '@pages/HalalShopOrderDetail';
import { HalalShopPage } from '@pages/HalalShopPage';
import { HalalShopPageSection } from '@pages/HalalShopPage/types';
import { HalalShopProductDetailPage } from '@pages/HalalShopProductDetailPage';
import { HalalShopProductReturnDetailPage } from '@pages/HalalShopProductReturnDetailPage';
import { HalalTradingPage } from '@pages/HalalTradingPage';
import { LoginPage } from '@pages/LoginPage';
import { MiscImages } from '@pages/MiscImages';
import { ReviewsPage } from '@pages/ReviewsPage';
import { GlobalStyle } from '@utils/globalStyle';

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export const App: React.FC = () => {
  const isAuth = useAppSelector(selectIsAuth);

  return (
    <div className="App">
      <GlobalStyle />
      <Routes>
        {!isAuth && (
          <Route path="/" element={<IdentityLayout />}>
            <Route index element={<Navigate to="login" replace />} />
            <Route path="login" element={<LoginPage />} />
          </Route>
        )}

        <Route path="/" element={<MainLayout />}>
          {isAuth && (
            <Route index element={<Navigate to="establishment" replace />} />
          )}

          <Route path="establishment">
            <Route index element={<EstablishmentPage />} />
          </Route>

          <Route path="company">
            <Route index element={<CompanyPage />} />
          </Route>

          <Route path="analytics">
            <Route index element={<AnalyticsPage />} />
          </Route>

          <Route path="accounting">
            <Route index element={<AccountingPage />} />
          </Route>

          <Route path="halalEda">
            <Route index element={<HalalEdaPage />} />

            <Route
              path={`${HalalEdaPageSection.Orders}/detail/:id`}
              element={<HalalEdaOrderDetailPage />}
            />
          </Route>

          <Route path="halalBonus">
            <Route index element={<HalalBonusPage />} />

            <Route
              path={`${HalalBonusPageTab.Employees}/detail/:id`}
              element={<HalalBonusEmployeeDetailPage />}
            />
            <Route
              path={`${HalalBonusPageTab.Clients}/detail/:id`}
              element={<HalalBonusClientDetailPage />}
            />
            <Route
              path={`${HalalBonusPageTab.Employees}/detail/:id`}
              element={<HalalBonusEmployeeDetailPage />}
            />
          </Route>

          <Route path="booking">
            <Route index element={<BookingPage />} />
          </Route>

          <Route path="reviews">
            <Route index element={<ReviewsPage />} />
          </Route>

          <Route path="reviews">
            <Route index element={<ReviewsPage />} />
          </Route>

          <Route path="halalTrading">
            <Route index element={<HalalTradingPage />} />
          </Route>

          <Route path="halalShop">
            <Route index element={<HalalShopPage />} />
            <Route path={`${HalalShopPageSection.Orders}`}>
              <Route path=":id" element={<HalalShopOrderDetail />} />
            </Route>
            <Route path={`${HalalShopPageSection.Catalog}`}>
              <Route path=":id" element={<HalalShopProductDetailPage />} />
            </Route>
            <Route path={`${HalalShopPageSection.Returns}`}>
              <Route
                path=":id"
                element={<HalalShopProductReturnDetailPage />}
              />
            </Route>
          </Route>

          <Route path="*" element={'404 Not found'} />
        </Route>
        <Route path="misc" element={<MiscImages />}></Route>
      </Routes>
    </div>
  );
};
