import { StyledInputNumber } from './InputNumber.styles';

import { InputProps } from '../Input';

import React from 'react';

export interface InputNumberProps
  extends Omit<InputProps, 'onChange' | 'value'> {
  value?: number | null;
  onChange?: (value?: number | null) => void;
}

export const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  (props, ref) => {
    const { onChange, value, ...restProps } = props;

    const isValidNumber =
      value !== undefined && value !== null && !Number.isNaN(value);

    const stringValue = isValidNumber ? value?.toString() : '';

    const onChangeHandler: InputProps['onChange'] | undefined = onChange
      ? (value) => {
          if (!value) {
            onChange(null);
            return;
          }

          const valueAsNumber = Number(value);

          if (Number.isNaN(valueAsNumber)) {
            return;
          }

          if (props.maxLength) {
            if (value.toString().length <= props.maxLength) {
              onChange(Number(value));
            }
          } else {
            onChange(valueAsNumber);
          }
        }
      : undefined;

    return (
      <StyledInputNumber
        ref={ref}
        value={stringValue}
        onChange={onChangeHandler}
        maxLength={1}
        type="number"
        {...restProps}
      />
    );
  },
);

InputNumber.displayName = 'InputNumber';
