import { SelectOption } from '@packages/uiKit';
import { BonusWorkTime } from './types';

export const mock: BonusWorkTime = {
  5: [
    {
      id: 10,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 0,
    },
    {
      id: 11,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 1,
    },
  ],
  6: [
    {
      id: 10,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 0,
    },
    {
      id: 11,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 1,
    },
  ],
  7: [
    {
      id: 10,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 0,
    },
    {
      id: 11,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 1,
    },
  ],
  4: [
    {
      id: 10,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 0,
    },
    {
      id: 11,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 1,
    },
  ],
  2: [
    {
      id: 10,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 0,
    },
    {
      id: 11,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 1,
    },
  ],
  3: [
    {
      id: 10,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 0,
    },
    {
      id: 11,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 1,
    },
  ],
  1: [
    {
      id: 10,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 0,
    },
    {
      id: 11,
      end_time: '24:00',
      start_time: '24:00',
      value: 10,
      active: true,
      temp_id: 1,
    },
  ],
};

export const allDays: Array<keyof BonusWorkTime> = [1, 2, 3, 4, 5, 6, 7];

export const dayDict = {
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
  7: 'Воскресенье',
};

export const emptyWorkTime: BonusWorkTime = {
  1: null,
  2: null,
  3: null,
  4: null,
  5: null,
  6: null,
  7: null,
};

const getLabelTimeOptions = (variant: 'start' | 'end', value: string) =>
  `${variant === 'start' ? 'c' : 'до'} ${value}`;

const timeValues = [
  ...Array.from({ length: 24 })
    .map((_, index) => [`${index}:00`, `${index}:30`])
    .flat(),
  '24:00',
];

const getTimeOptions = (variant: 'start' | 'end'): SelectOption<string>[] =>
  timeValues.map((value) => ({
    value,
    label: getLabelTimeOptions(variant, value),
  }));

export const startTimeOptions = getTimeOptions('start');
export const endTimeOptions = getTimeOptions('end');
