import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '../utils';
import { IconButtonVariant } from './types';

const BORDER_RADIUS = 16;

const variantStylesMap: Record<IconButtonVariant, FlattenSimpleInterpolation> =
  {
    text: css`
      color: ${colors.dark1};

      :hover {
        color: ${colors.dark1};
      }
    `,

    'secondary-text': css`
      color: ${colors.gray1};

      :hover {
        color: ${colors.gray1};
      }
    `,

    secondary: css`
      padding: 10px;
      border-radius: ${BORDER_RADIUS}px;
      background-color: ${colors.gray3};
      color: ${colors.dark1};
      :hover {
        background-color: ${colors.gray3};
      }
    `,

    primary: css`
      padding: 10px;
      border-radius: ${BORDER_RADIUS}px;
      background-color: ${colors.primary};
      color: ${colors.dark1};
      :hover {
        background-color: ${colors.primaryLight};
      }
    `,

    'secondary-outlined': css`
      padding: 10px;
      border-radius: ${BORDER_RADIUS}px;
      background-color: 'transparent';
      border: 1px solid ${colors.gray1};
      color: ${colors.dark1};

      :hover {
        border: 1px solid ${colors.dark1};
      }
    `,
  };

export type StyledIconButtonProps = {
  $variant: IconButtonVariant;
};

export const StyledIconButton = styled.button<StyledIconButtonProps>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s ease;
  padding: 0;
  ${({ $variant }) => variantStylesMap[$variant]}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      cursor: not-allowed;
    `}
`;
