import { Loader } from '@components/Loader';
import { HalalShopOrderForm } from './HalalShopOrderForm';
import { StyledHeader, StyledTitle } from './styles';

import { TextPlaceholder } from '@components/TextPlaceholder';

import { getDeliveryValues } from '@features/halalShop/orders/utils/getDeliveryValues';
import { getInitValues } from '@features/halalShop/orders/utils/getInitValues';
import { shopOrdersEndpoints } from '@features/halalShop/store/apiSerive';
import { HalalShopOrderStatus } from '@features/halalShop/store/types';
import { useAuthProtection } from '@hooks/useAuthProtection';
import { MainLayout } from '@layouts/MainLayout';
import { FC, memo } from 'react';
import { useParams } from 'react-router-dom';

const _HalalShopOrderDetail: FC = () => {
  useAuthProtection();

  const params = useParams();

  const id = Number(params.id!);

  const {
    data: orderData,
    isLoading,
    isSuccess,
  } = shopOrdersEndpoints.useGetOrderDetailQuery({
    id,
  });

  const initValues = getInitValues(orderData ? orderData : null);
  const deliveryData = getDeliveryValues(orderData ? orderData : null);
  const orderStatus = orderData
    ? orderData.status
    : HalalShopOrderStatus.Unknown;
  const orderId = orderData ? orderData.id : 0;

  return (
    <MainLayout.Content>
      <MainLayout.Content.BackLink to={'/halalShop/?section=orders'}>
        Все заказы
      </MainLayout.Content.BackLink>
      {isLoading ? (
        <Loader />
      ) : isSuccess ? (
        <>
          <StyledHeader>
            <StyledTitle>Заказ · {orderId}</StyledTitle>
            {/*   <OrderStatusTag status={orderStatus} /> */}
          </StyledHeader>
          <HalalShopOrderForm
            initialValues={initValues}
            orderStatus={orderStatus}
            deliveryData={deliveryData}
          />
        </>
      ) : (
        <TextPlaceholder variant="error" />
      )}
    </MainLayout.Content>
  );
};

export const HalalShopOrderDetail = memo(_HalalShopOrderDetail);
