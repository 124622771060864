import { ReactComponent as DeleteSvg } from '@packages/icons/assets/svgs/close.svg';
import { Typography } from '@packages/uiKit/Typography';

import { zIndexes } from '@packages/uiKit/utils/zIndexes';
import { scrollbarStylesLightVariant } from '@utils/scrollBarStyles';

import styled, { css } from 'styled-components';

export const StyledPopover = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndexes.modal};
`;

export const StyledOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: ${zIndexes.modalOverlay};
`;

export const StyledModalWrapper = styled.div<{ $width?: number }>`
  z-index: ${zIndexes.modal};
  position: absolute;
  background: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 40px);
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `}
  border-radius: 10px;

  max-height: calc(100% - 40px);

  display: flex;
  flex-direction: column;
`;

export const StyledModalCloseButton = styled.div`
  cursor: pointer;
  padding-top: 22px;
`;

export const StyledDeleteIcon = styled(DeleteSvg)`
  width: 16px;
  height: 16px;
`;

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding-bottom: 25px;
  padding-right: 30px;
  padding-left: 30px;
`;

export const StyledModalContent = styled.div`
  flex: 1;
  padding: 0 30px 30px 30px;
  overflow: auto;
  ${scrollbarStylesLightVariant}
`;

export const StyledModalTitle = styled(Typography).attrs({
  size: 22,
  weight: 700,
})`
  flex: 1;
  padding-top: 23px;
`;
