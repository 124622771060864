import {
  StyledStatisticsLayout,
  StyledStatisticsSection,
} from './StatisticsSection.styles';

import { Tabs } from '@components';
import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';
import { InfoCard } from '@features/analytics';
import { HalalEdaCommissionCard } from '@features/analytics/components/HalalEdaCommissionCard';
import { OrderDynamicCard } from '@features/analytics/components/OrderDynamicCard';
import { OrdersDeliveredCard } from '@features/analytics/components/OrdersDeliveredCard';
import { OrdersIssuedCard } from '@features/analytics/components/OrdersIssuedCard';
import { OrdersStructureCard } from '@features/analytics/components/OrdersStructureCard';
import { SumAllOrdersCard } from '@features/analytics/components/SumAllOrdersCard';
import { TopDishesCard } from '@features/analytics/components/TopDishesCard';
import { halalEdaOrdersApi } from '@features/halalEda';
import { useSearchParam } from '@hooks/useSearchParam';
import { EyeIcon } from '@packages/icons';
import { periodTabs } from '@utils/types';
import React, { FC } from 'react';

export interface StatisticsSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

export const StatisticsSection: FC<StatisticsSectionProps> = (props) => {
  const { ...restProps } = props;

  const [searchPeriod, setSearchPeriod] = useSearchParam<string>({
    paramName: 'period',
    defaultValue: 'year',
  });

  const {
    data: statsData,
    isLoading,
    isSuccess,
  } = halalEdaOrdersApi.useGetStatsPageQuery({ period: searchPeriod });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : !isSuccess ? (
        <TextPlaceholder />
      ) : (
        <StyledStatisticsSection {...restProps}>
          <Tabs
            tabs={periodTabs}
            value={searchPeriod}
            onChange={setSearchPeriod}
            variant="round"
          />
          <StyledStatisticsLayout>
            <TopDishesCard dataItems={statsData} />
            <OrdersStructureCard dataItems={statsData} />
            <OrderDynamicCard dataItems={statsData} />
            <InfoCard
              value={statsData?.total_hits}
              icon={<EyeIcon size={36} />}
              description="Просмотров заведения"
              color={'primary'}
            />
            {/* <AddToBagCard value={4308} percent={43} /> */}
            <OrdersIssuedCard value={statsData.orders_count} />
            <OrdersDeliveredCard value={statsData.orders_delivered_count} />
            <SumAllOrdersCard value={statsData.orders_sum} />
            <HalalEdaCommissionCard value={statsData.commission_sum} />
          </StyledStatisticsLayout>
        </StyledStatisticsSection>
      )}
    </>
  );
};
