import { SelectOption } from '@packages/uiKit';

export const numberOfPersonOptions: SelectOption<number>[] = [
  {
    value: 1,
    label: '1 персона',
  },
  {
    value: 2,
    label: '2 персоны',
  },
  {
    value: 3,
    label: '3 персоны',
  },
  {
    value: 4,
    label: '4 персоны',
  },
  {
    value: 5,
    label: '5 персон',
  },
];

export const paymentMethodOptions: SelectOption<string>[] = [
  {
    value: 'cash',
    label: 'Наличными',
  },
  {
    value: 'bankCard',
    label: 'Катрой банка',
  },
];
