import { StyledGridAdaptive, StyledGridAdaptiveItem } from './styles';

import { GridAdaptiveParam } from './types';

import React, { Children, FC, PropsWithChildren } from 'react';

export interface WrapGridProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
  cols: GridAdaptiveParam<number>;
  columnGap?: GridAdaptiveParam<number>;
  rowGap?: GridAdaptiveParam<number>;
  square?: boolean;
}

export const GridAdaptive: FC<WrapGridProps> = (props) => {
  const { cols, columnGap, rowGap, children, square, ...restProps } = props;

  const childrenArr = Children.toArray(children);

  return (
    <StyledGridAdaptive
      $cols={cols}
      $columnGap={columnGap}
      $rowGap={rowGap}
      {...restProps}>
      {childrenArr.map((child, index) => (
        <StyledGridAdaptiveItem $square={square} key={index}>
          {child}
        </StyledGridAdaptiveItem>
      ))}
    </StyledGridAdaptive>
  );
};
