import {
  StyledMenuPositionListLayout,
  StyledMenuSection,
} from './MenuSection.styles';

import { OrderFormValues } from '../OrderForm/types';

import { Tabs, TabsTabItem } from '@components';
import { ApiRecordId } from '@features/api';
import { MenuPositionCard } from '@features/halalEda';
import { menuApi } from '@features/menu';
import { useSearchParam } from '@hooks/useSearchParam';
import React, { FC, memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface MenuSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

const _MenuSection: FC<MenuSectionProps> = (props) => {
  const { ...restProps } = props;

  const [searchCategory, setSearchCategory] = useSearchParam<number | 'all'>({
    paramName: 'category',
    defaultValue: 'all',
    serializer: (param) => (param === 'all' ? param : Number(param)),
  });

  const { data: menuPositionsData } = menuApi.useGetMenuItemsQuery({
    category: searchCategory === 'all' ? undefined : searchCategory,
  });

  const { data: includedCategoriesData } =
    menuApi.useGetMenuIncludedCategoriesQuery();

  const categoriesTabs: TabsTabItem<ApiRecordId | 'all'>[] = useMemo(() => {
    return [
      { value: 'all', label: 'Все' },
      ...(includedCategoriesData?.map((cat) => ({
        value: cat.id,
        label: cat.name,
      })) ?? []),
    ];
  }, [includedCategoriesData]);

  const { control } = useFormContext<OrderFormValues>();

  return (
    <StyledMenuSection {...restProps}>
      <Tabs
        tabs={categoriesTabs}
        value={searchCategory}
        onChange={setSearchCategory}
        isExpandable
        variant="round"
        visibleAmount={15}
      />
      <Controller
        control={control}
        name="cart"
        render={({ field: { value, onChange } }) => {
          return (
            <StyledMenuPositionListLayout>
              {menuPositionsData?.map((menuPosition) => {
                const addToCartHandler = () => {
                  const newValue = value;
                  if (
                    !value.some((cartItem) => cartItem.id === menuPosition.id)
                  ) {
                    newValue.push({
                      id: menuPosition.id,
                      name: menuPosition.name,
                      cost: menuPosition.cost,
                      photo: menuPosition.image,
                      quantity: 1,
                    });
                  }
                  onChange(newValue);
                };
                return (
                  <MenuPositionCard
                    key={menuPosition.id}
                    {...menuPosition}
                    buttonProps={{ onClick: addToCartHandler }}
                  />
                );
              })}
            </StyledMenuPositionListLayout>
          );
        }}
      />
    </StyledMenuSection>
  );
};

export const MenuSection = memo(_MenuSection);
