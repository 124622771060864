import { StyledImageList, StyledImageListWithUpload } from './styles';

import { ImageModal } from '../ImageModal';

import { UploadButtonProps } from '../UploadButton';

import { PhotoResponseRecord } from '@features/photos';
import { useModalState } from '@hooks/useModalState';
import { ImagePreviewCard } from '@packages/uiKit/ImagePreviewCard';
import { UploadImageButton } from '@packages/uiKit/UploadImageButton';
import React, { forwardRef, useCallback } from 'react';

export type InputImageListProps = {
  className?: string;
  styles?: React.CSSProperties;

  value: (PhotoResponseRecord | File)[];

  onChange?: (value: (PhotoResponseRecord | File)[]) => void;
  multiple?: boolean;
  maxListSizeVisible?: number;
  max?: number;

  isNoUpload?: boolean;
  direction?: 'row' | 'column';
};

export const InputImageList = forwardRef<HTMLDivElement, InputImageListProps>(
  (props, ref) => {
    const {
      value,
      multiple,
      onChange,
      maxListSizeVisible,
      isNoUpload,
      direction = 'row',
      max,
      ...restProps
    } = props;

    const [modalState, modalActions] = useModalState<{ image: string }>();

    const uploadHandler: UploadButtonProps['onUpload'] = useCallback(
      (_: File, files: FileList) => {
        onChange?.([...value, ...Array.from<File>(files)]);
      },
      [onChange, value],
    );

    const deleteHandler = (image: File | PhotoResponseRecord) => {
      if (image instanceof File) {
        onChange?.(value.filter((x) => x !== image));
        return;
      }
      onChange?.(
        value.filter((x) => (x instanceof File ? true : x.id !== image.id)),
      );
    };

    const isAllowedMore = max ? value.length < max : true;

    return (
      <StyledImageListWithUpload
        ref={ref}
        $direction={direction}
        {...restProps}>
        <StyledImageList
          maxListSizeVisible={maxListSizeVisible}
          $direction={direction}>
          {value.map((image) => {
            const key = image instanceof File ? image.name : image.id;
            const url =
              image instanceof File ? URL.createObjectURL(image) : image.photo;

            const onClickHandler = () => {
              modalActions.openHandler({ image: url });
            };

            return (
              <ImagePreviewCard
                key={key}
                src={url}
                onClick={onClickHandler}
                onDelete={() => deleteHandler(image)}
              />
            );
          })}
          {isAllowedMore && !isNoUpload && (
            <UploadImageButton multiple={multiple} onUpload={uploadHandler}>
              Загрузить фото
            </UploadImageButton>
          )}
        </StyledImageList>

        <ImageModal {...modalState} onClose={modalActions.closeHandler} />
      </StyledImageListWithUpload>
    );
  },
);

InputImageList.displayName = 'InputImageList';
