import { BadgeColor } from './types';

import { colors } from '../utils';

import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

const badgeColorStyles: Record<BadgeColor, FlattenSimpleInterpolation> = {
  primary: css`
    background-color: ${colors.primary};
    color: ${colors.white};
  `,
  danger: css`
    background-color: ${colors.danger};
    color: ${colors.white};
  `,
};

export const StyledBadge = styled.div<{ $color: BadgeColor }>`
  display: flex;
  align-items: center;
  padding: 2px 6px 1px;
  min-height: 16px;
  min-width: 16px;
  width: fit-content;
  border-radius: 10px;

  ${({ $color }) => badgeColorStyles[$color]}
`;
