import { StyledTopDishesCard } from './TopDishesCard.styles';

import { Bar, BarConfig } from '@ant-design/plots';
import { GetHalalEdaStatsPageResponse } from '@features/halalEda';
import { colors } from '@packages/uiKit';
import React, { FC } from 'react';

export interface TopDishesCardProps {
  className?: string;
  style?: React.CSSProperties;
  dataItems: GetHalalEdaStatsPageResponse;
}

// const dataMock: DataItem[] = [
//   {
//     dishesName: 'Суши-буритто с голубым тунцом',
//     sumAmount: 105_000,
//   },
//   {
//     dishesName: 'Острое поке с голубым тунцом',
//     sumAmount: 22_000,
//   },
//   {
//     dishesName: 'Лимонад Манго-маракуйя',
//     sumAmount: 14_000,
//   },
//   {
//     dishesName: 'Козырьки для авто',
//     sumAmount: 12_000,
//   },
//   {
//     dishesName: 'Маникюрные наборы',
//     sumAmount: 9_000,
//   },
//   {
//     dishesName: 'Подушки для ванны',
//     sumAmount: 7_000,
//   },
//   {
//     dishesName: 'Контейнеры медицинские',
//     sumAmount: 5_000,
//   },
// ];

export const TopDishesCard: FC<TopDishesCardProps> = (props) => {
  const { dataItems, ...restProps } = props;

  const data = dataItems.top_menu_items;

  const config: BarConfig = {
    data,
    height: 250,
    color: colors.primary,
    xField: 'count',
    yField: 'name',
    seriesField: 'count',
    legend: false as const,
    meta: {
      count: {
        formatter: (value: number) => `${(value / 1).toFixed(0)} ед.`,
      },
    },
  };

  return (
    <StyledTopDishesCard title="Топ 7 блюд" {...restProps}>
      <Bar {...config} />
    </StyledTopDishesCard>
  );
};
