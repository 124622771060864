import { OrderTable } from './OrderTable';
import {
  StyledOrdersSection,
  StyledPaginationLimitOffsetAdapter,
} from './styles';

import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';
import { shopOrdersEndpoints } from '@features/halalShop/store/apiSerive';
import { ShopOrdersRecord } from '@features/halalShop/store/types';
import { LIST_ITEMS_PER_PAGE } from '@utils/globalContants';
import { FC, memo, useEffect, useState } from 'react';

export interface OrdersSectionProps {}

const _OrdersSection: FC<OrdersSectionProps> = (props) => {
  const { ...restProps } = props;

  const [data, setData] = useState<ShopOrdersRecord[] | null>();

  const [offset, setOffset] = useState<number>(0);

  const [elementsCount, setElementsCount] = useState<number>(0);

  const [getOrdersQ, { isSuccess, isLoading, isFetching }] =
    shopOrdersEndpoints.useLazyGetOrdersQuery();

  useEffect(() => {
    const fn = async () => {
      const res = await getOrdersQ({
        limit: LIST_ITEMS_PER_PAGE,
        offset: offset,
      }).unwrap();

      setElementsCount(res.count);
      setData(res.results);
    };

    fn();
  }, [getOrdersQ, offset]);

  const isEmpty = data && data.length === 0;

  return (
    <StyledOrdersSection {...restProps}>
      {isLoading || isFetching ? (
        <Loader />
      ) : (
        <>
          {isSuccess ? (
            isEmpty ? (
              <TextPlaceholder variant="noData" />
            ) : (
              <>
                <OrderTable data={data ? data : []} onChangeStatus={() => {}} />
                <StyledPaginationLimitOffsetAdapter
                  offset={offset}
                  onChangeOffset={setOffset}
                  totalElementsCount={elementsCount}
                  perPage={LIST_ITEMS_PER_PAGE}
                />
              </>
            )
          ) : (
            <TextPlaceholder variant="error" />
          )}
        </>
      )}
    </StyledOrdersSection>
  );
};

export const OrdersSection = memo(_OrdersSection);
