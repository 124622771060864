import {
  StyledBottom,
  StyledBottomLeft,
  StyledCode,
  StyledCost,
  StyledDropdownIconButton,
  StyledHalalShopProductCard,
  StyledImage,
  StyledName,
  StyledRight,
  StyledTop,
} from './styles';

import { SwitchWithValueLabel } from '@components/SwitchWithValueLabel';
import { NotePencilIcon } from '@packages/icons';
import { Button } from '@packages/uiKit';
import React, { FC, memo, useState } from 'react';

export interface HalalTradingProductCardProps {
  className?: string;
  style?: React.CSSProperties;
  image: string;
  name: string;
  id: number | string;
  cost: number | string | null;
  isActive: boolean;
  onEditClick?: () => void;
}

const _HalalTradingProductCard: FC<HalalTradingProductCardProps> = (props) => {
  const {
    image,
    name,
    id,
    cost,
    isActive,
    onEditClick,

    ...restProps
  } = props;

  const [isVisible, setIsVisible] = useState(false);

  return (
    <StyledHalalShopProductCard {...restProps}>
      <StyledImage src={image} placeholderType="gray" />
      <StyledRight>
        <StyledTop>
          <StyledName>{name}</StyledName>
          <StyledCode>Код товара: {id}</StyledCode>
          <StyledDropdownIconButton
            dropdown={<h1>Hello</h1>}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          />
        </StyledTop>
        <StyledBottom>
          <StyledBottomLeft>
            <Button
              icon={<NotePencilIcon />}
              color="white"
              onClick={onEditClick}
            />
            <StyledCost>
              {cost ? `${Number(cost).toFixed(1).toString()} ₽` : 'нет цены'}
            </StyledCost>
          </StyledBottomLeft>

          <SwitchWithValueLabel
            trueLabel="Продается"
            falseLabel="Не продается"
            value={isActive}
          />
        </StyledBottom>
      </StyledRight>
    </StyledHalalShopProductCard>
  );
};

export const HalalTradingProductCard = memo(_HalalTradingProductCard);
