import { CaretDownIcon } from '@packages/icons';
import { Typography } from '@packages/uiKit/Typography';
import { motion } from 'framer-motion';

import styled, { css } from 'styled-components';
import { AccordionSizeItem } from '.';
import { colors } from '../utils';

export const StyledAccordionExpandable = styled(motion.div)<{
  $hideBottomBorder?: boolean;
}>`
  ${({ $hideBottomBorder }) =>
    $hideBottomBorder
      ? css`
          border-bottom: 1px solid transparent;
        `
      : css`
          border-bottom: 1px solid ${colors.gray3};
        `}
`;

export const StyledAccordionExpandableHeader = styled.div<{
  $size: AccordionSizeItem;
}>`
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${({ $size }) =>
    $size === 'small'
      ? css`
          padding: 12px 0;
        `
      : css``}
`;

export const StyledCaretDownIcon = styled(CaretDownIcon)<{
  $isOpen: boolean;
}>`
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          transition: 0.3s ease;
          transform: rotate(180deg);
        `
      : css`
          transition: 0.3s ease;
          transform: rotate(0deg);
        `}
`;

export const StyledTitle = styled(Typography).attrs({
  size: 16,
  weight: 700,
  forwardedAs: 'div',
})<{
  $isOpen: boolean;
  $titleVariant: 'bold' | 'normal';
  $size: AccordionSizeItem;
}>`
  user-select: none;
  :hover {
    color: ${colors.dark1};
  }
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          color: ${colors.dark1};
        `
      : css`
          color: ${colors.dark1};
        `}

  ${({ $titleVariant }) =>
    $titleVariant === 'normal'
      ? css`
          font-weight: 400;
        `
      : css``}


${({ $size }) =>
    $size === 'small'
      ? css`
          font-size: 14px;
        `
      : css``}
`;

export const StyledContent = styled(motion.div)``;
