import {
  useEditMenuPositionForm,
  UseEditMenuPositionFormParams,
} from './hooks';
import { StyledEditMenuPositionModal } from './styles';

import { MenuPositionFormFields } from '../MenuPositionFormFields';

import { menuApi, MenuItemRecord } from '@features/menu';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { useModalState } from '@hooks/useModalState';
import { UncontrolledFormProvider } from '@hooks/useUncontrolledFromContext';
import { Button, Modal, ModalProps } from '@packages/uiKit';
import { ConfirmModal } from '@packages/uiKit/ConfirmModal';
import { Col, Row } from 'antd';
import { FC, memo, useCallback } from 'react';

export type EditMenuPositionModalProps = Pick<
  ModalProps,
  'onClose' | 'styles' | 'className'
> &
  (
    | { isOpen: false; editedItem?: MenuItemRecord }
    | { isOpen: true; editedItem: MenuItemRecord }
  );

type EditMenuPositionModalInnerProps = {
  editedItem: MenuItemRecord;
  onClose?: () => void;
};

const EditMenuPositionModalInner: FC<EditMenuPositionModalInnerProps> = ({
  editedItem,
  onClose,
}) => {
  const createNotification = useNotification();

  const [putMenuItemM] = menuApi.usePutMenuItemMutation();

  const [putMenuItemImageM] = menuApi.usePutMenuItemImageMutation();

  const [deleteMenuItem, { isLoading: isDeleting }] =
    menuApi.useDeleteMenuItemMutation();

  const onSubmitHandler: UseEditMenuPositionFormParams['onSubmit'] =
    useCallback(
      async ({ image, ...data }, isChangedImage) => {
        const promises: Promise<any>[] = [
          putMenuItemM({
            id: editedItem.id,
            put: {
              ...data,
            },
          }).unwrap(),
        ];

        if (isChangedImage) {
          promises.push(
            putMenuItemImageM({
              id: editedItem.id,
              image: image as File | null,
            }).unwrap(),
          );
        }

        try {
          await Promise.all(promises);
          onClose?.();
          createNotification({
            message: `Блюдо "${data.name}" успешно изменено`,
            type: 'success',
          });
        } catch {
          createNotification({
            message: `При изменении блюда произошла ошибка`,
            type: 'error',
          });
        }
      },
      [
        putMenuItemM,
        editedItem.id,
        putMenuItemImageM,
        onClose,
        createNotification,
      ],
    );

  const deleteHandler = useCallback(async () => {
    await deleteMenuItem({ id: editedItem.id });
    onClose?.();
  }, [deleteMenuItem, editedItem.id, onClose]);

  const form = useEditMenuPositionForm({
    onSubmit: onSubmitHandler,
    initValues: { ...editedItem, cost: Number(editedItem.cost) },
  });

  const [confirmDeleteModalState, confirmDeleteActions] = useModalState();

  return (
    <StyledEditMenuPositionModal>
      <UncontrolledFormProvider {...form}>
        <MenuPositionFormFields />
        <Row gutter={10}>
          <Col>
            <Button size="large" onClick={form.handleSubmit}>
              Сохранить блюдо
            </Button>
          </Col>
          <Col>
            <Button
              size="large"
              variant="filled"
              color="secondary"
              onClick={confirmDeleteActions.openHandler}
              loading={isDeleting}>
              Удалить блюдо
            </Button>
          </Col>
        </Row>
      </UncontrolledFormProvider>

      <ConfirmModal
        title="Удаление блюда"
        description={`Вы действительно хотите удалить "${editedItem.name}"? Данное действие нельзя будет отменить.`}
        confirmButtonText="Да, удалить"
        {...confirmDeleteModalState}
        onClose={confirmDeleteActions.closeHandler}
        onConfirm={deleteHandler}
      />
    </StyledEditMenuPositionModal>
  );
};

const _EditMenuPositionModal: FC<EditMenuPositionModalProps> = (props) => {
  const { editedItem, ...restProps } = props;

  return (
    <Modal title="Изменить блюдо" {...restProps}>
      {editedItem && restProps.isOpen && (
        <EditMenuPositionModalInner
          editedItem={editedItem}
          onClose={restProps.onClose}
        />
      )}
    </Modal>
  );
};

export const EditMenuPositionModal = memo(_EditMenuPositionModal);
