import { MainLayout } from '@layouts/MainLayout';
import { OrderDetailLayout } from '@layouts/OrderDetailLayout';
import { HalalShopPageSection } from '@pages/HalalShopPage/types';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ContentFormSection } from './ContentFormSection';
import { MainInfoSection } from './MainInfoSection';
import { SidebarSection } from './SidebarSection';
import { returnFormClientMocks } from './constants';
import { ReturnFormClientValues } from './type';

export interface HalalShopProductReturnDetailPageProps {
  className?: string;
  style?: React.CSSProperties;
}

export const HalalShopProductReturnDetailPage: FC<
  HalalShopProductReturnDetailPageProps
> = (props) => {
  const { ...restProps } = props;

  const form = useForm<ReturnFormClientValues>({
    defaultValues: returnFormClientMocks,
  });

  return (
    <MainLayout.Content {...restProps}>
      <MainLayout.Content.BackLink
        to={`${`/halalShop?section=${HalalShopPageSection.Returns}`}`}>
        Все товары
      </MainLayout.Content.BackLink>
      <OrderDetailLayout.Content>
        <MainInfoSection />
        <FormProvider {...form}>
          <ContentFormSection />
          <SidebarSection />
        </FormProvider>
      </OrderDetailLayout.Content>
    </MainLayout.Content>
  );
};
