import React, { FC, memo } from 'react';

import { ListAsTableRow } from '@components';
import { ReturnFormClientValues } from '@pages/HalalShopProductReturnDetailPage/type';
import { Col, Row } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledReturnSection } from './styles';

import photo from '@assets/imgs/imageMockProduct1.png';

import photoPlaceholder from '@assets/imgs/placeholderImageGray.png';

import { Space } from '@components/Space';
import { HalalShopCartItemRow } from '@features/halalShop/orders/components/HalalShopCartItemRow';
import { Button, InputImageList } from '@packages/uiKit';

export interface ReturnSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

const photos = [
  {
    id: 0,
    photo: photoPlaceholder,
  },
  {
    id: 1,
    photo: photoPlaceholder,
  },
  {
    id: 2,
    photo: photoPlaceholder,
  },
];

const _ReturnSection: FC<ReturnSectionProps> = (props) => {
  const { ...restProps } = props;

  const { control } = useFormContext<ReturnFormClientValues>();

  return (
    <StyledReturnSection {...restProps}>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <ListAsTableRow>
            <ListAsTableRow.Item>
              <ListAsTableRow.Label label="Карточка товара" />
              <Space size={10} />
              <HalalShopCartItemRow
                cost={2250}
                count={1}
                id={590910979}
                image={photo}
                name="Рубашка/мусульманская одежда"
              />{' '}
              <Space size={25} />
              <Button size="medium" variant="outlined" color="secondary">
                Перейти к товару
              </Button>
            </ListAsTableRow.Item>
          </ListAsTableRow>
        </Col>
        <Col span={24}>
          <Controller
            control={control}
            name="returnInfo.reason"
            render={({ field }) => {
              return (
                <ListAsTableRow>
                  <ListAsTableRow.Item>
                    <ListAsTableRow.Label label="Причина возврата" />
                    <ListAsTableRow.Value value={field.value} />
                  </ListAsTableRow.Item>
                </ListAsTableRow>
              );
            }}
          />
        </Col>
        <Col span={24}>
          <Controller
            control={control}
            name="returnInfo.description"
            render={({ field }) => {
              return (
                <ListAsTableRow>
                  <ListAsTableRow.Item>
                    <ListAsTableRow.Label label="Описание проблемы" />
                    <ListAsTableRow.Value value={field.value} />
                  </ListAsTableRow.Item>
                </ListAsTableRow>
              );
            }}
          />
        </Col>
        <Col span={24}>
          <ListAsTableRow>
            <ListAsTableRow.Item>
              <ListAsTableRow.Label label="Фотографии клиента" />
              <Space size={10} />
              <InputImageList value={photos} isNoUpload />
            </ListAsTableRow.Item>
          </ListAsTableRow>
        </Col>
      </Row>
    </StyledReturnSection>
  );
};

export const ReturnSection = memo(_ReturnSection);
