import {
  ApiImageUrl,
  ApiPaginationSecondaryResponse,
  ApiRecordId,
} from '@features/api';
import { CurrencyRequestTypes } from '@features/currency/types';
import { UserInfoRecord } from '@features/user/types';

export enum HalalShopOrderStatus {
  New = 'new',
  Assembly = 'assembly',
  AwaitingShipment = 'awaitingShipment',
  Delivering = 'delivering',
  Canceled = 'canceled',
  Delivered = 'delivered',
  Unknown = 'unknown',
}

export type PriceRecord = { value: number; currency: string };

export type HalalShopCartItem = {
  buy_price: { value: number; currency: string };
  offer_id: number;
  product_id: number;
  quantity: number;
};

export type DeliveryRecord = { name: string; price: PriceRecord };

export type HalalShopOrderDetailResponse = {
  id: number;
  address: { apt: string; house: string; postcode: string; street: string };
  data: HalalShopCartItem[];
  delivery: DeliveryRecord;
  price: PriceRecord;
  status: HalalShopOrderStatus;
  user: UserInfoRecord;
};

export type ShopOrdersRecord = {
  address: string;
  created_at: Date | string;
  data: HalalShopCartItem[];
  id: number;
  name: string;
  phone: number;
  price: PriceRecord;
  quantity: number;
  status: HalalShopOrderStatus;
};

export type ShopOrdersProduct = {
  brand: string | null;
  category: number | null;

  id: number;
  image: string;
  is_new: boolean;
  name: string;
  offer: any | null;

  percent: number | null;
  price: { value: number; currency: string };
  price_old: number;
  quantity: number;
  season: number;
};

export type GetHalalShopOrdersResponse =
  ApiPaginationSecondaryResponse<ShopOrdersRecord>;

export type GetHalalShopProductsResponse =
  ApiPaginationSecondaryResponse<ShopOrdersProduct>;

export type GetHalalShopProductsRequest = { limit?: number; offset?: number };

// PRODUCT DETAIL

export type BrandRecord = {
  id: ApiRecordId;
  name: string;
  image: ApiImageUrl;
  categories: ApiRecordId[];
  main_category: CategoryRecord;
};

export type CategoryRecord = {
  id: ApiRecordId;
  title: string;
  alias: string;
  content: string;
  position: number;
  image: ApiImageUrl;
  parent_path: CategorySmallRecord[] | null;
};
export type CategorySmallRecord = {
  id: ApiRecordId;
  title: string;
  alias: string;
};

export type ProductPrice = { currency: string; value: number };

export type ProductColorVariant = {
  name: string;
  color: string;
  product: ApiRecordId;
  product_image: ApiImageUrl | null;
};

export type ProductOffer = {
  id: ApiRecordId;
  price: ProductPrice;
  oldPrice: number | null;
  quantity: number;
  size: string;
  sky: number;
};

export enum ProductSeasonEnum {
  Summer = 0,
  DemiSeason = 1,
  Winter = 2,
}

export enum ProductGenderStringEnum {
  Male = 'male',
  Female = 'female',
  Boy = 'boy',
  Girl = 'girl',
}

export type ProductDetailRecord = {
  brand: BrandRecord | null;
  category: CategoryRecord | null;
  certificate: string[] | null;
  consist: string | null;
  description: string | null;
  ext_id: string; // Сторонний код
  gender: ProductGenderStringEnum | null;
  id: ApiRecordId;
  image: ApiImageUrl | null;
  is_active: boolean | null;
  is_new: boolean;
  manufacturer: string | null;
  name: string;
  offers: ProductOffer[];
  params: string | null;
  photos: ApiImageUrl[] | null;
  price: ProductPrice;
  price_old: number | null;
  product_color: ProductColorVariant[] | null;
  quantity: number;
  season: ProductSeasonEnum | null;
  shipping: boolean;
  show_always: boolean | null;
  sku: string; //Артикль
  video: string | null;
};

export type GetProductDetailRequest = {
  id: ApiRecordId;
  currency: CurrencyRequestTypes;
};

export type GetProductDetailResponse = ProductDetailRecord;

export type ChangeProductDetailResponse = void;

export type ChangeProductDetailRequest = Omit<
  ProductDetailRecord,
  'category'
> & {
  category: number;
};
