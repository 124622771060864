import { Typography } from '../Typography';

import styled from 'styled-components';
export const StyledDescription = styled(Typography).attrs({
  size: 16,
  color: 'gray0',
})`
  margin-bottom: 20px;
`;

export const StyledButtons = styled.div`
  display: flex;
  gap: 15px;
`;
