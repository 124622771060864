import {
  StyledLoginForm,
  StyledSubmitButton,
  StyledValuesLayout,
} from './LoginForm.styles';

import { LoginFormValues } from '../types';

import { FormError } from '@components';
import { useUncontrolledFormContext } from '@hooks/useUncontrolledFromContext';
import { UserIcon } from '@packages/icons';
import { InputFieldAdapter } from '@packages/uiKit/fieldAdapters/InputFieldAdapter';
import { InputPasswordFieldAdapter } from '@packages/uiKit/fieldAdapters/InputPasswordFieldAdapter';
import React, { FC } from 'react';

export interface LoginFormProps {
  className?: string;
  style?: React.CSSProperties;

  isLoading: boolean;
}

export const LoginForm: FC<LoginFormProps> = (props) => {
  const { isLoading, ...restProps } = props;

  const {
    control,
    handleSubmit,
    formError,
    formState: { isSubmitting },
  } = useUncontrolledFormContext<LoginFormValues>();

  return (
    <StyledLoginForm {...restProps}>
      <StyledValuesLayout>
        <InputFieldAdapter
          control={control}
          name="login"
          label="Логин"
          right={<UserIcon size={24} />}
        />
        <InputPasswordFieldAdapter
          control={control}
          name="password"
          label="Ваш пароль"
          type={'password'}
          placeholder="················"
        />

        <FormError message={formError} />
      </StyledValuesLayout>

      <StyledSubmitButton
        onClick={handleSubmit}
        loading={isSubmitting}
        disabled={isLoading}>
        Войти
      </StyledSubmitButton>
    </StyledLoginForm>
  );
};
