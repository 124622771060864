import { StyledItem } from './styles';

import { GridBorder } from '../types';

import React, { FC, PropsWithChildren } from 'react';

export interface ItemProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
  isFirstRow: boolean;
  isLastRow: boolean;
  border: GridBorder;
}

export const Item: FC<ItemProps> = (props) => {
  const { isFirstRow, border, isLastRow, ...restProps } = props;
  return (
    <StyledItem
      $isFirstRow={isFirstRow}
      $isLastRow={isLastRow}
      $border={border}
      {...restProps}
    />
  );
};
