import { ReviewsPageTabs } from './types';

import { TabsTabItem } from '@components';

export const tabs: TabsTabItem<ReviewsPageTabs>[] = [
  {
    value: ReviewsPageTabs.All,
    label: 'Все',
  },
  {
    value: ReviewsPageTabs.Positive,
    label: 'Позитивные',
  },
  {
    value: ReviewsPageTabs.Average,
    label: 'Средние',
  },

  {
    value: ReviewsPageTabs.Negative,
    label: 'Негативные',
  },
  {
    value: ReviewsPageTabs.NoAnswer,
    label: 'Без ответа',
  },
];

export const tabsFilterDict: Record<
  ReviewsPageTabs,
  {
    is_replied?: boolean;
    rating?: 'positive' | 'negative' | 'medium';
  }
> = {
  all: {},
  average: {
    rating: 'medium',
  },
  negative: {
    rating: 'negative',
  },
  positive: {
    rating: 'positive',
  },
  noAnswer: {
    is_replied: false,
  },
};
