import { HalalShopOrderStatus } from '@features/halalShop/store/types';
import { StyledButtonsContainer } from './styles';

import { Button } from '@packages/uiKit';
import { useMemo } from 'react';

const statusButtonsDict: Record<
  HalalShopOrderStatus,
  ('confirm' | 'shippingConfirm' | 'cancel' | 'save')[]
> = {
  [HalalShopOrderStatus.Assembly]: ['confirm', 'cancel'],
  [HalalShopOrderStatus.AwaitingShipment]: ['shippingConfirm'],
  [HalalShopOrderStatus.Delivered]: ['save'],
  [HalalShopOrderStatus.New]: ['save'],
  [HalalShopOrderStatus.Canceled]: ['save'],
  [HalalShopOrderStatus.Delivering]: ['save'],
  [HalalShopOrderStatus.Unknown]: ['save'],
};

export type UseGetActionButtonsParams = {
  status: HalalShopOrderStatus;
  onConfirm: () => void;
  onCancel: () => void;
  onShippingConfirm: () => void;
  onSave: () => void;
};

export const useGetActionButtons = (params: UseGetActionButtonsParams) => {
  const { status, onConfirm, onShippingConfirm, onCancel, onSave } = params;

  const actionsButtons = useMemo(() => {
    const buttons = statusButtonsDict[status];
    return (
      <StyledButtonsContainer>
        {buttons.map((btn, index) => {
          switch (btn) {
            case 'confirm': {
              return (
                <Button
                  key={index}
                  variant="filled"
                  size="large"
                  onClick={onConfirm}>
                  Подтвердить заказ
                </Button>
              );
            }
            case 'cancel': {
              return (
                <Button
                  key={index}
                  variant="filled"
                  size="large"
                  color="secondary"
                  onClick={onCancel}>
                  Оменить заказ
                </Button>
              );
            }
            case 'shippingConfirm': {
              return (
                <Button
                  key={index}
                  variant="filled"
                  size="large"
                  color="primary"
                  onClick={onShippingConfirm}>
                  Подтвердить отправку
                </Button>
              );
            }
            case 'save': {
              return (
                <Button
                  key={index}
                  variant="filled"
                  size="large"
                  color="primary"
                  onClick={onSave}>
                  Сохранить изменения
                </Button>
              );
            }
            default:
              return null;
          }
        })}
      </StyledButtonsContainer>
    );
  }, [onCancel, onConfirm, onSave, onShippingConfirm, status]);

  return actionsButtons;
};
