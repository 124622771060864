import { Pagination } from '@packages/uiKit/Pagination';
import { Typography } from '@packages/uiKit/Typography';
import { colors } from '@packages/uiKit/utils/colors';
import { scrollbarStylesLightVariant } from '@utils/scrollBarStyles';
import styled, { css } from 'styled-components';

export const StyledContainer = styled.div``;

export const StyledTableWrapper = styled.div`
  padding-bottom: 10px;
`;
export const StyledTable = styled.div<{ $enableScroll: boolean }>`
  font-size: 14px;

  ${scrollbarStylesLightVariant}
  padding-bottom: 15px;

  ${({ $enableScroll }) =>
    $enableScroll &&
    css`
      overflow: auto;
    `}
`;

export const StyledTableHead = styled.div``;

export const StyledTableDataCell = styled.div`
  :not(:last-child) {
    padding-right: 11px;
  }
`;

export const StyledTableDataCellContentWrapper = styled.div<{
  $isOverflow: boolean;
}>`
  padding: 10px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ $isOverflow }) =>
    $isOverflow
      ? css`
          overflow: hidden;
        `
      : css`
          overflow: visible;
        `}
`;

export const StyledHeaderRow = styled.div``;

export const StyledTableBodyRow = styled.div`
  width: fit-content;
  min-width: 100% !important;

  :nth-child(odd) {
    ${StyledTableDataCellContentWrapper} {
      background-color: white;
      border-radius: 4px;
    }
  }

  :nth-child(even):last-child {
    ${StyledTableDataCellContentWrapper} {
      border-bottom: 1px solid ${colors.gray1};
    }
  }
`;

export const StyledTableHeader = styled(Typography).attrs({
  color: 'gray1',
})`
  padding: 10px 0;
  text-align: left;
`;

export const StyledTableBody = styled.div``;

export const StyledPagination = styled(Pagination)`
  margin-top: 36px;
`;
