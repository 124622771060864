import { Button, Image, Typography, colors } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledInAppPreview = styled.div`
  position: absolute;
  right: 30%;
  top: 20%;

  width: 270px;
  background: ${colors.white};

  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  padding-bottom: 15px;
`;

export const StyledImage = styled(Image)`
  width: 270px;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 10px; /* Adjust the radius as needed */
  border-top-right-radius: 10px; /* Adjust the radius as needed */
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;

export const StyledTitle = styled(Typography).attrs({
  size: 16,

  maxLines: 3,
  ellipsis: true,
})`
  text-align: center;
  width: 270px;

  padding-left: 15px;
  padding-right: 15px;
`;

export const StyledMainText = styled(Typography).attrs({
  size: 12,

  maxLines: 4,
  ellipsis: true,
})`
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 270px;
`;

export const StyledButtonContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  padding: 10px 0px 10px 0px;
`;
