import { DeliveryCell } from './DeliveryCell';

import { AmountCell } from '@components/table/AmountCell';
import { BoldTextCell } from '@components/table/BoldTextCell';
import { DateCell } from '@components/table/DateCell';
import {
  HalalEdaOrderRecord,
  HalalEdaOrderStatus,
} from '@features/halalEda/orders';
import {
  OrderStatusCell,
  OrderStatusCellProps,
} from '@features/halalEda/orders/components/OrderStatusCell';

import { TableColumn } from '@packages/uiKit/Table';
import { DeliveryUntilTimeCell } from './DeliveryUntilTimeCell';

type GetColumnsParams = {
  onChangeStatus: (
    record: HalalEdaOrderRecord,
    newStatus: HalalEdaOrderStatus,
  ) => void;
};
export const getColumns = (
  params: GetColumnsParams,
): TableColumn<HalalEdaOrderRecord>[] => [
  {
    Header: 'Статус',
    accessor: 'status',
    width: 200,
    overflow: false,
    Cell: ({ value, row: { original } }) => {
      const changeHandler: OrderStatusCellProps['onChange'] = (value) => {
        params.onChangeStatus(original, value);
      };
      return <OrderStatusCell status={value} onChange={changeHandler} />;
    },
  },
  {
    Header: 'Номер заказа',
    accessor: 'id',
    Cell: ({ value }) => <BoldTextCell>{value}</BoldTextCell>,
  },
  {
    Header: 'Сумма',
    accessor: 'cost',
    Cell: ({ value }) => <AmountCell amount={value} />,
  },
  {
    Header: 'Заказ',
    accessor: 'cart',
    overflow: false,
    Cell: ({ value }) => <DeliveryCell value={value} />,
  },
  {
    Header: 'Время',
    accessor: 'created',
    Cell: ({ value }) => <DateCell date={value} format="dateTime" />,
  },
  {
    Header: 'Адрес доставки',
    accessor: 'address',
    width: 320,
  },
  {
    Header: 'Клиент',
    accessor: (record) => record.client.full_name,
  },
  {
    Header: 'Номер телефона',
    accessor: (record) => record.client.tel,
    width: 200,
  },
  {
    Header: 'Доставка до',
    accessor: 'when',
    Cell: ({ value }) => <DeliveryUntilTimeCell date={value} />,
    width: 100,
  },
];
