import { CloseIcon } from '@packages/icons';
import { Button, colors, Image, Typography } from '@packages/uiKit';

import styled from 'styled-components';

export const StyledHalalShopProductRow = styled.div`
  display: flex;
  gap: 15px;
`;

export const StyledImage = styled(Image)`
  height: auto;
  width: 58px;
  min-width: 58px;

  border-radius: 10px;
  object-fit: cover;
`;

export const StyledMiddle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
`;

export const StyledRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

export const StyledName = styled(Typography).attrs({
  maxLines: 2,
  ellipsis: true,
})`
  margin-bottom: 2px;
`;

export const StyledCode = styled(Typography).attrs({
  nowrap: true,
  ellipsis: true,
  size: 12,
  color: 'gray1',
})`
  margin-bottom: 3px;
`;

export const StyledCost = styled(Typography).attrs({
  nowrap: true,
  size: 16,
  weight: 700,
})``;

export const StyledCountTag = styled(Typography).attrs({ size: 12 })`
  padding: 4px 12px;
  border: 1px solid ${colors.gray2};
  border-radius: 12px;
`;

export const StyledCloseButton = styled(Button).attrs({
  variant: 'text',
  icon: <CloseIcon />,
})`
  padding: 0;
`;
