import { StyledOrdersDeliveredCard } from './OrdersDeliveredCard.styles';

import { InfoCardProps } from '../common/InfoCard';

import { SmileyIcon } from '@packages/icons';
import React, { FC, memo } from 'react';

export interface OrdersDeliveredCardProps
  extends Pick<InfoCardProps, 'value' | 'percent'> {
  className?: string;
  style?: React.CSSProperties;
}

const _OrdersDeliveredCard: FC<OrdersDeliveredCardProps> = (props) => {
  const { ...restProps } = props;

  return (
    <StyledOrdersDeliveredCard
      {...restProps}
      icon={<SmileyIcon />}
      description={'Заказов доставили'}
      color="primary"
    />
  );
};

export const OrdersDeliveredCard = memo(_OrdersDeliveredCard);
