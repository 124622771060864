import {
  ImageListWithUploadField,
  ImageListWithUploadFieldProps,
} from '@packages/uiKit/fields/ImageListWithUploadField';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import { Controller } from 'react-hook-form';

export const ImageListWithUploadFieldAdapter: FieldAdapterFCComponent<
  Omit<ImageListWithUploadFieldProps, 'images' | 'onUpload'>
> = (props) => {
  const {
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onBlur: _, ...restField },
        fieldState: { error },
      }) => {
        return (
          <ImageListWithUploadField
            {...inputProps}
            {...restField}
            error={error?.message}
          />
        );
      }}
    />
  );
};
