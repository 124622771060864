import { cuisinesApi } from '@features/cuisines/store';
import { MultiselectOption } from '@packages/uiKit';
import {
  MultiselectFieldAdapter,
  MultiselectFieldAdapterProps,
} from '@packages/uiKit/fieldAdapters/MultiselectFieldAdapter';
import { memo, useMemo } from 'react';
import { FieldValues, Path } from 'react-hook-form';

export interface CuisinesMultiselectFieldAdapterProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> extends Omit<MultiselectFieldAdapterProps<TFieldValues, TName>, 'options'> {}

const _CuisinesMultiselectFieldAdapter = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>(
  props: CuisinesMultiselectFieldAdapterProps<TFieldValues, TName>,
) => {
  const { data } = cuisinesApi.useGetCuisinesQuery();

  const options: MultiselectOption<number>[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((certCenter) => ({
      value: certCenter.id,
      label: certCenter.name,
    }));
  }, [data]);

  return <MultiselectFieldAdapter options={options} {...props} />;
};

export const CuisinesMultiselectFieldAdapter = memo(
  _CuisinesMultiselectFieldAdapter,
) as typeof _CuisinesMultiselectFieldAdapter;
