import { selectIsAuth } from '@features/auth';
import { useAppSelector } from '@features/store';
import { useNavigate } from 'react-router-dom';

export const useAuthProtection = () => {
  const navigate = useNavigate();
  const isAuth = useAppSelector(selectIsAuth);

  if (!isAuth) {
    navigate('/login');
  }
};
