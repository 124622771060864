import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledTitleAverageRate = styled(Typography).attrs({
  size: 28,
  weight: 700,
  forwardedAs: 'span',
})``;

export const StyledTitleRateDynamic = styled(Typography).attrs({
  size: 28,
  forwardedAs: 'span',
})``;
