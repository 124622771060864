import { TabsTabItem } from '@components';

export const statusTabs: TabsTabItem[] = [
  {
    value: 'active',
    label: 'Активные',
  },
  {
    value: 'completed',
    label: 'Завершенные',
  },
];
