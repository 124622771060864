import { getCurrencySymbol } from '@features/currency/hooks/getCurrencySymbol';
import {
  StyledCartCell,
  StyledItem,
  StyledPrice,
  StyledQuantity,
  StyledText,
  StyledTitle,
  StyledTitleCell,
} from './styles';

import { HalalShopCartItem } from '@features/halalShop/store/types';
import { DropdownIconButton } from '@packages/uiKit/DropdownIconButton';
import { FC, memo, useCallback, useMemo, useState } from 'react';

export interface CartCellProps {
  items: HalalShopCartItem[];
}

const _CartCell: FC<CartCellProps> = (props) => {
  const { items, ...restProps } = props;

  const [isVisible, setIsVisible] = useState(false);

  const onClickHandler: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
      setIsVisible((prev) => !prev);
    },
    [],
  );

  const dropdown = useMemo(() => {
    return (
      <>
        {items.map((item) => {
          return (
            <StyledItem key={item.offer_id}>
              <StyledTitleCell>
                <StyledTitle>{item.product_id}</StyledTitle>
              </StyledTitleCell>
              <StyledQuantity>{item.quantity} шт.</StyledQuantity>
              <StyledPrice>
                {item.buy_price.value.toLocaleString()}
                {getCurrencySymbol(item.buy_price.currency.toLocaleString())}
              </StyledPrice>
            </StyledItem>
          );
        })}
      </>
    );
  }, [items]);

  return (
    <StyledCartCell {...restProps} onClick={onClickHandler}>
      <StyledText>{items.length} · позиций</StyledText>
      <DropdownIconButton
        setIsVisible={setIsVisible}
        dropdown={dropdown}
        placement="right"
        isVisible={isVisible}
      />
    </StyledCartCell>
  );
};

export const CartCell = memo(_CartCell);
