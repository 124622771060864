import {
  UploadImageInputField,
  UploadImageInputFieldProps,
} from '@packages/uiKit/fields/UploadImageInputField';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import React from 'react';
import { Controller } from 'react-hook-form';

const _UploadImageInputFieldAdapter: FieldAdapterFCComponent<
  UploadImageInputFieldProps
> = (props) => {
  const {
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <UploadImageInputField
            {...inputProps}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        );
      }}
    />
  );
};

export const UploadImageInputFieldAdapter = React.memo(
  _UploadImageInputFieldAdapter,
) as typeof _UploadImageInputFieldAdapter;
