import { StyledLoader } from './styles';

import { colors } from '@packages/uiKit';
import React, { FC } from 'react';

import { PuffLoader, PulseLoader } from 'react-spinners';

export interface LoaderProps {
  className?: string;
  style?: React.CSSProperties;

  size?: number;
  color?: string;
  loaderVariant?: 'puff' | 'bar';

  margin?: [number, number, number, number];
}

export const Loader: FC<LoaderProps> = (props) => {
  const {
    size = 50,
    color = `${colors.primary}`,
    margin = [0, 0, 0, 0],
    loaderVariant = 'puff',
    ...restProps
  } = props;

  return (
    <>
      <StyledLoader {...restProps} $margin={margin}>
        {loaderVariant === 'puff' && (
          <PuffLoader
            color={color}
            loading={true}
            size={size}
            cssOverride={{ background: 'transparent' }}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
        {loaderVariant === 'bar' && (
          <PulseLoader
            color={color}
            loading={true}
            size={size ? size : 13}
            cssOverride={{ background: 'transparent' }}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </StyledLoader>
    </>
  );
};
