import { OrderDetailTab } from './types';

import { ClientTab } from '../ClientTab';
import { MenuSection } from '../MenuSection';

import { HalalEdaOrderRecord } from '@features/halalEda';
import React from 'react';

type SharedTabsProps = {
  mutableRecord: HalalEdaOrderRecord;
};

const getSectionToComponentDict = (
  props: SharedTabsProps,
): Record<OrderDetailTab, React.ReactNode> => {
  return {
    client: <ClientTab order={props.mutableRecord} />,
    menu: <MenuSection />,
    history: null,
  };
};

export const getSection = (tab: OrderDetailTab, props: SharedTabsProps) => {
  return getSectionToComponentDict(props)[tab];
};
