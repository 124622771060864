import {
  StyledLegendContainer,
  StyledOrderDynamicCard,
} from './OrderDynamicCard.styles';

import { LegendItem } from '../common/LegendItem';

import { Column, ColumnConfig } from '@ant-design/plots';
import { GetHalalEdaStatsPageResponse } from '@features/halalEda';
import { colors } from '@packages/uiKit';
import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';
import { periodTabs } from '@utils/types';
import React, { FC, memo } from 'react';

export interface OrderDynamicCardProps {
  className?: string;
  style?: React.CSSProperties;

  dataItems: GetHalalEdaStatsPageResponse;
}

type DataItem = {
  period: string;
  amount: number;
  isTheBest?: boolean;
};

// const data: DataItem[] = [
//   {
//     period: 'Ноя. 2021',
//     amount: 1,
//   },
//   {
//     period: 'Сен. 2022',
//     amount: 10,
//   },
// ];

const _OrderDynamicCard: FC<OrderDynamicCardProps> = (props) => {
  const { dataItems, ...restProps } = props;

  const { bar_chart_data } = dataItems;

  const sumAverage = formatPriceVisualFriendly(bar_chart_data.average);
  const sumBestMonth = formatPriceVisualFriendly(bar_chart_data.best_month.sum);

  const bestMonth = bar_chart_data.best_month.month
    ? periodTabs.find((item) => {
        return bar_chart_data.best_month.month.toString() === item.value;
      })
    : { value: 'none', label: 'Неизвестно' };

  const data = bar_chart_data.chart.map((monthItem) => {
    const convertedNumberToMonth = periodTabs.find((item) => {
      return monthItem.month.toString() === item.value;
    });

    return {
      period: convertedNumberToMonth?.label,
      amount: monthItem.sum,
      isTheBest:
        Number(convertedNumberToMonth?.value) ===
        bar_chart_data.best_month.month
          ? true
          : false,
    };
  });

  const config: ColumnConfig = {
    data,
    height: 250,
    xField: 'period',
    yField: 'amount',
    legend: false as const,
    yAxis: {
      maxLimit: bar_chart_data.best_month.sum,
    },
    seriesField: 'isTheBest',

    color: (item) => {
      if ((item as DataItem).isTheBest) {
        return colors.primaryDark3;
      }

      return colors.primary;
    },
    tooltip: {
      fields: ['amount'],
    },

    meta: {
      amount: {
        alias: 'Прибыль',
      },
    },
  };

  return (
    <StyledOrderDynamicCard
      title="Динамика заказов за 2022 год"
      right={
        <StyledLegendContainer>
          {/*           <LegendItem
            color={colors.primary}
            value={'3 980 033 ₸'}
            description={'Всего за год'}
          /> */}
          <LegendItem
            color={colors.primaryDark3}
            value={`${sumAverage} ₸`}
            description={'Среднее за год'}
          />
          <LegendItem
            color={colors.primaryDark3}
            value={`${sumBestMonth} ₸`}
            description={`Лучший месяц - ${bestMonth?.label}`}
          />
        </StyledLegendContainer>
      }
      {...restProps}>
      <Column {...config} />
    </StyledOrderDynamicCard>
  );
};

export const OrderDynamicCard = memo(_OrderDynamicCard);
