import { colors } from '../utils';

import { ReactComponent as CameraSvg } from '@assets/svgs/camera.svg';
import styled from 'styled-components';

export const StyledUploadButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: ${colors.dark1};
`;

export const StyledLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledCameraIcon = styled(CameraSvg)`
  min-width: 18px;
  min-height: 18px;
`;
