import {
  StyledButtonsContainer,
  StyledContactsAndWorkTimeArea,
  StyledContentForm,
  StyledFieldsLayout,
  StyledInfoSection,
  StyledPhotoSectionsArea,
} from './ContentForm.styles';

import { WorkTime } from './WorkTime';

import { Section } from '@components/Section';
import { EstablishmentCategoriesSelectFieldAdapter } from '@features/establishmentCategories';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { useUncontrolledFormContext } from '@hooks/useUncontrolledFromContext';
import { Button } from '@packages/uiKit/Button';
import { Field } from '@packages/uiKit/Field';
import { ImageListWithUploadFieldAdapter } from '@packages/uiKit/fieldAdapters/ImageListWithUploadFieldAdapter';
import { InputFieldAdapter } from '@packages/uiKit/fieldAdapters/InputFieldAdapter';
import { InputPhoneNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputPhoneNumberFieldAdapter';
import { TextAreaFieldAdapter } from '@packages/uiKit/fieldAdapters/TextAreaFieldAdapter';
import { UploadImageInputFieldAdapter } from '@packages/uiKit/fieldAdapters/UploadImageInputFieldAdapter';
import { TextAreaField } from '@packages/uiKit/fields/TextAreaField';

import { EstablishmentEditFormValues } from '@pages/EstablishmentPage/EstablishmentForm/types';
import { convertHTMLToText } from '@utils/convertHTMLToText';
import React, { FC, useCallback } from 'react';
import { Controller } from 'react-hook-form';

export interface ContentFormProps {
  className?: string;
  styles?: React.CSSProperties;
}

export const ContentForm: FC<ContentFormProps> = (props) => {
  const {
    control,
    handleSubmit,
    handleReset,
    formState: { isSubmitting, isDirty },
  } = useUncontrolledFormContext<EstablishmentEditFormValues>();

  const notification = useNotification();

  const resetHandler = useCallback(() => {
    handleReset();
    notification({ type: 'info', message: 'Форма отчищена от изменений' });
  }, [handleReset, notification]);

  return (
    <StyledContentForm {...props}>
      <StyledFieldsLayout>
        <StyledInfoSection title="Информация">
          <InputFieldAdapter
            control={control}
            name="name"
            label="Название заведения"
            placeholder="Введите название заведения"
          />
          <TextAreaFieldAdapter
            control={control}
            name="short"
            label="Краткое описание"
            placeholder="Введите краткое описание"
            isAutosize
          />
          <EstablishmentCategoriesSelectFieldAdapter
            control={control}
            name="category"
            label="Категория заведения"
            placeholder="Выберите категорию"
          />

          <UploadImageInputFieldAdapter
            control={control}
            name="logo"
            isCropable
            aspectRatio={1}
            cropType="image/jpeg"
            preview="icon">
            Загрузить логотип заведения
          </UploadImageInputFieldAdapter>

          <Controller
            control={control}
            name="description"
            render={({ field, fieldState: { error } }) => {
              return (
                <TextAreaField
                  label="Полное описание заведения"
                  placeholder="Введите полное описание"
                  value={convertHTMLToText(field.value ?? '') ?? ''}
                  isAutosize
                  error={error?.message}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </StyledInfoSection>

        <StyledContactsAndWorkTimeArea>
          <Section title="Контакты">
            <InputPhoneNumberFieldAdapter
              control={control}
              name="tel"
              label="Номер телефона заведения"
              placeholder="8 (999) 999-99-99"
            />
            <InputFieldAdapter
              control={control}
              name="mail"
              label="Электронная почта"
              placeholder="name@mail.com"
            />
            <InputFieldAdapter
              control={control}
              name="site"
              label="Адрес сайта"
              placeholder="https://site.com"
            />
          </Section>

          <Section title={'Время работы'}>
            <Controller
              control={control}
              name="work_time"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Field error={error?.message}>
                    <WorkTime value={value} onChange={onChange} />
                  </Field>
                );
              }}
            />
          </Section>
        </StyledContactsAndWorkTimeArea>

        <StyledPhotoSectionsArea>
          <Section title="Основное фото">
            <UploadImageInputFieldAdapter
              control={control}
              name="photo"
              label="Фото в общем каталоге"
              preview="card">
              Загрузить фото
            </UploadImageInputFieldAdapter>
          </Section>

          <Section title="Фотогалерея">
            <ImageListWithUploadFieldAdapter
              control={control}
              name="place_photo"
              label="Можно загрузить не более 6 фото"
              maxListSizeVisible={3}
              max={6}
            />
          </Section>
        </StyledPhotoSectionsArea>
      </StyledFieldsLayout>

      <StyledButtonsContainer>
        <Button
          size="large"
          variant="filled"
          onClick={handleSubmit}
          disabled={isSubmitting}
          width={240}
          loading={isSubmitting}>
          {isSubmitting ? 'Загрузка' : 'Сохранить изменения'}
        </Button>
        <Button
          size="large"
          variant="outlined"
          onClick={resetHandler}
          disabled={!isDirty}>
          Отменить изменения
        </Button>
      </StyledButtonsContainer>
    </StyledContentForm>
  );
};
