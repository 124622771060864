import { colors, Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledMessageWithIcon = styled.div`
  display: flex;
  gap: 11px;
`;

export const StyledIconContainer = styled.div`
  width: 38px;
  height: 38px;
  min-width: 38px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: ${colors.white};
  background-color: ${colors.dark1};
`;

export const StyledRight = styled.div``;

export const StyledTitle = styled(Typography).attrs({
  size: 16,
})``;

export const StyledDescription = styled(Typography).attrs({
  size: 12,
  color: 'gray1',
})``;
