import {
  ToDetailCell,
  ToDetailCellProps,
} from '@components/table/ToDetailCell';
import { formatDate } from '@utils/dateFormat';
import { FC } from 'react';

export interface DeliveryUntilTimeCellProps
  extends Omit<ToDetailCellProps, 'children'> {
  date: string;
}

export const DeliveryUntilTimeCell: FC<DeliveryUntilTimeCellProps> = (
  props,
) => {
  const { date } = props;

  const timeString = formatDate(date, 'time');

  return <ToDetailCell>{timeString}</ToDetailCell>;
};
