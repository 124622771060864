import { Section } from '@components/Section';
import { media } from '@packages/uiKit/utils/breakpoints';
import styled from 'styled-components';

export const StyledContentForm = styled.div``;

export const StyledFieldsLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-template-areas: 'info contacts-time photos';

  column-gap: 60px;
  row-gap: 15px;

  & > * {
    min-width: 0;
  }

  ${media.down('xxl')} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'info'
      'contacts-time'
      'photos';
  }
`;

export const StyledButtonsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 15px;
`;

export const StyledInfoSection = styled(Section)`
  grid-area: info;
`;

export const StyledPhotoSectionsArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: photos;
`;

export const StyledContactsAndWorkTimeArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: contacts-time;
`;
