import { HalalEdaOrderStatus } from '../../store';

import {
  SelectCellWithIcon,
  SelectCellWithIconConfig,
} from '@components/table';
import {
  AlarmIcon,
  CheckCircleOutlinedIcon,
  ForkKnifeIcon,
  ProhibitIcon,
} from '@packages/icons';
import { colors } from '@packages/uiKit/utils/colors';
import { FC, memo, useCallback } from 'react';

// const statusPropsDict: Record<
//   OrderStatus,
//   {
//     icon: ReactNode;
//     iconBackgroundColor: string;
//     iconColor: string;
//     iconBorderColor: string;
//     textColor: string;
//   }
// > = {
//   inProcessing: {
//     icon: <AlarmSvg />,
//     iconBackgroundColor: colors.danger,
//     iconColor: colors.white,
//     iconBorderColor: colors.danger,
//     textColor: colors.dark1,
//   },
//   isBeingPrepared: {
//     icon: <ForkKnifeSvg />,
//     iconBackgroundColor: colors.warning,
//     iconColor: colors.dark1,
//     iconBorderColor: colors.warning,
//     textColor: colors.dark1,
//   },
//   cancelled: {
//     icon: <ProhibitIcon />,
//     iconBackgroundColor: colors.gray1,
//     iconColor: colors.white,
//     iconBorderColor: colors.gray1,
//     textColor: colors.gray1,
//   },
//   isDelivered: {
//     icon: <CarSvg />,
//     iconBackgroundColor: colors.primary,
//     iconColor: colors.white,
//     iconBorderColor: colors.primary,
//     textColor: colors.dark1,
//   },
//   ready: {
//     icon: <HandbagIcon />,
//     iconBackgroundColor: colors.dark1,
//     iconColor: colors.white,
//     iconBorderColor: colors.dark1,
//     textColor: colors.dark1,
//   },
//   delivered: {
//     icon: <CheckCircleOutlinedIcon />,
//     iconBackgroundColor: 'transparent',
//     iconColor: colors.gray1,
//     iconBorderColor: colors.gray1,
//     textColor: colors.gray1,
//   },
// };

const config: SelectCellWithIconConfig<HalalEdaOrderStatus> = [
  {
    value: HalalEdaOrderStatus.Paid,
    label: 'Оплачен', // "Новый"
    icon: <AlarmIcon />,
    bgColor: colors.danger,
    color: colors.white,
  },
  {
    value: HalalEdaOrderStatus.UnPaid,
    label: 'Не оплачен',
    icon: <ForkKnifeIcon />,
    bgColor: colors.warning,
    color: colors.dark1,
  },
  {
    value: HalalEdaOrderStatus.InProgress,
    label: 'В процессе',
    icon: <ForkKnifeIcon />,
    bgColor: colors.warning,
    color: colors.dark1,
  },
  {
    value: HalalEdaOrderStatus.Cancelled,
    label: 'Отменен',
    icon: <ProhibitIcon />,
    bgColor: colors.gray1,
    color: colors.white,
  },
  {
    value: HalalEdaOrderStatus.Completed,
    label: 'Выполнен',
    icon: <CheckCircleOutlinedIcon />,
    bgColor: 'transparent',
    color: colors.gray1,
    borderColor: colors.gray1,
  },
];

export interface OrderStatusCellProps {
  status: HalalEdaOrderStatus;
  onChange: (value: HalalEdaOrderStatus) => void;
}

const _OrderStatusCell: FC<OrderStatusCellProps> = (props) => {
  const { status, onChange } = props;

  const clickHandler: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
    },
    [],
  );

  return (
    <SelectCellWithIcon<HalalEdaOrderStatus>
      value={status}
      config={config}
      onClick={clickHandler}
      onChange={onChange}
    />
  );
};

export const OrderStatusCell = memo(_OrderStatusCell);
