import React, { FC, useCallback, useState } from 'react';

import { Section } from '@components';
import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';
import { greenBonusClientsApi } from '@features/halalBonus/clients/store';
import { greenBonusTransactionsApi } from '@features/halalBonus/transactions';
import { ReviewAnswerModal, ReviewRecord, reviewsApi } from '@features/reviews';
import { useModalState } from '@hooks/useModalState';
import { MainLayout } from '@layouts/MainLayout';
import { HalalBonusTransactionsTable } from '@pages/HalalBonusPage/sections/TransactionsSection/HalalBonusTransactionsTable';
import { ReviewTableProps } from '@pages/ReviewsPage/ReviewTable';
import { useParams } from 'react-router-dom';
import { MainInfoClient } from './components/MainInfoClient';
import { UserReviewsSection } from './components/UserReviewsSection';
import {
  StyledBottomRow,
  StyledContainer,
  StyledHalalBonusClientDetailPage,
  StyledTopLeftContainer,
  StyledTopRightContainer,
  StyledTopRow,
} from './styles';

export interface HalalBonusClientDetailPageProps {
  className?: string;
  style?: React.CSSProperties;
}

export const HalalBonusClientDetailPage: FC<HalalBonusClientDetailPageProps> = (
  props,
) => {
  const { ...restProps } = props;

  const params = useParams();

  const id = Number(params.id!);

  const [page, setPage] = useState(1);

  const {
    data: transactionPageData,
    isLoading: transactionPageDataIsLoading,
    isSuccess: transactionPageDataIsSuccess,
    isFetching: transactionPageDataIsFetching,
  } = greenBonusTransactionsApi.useGetTransactionPageQuery({
    page: page,
    perPage: 6,
    user: id,
  });

  const { data, isLoading, isSuccess } =
    greenBonusClientsApi.useGetClientDetailQuery({ id });

  const {
    data: reviewsData,
    isSuccess: isReviewsSuccess,
    isLoading: isReviewsLoading,
  } = reviewsApi.useGetReviewPageQuery({ user: id });

  const isNoDataReviews =
    !isReviewsLoading && isReviewsSuccess && reviewsData.result.length === 0;

  const [reviewAnswerModalState, reviewModalActions] = useModalState<{
    reviewItem: ReviewRecord;
  }>();

  const onClickMakeAnswerHandler: ReviewTableProps['onClickMakeAnswer'] =
    useCallback(
      (reviewItem) => {
        reviewModalActions.openHandler({ reviewItem });
      },
      [reviewModalActions],
    );

  return (
    <StyledHalalBonusClientDetailPage {...restProps}>
      <MainLayout.Content.BackLink>Клиенты</MainLayout.Content.BackLink>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess && (
          <StyledContainer>
            <StyledTopRow>
              <StyledTopLeftContainer>
                <MainInfoClient data={data} />
              </StyledTopLeftContainer>
              <StyledTopRightContainer>
                <Section title={`Отзывы · ${data.name}`}>
                  {isReviewsLoading ? (
                    <Loader />
                  ) : (
                    isReviewsSuccess &&
                    !isNoDataReviews && (
                      <UserReviewsSection
                        data={reviewsData.result}
                        onClickMakeAnswer={onClickMakeAnswerHandler}
                      />
                    )
                  )}
                  {isNoDataReviews && <TextPlaceholder variant="noData" />}
                </Section>
              </StyledTopRightContainer>
            </StyledTopRow>
            <StyledBottomRow>
              <>
                {transactionPageDataIsLoading ||
                transactionPageDataIsFetching ? (
                  <Loader />
                ) : (
                  transactionPageDataIsSuccess && (
                    <Section title="Транзакции">
                      <HalalBonusTransactionsTable
                        data={transactionPageData?.result || []}
                        pagination={{
                          pageIndex: page,
                          onChangePage: setPage,
                          pageCount:
                            transactionPageData?.pagination.pageCount ?? 0,
                        }}
                      />
                    </Section>
                  )
                )}
              </>
            </StyledBottomRow>
          </StyledContainer>
        )
      )}
      <ReviewAnswerModal
        onClose={reviewModalActions.closeHandler}
        {...reviewAnswerModalState}
      />
      {!isSuccess && !isLoading && <TextPlaceholder variant="error" />}
    </StyledHalalBonusClientDetailPage>
  );
};
