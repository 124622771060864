import { GetAccountingPageParams, GetAccountingPageResponse } from './types';

import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const ACCOUNTING_TAG = 'ACCOUNTING' as const;

const LIST_TAG = { type: ACCOUNTING_TAG, id: 'list' };

export const accountingApi = createApi({
  reducerPath: 'accountingApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'report' }),
  tagTypes: [ACCOUNTING_TAG],
  endpoints: (builder) => {
    return {
      getAccountingPage: builder.query<
        GetAccountingPageResponse,
        GetAccountingPageParams
      >({
        providesTags: [LIST_TAG],
        query: (params) => ({
          url: '/',
          method: 'GET',
          params,
        }),
      }),
    };
  },
});
