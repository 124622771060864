import {
  BooleanSelect,
  BooleanSelectProps,
} from '@packages/uiKit/BooleanSelect';
import { Field } from '@packages/uiKit/Field';
import React, { forwardRef } from 'react';

export interface BooleanSelectFieldProps
  extends Omit<BooleanSelectProps, 'error'> {
  className?: string;
  style?: React.CSSProperties;

  label?: string;
  tip?: string;
  error?: string;
}

export const BooleanSelectField = forwardRef<
  HTMLDivElement,
  BooleanSelectFieldProps
>((props, ref) => {
  const { label, style, className, error, tip, ...restProps } = props;

  return (
    <Field label={label} error={error} tip={tip}>
      <BooleanSelect ref={ref} {...restProps} />
    </Field>
  );
});

BooleanSelectField.displayName = 'BooleanSelectField';
