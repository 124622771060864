import { StyledButtons, StyledDescription } from './styles';

import 'cropperjs/dist/cropper.css';
import { Button } from '../Button';
import { Modal, ModalProps } from '../Modal';

import { FC, memo } from 'react';

export type ConfirmModalProps = ModalProps & {
  onConfirm?: () => void;
  description: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

const _ConfirmModal: FC<ConfirmModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    onConfirm,
    description,
    confirmButtonText,
    cancelButtonText,
    ...restProps
  } = props;

  const confirmHandler = () => {
    onConfirm?.();
    onClose?.();
  };

  return (
    <Modal width={600} isOpen={isOpen} onClose={onClose} {...restProps}>
      <StyledDescription>{description}</StyledDescription>
      <StyledButtons>
        <Button color="primary" size="large" onClick={confirmHandler}>
          {confirmButtonText || 'Ок'}
        </Button>
        <Button color="secondary" size="large" onClick={onClose}>
          {cancelButtonText || 'Отмена'}
        </Button>
      </StyledButtons>
    </Modal>
  );
};

export const ConfirmModal = memo(_ConfirmModal);
