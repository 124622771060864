import React, { FC, memo } from 'react';

import { Section } from '@components';
import { ImageListWithUploadField } from '@packages/uiKit/fields/ImageListWithUploadField';
import { Controller, useForm } from 'react-hook-form';
import { StyledPhotoGalleryForm, StyledPhotosLabel } from './styles';

export interface PhotoGalleryFormProps {
  className?: string;
  style?: React.CSSProperties;
}

type PhotoGalleryFormValues = {
  photos: string[];
};

const _PhotoGalleryForm: FC<PhotoGalleryFormProps> = (props) => {
  const { ...restProps } = props;

  const { control } = useForm<PhotoGalleryFormValues>({});

  return (
    <StyledPhotoGalleryForm {...restProps}>
      <Section title="Фотогалерея">
        <StyledPhotosLabel>
          Можно загрузить не более 6 фото
          <br />
          Минимальный размер фото <strong>870х1064 px</strong>
        </StyledPhotosLabel>
        <Controller
          control={control}
          name="photos"
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            const newVal = value?.map((item) => {
              return {
                id: 0,
                photo: item,
              };
            });

            return (
              <ImageListWithUploadField
                direction="column"
                value={newVal ? newVal : [{ id: 0, photo: '' }]}
                onChange={onChange}
                error={error?.message}
              />
            );
          }}
        />
      </Section>
    </StyledPhotoGalleryForm>
  );
};

export const PhotoGalleryForm = memo(_PhotoGalleryForm);
