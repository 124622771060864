import { ApiDateString } from '@features/api';
import { HalalEdaOrderCartItem } from '@features/halalEda';

export enum OrderDetailTab {
  Client = 'client',
  Menu = 'menu',
  History = 'history',
}

export type OrderFormValues = {
  cart: HalalEdaOrderCartItem[];
  created: ApiDateString;
  address: string;
  when: ApiDateString;
  paid_card: boolean;
  persons_number: number | null;
  change_sum: number | null;

  client: {
    full_name: string;
    tel: string;
    email: string | null;
  };

  useBalance?: boolean;
};

type OrderFormValidValuesExceptions = {};

export type OrderFormValidValues = Omit<
  OrderFormValues,
  keyof OrderFormValidValuesExceptions
> &
  OrderFormValidValuesExceptions;
