import {
  StyledLegendContainer,
  StyledOrderDynamicCard,
} from './HalalShopDynamicCard.styles';

import { LegendItem } from '../common/LegendItem';

import { Column, ColumnConfig } from '@ant-design/plots';
import { colors } from '@packages/uiKit';
import React, { FC, memo } from 'react';

export interface HalalShopDynamicCardCardProps {
  className?: string;
  style?: React.CSSProperties;
}

type DataItem = {
  period: string;
  amount: number;
  isTheBest?: boolean;
};

const data: DataItem[] = [
  {
    period: 'Ноя. 2024',
    amount: 1,
  },
  {
    period: 'Дек. 2024',
    amount: 17,
  },
  {
    period: 'Янв. 2024',
    amount: 14,
  },
  {
    period: 'Фев. 2024',
    amount: 27,
  },
  {
    period: 'Мар. 2024',
    amount: 32,
  },
  {
    period: 'Апр. 2024',
    amount: 31,
  },
  {
    period: 'Май 2024',
    amount: 26,
  },
  {
    period: 'Июнь 2024',
    amount: 25,
  },
  {
    period: 'Июль 2024',
    amount: 21,
  },
  {
    period: 'Авг. 2024',
    amount: 51,
    isTheBest: true,
  },
  {
    period: 'Сен. 2024',
    amount: 10,
  },
];

const config: ColumnConfig = {
  data,
  height: 250,
  xField: 'period',
  yField: 'amount',
  legend: false as const,
  yAxis: {
    maxLimit: 51,
  },
  seriesField: 'isTheBest',

  color: (item) => {
    if ((item as DataItem).isTheBest) {
      return colors.primaryDark3;
    }

    return colors.primary;
  },
  tooltip: {
    fields: ['amount'],
  },

  meta: {
    amount: {
      alias: 'Прибыль',
    },
  },
};

const _HalalShopDynamicCard: FC<HalalShopDynamicCardCardProps> = (props) => {
  return (
    <StyledOrderDynamicCard
      title="Динамика заказов за 2022 год"
      right={
        <StyledLegendContainer>
          <LegendItem
            color={colors.primary}
            value={'3 980 033 ₸'}
            description={'Всего за год'}
          />
          <LegendItem
            color={colors.primaryDark3}
            value={'231 933 ₸'}
            description={'Среднее за год'}
          />
          <LegendItem
            color={colors.primaryDark3}
            value={'150 000 ₸'}
            description={'Лучший месяц Август'}
          />
        </StyledLegendContainer>
      }>
      <Column {...config} />
    </StyledOrderDynamicCard>
  );
};

export const HalalShopDynamicCard = memo(_HalalShopDynamicCard);
