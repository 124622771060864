import React, { FC, PropsWithChildren, memo } from 'react';

import { StyledContent } from './styles';

export interface ContentProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}

const _Content: FC<ContentProps> = (props) => {
  const { children, ...restProps } = props;

  return <StyledContent {...restProps}>{children}</StyledContent>;
};

export const Content = memo(_Content);
