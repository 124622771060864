import {
  TextAreaField,
  TextAreaFieldProps,
} from '@packages/uiKit/fields/TextAreaField';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import { memo } from 'react';
import { Controller } from 'react-hook-form';

const _TextAreaFieldAdapter: FieldAdapterFCComponent<TextAreaFieldProps> = (
  props,
) => {
  const {
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextAreaField {...inputProps} {...field} error={error?.message} />
        );
      }}
    />
  );
};

export const TextAreaFieldAdapter = memo(
  _TextAreaFieldAdapter,
) as typeof _TextAreaFieldAdapter;
