import { Item } from './Item';
import { StyledGrid } from './styles';

import { GridBorder } from './types';

import React, { FC } from 'react';

export interface WrapGridProps {
  className?: string;
  style?: React.CSSProperties;
  cols: number;
  columnGap?: number;
  rowGap?: number;
  children: React.ReactNode;
  border?: GridBorder;
}

export const Grid: FC<WrapGridProps> = (props) => {
  const {
    cols,
    columnGap,
    rowGap,
    children,
    border = 'none',
    ...restProps
  } = props;

  const childrenLength = React.Children.count(children);

  return (
    <StyledGrid
      $cols={cols}
      $columnGap={columnGap}
      $rowGap={rowGap}
      {...restProps}>
      {React.Children.map(children, (child, index) => {
        const isFirstRow = index < cols;
        const isLastRow = index >= childrenLength - cols;
        return (
          <Item
            key={index}
            isFirstRow={isFirstRow}
            isLastRow={isLastRow}
            border={border}>
            {child}
          </Item>
        );
      })}
    </StyledGrid>
  );
};
