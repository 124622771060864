import {
  HalalTradingGetProductRecord,
  HalalTradingProductMutationRequest,
} from '@features/halalTrading/store/types';
import { initProductValuesPlaceholder } from '../constants';

export const getInitProductValues = (
  data: HalalTradingGetProductRecord | undefined | null,
) => {
  if (!data) return initProductValuesPlaceholder;

  const res: HalalTradingProductMutationRequest = {
    category: data.category.id.toString(),
    //manufacturer_logo: data.manufacturer_logo,
    //manufacturer_name: data.manufacturer_name,
    name: data.name,
    desc: data.desc ? data.desc : '',
    photo: data.photo,
    price: data.price,
    barcode: data.barcode,
  };

  return res;
};
