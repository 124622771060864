import React, { FC, memo, useState } from 'react';

import {
  StyledButtonsContainer,
  StyledImage,
  StyledImageContainer,
  StyledPushNotificationPreview,
  StyledWarningTitle,
} from './styles';

import { Button } from '@packages/uiKit';
import image from '../assets/iphoneTemplate.png';
import { InAppPreview } from './InAppPreview';
import { OnScreenSaverPreview } from './OnScreenSaverPreview';

export interface PushNotificationPreviewProps {
  className?: string;
  style?: React.CSSProperties;
}

const _PushNotificationPreview: FC<PushNotificationPreviewProps> = (props) => {
  const { ...restProps } = props;

  const [isInApp, setIsInApp] = useState<boolean>(true);

  const onClickInApp = () => {
    setIsInApp(true);
  };

  const onClickOnScreenSaver = () => {
    setIsInApp(false);
  };

  return (
    <StyledPushNotificationPreview {...restProps}>
      <StyledImageContainer>
        <StyledImage src={image} />
      </StyledImageContainer>
      <StyledButtonsContainer>
        <Button
          size="small"
          variant={isInApp ? 'filled' : 'outlined'}
          color={isInApp ? 'primary' : 'secondary'}
          onClick={onClickInApp}>
          На экране блокировки
        </Button>
        <Button
          size="small"
          variant={!isInApp ? 'filled' : 'outlined'}
          color={!isInApp ? 'primary' : 'secondary'}
          onClick={onClickOnScreenSaver}>
          В приложении
        </Button>
      </StyledButtonsContainer>
      {isInApp && <OnScreenSaverPreview />}
      {!isInApp && <InAppPreview />}
      <StyledWarningTitle>
        Внешний вид объявления может незначительно отличаться от примера в
        предпросмотре
      </StyledWarningTitle>
    </StyledPushNotificationPreview>
  );
};

export const PushNotificationPreview = memo(_PushNotificationPreview);
