import { OrderFormValidValues, OrderFormValues } from './types';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export const validationSchema = yup
  .object({
    // clientName: yup.string().required(),
    // nameOfCompany: yup.string().required(),
    // email: yup.string().email().required(),
    // comment: yup.string().nullable().optional(),
    // streetAndHouse: yup.string().required(),
    // apartmentNumber: yup.number().nullable().optional(),
    // entranceNumber: yup.number().nullable().optional(),
    // intercom: yup.number().nullable().optional(),
    // floor: yup.number().nullable().optional(),
    // numberOfPersons: yup.string().required(),
    // paymentMethod: yup.string().required(),
    // changeFromAmount: yup.number().nullable().optional(),
    // useBonuses: yup.boolean().required(),
    // usePromotion: yup.string().nullable().optional(),
  })
  .required();

export type UseOrderFormParams = {
  initValues: OrderFormValues;
};
export const useOrderForm = (params: UseOrderFormParams) => {
  const { initValues } = params;

  return useForm<OrderFormValues, OrderFormValidValues>({
    defaultValues: initValues,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
};
