import { StyledDeliveryCell, StyledTextContainer } from './DeliveryCell.styles';

import { HalalEdaOrderRecord } from '@features/halalEda/orders';
import { DropdownIconButton } from '@packages/uiKit/DropdownIconButton';
import { FC, useCallback, useState } from 'react';
import { DeliveryTooltip } from './DeliveryTooltip';

export interface DeliveryCellProps {
  value: HalalEdaOrderRecord['cart'];
}

export const DeliveryCell: FC<DeliveryCellProps> = (props) => {
  const { value } = props;

  const handleStopPropagation: React.MouseEventHandler<HTMLDivElement> =
    useCallback((e) => {
      e.stopPropagation();
    }, []);

  const [isVisible, setIsVisible] = useState(false);

  return (
    <StyledDeliveryCell onClick={handleStopPropagation}>
      <StyledTextContainer>{value.length + ' · позиций'}</StyledTextContainer>

      <DropdownIconButton
        dropdown={<DeliveryTooltip cart={value} />}
        placement="right"
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
    </StyledDeliveryCell>
  );
};
