import { colors } from '@packages/uiKit';
import { Typography } from '@packages/uiKit/Typography';
import styled from 'styled-components';

export const StyledControlContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  min-height: 36.2px;
  border-bottom: 1px solid ${colors.gray1};
  padding: 8px 0;
`;

export const StyledValueLabel = styled(Typography).attrs({
  size: 16,
  nowrap: true,
  ellipsis: true,
})`
  flex: 1;
  min-width: 0;
`;
