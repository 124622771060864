import {
  EstablishmentSection,
  MenuSection,
  StatisticsSection,
} from './sections';
import { DiscountsSection } from './sections/DiscountsSection';

import { ReactNode } from 'react';
import { OrdersSection } from './sections/OrdersSection/OrdersSection';
import { HalalEdaPageSection } from './types';

export const sectionToComponentDict: Record<HalalEdaPageSection, ReactNode> = {
  orders: <OrdersSection />,
  menu: <MenuSection />,
  establishment: <EstablishmentSection />,
  statistics: <StatisticsSection />,
  stock: <DiscountsSection />,
};
