import { GridAdaptiveParam } from './types';

import { media } from '@features/adaptive/breakpoints';
import styled, { css } from 'styled-components';

const getStylesAdaptiveParam = <T,>(
  param: GridAdaptiveParam<T>,
  getString: (value: T) => string,
) => {
  if (param instanceof Object) {
    let resString = getString(param.desktop);
    const { desktop: _, ...withNoDefault } = param;

    Object.entries(withNoDefault).forEach(([key, value]) => {
      resString += `
        ${media.down(key === 'tablet' ? 'desktop' : 'tablet')}{
          ${getString(value)}
        }
      `;
    });

    return resString;
  }

  return getString(param);
};

export const StyledGridAdaptive = styled.div<{
  $cols: GridAdaptiveParam<number>;
  $columnGap?: GridAdaptiveParam<number>;
  $rowGap?: GridAdaptiveParam<number>;
}>`
  ${({ $cols, $columnGap, $rowGap }) => css`
    display: grid;
    height: fit-content;

    ${getStylesAdaptiveParam(
      $cols,
      (cols) => `grid-template-columns: repeat(${cols}, 1fr);`,
    )}

    ${$columnGap &&
    getStylesAdaptiveParam(
      $columnGap,
      (columnGap) => `column-gap: ${columnGap}px;`,
    )}

${$rowGap &&
    getStylesAdaptiveParam($rowGap, (rowGap) => `row-gap: ${rowGap}px;`)}
  `}
`;

export const StyledGridAdaptiveItem = styled.div<{ $square?: boolean }>`
  min-width: 0;

  ${({ $square }) =>
    $square &&
    css`
      aspect-ratio: 1;
    `}
`;
