import { ComplaintsTable, ComplaintsTableProps } from './ComplaintsTable';

import { Loader } from '@components/Loader';

import { greenBonusComplaintsApi } from '@features/halalBonus/complaints';
import { FC, useCallback, useState } from 'react';

export interface ComplaintsSectionProps {}

const PAGE_SIZE = 10;

export const ComplaintsSection: FC<ComplaintsSectionProps> = () => {
  const [page, setPage] = useState(1);

  const [patchComplaintM] = greenBonusComplaintsApi.usePatchComplaintMutation();

  const { data: complaintsPageData, isLoading } =
    greenBonusComplaintsApi.useGetComplaintPageQuery({
      page: page,
      perPage: PAGE_SIZE,
    });

  const changeStatusHandler: ComplaintsTableProps['onChangeStatus'] =
    useCallback(
      async (record, status) => {
        await patchComplaintM({
          id: record.id,
          patch: {
            status,
          },
        });
      },
      [patchComplaintM],
    );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <ComplaintsTable
          data={complaintsPageData?.result ?? []}
          pagination={{
            pageIndex: page,
            onChangePage: setPage,
            pageCount: complaintsPageData?.pagination.pageCount ?? 0,
          }}
          onChangeStatus={changeStatusHandler}
        />
      )}
    </>
  );
};
