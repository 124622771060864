import styled, { css } from 'styled-components';

export interface StyledInfoContainerProps {
  color: string;
  type: 'filled' | 'outlined';
}
export const StyledInfoContainer = styled.div<StyledInfoContainerProps>`
  border-radius: 10px;
  padding: 8px;
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  align-items: center;
  ${({ color }) => css`
    border: 1px solid ${color};
  `}
  ${({ color, type }) =>
    type === 'filled'
      ? css`
          background-color: ${color};
          color: white;
        `
      : ''};
`;
