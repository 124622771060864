import { Typography } from '@packages/uiKit/Typography';
import { colors } from '@packages/uiKit/utils/colors';
import styled from 'styled-components';

export const StyledSwitchFieldAdapter = styled.div`
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid ${colors.gray2};
`;

export const StyledFieldLabel = styled(Typography).attrs({
  size: 16,
})``;
