import { Tabs, TabsTabItem } from '@components';
import { useAuthProtection } from '@hooks/useAuthProtection';
import { useSearchParam } from '@hooks/useSearchParam';
import { MainLayout } from '@layouts/MainLayout';
import { FC, useMemo } from 'react';
import { sectionToComponentDict } from './constants';
import { StyledSectionContainer } from './styles';
import { HalalTradingPageSection } from './types';

export const HalalTradingPage: FC = () => {
  useAuthProtection();

  const tabs: TabsTabItem<HalalTradingPageSection>[] = useMemo(() => {
    return [
      {
        value: HalalTradingPageSection.Catalog,
        label: `Каталог`,
      },
      {
        value: HalalTradingPageSection.MyCompany,
        label: `Информация`,
      },
    ];
  }, []);

  const [section, setSection] = useSearchParam<HalalTradingPageSection>({
    paramName: 'section',
    defaultValue: HalalTradingPageSection.Catalog,
  });

  return (
    <MainLayout.Content>
      <MainLayout.Content.Header>HalalTrading</MainLayout.Content.Header>
      <Tabs tabs={tabs} value={section} onChange={setSection} />
      <StyledSectionContainer>
        {sectionToComponentDict[section]}
      </StyledSectionContainer>
    </MainLayout.Content>
  );
};
