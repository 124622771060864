import {
  StyledButtonsContainer,
  StyledHeader,
  StyledMenuPositionListLayout,
  StyledMenuSection,
  StyledTabs,
} from './MenuSection.styles';

import { TabsTabItem } from '@components';
import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';
import { ApiRecordId } from '@features/api';
import {
  CreateMenuPositionModal,
  EditMenuPositionModal,
  MenuPositionCard,
} from '@features/halalEda';
import { MenuItemRecord, menuApi } from '@features/menu';
import { useModalState } from '@hooks/useModalState';
import { useSearchParam } from '@hooks/useSearchParam';
import { NotePencilIcon } from '@packages/icons';
import { Button } from '@packages/uiKit';
import React, { FC, memo, useMemo } from 'react';

export interface MenuSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

const _MenuSection: FC<MenuSectionProps> = (props) => {
  const { ...restProps } = props;

  const [searchCategory, setSearchCategory] = useSearchParam<number | 'all'>({
    paramName: 'category',
    defaultValue: 'all',
    serializer: (param) => (param === 'all' ? param : Number(param)),
  });

  const { data, isLoading: isLoadingCategory } = menuApi.useGetMenuItemsQuery({
    category: searchCategory === 'all' ? undefined : searchCategory,
  });

  const {
    data: includedCategoriesData,
    isLoading: isLoadingData,
    isSuccess: isSuccessData,
  } = menuApi.useGetMenuIncludedCategoriesQuery();

  const [createModalState, createModalActions] = useModalState();

  // const [createCategoryModalState, createCategoryModalActions] =
  //   useModalState();

  const [editModalState, editModalActions] = useModalState<{
    editedItem: MenuItemRecord;
  }>();

  const categoriesTabs: TabsTabItem<ApiRecordId | 'all'>[] = useMemo(() => {
    return [
      { value: 'all', label: 'Все' },
      ...(includedCategoriesData?.map((cat) => ({
        value: cat.id,
        label: cat.name,
      })) ?? []),
    ];
  }, [includedCategoriesData]);

  return (
    <StyledMenuSection {...restProps}>
      {isLoadingCategory ? (
        <></>
      ) : (
        <>
          <StyledHeader>
            <StyledTabs
              tabs={categoriesTabs}
              value={searchCategory}
              onChange={setSearchCategory}
              variant="round"
              isExpandable
              visibleAmount={20}
            />
            <StyledButtonsContainer>
              <Button onClick={createModalActions.openHandler}>
                Добавить блюдо
              </Button>
              {/* <Button onClick={createCategoryModalActions.openHandler}>
                Добавить категорию
              </Button> */}
            </StyledButtonsContainer>
          </StyledHeader>
        </>
      )}

      {isLoadingData ? (
        <Loader />
      ) : (
        <>
          {data?.length === 0 ? (
            <TextPlaceholder variant="noData" />
          ) : (
            <StyledMenuPositionListLayout>
              {data?.map((menuPosition) => {
                return (
                  <MenuPositionCard
                    key={menuPosition.id}
                    {...menuPosition}
                    buttonProps={{
                      icon: <NotePencilIcon />,
                      color: 'white',
                      onClick: () =>
                        editModalActions.openHandler({
                          editedItem: menuPosition,
                        }),
                    }}
                  />
                );
              })}
            </StyledMenuPositionListLayout>
          )}
        </>
      )}
      {!isLoadingData && !isSuccessData && data?.length !== 0 && (
        <TextPlaceholder />
      )}

      {/* <CreateCategoryPositionModal
        {...createCategoryModalState}
        onClose={createCategoryModalActions.closeHandler}
      /> */}

      <CreateMenuPositionModal
        {...createModalState}
        onClose={createModalActions.closeHandler}
      />
      <EditMenuPositionModal
        {...editModalState}
        onClose={editModalActions.closeHandler}
      />
    </StyledMenuSection>
  );
};

export const MenuSection = memo(_MenuSection);
