import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';
import { StyledAmountCell } from './AmountCell.styles';

import { FC, memo } from 'react';

export interface ProjectCellProps {
  amount: number;
}

const _AmountCell: FC<ProjectCellProps> = (props) => {
  const { amount } = props;

  //const amountString = `${amount} ₸`;

  return (
    <StyledAmountCell>{formatPriceVisualFriendly(amount)} ₸</StyledAmountCell>
  );
};

export const AmountCell = memo(_AmountCell);
