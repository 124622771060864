import { Button } from '../Button';

import { CloseIcon } from '@packages/icons';

import { zIndexes } from '@packages/uiKit/utils/zIndexes';
import styled from 'styled-components';

export const StyledPopover = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndexes.modal};
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const StyledOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: ${zIndexes.modalOverlay};
`;

export const StyledModalContainer = styled.div`
  z-index: ${zIndexes.modal};
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;

export const StyledCloseButton = styled(Button).attrs({
  variant: 'text',
  icon: <CloseIcon />,
  color: 'white',
})`
  position: absolute;
  right: 26px;
  top: 26px;

  border-radius: 100%;

  background-color: rgba(0, 0, 0, 0.3);
`;

export const StyledImage = styled.img`
  display: block;

  max-width: calc(100vw - 52px);
  max-height: calc(100vh - 52px);

  background: white;
  border-radius: 20px;
  border: 6px solid white;
`;
