import { certificateCentersApi } from '@features/certificateCenters/store';
import { SelectOption } from '@packages/uiKit';
import {
  SelectFieldAdapter,
  SelectFieldAdapterProps,
} from '@packages/uiKit/fieldAdapters/SelectFieldAdapter';
import { memo, useMemo } from 'react';
import { FieldValues, Path } from 'react-hook-form';

export interface CertificateCentersSelectFieldAdapterProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> extends Omit<SelectFieldAdapterProps<TFieldValues, TName>, 'options'> {}

const _CertificateCentersSelectFieldAdapter = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>(
  props: CertificateCentersSelectFieldAdapterProps<TFieldValues, TName>,
) => {
  const { data } = certificateCentersApi.useGetCompanyQuery();

  const options: SelectOption<number>[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((certCenter) => ({
      value: certCenter.id,
      label: certCenter.name,
    }));
  }, [data]);

  return <SelectFieldAdapter options={options} {...props} />;
};

export const CertificateCentersSelectFieldAdapter = memo(
  _CertificateCentersSelectFieldAdapter,
) as typeof _CertificateCentersSelectFieldAdapter;
