import { Field } from '@packages/uiKit/Field';
import {
  InputImageList,
  InputImageListProps,
} from '@packages/uiKit/InputImageList';

import React, { forwardRef } from 'react';

export interface ImageListWithUploadFieldProps
  extends Omit<InputImageListProps, 'error'> {
  className?: string;
  style?: React.CSSProperties;

  label?: string;
  tip?: string;
  error?: string;
}

export const ImageListWithUploadField = forwardRef<
  HTMLInputElement,
  ImageListWithUploadFieldProps
>((props, ref) => {
  const { label, style, className, error, tip, ...restProps } = props;

  return (
    <Field label={label} error={error} tip={tip}>
      <InputImageList ref={ref} {...restProps} />
    </Field>
  );
});

ImageListWithUploadField.displayName = 'ImageListWithUploadField';
