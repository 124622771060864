import { authApi } from './apiService';
import { authSlice } from './slice';

import { AuthData, LoginRequest, LoginResponse } from './types';

import { accountingApi } from '@features/accounting';
import { analyticsApi } from '@features/analytics/store';
import { bookingsApi } from '@features/booking';

import { certificateCentersApi } from '@features/certificateCenters';

import { companyApi } from '@features/company';
import { cuisinesApi } from '@features/cuisines';
import { establishmentCategoriesApi } from '@features/establishmentCategories';

import { establishmentApi } from '@features/establishments';
import { greenBonusComplaintsApi } from '@features/halalBonus/complaints';
import { greenBonusEstablishmentApi } from '@features/halalBonus/establishment';
import { greenBonusTransactionsApi } from '@features/halalBonus/transactions';
import {
  halalEdaEstablishmentApi,
  halalEdaOrdersApi,
} from '@features/halalEda';
import {
  shopOrdersEndpoints,
  shopProductsEndpoints,
} from '@features/halalShop/store/apiSerive';

import { halalTradingEndpoints } from '@features/halalTrading/store/apiService';

import { localStorageService } from '@features/localStorageService';
import { menuApi } from '@features/menu';
import { menuCategoriesApi } from '@features/menuCategories';
import { reviewsApi } from '@features/reviews';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const setAuthData = createAsyncThunk<void, AuthData>(
  'auth/setAuthData',
  (args, { dispatch }) => {
    dispatch(authSlice.actions.setAuthDataInStorage(args));
    localStorageService.setAuthKey(args.token);
  },
);

export const clearAuthData = createAsyncThunk<void, void>(
  'auth/setAuthData',
  (_, { dispatch }) => {
    dispatch(authSlice.actions.clearAuthDataInStorage());
    localStorageService.clearAuthKey();
  },
);

export const login = createAsyncThunk<LoginResponse, LoginRequest>(
  'auth/login',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const response = await dispatch(
        authApi.endpoints.login.initiate(args),
      ).unwrap();

      dispatch(setAuthData({ token: response.token }));

      // Вручную инвалидирую теги, чтобы после логина точно был перезапрос и данные от юзера который был залогинен до этого - перезаписывались.
      // да простит меня Бог, за такой код, Но я обещаю это зарефакторить...
      dispatch(companyApi.util.resetApiState());
      dispatch(certificateCentersApi.util.resetApiState());
      dispatch(establishmentApi.util.resetApiState());
      dispatch(cuisinesApi.util.resetApiState());
      dispatch(establishmentCategoriesApi.util.resetApiState());

      dispatch(accountingApi.util.resetApiState());
      dispatch(menuApi.util.resetApiState());
      dispatch(menuCategoriesApi.util.resetApiState());
      dispatch(halalEdaOrdersApi.util.resetApiState());
      dispatch(halalEdaEstablishmentApi.util.resetApiState());
      dispatch(greenBonusTransactionsApi.util.resetApiState());
      dispatch(greenBonusComplaintsApi.util.resetApiState());
      dispatch(greenBonusEstablishmentApi.util.resetApiState());
      dispatch(reviewsApi.util.resetApiState());
      dispatch(bookingsApi.util.resetApiState());
      dispatch(analyticsApi.util.resetApiState());
      dispatch(shopOrdersEndpoints.util.resetApiState());
      dispatch(halalTradingEndpoints.util.resetApiState());
      dispatch(shopProductsEndpoints.util.resetApiState());

      return response;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

export const logout = createAsyncThunk<void, void>(
  'auth/logout',
  async (_, { dispatch }) => {
    try {
      await dispatch(authApi.endpoints.logout.initiate()).unwrap();
    } finally {
      dispatch(clearAuthData());
    }
  },
);
