import {
  InputPhoneNumberField,
  InputPhoneNumberFieldProps,
} from '@packages/uiKit/fields/InputPhoneNumberField';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import { Controller } from 'react-hook-form';
export const InputPhoneNumberFieldAdapter: FieldAdapterFCComponent<
  InputPhoneNumberFieldProps
> = (props) => {
  const {
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputPhoneNumberField
            value={field.value ? field.value : ''}
            onChange={field.onChange}
            {...inputProps}
            error={error?.message}
          />
        );
      }}
    />
  );
};
