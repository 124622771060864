import { Field } from '@packages/uiKit/Field';
import { Input, InputProps } from '@packages/uiKit/Input';
import { forwardRef } from 'react';

export interface InputFieldProps extends Omit<InputProps, 'error'> {
  label?: string;
  tip?: string;
  error?: string;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const { label, style, className, error, tip, ...restProps } = props;

    return (
      <Field
        style={style}
        className={className}
        label={label}
        error={error}
        tip={tip}>
        <Input ref={ref} error={Boolean(error)} {...restProps} />
      </Field>
    );
  },
);

InputField.displayName = 'InputField';
