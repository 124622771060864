import { StyledClientTab } from './ClientTab.styles';

import { numberOfPersonOptions } from './constants';

import { OrderFormValues } from '../OrderForm/types';

import { Section } from '@components/Section';
import { HalalEdaOrderRecord } from '@features/halalEda';
import { BooleanSelectFieldAdapter } from '@packages/uiKit/fieldAdapters/BooleanSelectFieldAdapter';
import { InputFieldAdapter } from '@packages/uiKit/fieldAdapters/InputFieldAdapter';
import { InputNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputNumberFieldAdapter';
import { InputPhoneNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputPhoneNumberFieldAdapter';
import { SelectFieldAdapter } from '@packages/uiKit/fieldAdapters/SelectFieldAdapter';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

export interface ClientTabProps {
  className?: string;
  style?: React.CSSProperties;

  order: HalalEdaOrderRecord;
}

export const ClientTab: FC<ClientTabProps> = (props) => {
  const { ...restProps } = props;

  const { control } = useFormContext<OrderFormValues>();

  return (
    <StyledClientTab {...restProps}>
      <Section level={2} title={'Информация'}>
        <Row gutter={15}>
          <Col span={8}>
            <InputFieldAdapter
              control={control}
              name="client.full_name"
              label="Имя"
            />
          </Col>
          <Col span={8}>
            <InputPhoneNumberFieldAdapter
              control={control}
              name="client.tel"
              label="Телефон"
            />
          </Col>
          <Col span={8}>
            <InputFieldAdapter
              control={control}
              name="client.email"
              label="Электронная почта"
            />
          </Col>
        </Row>
      </Section>

      <Section level={2} title={'Адрес доставки'}>
        <Row gutter={15}>
          <Col span={24}>
            <InputFieldAdapter control={control} name="address" label="Адрес" />
          </Col>
          {/* <Col span={8}>
            <InputFieldAdapter
              control={control}
              name="streetAndHouse"
              label="Улица и дом"
            />
          </Col>
          <Col span={4}>
            <InputNumberFieldAdapter
              control={control}
              name="apartmentNumber"
              label="Квартира"
            />
          </Col>
          <Col span={4}>
            <InputNumberFieldAdapter
              control={control}
              name="entranceNumber"
              label="Подъезд"
            />
          </Col>

          <Col span={4}>
            <InputNumberFieldAdapter
              control={control}
              name="intercom"
              label="Домофон"
            />
          </Col>

          <Col span={4}>
            <InputNumberFieldAdapter
              control={control}
              name="floor"
              label="Этаж"
            />
          </Col> */}
        </Row>
      </Section>

      <Section level={2} title={'Детали заказа'}>
        <Row gutter={15}>
          <Col span={8}>
            <SelectFieldAdapter
              control={control}
              name="persons_number"
              label="Количество персон"
              options={numberOfPersonOptions}
            />
          </Col>
          <Col span={8}>
            <BooleanSelectFieldAdapter
              control={control}
              name="paid_card"
              label="Оплата заказа"
              trueLabel="Картой"
              falseLabel="Наличными"
            />
          </Col>

          <Col span={8}>
            <InputNumberFieldAdapter
              control={control}
              name="change_sum"
              label="Сдача с суммы"
            />
          </Col>
        </Row>
      </Section>
    </StyledClientTab>
  );
};
