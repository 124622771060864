import { InputDateType } from './types';

import { StyledDatePicker } from '@packages/uiKit/InputDate/InputDate.styles';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { DatePickerProps } from 'antd/lib/date-picker';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import moment from 'moment';
import 'moment/locale/ru';
import { FC } from 'react';

export type InputDateProps = Omit<
  PickerDateProps<moment.Moment>,
  'showHour' | 'showMinute' | 'showSecond' | 'showTime' | 'value' | 'onChange'
> & {
  value?: Date | null;
  onChange?: (value: string | null) => void;
  error?: boolean;
  showHour?: boolean;
  showMinute?: boolean;
  showSecond?: boolean;
  showTime?: boolean;
  type?: InputDateType;
};

const typeToFormater: Record<InputDateType, (date: moment.Moment) => string> = {
  date: (date) => date.format('YYYY-MM-DD'),
  dateTime: (date) => date.toISOString(),
  time: (date) => date.toISOString(),
};

export const InputDate: FC<InputDateProps> = (props) => {
  const {
    error = false,
    value,
    onChange,
    type = 'dateTime',
    ...restProps
  } = props;

  const momentValue = value ? moment(value) : null;

  const onChangeHandler: DatePickerProps['onChange'] = onChange
    ? (value) => {
        const dateValue = value ? typeToFormater[type](value) : null;
        onChange(dateValue);
      }
    : undefined;

  return (
    <StyledDatePicker
      value={momentValue}
      onChange={onChangeHandler}
      locale={locale}
      error={error}
      {...restProps}
    />
  );
};
