import {
  StyledBottomContainer,
  StyledCost,
  StyledIconButton,
  StyledImage,
  StyledMainInfoContainer,
  StyledMenuPositionCard,
  StyledName,
  StyledParamsContainer,
  StyledRightContainer,
  StyledWeight,
} from './MenuPositionCard.styles';

import { PlusIcon } from '@packages/icons';
import { ButtonProps } from '@packages/uiKit';
import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';
import React, { FC, memo } from 'react';

export interface MenuPositionCardProps {
  className?: string;
  style?: React.CSSProperties;

  name: string;
  weight: number;
  cost: number;
  image: string;
  buttonProps?: ButtonProps;
}

const _MenuPositionCard: FC<MenuPositionCardProps> = (props) => {
  const { name, weight, cost, image, buttonProps, ...restProps } = props;

  return (
    <StyledMenuPositionCard {...restProps}>
      <StyledImage src={image} />
      <StyledRightContainer>
        <StyledMainInfoContainer>
          <StyledName>{name}</StyledName>

          <StyledBottomContainer>
            <StyledIconButton icon={<PlusIcon />} {...buttonProps} />
            <StyledParamsContainer>
              <StyledWeight>{weight} г</StyledWeight>
              <StyledCost>{formatPriceVisualFriendly(cost)} ₸</StyledCost>
            </StyledParamsContainer>
          </StyledBottomContainer>
        </StyledMainInfoContainer>
        {/* <StyledActionButtonContainer>
          <StyledActionsButton />
        </StyledActionButtonContainer> */}
      </StyledRightContainer>
    </StyledMenuPositionCard>
  );
};

export const MenuPositionCard = memo(_MenuPositionCard);
