import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledPhotoGalleryForm = styled.div``;

export const StyledPhotosLabel = styled(Typography).attrs({
  size: 12,
  color: 'gray1',
})`
  margin-bottom: 11px;
`;
