import { StyledSumAllOrdersCard } from './SumAllOrdersCard.styles';

import { InfoCardProps } from '../common/InfoCard';

import { WalletIcon } from '@packages/icons';
import React, { FC, memo } from 'react';

export interface SumAllOrdersCardProps extends Pick<InfoCardProps, 'value'> {
  className?: string;
  style?: React.CSSProperties;
}

const _SumAllOrdersCard: FC<SumAllOrdersCardProps> = (props) => {
  const { value, ...restProps } = props;

  const valueWithCurrency = `${value.toLocaleString()} ₸`;

  return (
    <StyledSumAllOrdersCard
      {...restProps}
      value={valueWithCurrency}
      icon={<WalletIcon />}
      description={'Сумма всех заказов'}
      color="primary"
    />
  );
};

export const SumAllOrdersCard = memo(_SumAllOrdersCard);
