import {
  StyledLeftTip,
  StyledRightTip,
  StyledSwitch,
  StyledSwitchCircle,
} from './Switch.styles';

import React, { FC, useMemo } from 'react';

export interface SwitchProps {
  value?: boolean | null;
  onChange?: (value: boolean) => void;
  withTips?: boolean;

  className?: string;
  styles?: React.CSSProperties;
}

export const Switch: FC<SwitchProps> = (props) => {
  const { value = false, onChange, withTips = false, ...restProps } = props;

  const onClickHandler = useMemo(() => {
    return onChange ? () => onChange(!value) : undefined;
  }, [onChange, value]);

  return (
    <StyledSwitch
      value={value ? value : false}
      withTips={withTips}
      {...restProps}
      onClick={onClickHandler}>
      <StyledSwitchCircle />
      {withTips && (
        <>
          <StyledLeftTip>да</StyledLeftTip>
          <StyledRightTip>нет</StyledRightTip>
        </>
      )}
    </StyledSwitch>
  );
};
