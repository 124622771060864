import { colors } from '../utils';

import styled, { css } from 'styled-components';

const getDynamicProps = (props: { rating: number }) => {
  const { rating } = props;
  if (rating <= 2.5) {
    return {
      color: colors.dark1,
    };
  }

  if (rating <= 3.5) {
    return {
      color: colors.gray1,
    };
  }

  return {
    color: colors.primary,
  };
};
export const StyledRatingLabel = styled.div<{ rating: number }>`
  display: flex;
  gap: 3px;

  ${(props) => {
    const { color } = getDynamicProps(props);

    return css`
      color: ${color};
    `;
  }}
`;
