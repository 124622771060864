import {
  GetHalalBonusComplaintPageRequest,
  GetHalalBonusComplaintPageResponse,
  PatchHalalBonusComplaintRequest,
} from './types';

import { ApiRecordId } from '@features/api';
import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const GREEN_BONUS_COMPLAINTS_TAG = 'GREEN_BONUS_COMPLAINTS_TAG' as const;

const getDetailTag = (id: ApiRecordId) => ({
  type: GREEN_BONUS_COMPLAINTS_TAG,
  id,
});

export const greenBonusComplaintsApi = createApi({
  reducerPath: 'greenBonusComplaintsApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'green-bonus/complaints' }),
  tagTypes: [GREEN_BONUS_COMPLAINTS_TAG],
  endpoints: (builder) => {
    return {
      getComplaintPage: builder.query<
        GetHalalBonusComplaintPageResponse,
        GetHalalBonusComplaintPageRequest
      >({
        providesTags: (response) => [
          GREEN_BONUS_COMPLAINTS_TAG,
          ...(response?.result.map((order) => getDetailTag(order.id)) ?? []),
        ],
        query: (params) => ({
          url: '/',
          method: 'GET',
          params,
        }),
      }),

      patchComplaint: builder.mutation<void, PatchHalalBonusComplaintRequest>({
        invalidatesTags: (_, _2, { id }) => [getDetailTag(id)],
        query: (request) => ({
          url: `/${request.id}/`,
          method: 'PATCH',
          body: request.patch,
        }),
      }),
    };
  },
});
