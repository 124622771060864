import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const defaultSerializer = <ParamType>(param: string) => param as ParamType;

export type UseSearchParamOptions<ParamType extends string | number> = {
  paramName: string;
  defaultValue: ParamType;
  serializer?: (param: string) => ParamType;
};
export const useSearchParam = <
  ParamType extends string | number = string | number,
>(
  options: UseSearchParamOptions<ParamType>,
) => {
  const {
    paramName,
    defaultValue,
    serializer = defaultSerializer<ParamType>,
  } = options;

  const [params, setParams] = useSearchParams({
    [paramName]: defaultValue.toString(),
  });

  const realParam = useMemo(() => params.get(paramName), [paramName, params]);

  const param = realParam ? serializer(realParam) : defaultValue;

  const setParam = useCallback(
    (value: ParamType) => {
      setParams(
        (prev) => {
          const newParams: Record<string, string> = {};
          prev.forEach((value, key) => {
            newParams[key] = value;
          });
          newParams[paramName] = value.toString();

          return newParams;
        },
        {
          replace: true,
        },
      );
    },
    [paramName, setParams],
  );

  return [param, setParam] as const;
};
