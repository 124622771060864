import { ClientsSection } from './sections/ClientsSection';
import { ComplaintsSection } from './sections/ComplaintsSection';
import { EmployeesSection } from './sections/EmployeesSection/EmployeesSection';
import { EstablishmentSection } from './sections/EstablishmentSection';
import { StatisticsSection } from './sections/StatisticsSection';
import { TransactionsSection } from './sections/TransactionsSection';
import { HalalBonusPageTab } from './types';

import { TabsTabItem } from '@components';
import { ReactNode } from 'react';

export const tabs: TabsTabItem<HalalBonusPageTab>[] = [
  {
    value: HalalBonusPageTab.Transactions,
    label: 'Транзакции',
  },
  { value: HalalBonusPageTab.Complaints, label: 'Жалобы' },
  { value: HalalBonusPageTab.Establishment, label: 'Гибкая скидка' },
  { value: HalalBonusPageTab.Statistics, label: 'Статистика' },
  { value: HalalBonusPageTab.Clients, label: 'Клиенты' },
  { value: HalalBonusPageTab.Employees, label: 'Сотрудники' },
];

export const sectionToComponentDict: Record<HalalBonusPageTab, ReactNode> = {
  transactions: <TransactionsSection />,
  complaints: <ComplaintsSection />,
  establishment: <EstablishmentSection />,
  statistics: <StatisticsSection />,
  employees: <EmployeesSection />,
  clients: <ClientsSection />,
};

export const PAGE_SIZE = 11;
