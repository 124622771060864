import {
  GetHalalBonusTransactionPageRequest,
  GetHalalBonusTransactionPageResponse,
  GetTransactionsStatsRequest,
  GetTransactionsStatsResponse,
  PatchHalalEdaOrderPageRequest,
} from './types';

import { ApiRecordId } from '@features/api';
import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const GREEN_BONUS_TRANSACTIONS_TAG =
  'GREEN_BONUS_TRANSACTIONS_TAG' as const;

export const GREEN_BONUS_STATS_TAG = 'GREEN_BONUS_STATS_TAG' as const;

const getDetailTag = (id: ApiRecordId) => ({
  type: GREEN_BONUS_TRANSACTIONS_TAG,
  id,
});

export const greenBonusTransactionsApi = createApi({
  reducerPath: 'greenBonusTransactionsApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'green-bonus/' }),
  tagTypes: [GREEN_BONUS_TRANSACTIONS_TAG, GREEN_BONUS_STATS_TAG],
  endpoints: (builder) => {
    return {
      getTransactionPage: builder.query<
        GetHalalBonusTransactionPageResponse,
        GetHalalBonusTransactionPageRequest
      >({
        providesTags: (response) => [
          GREEN_BONUS_TRANSACTIONS_TAG,
          ...(response?.result.map((order) => getDetailTag(order.id)) ?? []),
        ],
        query: (params) => ({
          url: 'transactions/',
          method: 'GET',
          params,
        }),
      }),

      getStatsPage: builder.query<
        GetTransactionsStatsResponse,
        GetTransactionsStatsRequest
      >({
        providesTags: (response) => [GREEN_BONUS_STATS_TAG],
        query: (params) => ({
          url: 'stats/',
          method: 'GET',
          params,
        }),
      }),

      patchOrder: builder.mutation<void, PatchHalalEdaOrderPageRequest>({
        invalidatesTags: (_, _2, { id }) => [getDetailTag(id)],
        query: (request) => ({
          url: `/${request.id}/`,
          method: 'PATCH',
          body: request.patch,
        }),
      }),
    };
  },
});
