import React, { FC, memo } from 'react';

import { StyledListAsTableRowItemLabel } from './styles';

export interface ListAsTableRowItemLabelProps {
  className?: string;
  style?: React.CSSProperties;

  label: string;
}

const _ListAsTableRowItemLabel: FC<ListAsTableRowItemLabelProps> = (props) => {
  const { label, ...restProps } = props;

  return (
    <StyledListAsTableRowItemLabel {...restProps}>
      {label}
    </StyledListAsTableRowItemLabel>
  );
};

export const ListAsTableRowItemLabel = memo(_ListAsTableRowItemLabel);
