import { HalalShopReturnMockRecord, HalalShopReturnStatus } from '../types';

import { halalShopCartItemMockList } from '@features/halalShop/orders/mock/data';

export const halalShopReturnMockDataList: HalalShopReturnMockRecord[] = [
  {
    id: 1,
    status: HalalShopReturnStatus.Processed,
    amount: 12001,
    created: '2022-10-13T22:09:50Z',
    client: 'Larine Oldale',
    phone: '+7 932 765-64-54',
    order: halalShopCartItemMockList,
    cause: 'Бракованный товар',
  },
  {
    id: 2,
    status: HalalShopReturnStatus.Accepted,
    amount: 12001,
    created: '2022-10-13T22:09:50Z',
    client: 'Larine Oldale',
    phone: '+7 932 765-64-54',
    order: halalShopCartItemMockList,
    cause: 'Бракованный товар',
  },
  {
    id: 3,
    status: HalalShopReturnStatus.Rejected,
    amount: 12001,
    created: '2022-10-13T22:09:50Z',
    client: 'Larine Oldale',
    phone: '+7 932 765-64-54',
    order: halalShopCartItemMockList,
    cause: 'Бракованный товар',
  },
  {
    id: 4,
    status: HalalShopReturnStatus.Delivered,
    amount: 12001,
    created: '2022-10-13T22:09:50Z',
    client: 'Larine Oldale',
    phone: '+7 932 765-64-54',
    order: halalShopCartItemMockList,
    cause: 'Бракованный товар',
  },
];
