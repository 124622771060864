import styled from 'styled-components';

export const StyledStatisticSection = styled.div``;

export const StyledStatisticsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 100px);
  grid-template-areas:
    'topProducts topProducts orderStructure orderStructure orderStructure searches'
    'topProducts topProducts orderStructure orderStructure orderStructure addToBag'
    'topProducts topProducts orderStructure orderStructure orderStructure ordersIssued'
    'orderDynamic orderDynamic orderDynamic orderDynamic orderDynamic ordersDelivered'
    'orderDynamic orderDynamic orderDynamic orderDynamic orderDynamic sumAllOrders'
    'orderDynamic orderDynamic orderDynamic orderDynamic orderDynamic halalEdaCommission';
  gap: 15px;
`;
