import React, { FC, PropsWithChildren, memo } from 'react';

import { StyledTablesScrollX } from './styles';

export interface TablesScrollXProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}

const _TablesScrollX: FC<TablesScrollXProps> = (props) => {
  const { children, ...restProps } = props;

  return <StyledTablesScrollX {...restProps}>{children}</StyledTablesScrollX>;
};

export const TablesScrollX = memo(_TablesScrollX);
