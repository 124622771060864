import {
  StyledBottomArea,
  StyledChartCard,
  StyledRightArea,
  StyledTitle,
} from './ChartCard.styles';

import React, { FC, PropsWithChildren, ReactNode } from 'react';

export interface ChartCardProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
  title: string;
  right?: ReactNode;
}

export const ChartCard: FC<ChartCardProps> = (props) => {
  const { title, children, right, ...restProps } = props;

  return (
    <StyledChartCard withRight={Boolean(right)} {...restProps}>
      <StyledTitle>{title}</StyledTitle>
      {right && <StyledRightArea>{right}</StyledRightArea>}
      <StyledBottomArea>{children}</StyledBottomArea>
    </StyledChartCard>
  );
};
