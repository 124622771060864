import {
  StyledCommentCell,
  StyledCommentText,
  StyledEditButton,
} from './CommentCell.styles';

import React, { FC, memo } from 'react';

export interface QrCodeCellProps {
  className?: string;
  style?: React.CSSProperties;
  value?: string | null;
  onEditClick: () => void;
}

const _CommentCell: FC<QrCodeCellProps> = (props) => {
  const { value, onEditClick, ...restProps } = props;

  return (
    <StyledCommentCell {...restProps}>
      <StyledCommentText
        title={value || undefined}
        color={Boolean(value) ? 'dark1' : 'gray1'}>
        {value || 'Нет комментария'}
      </StyledCommentText>

      <StyledEditButton onClick={onEditClick} />
    </StyledCommentCell>
  );
};

export const CommentCell = memo(_CommentCell);
