import { HalalTradingProductMutationRequest } from '../store/types';

export const getProductFormData = (
  data: HalalTradingProductMutationRequest,
) => {
  const formData = new FormData();

  // const isUploadedLogo = typeof data.manufacturer_logo === 'string';
  // formData.append('manufacturer_name', data.manufacturer_name);
  // if (data.manufacturer_logo && !isUploadedLogo) {
  //   formData.append('manufacturer_logo', data.manufacturer_logo);
  // }

  const isUploadedPhoto = typeof data.photo === 'string';

  formData.append('name', data.name);

  formData.append('category', String(data.category));

  formData.append('desc', data.desc);

  if (data.price) {
    formData.append('price', String(data.price));
  }

  if (data.barcode) {
    formData.append('barcode', String(data.barcode));
  }

  if (data.photo && !isUploadedPhoto) {
    formData.append('photo', data.photo);
  }

  return formData;
};
