import { StyledDateCell } from './DateCell.styles';

import { DateFormat, formatDate } from '@utils/dateFormat';
import { FC, memo } from 'react';

export interface DateCellProps {
  date: string | Date;
  format: DateFormat;
}

const _DateCell: FC<DateCellProps> = (props) => {
  const { date, format } = props;

  const dateStr = formatDate(date, format);

  return <StyledDateCell>{dateStr}</StyledDateCell>;
};

export const DateCell = memo(_DateCell);
