import { FieldVariant } from './types';

import { colors } from '../utils';

import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Typography } from '../Typography';

const variantStyles: Record<FieldVariant, FlattenSimpleInterpolation | null> = {
  default: null,
  'filled-gray': css`
    padding: 7px 10px;
    background-color: ${colors.rootBackground};
    border-radius: 4px;
  `,
  'filled-white': css`
    padding: 7px 10px;
    background-color: ${colors.white};
    border-radius: 4px;
    height: fit-content;
  `,
};

export const StyledField = styled.div<{
  variant: FieldVariant;
  $marginBottom?: number;
  $isCustomMargin?: boolean;
}>`
  min-height: 55px;

  ${({ $isCustomMargin, $marginBottom }) =>
    $isCustomMargin
      ? css`
          margin-bottom: ${$marginBottom}px;
        `
      : css`
          margin-bottom: 28px;
        `}

  ${({ variant }) => variantStyles[variant]}
`;

export const StyledFieldTip = styled(Typography).attrs({
  color: 'gray1',
  size: 12,
})`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledFieldLabel = styled(Typography).attrs({
  forwardedAs: 'label',
  color: 'gray1',
  size: 12,
})`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledFieldError = styled(Typography).attrs({
  size: 12,
  color: 'danger',
})`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledFieldControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
