import { sectionToComponentDict } from './constants';
import { HalalEdaPageSection } from './types';

import { Tabs } from '@components';
import { TabsTabItem } from '@components/Tabs/types';
import { useAuthProtection } from '@hooks/useAuthProtection';
import { useSearchParam } from '@hooks/useSearchParam';
import { MainLayout } from '@layouts/MainLayout';
import { FC, useMemo } from 'react';

export const HalalEdaPage: FC = () => {
  useAuthProtection();

  const tabs: TabsTabItem<HalalEdaPageSection>[] = useMemo(() => {
    return [
      {
        value: HalalEdaPageSection.Orders,
        label: `Заказы`,
      },
      {
        value: HalalEdaPageSection.Menu,
        label: `Меню`,
      },
      {
        value: HalalEdaPageSection.Establishment,
        label: `Заведение`,
      },
      {
        value: HalalEdaPageSection.Statistics,
        label: `Статистика`,
      },
      {
        value: HalalEdaPageSection.Stock,
        label: `Акции`,
      },
    ];
  }, []);

  const [section, setSection] = useSearchParam<HalalEdaPageSection>({
    paramName: 'section',
    defaultValue: HalalEdaPageSection.Orders,
  });

  return (
    <MainLayout.Content>
      <MainLayout.Content.Header>Доставка еды</MainLayout.Content.Header>
      <Tabs tabs={tabs} value={section} onChange={setSection} />
      {sectionToComponentDict[section]}
    </MainLayout.Content>
  );
};
