import { defaultParams } from './constants';
import {
  StyledSelect,
  StyledSelectCellWithIcon,
  StyledStatusIconContainer,
} from './SelectCellWithIcon.styles';

import { SelectCellWithIconConfig } from './types';

import { SelectOption } from '@packages/uiKit';
import { HTMLAttributes, memo, useMemo } from 'react';

type DivProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>;
export interface SelectCellWithIconProps<ValueType extends string | number>
  extends DivProps {
  config: SelectCellWithIconConfig<ValueType>;
  value: ValueType;
  onChange?: (value: ValueType) => void;
}

const _SelectCellWithIcon = <
  ValueType extends string | number = string | number,
>(
  props: SelectCellWithIconProps<ValueType>,
) => {
  const { value, config, onChange, ...restProps } = props;

  const options: SelectOption<ValueType>[] = useMemo(() => {
    return config.map((confItem) => ({
      value: confItem.value,
      label: confItem.label,
    }));
  }, [config]);

  const { bgColor, color, icon, borderColor } = useMemo(() => {
    const confItem = config.find((confItem) => confItem.value === value);
    if (confItem) {
      const { bgColor, color, icon, borderColor } = confItem;
      return {
        bgColor,
        color,
        icon,
        borderColor: borderColor ?? bgColor,
      };
    }
    return defaultParams;
  }, [config, value]);

  return (
    <StyledSelectCellWithIcon {...restProps}>
      <StyledSelect<ValueType>
        value={value}
        options={options}
        onChange={onChange}
      />
      <StyledStatusIconContainer
        style={{ backgroundColor: bgColor, color, borderColor }}>
        {icon}
      </StyledStatusIconContainer>
    </StyledSelectCellWithIcon>
  );
};

export const SelectCellWithIcon = memo(
  _SelectCellWithIcon,
) as typeof _SelectCellWithIcon;
