import {
  StyledDeleteIcon,
  StyledModalCloseButton,
  StyledModalContent,
  StyledModalHeader,
  StyledModalTitle,
  StyledModalWrapper,
  StyledOverlay,
  StyledPopover,
} from './Modal.styles';

import { useDisableBodyScroll } from '../hooks';

import { PopoverPortal } from '@packages/uiKit/PopoverPortal';
import React, { FC, PropsWithChildren } from 'react';

export interface ModalProps extends PropsWithChildren {
  className?: string;
  styles?: React.CSSProperties;

  title: string;
  isOpen: boolean;

  onClose?: () => void;
  width?: number;
}

export const Modal: FC<ModalProps> = (props) => {
  const { children, isOpen, onClose, title, width } = props;

  useDisableBodyScroll(isOpen);

  if (!isOpen) return null;

  return (
    <PopoverPortal>
      <StyledPopover>
        <StyledOverlay onClick={onClose} />
        <StyledModalWrapper $width={width}>
          <StyledModalHeader>
            <StyledModalTitle>{title}</StyledModalTitle>
            <StyledModalCloseButton onClick={onClose}>
              <StyledDeleteIcon />
            </StyledModalCloseButton>
          </StyledModalHeader>
          <StyledModalContent>{children}</StyledModalContent>
        </StyledModalWrapper>
      </StyledPopover>
    </PopoverPortal>
  );
};
