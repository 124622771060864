import {
  StyledContainer,
  StyledHeaderRow,
  StyledPagination,
  StyledTable,
  StyledTableBody,
  StyledTableBodyRow,
  StyledTableDataCell,
  StyledTableDataCellContentWrapper,
  StyledTableHead,
  StyledTableHeader,
  StyledTableWrapper,
} from './Table.styles';

import { TableColumn } from './types';

import { PaginationProps } from '@packages/uiKit/Pagination';
import cn from 'classnames';
import React, { memo } from 'react';
import { Row, TableOptions, useFlexLayout, useTable } from 'react-table';

export type TableProps<T extends {} = {}> = {
  className?: string;
  style?: React.CSSProperties;

  data: T[];
  columns: TableColumn<T>[];

  getRowId?: TableOptions<T>['getRowId'];

  getRowProps?: (row: Row<T>) => JSX.IntrinsicElements['tr'] | undefined;

  pagination?: PaginationProps;

  scroll?: boolean;
  hideHeader?: boolean;
};

const _Table = <T extends {} = {}>(props: TableProps<T>) => {
  const {
    data,
    columns,
    getRowId,
    getRowProps,
    pagination,
    scroll,
    hideHeader,
    className,
    style,
    ...restProps
  } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data,
        columns,
        getRowId,
      },
      useFlexLayout,
    );

  return (
    <StyledContainer className={className} style={style}>
      <StyledTableWrapper>
        <StyledTable
          {...getTableProps()}
          {...restProps}
          $enableScroll={scroll ?? true}>
          {!hideHeader && (
            <StyledTableHead>
              {headerGroups.map((headerGroup) => {
                return (
                  <StyledHeaderRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <StyledTableHeader {...header.getHeaderProps()}>
                          {header.render('Header')}
                        </StyledTableHeader>
                      );
                    })}
                  </StyledHeaderRow>
                );
              })}
            </StyledTableHead>
          )}

          <StyledTableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              let rowProps = row.getRowProps();

              if (getRowProps) {
                const { className, style, key, ...restAddProps } =
                  getRowProps(row) ?? {};

                if (className) {
                  rowProps.className = cn(rowProps.className, className);
                }
                if (style) {
                  rowProps.style = { ...rowProps.style, ...style };
                }

                rowProps = {
                  ...rowProps,
                  ...restAddProps,
                };
              }

              return (
                <StyledTableBodyRow {...rowProps}>
                  {row.cells.map((cell) => {
                    const isOverflow =
                      (cell.column as TableColumn<T>).overflow ?? true;

                    return (
                      <StyledTableDataCell {...cell.getCellProps()}>
                        <StyledTableDataCellContentWrapper
                          $isOverflow={isOverflow}>
                          {cell.render('Cell')}
                        </StyledTableDataCellContentWrapper>
                      </StyledTableDataCell>
                    );
                  })}
                </StyledTableBodyRow>
              );
            })}
          </StyledTableBody>
        </StyledTable>
      </StyledTableWrapper>

      {pagination && <StyledPagination {...pagination} />}
    </StyledContainer>
  );
};

export const Table = memo(_Table) as typeof _Table;
