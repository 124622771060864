import {
  ApiDateString,
  ApiPaginationMainResponse,
  ApiPaginationParams,
  ApiRecordId,
} from '@features/api';

export enum HalalBonusTransactionStatus {
  Accrual = 'Начисление',
  Withdrawal = 'Снятие',
}

export type HalalBonusTransactionRecord = {
  id: ApiRecordId;
  status: HalalBonusTransactionStatus;
  user: {
    full_name: string;
    tel: number | null;
  };
  created_at: ApiDateString;
  amount: number;
  points: number;
  balance: number | null;
};

export type PatchHalalBonusTransactionRecord = {
  status?: HalalBonusTransactionStatus;
};

export type GetHalalBonusTransactionPageRequest = ApiPaginationParams & {
  user?: number;
};

export type GetHalalBonusTransactionPageResponse =
  ApiPaginationMainResponse<HalalBonusTransactionRecord>;

export type GetTransactionsStatsRequest = {
  period: string;
};

export type TopUser = {
  full_name: string;
  tel: bigint;
  total_points: number;
};

export type TransactionsChart = {
  month: number;
  poins_take: number;
  points_give: number;
  transaction_count: number;
};

export type GetTransactionsStatsResponse = {
  id: number;
  total_hits: number;
  tel_hits: number;
  green_transactions: {
    count: number;
    ratio: number;
  };
  complaints: {
    count: number;
    ratio: number;
  };
  transaction_count: number;
  points_take: number;
  points_give: number;
  top_users: TopUser[];
  transactions_chart: TransactionsChart[];
};

export type PatchHalalEdaOrderPageRequest = {
  id: ApiRecordId;
  patch: PatchHalalBonusTransactionRecord;
};
