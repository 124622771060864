import { scrollbarStylesLightVariant } from '@utils/scrollBarStyles';
import styled from 'styled-components';

export const StyledHalalBonusTable = styled.div``;

export const StyledScrollXContainer = styled.div`
  overflow-x: auto;
  margin-bottom: 20px;

  ${scrollbarStylesLightVariant}
`;
