import { ReturnStatusCell } from './ReturnStatusCell';

import { ToDetailCell } from '@components/table/ToDetailCell';
import {
  HalalShopReturnMockRecord,
  HalalShopReturnStatus,
} from '@features/halalShop/returns/types';

import { TableColumn, TableProps, Typography, colors } from '@packages/uiKit';
import { formatDate } from '@utils/dateFormat';

export type GetColumnsParams = {
  onChangeStatus: (
    record: HalalShopReturnMockRecord,
    status: HalalShopReturnStatus,
  ) => void;
  onClickToDetail: (record: HalalShopReturnMockRecord) => void;
};
export const getColumns = (
  params: GetColumnsParams,
): TableColumn<HalalShopReturnMockRecord>[] => [
  {
    Header: 'Статус',
    accessor: 'status',
    overflow: false,
    width: 250,
    Cell: ({ value, row: { original } }) => {
      const changeHandler = (status: HalalShopReturnStatus) => {
        params.onChangeStatus(original, status);
      };
      return <ReturnStatusCell value={value} onChange={changeHandler} />;
    },
  },
  {
    Header: 'Номер заказа',
    accessor: 'id',
    Cell: ({ value }) => <Typography weight={700}>{value}</Typography>,
  },
  {
    Header: 'Сумма',
    accessor: 'amount',
    Cell: ({ value }) => (
      <Typography weight={700}>{value.toLocaleString()} ₸</Typography>
    ),
  },

  {
    Header: 'Заказ',
    accessor: 'order',
    Cell: ({ value }) => <>{/* <CartCell items={value} /> */}</>,
  },

  {
    Header: 'Время',
    accessor: 'created',
    Cell: ({ value }) => (
      <Typography>{formatDate(value, 'dateTime')}</Typography>
    ),
  },
  {
    Header: 'Клиент',
    accessor: 'client',
  },
  {
    Header: 'Номер телефона',
    accessor: 'phone',
    minWidth: 200,
    Cell: ({ value, row: { original } }) => (
      <ToDetailCell onClickToDetail={() => params.onClickToDetail(original)}>
        <Typography nowrap>{value}</Typography>
      </ToDetailCell>
    ),
  },
];

export const getRowId = (item: HalalShopReturnMockRecord) => item.id.toString();

export const getRowProps: TableProps<HalalShopReturnMockRecord>['getRowProps'] =
  (item) => {
    if (
      item.original.status === HalalShopReturnStatus.Delivered ||
      item.original.status === HalalShopReturnStatus.Rejected
    ) {
      return {
        style: {
          color: colors.gray1,
        },
      };
    }
  };
