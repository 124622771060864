import React, { FC, memo } from 'react';

import { ListAsTableRow } from '@components';
import { Space } from '@components/Space';
import { ReviewRecord } from '@features/reviews';
import { ReviewRatingCell } from '@pages/ReviewsPage/ReviewTable/ReviewRatingCell';
import { ReviewTextCell } from '@pages/ReviewsPage/ReviewTable/ReviewTextCell';
import { formatDate } from '@utils/dateFormat';
import { StyledUserReviewsSection } from './styles';

export interface UserReviewsSectionProps {
  className?: string;
  style?: React.CSSProperties;

  data: ReviewRecord[];

  onClickMakeAnswer: (item: ReviewRecord) => void;
}

const _UserReviewsSection: FC<UserReviewsSectionProps> = (props) => {
  const { data, onClickMakeAnswer, ...restProps } = props;

  return (
    <StyledUserReviewsSection {...restProps}>
      <ListAsTableRow>
        {data.map((item) => {
          const onMakeAnswerClickHandler = () => {
            onClickMakeAnswer(item);
          };
          return (
            <ListAsTableRow.Item key={item.id}>
              <ListAsTableRow.Label
                label={formatDate(item.create, 'dateTime')}
              />
              <ReviewRatingCell rating={item.rating} />
              <Space size={15} />
              <ReviewTextCell
                isAnswered={item.is_replied}
                onMakeAnswerClick={onMakeAnswerClickHandler}>
                {item.msg}
              </ReviewTextCell>
            </ListAsTableRow.Item>
          );
        })}
      </ListAsTableRow>
    </StyledUserReviewsSection>
  );
};

export const UserReviewsSection = memo(_UserReviewsSection);
