import { FinanceStatusCell } from './FinanceStatusCell';

import { FileCell } from '@components/table/FileCell';
import {
  HalalShopFinanceRecord,
  HalalShopFinanceStatus,
} from '@features/halalShop/finances/types';
import { colors, TableColumn, TableProps, Typography } from '@packages/uiKit';
import { formatDate } from '@utils/dateFormat';

export type GetColumnsParams = {};
export const getColumns = (
  params: GetColumnsParams,
): TableColumn<HalalShopFinanceRecord>[] => [
  {
    Header: 'Статус',
    accessor: 'status',
    width: 170,
    canResize: false,
    Cell: ({ value }) => {
      return <FinanceStatusCell value={value} />;
    },
  },
  {
    Header: 'Дата',
    accessor: 'created',
    width: 120,
    canResize: false,
    Cell: ({ value }) => <Typography>{formatDate(value, 'date')}</Typography>,
  },
  {
    Header: 'Сумма',
    accessor: 'amount',
    width: 200,
    canResize: false,

    Cell: ({ value }) => (
      <Typography weight={700}>{value.toLocaleString()} ₸</Typography>
    ),
  },

  {
    Header: 'Номер',
    accessor: 'id',
    width: 120,
    canResize: false,
    Cell: ({ value }) => <Typography weight={700}>#{value}</Typography>,
  },

  {
    Header: 'Тип оплаты',
    accessor: 'orderIds',
    maxWidth: 400,
    minWidth: 200,

    Cell: ({ value }) => (
      <Typography nowrap ellipsis>
        Оплата за услуги размещения
      </Typography>
    ),
  },

  {
    Header: 'Документ',
    accessor: 'document',
    width: 120,
    canResize: false,
    Cell: ({ value, row: { original } }) => (
      <FileCell href={value}>Акт #{original.id}</FileCell>
    ),
  },
];

export const getRowId = (item: HalalShopFinanceRecord) => item.id.toString();

export const getRowProps: TableProps<HalalShopFinanceRecord>['getRowProps'] = (
  item,
) => {
  if (item.original.status === HalalShopFinanceStatus.Replenishment) {
    return {
      style: {
        color: colors.gray1,
      },
    };
  }
};
