import { CloseIcon, MinusIcon, PlusIcon } from '@packages/icons';
import { Button, colors, Image, Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledOrderItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  grid-template-areas:
    'photo name count delete'
    'photo sum count delete';
  column-gap: 13px;
  row-gap: 8px;
  margin-top: 24px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${colors.gray2};
`;

export const StyledImage = styled(Image).attrs({ size: 50 })`
  grid-area: photo;
`;

export const StyledName = styled(Typography)`
  grid-area: name;
  line-height: 120%;
`;

export const StyledSum = styled(Typography).attrs({ size: 16, weight: 700 })`
  grid-area: sum;
`;

export const StyledCount = styled.div.attrs({ size: 16, weight: 700 })`
  grid-area: count;
  border-radius: 12px;
  display: flex;
  gap: 8px;
  height: 24px;
  background: ${colors.gray2};
`;

export const StyledCountMinusBtn = styled(Button).attrs({
  icon: <MinusIcon size={16} />,
  variant: 'text',
})`
  padding: 0 8px;
  border-radius: 12px 0 0 12px;
`;

export const StyledCountNumber = styled(Typography).attrs({
  weight: 700,
})`
  flex: 1;
  text-align: center;
  align-self: center;
`;

export const StyledCountPlusBtn = styled(Button).attrs({
  icon: <PlusIcon size={16} />,
  variant: 'text',
})`
  padding: 0 8px;
  border-radius: 0 12px 12px 0;
`;

export const StyledDeleteBtn = styled(Button).attrs({
  icon: <CloseIcon size={16} />,
  variant: 'text',
})`
  padding: 3px;
`;

export const StyledDeleteArea = styled.div`
  grid-area: delete;
`;
