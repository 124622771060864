import { Card } from '@components/Card/Card';
import { Typography } from '@packages/uiKit';
import styled, { css } from 'styled-components';

export const StyledChartCard = styled(Card)<{ withRight: boolean }>`
  display: grid;

  > * {
    min-width: 0;
  }

  ${({ withRight }) =>
    withRight
      ? css`
          grid-template-columns: 1fr auto;
          grid-template-areas:
            'title right'
            'bottom right';
        `
      : css`
          grid-template-columns: 1fr;
          grid-template-areas:
            'title'
            'bottom';
        `}

  column-gap: 17px;
  row-gap: 13px;
`;

export const StyledTitle = styled(Typography).attrs({
  size: 22,
  weight: 300,
})`
  grid-area: title;
`;

export const StyledRightArea = styled.div`
  grid-area: right;
  display: flex;
  align-items: center;
`;

export const StyledBottomArea = styled.div`
  grid-area: bottom;
`;
