import { SelectCellWithIconConfig } from '@components/table/SelectCellWithIcon/types';
import { BookingStatus } from '@features/booking/store/types';
import {
  AlarmIcon,
  CheckCircleOutlinedIcon,
  ProhibitIcon,
} from '@packages/icons';
import { colors } from '@packages/uiKit';

export const statusConfig: SelectCellWithIconConfig<BookingStatus> = [
  {
    value: BookingStatus.New,
    label: 'Новый',
    bgColor: colors.danger,
    color: colors.white,
    icon: <AlarmIcon size={16} />,
  },
  {
    value: BookingStatus.Completed,
    label: 'Обработано',
    bgColor: colors.primary,
    color: colors.white,
    icon: <CheckCircleOutlinedIcon size={16} />,
  },
  {
    value: BookingStatus.Canceled,
    label: 'Закрыто',
    bgColor: colors.gray2,
    color: colors.white,
    icon: <ProhibitIcon size={16} />,
  },
];
