import { useEditBookingForm, UseEditBookingFormParams } from './hooks';
import { StyledButtonsContainer } from './styles';

import { BookingFormFields } from '../BookingFormFields';

import { bookingsApi } from '@features/booking/store';
import { BookingRecord } from '@features/booking/store/types';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { UncontrolledFormProvider } from '@hooks/useUncontrolledFromContext';
import { Button, Modal } from '@packages/uiKit';
import React, { FC, memo, useCallback } from 'react';

export type EditBookingModalProps = {
  className?: string;
  style?: React.CSSProperties;
  onClose: () => void;
} & (
  | { isOpen: false; bookingItem?: BookingRecord }
  | { isOpen: true; bookingItem: BookingRecord }
);

const EditBookingModalInner: FC<{
  bookingItem: BookingRecord;
  onClose: () => void;
}> = ({ bookingItem, onClose }) => {
  const createNotification = useNotification();

  const [patchBooking] = bookingsApi.usePatchBookingMutation();
  const [deleteBookingM] = bookingsApi.useDeleteBookingMutation();

  const onSubmitHandler: UseEditBookingFormParams['onSubmit'] = useCallback(
    async (data) => {
      try {
        await patchBooking({
          id: bookingItem.id,
          patch: {
            ...data,
            note: data.note ?? '',
            phone: data.phone,
            time: data.time.toISOString(),
          },
        }).unwrap();
        onClose();

        createNotification({
          message: 'Бронь успешно изменена',
          type: 'success',
        });
      } catch {
        createNotification({
          message: 'Произошла ошибка при изменении брони',
          type: 'error',
        });
      }
    },
    [bookingItem.id, createNotification, onClose, patchBooking],
  );

  const deleteHandler = useCallback(async () => {
    try {
      await deleteBookingM({ id: bookingItem.id }).unwrap();

      onClose();

      createNotification({
        message: 'Бронь успешно Удалена',
        type: 'success',
      });
    } catch {
      createNotification({
        message: 'Произошла ошибка при удалении брони',
        type: 'error',
      });
    }
  }, [bookingItem.id, createNotification, deleteBookingM, onClose]);

  const initValues = {
    ...bookingItem,
    time: new Date(bookingItem.time),
  };

  const form = useEditBookingForm({
    initValues,
    onSubmit: onSubmitHandler,
  });
  return (
    <UncontrolledFormProvider {...form}>
      <BookingFormFields />
      <StyledButtonsContainer>
        <Button onClick={form.handleSubmit} size="large">
          Сохранить
        </Button>
        <Button size="large" variant="outlined" onClick={deleteHandler}>
          Удалить бронь
        </Button>
      </StyledButtonsContainer>
    </UncontrolledFormProvider>
  );
};

const _EditBookingModal: FC<EditBookingModalProps> = (props) => {
  const { isOpen, onClose, bookingItem, ...restProps } = props;

  return (
    <Modal
      isOpen={isOpen}
      title="Изменить бронь"
      onClose={onClose}
      {...restProps}>
      {isOpen && (
        <EditBookingModalInner bookingItem={bookingItem} onClose={onClose} />
      )}
    </Modal>
  );
};

export const EditBookingModal = memo(_EditBookingModal);
