import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  GetClientDetailResponse,
  GetClientsRequest,
  GetClientsResponse,
  PushNotificationRequest,
} from './types';

export const GREEN_BONUS_CLIENTS_TAG = 'GREEN_BONUS_CLIENTS_TAG' as const;

export const greenBonusClientsApi = createApi({
  reducerPath: 'greenBonusClientsApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'green-bonus/users' }),
  tagTypes: [GREEN_BONUS_CLIENTS_TAG],
  endpoints: (builder) => {
    return {
      getClients: builder.query<GetClientsResponse, GetClientsRequest>({
        providesTags: [GREEN_BONUS_CLIENTS_TAG],
        query: (params) => ({
          url: '/',
          method: 'GET',
          params,
        }),
      }),
      getClientDetail: builder.query<GetClientDetailResponse, { id: number }>({
        providesTags: [GREEN_BONUS_CLIENTS_TAG],
        query: (params) => ({
          url: `/${params.id}`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const pushNotificationApi = createApi({
  reducerPath: 'pushNotificationApi',
  baseQuery: getQueryWithNoRefresh({ subpath: '' }),

  endpoints: (builder) => {
    return {
      sendPushNotification: builder.mutation<any, PushNotificationRequest>({
        query: (body) => {
          const formData = new FormData();

          if (body.pic) {
            formData.append('pic', body.pic);
          }
          if (body.body) {
            formData.append('body', body.body);
          }
          if (body.href) {
            formData.append('href', body.href);
          }
          if (body.title) {
            formData.append('title', body.title);
          }
          if (body.push_token) {
            formData.append('push_token', body.push_token);
          }

          return {
            url: '/push-notification/',
            method: 'POST',
            body: formData,
          };
        },
      }),
    };
  },
});
