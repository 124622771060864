import { HalalTradingCompanyInfoValues } from './types';

export const companyInfoInitValuesPlaceholder: HalalTradingCompanyInfoValues = {
  account: '',
  comission: '',
  date_from: '',
  id: 0,
  managerName: '',
  number: null,
};
