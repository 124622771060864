import React, { FC, memo } from 'react';

import { ListAsTableRow, Section } from '@components';
import { GetClientDetailResponse } from '@features/halalBonus/clients/store';
import { formatDate } from '@utils/dateFormat';
import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';
import { StyledMainInfoClient } from './styles';

export interface MainInfoClientProps {
  className?: string;
  style?: React.CSSProperties;

  data: GetClientDetailResponse;
}

const calculateDaysPassed = (last: Date, current: string) => {
  if (!last || !current) return 0;
  const lastDate = new Date(last);
  const currentDate = new Date(current);

  const timeDifference = currentDate.getTime() - lastDate.getTime();

  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

  return daysDifference;
};

const _MainInfoClient: FC<MainInfoClientProps> = (props) => {
  const { data, ...restProps } = props;

  const theDate = new Date();

  const firstVisitDate = formatDate(data.first_visit_date, 'date');
  const lastVisitDate = formatDate(data.last_visit_date, 'date');

  const currentDate = formatDate(theDate, 'date2');

  const absentDays = calculateDaysPassed(data.last_visit_date, currentDate)
    ? calculateDaysPassed(data.last_visit_date, currentDate)
    : 0;

  return (
    <StyledMainInfoClient {...restProps}>
      <Section title={`Клиент · ${data.name}`}>
        <ListAsTableRow>
          <ListAsTableRow.Item>
            <ListAsTableRow.Label label="ID" />
            <ListAsTableRow.Value value={`${data.id}`} />
          </ListAsTableRow.Item>

          <ListAsTableRow.Item>
            <ListAsTableRow.Label label="Имя" />
            <ListAsTableRow.Value value={`${data.name}`} />
          </ListAsTableRow.Item>

          <ListAsTableRow.Item>
            <ListAsTableRow.Label label="Телефон" />
            <ListAsTableRow.Value value={`${data.tel}`} />
          </ListAsTableRow.Item>

          <ListAsTableRow.Item>
            <ListAsTableRow.Label label="Накоплено бонусов" />
            <ListAsTableRow.Value
              value={`${
                data.bonus_points
                  ? formatPriceVisualFriendly(data.bonus_points)
                  : 'неизвестно'
              }`}
            />
          </ListAsTableRow.Item>

          <ListAsTableRow.Item>
            <ListAsTableRow.Label label="Потрачено средств" />
            <ListAsTableRow.Value
              value={`${formatPriceVisualFriendly(data.spent_amount)}  ₸`}
            />
          </ListAsTableRow.Item>

          <ListAsTableRow.Item>
            <ListAsTableRow.Label label="Количество транзакций" />
            <ListAsTableRow.Value value={`${data.transaction_count}`} />
          </ListAsTableRow.Item>

          <ListAsTableRow.Item>
            <ListAsTableRow.Label label="Общее количество посещений" />
            <ListAsTableRow.Value value={`${data.visits_count}`} />
          </ListAsTableRow.Item>

          <ListAsTableRow.Item>
            <ListAsTableRow.Label label="Дата первого визита" />
            <ListAsTableRow.Value value={firstVisitDate} />
          </ListAsTableRow.Item>

          <ListAsTableRow.Item>
            <ListAsTableRow.Label label="Дата последнего визита" />
            <ListAsTableRow.Value value={lastVisitDate} />
          </ListAsTableRow.Item>

          <ListAsTableRow.Item>
            <ListAsTableRow.Label label="Сколько прошло дней с последнего визита" />
            <ListAsTableRow.Value value={absentDays} />
          </ListAsTableRow.Item>
        </ListAsTableRow>
      </Section>
    </StyledMainInfoClient>
  );
};

export const MainInfoClient = memo(_MainInfoClient);
