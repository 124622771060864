import { StyledMain } from './styles';

import { HalalShopOrderFormValues } from '../types';

import { isNeedPostCode } from '../utils';

import { FormSection } from '@components/FormSection';
import { MessageWithIcon } from '@components/MessageWithIcon';
import { getCurrencySymbol } from '@features/currency/hooks/getCurrencySymbol';

import {
  DeliveryRecord,
  HalalShopOrderStatus,
} from '@features/halalShop/store/types';
import { AirplaneTiltIcon } from '@packages/icons';
import { Field, Typography } from '@packages/uiKit';
import { InputFieldAdapter } from '@packages/uiKit/fieldAdapters/InputFieldAdapter';
import { InputNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputNumberFieldAdapter';
import { InputPhoneNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputPhoneNumberFieldAdapter';
import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';
import { Col, Row } from 'antd';
import React, { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';

export interface MainProps {
  className?: string;
  style?: React.CSSProperties;

  deliveryData: DeliveryRecord;
  orderStatus: HalalShopOrderStatus;
}

const _Main: FC<MainProps> = (props) => {
  const { deliveryData, orderStatus, ...restProps } = props;

  const { control } = useFormContext<HalalShopOrderFormValues>();

  return (
    <StyledMain {...restProps}>
      <FormSection title="Информация">
        <Row gutter={15}>
          <Col span={8}>
            <InputFieldAdapter control={control} name="firstName" label="Имя" />
          </Col>
          <Col span={8}>
            <InputFieldAdapter
              control={control}
              name="lastName"
              label="Фамилия"
            />
          </Col>
          <Col span={8}>
            <InputFieldAdapter
              control={control}
              name="middleName"
              label="Отчество"
            />
          </Col>
          <Col span={8}>
            <InputPhoneNumberFieldAdapter
              control={control}
              name="phone"
              label="Номер телефона"
            />
          </Col>
          <Col span={8}>
            <InputFieldAdapter
              control={control}
              name="email"
              label="Электронная почта"
            />
          </Col>
          <Col span={8}>
            {/* <InputFieldAdapter
              control={control}
              name="postCode"
              label="Индекс"
            /> */}
          </Col>
        </Row>
      </FormSection>
      <FormSection title="Адрес доставки">
        <Row gutter={15}>
          <Col span={12}>
            <InputFieldAdapter control={control} name="street" label="Улица" />
          </Col>
          <Col span={4}>
            <InputNumberFieldAdapter
              control={control}
              name="house"
              label="Дом"
            />
          </Col>
          <Col span={4}>
            <InputNumberFieldAdapter
              control={control}
              name="apt"
              label="Квартира"
            />
          </Col>
          <Col span={4}>
            <InputFieldAdapter
              control={control}
              name="postCode"
              label="Индекс"
            />
          </Col>
        </Row>
      </FormSection>

      <FormSection title="Способ доставки">
        <Row gutter={15} align="bottom">
          <Col span={8}>
            <Field variant="filled-white" label="Компания">
              <Typography nowrap ellipsis>
                {deliveryData.name}
              </Typography>
            </Field>
          </Col>
          <Col span={8}>
            <Field variant="filled-white" label="Стоимость доставки">
              <Typography nowrap ellipsis>
                {formatPriceVisualFriendly(deliveryData.price.value)}{' '}
                {getCurrencySymbol(deliveryData.price.currency)}
              </Typography>
            </Field>
          </Col>
          {isNeedPostCode(orderStatus) && (
            <Col span={8}>
              <InputFieldAdapter
                control={control}
                name="postId"
                label="Номер отслеживания"
              />
            </Col>
          )}
        </Row>

        {orderStatus === HalalShopOrderStatus.AwaitingShipment && (
          <MessageWithIcon
            icon={<AirplaneTiltIcon />}
            title="Впишите номер заказа для отслеживания отправления."
            description="Клиент сможет отслеживать заказ в процессе доставки."
          />
        )}
      </FormSection>
    </StyledMain>
  );
};

export const Main = memo(_Main);
