import { Image, Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledPushNotificationPreview = styled.div`
  margin-top: 35px;
  position: relative;
`;

export const StyledImageContainer = styled.div`
  height: fit-content;
  display: flex;
  justify-content: center;
`;

export const StyledImage = styled(Image)`
  object-fit: contain;

  border-radius: 48px;
  height: 650px;
  background: #dbdbdb;
`;

export const StyledButtonsContainer = styled.div`
  position: absolute;
  right: 0;
  gap: 5px;
  display: flex;
  flex-direction: column;
  top: 0;
`;

export const StyledWarningTitle = styled(Typography).attrs({ size: 14 })`
  text-align: center;
  padding-top: 45px;
  padding-bottom: 5px;
`;
