import {
  StyledMultiselect,
  StyledSelectedDelete,
  StyledSelectedList,
  StyledSelectItem,
  StyledSelectLabel,
} from './Multiselect.styles';

import { OptionsModal } from './OptionsModal';
import { MultiselectOption } from './types';

import { CloseIcon } from '@packages/icons';
import { Button } from '@packages/uiKit/Button';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';

export interface MultiselectProps<TValue extends string | number> {
  options: MultiselectOption<TValue>[];
  value?: TValue[];

  onChange?: (value: TValue[]) => void;

  className?: string;
  styles?: React.CSSProperties;

  modalTitle: string;
}

const _Multiselect = <TValue extends string | number = string>(
  props: MultiselectProps<TValue>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { options, value, onChange, modalTitle, ...restProps } = props;

  const [isOpen, setIsOpen] = useState(false);

  const onOpenHandler = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onCloseModalHandler = useCallback(() => {
    setIsOpen(false);
  }, []);

  const selectedOptions = useMemo(() => {
    return value && options.filter((opt) => value.includes(opt.value));
  }, [options, value]);

  return (
    <StyledMultiselect ref={ref} {...restProps}>
      <StyledSelectedList>
        {selectedOptions?.map((opt) => {
          const onDeleteHandler =
            onChange &&
            (() => {
              onChange(value?.filter((val) => val !== opt.value) || []);
            });

          return (
            <StyledSelectItem key={opt.value}>
              <StyledSelectLabel key={opt.value}>{opt.label}</StyledSelectLabel>

              <StyledSelectedDelete onClick={onDeleteHandler}>
                <CloseIcon size={12} />
              </StyledSelectedDelete>
            </StyledSelectItem>
          );
        })}
        <Button size="small" onClick={onOpenHandler}>
          Добавить
        </Button>
      </StyledSelectedList>

      <OptionsModal
        options={options}
        title={modalTitle}
        isOpen={isOpen}
        onChange={onChange}
        value={value}
        onClose={onCloseModalHandler}
      />
    </StyledMultiselect>
  );
};

const MultiselectForwardRef = forwardRef(_Multiselect);
MultiselectForwardRef.displayName = 'Multiselect';

export const Multiselect = MultiselectForwardRef as <T extends string | number>(
  props: MultiselectProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof _Multiselect>;
