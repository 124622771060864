import {
  StyledCompanyName,
  StyledLogoImg,
  StyledMainLogo,
  StyledProjectName,
} from './MainLogo.styles';

import logo from '@assets/svgs/logo.svg';

import { FC } from 'react';

export const MainLogo: FC = () => {
  return (
    <StyledMainLogo to="/">
      <StyledLogoImg src={logo} alt="Logo" />
      <StyledCompanyName>Halal Guide</StyledCompanyName>
      <StyledProjectName>My Board</StyledProjectName>
    </StyledMainLogo>
  );
};
