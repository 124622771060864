import { bookingFormSchema } from '../BookingFormFields';
import {
  BookingFormValidValues,
  BookingFormValues,
} from '../BookingFormFields/types';

import { yupResolver } from '@hookform/resolvers/yup';
import { useUncontrolledForm } from '@hooks/useUncontrolledForm';

export type UseEditBookingFormParams = {
  initValues: BookingFormValues;
  onSubmit: (data: BookingFormValidValues) => Promise<void>;
};
export const useEditBookingForm = (params: UseEditBookingFormParams) => {
  const { initValues, onSubmit } = params;

  return useUncontrolledForm<BookingFormValues, BookingFormValidValues>({
    defaultValues: initValues,
    onSubmit,
    mode: 'onBlur',
    resolver: yupResolver(bookingFormSchema),
  });
};
