import {
  StyledDeleteButton,
  StyledImage,
  StyledImagePreviewCard,
} from './ImagePreviewCard.styles';

import { ButtonProps } from '../Button';

import placeHolderImgWhite from '@assets/imgs/placeholderImageWhite.jpg';
import { FC, PropsWithoutRef } from 'react';

type DivProps = PropsWithoutRef<JSX.IntrinsicElements['div']>;
export interface ImagePreviewCardProps extends DivProps {
  src: string;
  onDelete?: () => void;
}

export const ImagePreviewCard: FC<ImagePreviewCardProps> = (props) => {
  const { src, onDelete, ...restProps } = props;

  const deleteClickHandler: ButtonProps['onClick'] = (e) => {
    e.stopPropagation();
    onDelete?.();
  };

  return (
    <StyledImagePreviewCard {...restProps}>
      <StyledImage src={src ? src : placeHolderImgWhite} />
      <StyledDeleteButton onClick={deleteClickHandler} />
    </StyledImagePreviewCard>
  );
};
