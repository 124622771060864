import { Button } from '../Button';

import { Image } from '../Image/Image';

import { TrashIcon } from '@packages/icons';
import styled from 'styled-components';

export const StyledImagePreviewCard = styled.div`
  position: relative;
  height: 116px;
  min-height: 116px;
  width: 219px;
  //width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const StyledImage = styled(Image)`
  width: 100%;

  height: 100%;
  object-fit: cover;
`;

export const StyledDeleteButton = styled(Button).attrs({
  icon: <TrashIcon />,
  color: 'white',
  size: 'medium',
  variant: 'text',
})`
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
