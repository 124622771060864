import { StyledInput } from './Input.styles';

import React, { useMemo } from 'react';

export interface InputPhoneNumberProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (value: string | null) => void;
  error?: boolean;
}

export const InputPhoneNumber = React.forwardRef<
  HTMLInputElement,
  InputPhoneNumberProps
>((props, ref) => {
  const { onChange, error = false, ...restProps } = props;

  const onChangeHandler:
    | React.ChangeEventHandler<HTMLInputElement>
    | undefined = useMemo(() => {
    return onChange
      ? (event) => {
          onChange(event.target.value || null);
        }
      : undefined;
  }, [onChange]);

  return (
    <StyledInput
      inputRef={ref}
      mask={'+7 (999) 999-99-99'}
      onChange={onChangeHandler}
      $error={error}
      {...restProps}
    />
  );
});

InputPhoneNumber.displayName = 'InputPhoneNumber';
