import {
  StyledTooltip,
  StyledTooltipContent,
  StyledTooltipWrapper,
} from './Tooltip.styles';

import React, { FC, PropsWithChildren, ReactNode } from 'react';

export interface TooltipProps extends PropsWithChildren {
  className?: string;
  styles?: React.CSSProperties;
  content?: ReactNode;
  position?: 'left' | 'right' | 'top' | 'bottom';

  isVisible: boolean;
}

export const Tooltip: FC<TooltipProps> = (props) => {
  const {
    children,
    content,
    isVisible = true,
    position = 'right',

    ...restProps
  } = props;

  return (
    <StyledTooltip {...restProps}>
      <StyledTooltipWrapper>{children}</StyledTooltipWrapper>
      <StyledTooltipContent position={position} isVisible={isVisible}>
        {props.content}
      </StyledTooltipContent>
    </StyledTooltip>
  );
};
