import { placeholderStyles } from '../utils/placeholderStyles';

import { inputStyles } from '@packages/uiKit/utils/inputStyles';
import InputMask from 'react-input-mask';

import styled from 'styled-components';

export const StyledInput = styled(InputMask)<{ $error: boolean }>`
  ${({ $error: error }) => inputStyles({ error })}

  ::placeholder {
    ${placeholderStyles}
  }
`;
