import { StyledEstablishmentSection } from './styles';

import { Button, UploadImageInput } from '@packages/uiKit';
import { InputField } from '@packages/uiKit/fields/InputField';
import { InputPhoneNumberField } from '@packages/uiKit/fields/InputPhoneNumberField';
import { SelectField } from '@packages/uiKit/fields/SelectField';
import { Col, Row } from 'antd';
import { FC, memo } from 'react';

const _EstablishmentSection: FC = () => {
  return (
    <StyledEstablishmentSection>
      <Row gutter={60}>
        <Col span={8}>
          <InputField label="Название компании" />
          <SelectField options={[]} label="Основаня категория компании" />
          <UploadImageInput preview="icon" isCropable>
            Загрузить логотип компании
          </UploadImageInput>
        </Col>
        <Col span={16}>
          <Row gutter={15}>
            <Col span={12}>
              <InputPhoneNumberField label="Номер телефона компании" />
            </Col>
            <Col span={12}>
              <InputField label="Менеджер по доставке · Имя и фамилия" />
            </Col>
            <Col span={12}>
              <InputField
                label="Электронная почта"
                placeholder="name@mail.com"
              />
            </Col>
            <Col span={12}>
              <InputPhoneNumberField label="Номер телефона менеджера" />
            </Col>
            <Col span={12}>
              <InputField label="Номер договора" />
            </Col>
            <Col span={6}>
              <InputField label="WhatsApp менеджера" />
            </Col>
            <Col span={6}>
              <InputField label="Telegram менеджера" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={15}>
        <Col>
          <Button size="large">Сохранить изменения</Button>
        </Col>
        <Col>
          <Button size="large" variant="outlined">
            Отменить изменения
          </Button>
        </Col>
      </Row>
    </StyledEstablishmentSection>
  );
};

export const EstablishmentSection = memo(_EstablishmentSection);
