import { useOrderForm } from './hooks';
import { StyledOrderForm, StyledTabContentContainer } from './styles';

import { OrderDetailTab, OrderFormValues } from './types';

import { getSection } from './utils';

import { Tabs } from '@components';
import {
  HalalEdaEstablishmentRecord,
  HalalEdaOrderRecord,
  HalalEdaOrderStatus,
} from '@features/halalEda';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { useSearchParam } from '@hooks/useSearchParam';
import { FC, memo, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { OrderSidebar } from '../OrderSidebar';
import { tabs } from './constants';

export interface OrderFormProps {
  mutableRecord: HalalEdaOrderRecord;
  establishment: HalalEdaEstablishmentRecord;
  onPatch: (data: OrderFormValues) => Promise<void> | void;
  onChangeStatus: (status: HalalEdaOrderStatus) => Promise<void> | void;
}

const _OrderForm: FC<OrderFormProps> = (props) => {
  const {
    mutableRecord,
    establishment,
    onPatch,
    onChangeStatus,
    ...restProps
  } = props;

  const [searchTab, setSearchTab] = useSearchParam<OrderDetailTab>({
    paramName: 'tab',
    defaultValue: OrderDetailTab.Client,
  });

  const tabContent = useMemo(
    () => getSection(searchTab, { mutableRecord }),
    [mutableRecord, searchTab],
  );

  const {
    status,
    cost,
    id,
    client: { balance, ...editableClientValues },
    ...editableValues
  } = mutableRecord;

  const initValues: OrderFormValues = {
    ...editableValues,
    client: {
      ...editableClientValues,
    },
  };

  const form = useOrderForm({
    initValues,
  });

  const createNotification = useNotification();

  const confirmOrderHandler = form.handleSubmit(async (data) => {
    try {
      if (form.formState.isDirty) {
        await onPatch({ ...data });
      }

      await onChangeStatus(HalalEdaOrderStatus.InProgress);

      createNotification({
        message: `Заказ ${id} подтвержден`,
        type: 'success',
      });
    } catch {
      createNotification({
        message: `Произошла ошибка при подтверждении заказа`,
        type: 'error',
      });
    }
  });

  const cancelOrderHandler = form.handleSubmit(async (data) => {
    try {
      if (form.formState.isDirty) {
        await onPatch({ ...data });
      }

      await onChangeStatus(HalalEdaOrderStatus.Cancelled);

      createNotification({
        message: `Заказ ${id} отменен`,
        type: 'success',
      });
    } catch {
      createNotification({
        message: `Произошла ошибка при отмене заказа`,
        type: 'error',
      });
    }
  });

  const completeOrderHandler = form.handleSubmit(async (data) => {
    try {
      if (form.formState.isDirty) {
        await onPatch({ ...data });
      }

      await onChangeStatus(HalalEdaOrderStatus.Completed);

      createNotification({
        message: `Заказ ${id} помечен как выполненный`,
        type: 'success',
      });
    } catch {
      createNotification({
        message: `Произошла ошибка при пометке заказа как выполненного`,
        type: 'error',
      });
    }
  });

  const saveOrderHandler = form.handleSubmit(async (data) => {
    try {
      if (form.formState.isDirty) {
        //await onPatch({ ...data });
      }
      await onPatch({ ...data });

      console.log(form.formState);

      createNotification({
        message: `Заказ ${id} изменен`,
        type: 'success',
      });
    } catch {
      createNotification({
        message: `Произошла ошибка при изменении заказа`,
        type: 'error',
      });
    }
  });

  return (
    <StyledOrderForm {...restProps}>
      <Tabs<OrderDetailTab>
        tabs={tabs}
        value={searchTab}
        onChange={setSearchTab}
      />

      <FormProvider {...form}>
        <StyledTabContentContainer>{tabContent}</StyledTabContentContainer>
        <OrderSidebar
          mutableRecord={mutableRecord}
          establishment={establishment}
          onConfirm={confirmOrderHandler}
          onCancel={cancelOrderHandler}
          onComplete={completeOrderHandler}
          onSave={saveOrderHandler}
        />
      </FormProvider>
    </StyledOrderForm>
  );
};

export const OrderForm = memo(_OrderForm);
