import { Table } from '@packages/uiKit/Table';
import { colors } from '@packages/uiKit/utils/colors';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  .row {
    padding: 2px;
    transition: 0.3s ease;
    cursor: pointer;
    border-radius: 8px;
    border-color: transparent;
    border-width: 1px;
    border-style: solid;

    :hover {
      border-color: ${colors.primary};
    }

    & .row--disabled {
      color: ${colors.gray1};
    }
  }
` as typeof Table;
