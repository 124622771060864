export const formatPriceVisualFriendly = (
  price: number | null,
): string | null => {
  if (price) {
    const formattedPrice = price
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return `${formattedPrice}`;
  }

  if (price === 0) {
    return '0';
  }

  return null;
};
