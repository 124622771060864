import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledTextPlaceholder = styled.div``;

export const StyledTitle = styled(Typography).attrs({
  size: 18,
  color: 'dark1',
  weight: 400,
})``;

export const StyledDescription = styled(Typography).attrs({
  size: 14,
  color: 'gray0',
  weight: 300,
})``;
