import {
  StyledItems,
  StyledPrice,
  StyledQuantity,
  StyledTitle,
  StyledTitleCell,
} from './DeliveryTooltip.styles';

import { HalalEdaOrderRecord } from '@features/halalEda';
import { forwardRef } from 'react';

export interface DeliveryTooltipProps {
  cart: HalalEdaOrderRecord['cart'];
}

export const DeliveryTooltip = forwardRef<HTMLDivElement, DeliveryTooltipProps>(
  (props, ref) => {
    const { cart } = props;

    return (
      <>
        {cart.map((item) => {
          return (
            <StyledItems key={item.id}>
              <StyledTitleCell>
                <StyledTitle>{item.name}</StyledTitle>
              </StyledTitleCell>
              <StyledQuantity>{item.quantity} шт.</StyledQuantity>
              <StyledPrice>{item.cost.toLocaleString()} ₸</StyledPrice>
            </StyledItems>
          );
        })}
      </>
    );
  },
);
