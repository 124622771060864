import { StyledListAsTableRowItem } from './ListAsTableRowItem/ListAsTableRowItem.styles';

import styled from 'styled-components';

export const StyledListAsTableRow = styled.div`
  display: flex;
  flex-direction: column;

  ${StyledListAsTableRowItem}:nth-child(odd) {
    background-color: white;
  }
`;
