import { CatalogSection } from './sections/CatalogSection';
import { CompanyInformationSection } from './sections/CompanyInformationSection';

import { HalalTradingPageSection } from './types';

import { ReactNode } from 'react';

export const sectionToComponentDict: Record<
  HalalTradingPageSection,
  ReactNode
> = {
  catalog: <CatalogSection />,
  myCompany: <CompanyInformationSection />,
};
