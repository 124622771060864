import { TabsTabItem } from '@components';
import { ReturnDetailTab } from './types';

export const tabs: TabsTabItem<ReturnDetailTab>[] = [
  {
    value: ReturnDetailTab.Return,
    label: 'Товар',
  },
  {
    value: ReturnDetailTab.Client,
    label: 'Клиент',
  },
];
