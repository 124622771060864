import { Select } from '@packages/uiKit/Select';
import { Typography } from '@packages/uiKit/Typography';
import { colors } from '@packages/uiKit/utils/colors';
import styled from 'styled-components';

export const StyledWorkTime = styled.div``;

export const StyledHeader = styled.div`
  margin-bottom: 12px;
  display: flex;
  gap: 10px;
`;

const StyledColumnTitle = styled(Typography).attrs({
  size: 12,
  color: 'gray1',
})``;

export const StyledDayTitle = styled(StyledColumnTitle)`
  width: 145px;
  min-width: 145px;
`;

export const StyledTimeTitle = styled(StyledColumnTitle)`
  min-width: 190px;
  flex: 1;
`;

export const StyledRowList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledRow = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledDayCell = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0;
  border-bottom: 1px solid ${colors.gray1};
  width: 145px;
  min-width: 145px;
`;

export const StyledTimeCell = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
`;

export const StyledTimeSelect = styled(Select)`
  flex: 1;
  min-width: 90px;
` as typeof Select;

export const StyledTimeWeekendsCell = styled(Typography).attrs({
  color: 'gray1',
})`
  flex: 1;
  min-width: 190px;
  padding: 8px;
  border-bottom: 1px solid ${colors.gray1};
`;
