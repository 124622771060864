import { inputStyles } from '../utils/inputStyles';
import { placeholderStyles } from '../utils/placeholderStyles';

import { scrollbarStylesLightVariant } from '@utils/scrollBarStyles';

import styled, { css } from 'styled-components';

export const StyledTextArea = styled.textarea<{
  isResizable: boolean;
  error: boolean;
}>`
  ${({ error }) => inputStyles({ error })}

  ${scrollbarStylesLightVariant}

  ::placeholder {
    ${placeholderStyles}
  }

  ${({ isResizable }) =>
    !isResizable &&
    css`
      resize: none;
    `}
`;
