import { colors } from '../utils/colors';

import styled, { css } from 'styled-components';

const colorConfig = {
  primary: {
    filled: {
      textColor: 'white',
      bgColor: colors.primary,
      borderColor: colors.primary,

      hoverTextColor: 'white',
      hoverBgColor: colors.primaryLight,
      hoverBorderColor: colors.primaryLight,
    },

    outlined: {
      textColor: colors.dark1,
      bgColor: 'transparent',
      borderColor: colors.primary,

      hoverTextColor: 'white',
      hoverBgColor: colors.primary,
      hoverBorderColor: colors.primary,
    },
    text: {
      textColor: colors.dark1,
      bgColor: 'transparent',
      borderColor: 'transparent',

      hoverTextColor: 'tranparent',
      hoverBgColor: 'transparent',
      hoverBorderColor: 'transparent',
    },
  },
  white: {
    filled: {
      textColor: colors.dark1,
      bgColor: colors.white,
      borderColor: colors.white,

      hoverTextColor: colors.dark1,
      hoverBgColor: colors.gray5,
      hoverBorderColor: colors.gray5,
    },

    outlined: {
      textColor: colors.dark1,
      bgColor: 'transparent',
      borderColor: colors.gray1,

      hoverTextColor: colors.dark1,
      hoverBgColor: colors.white,
      hoverBorderColor: colors.gray1,
    },
    text: {
      textColor: colors.white,
      bgColor: 'transparent',
      borderColor: 'transparent',

      hoverTextColor: 'tranparent',
      hoverBgColor: 'transparent',
      hoverBorderColor: 'transparent',
    },
  },
  secondary: {
    filled: {
      textColor: colors.gray0,
      bgColor: colors.rootBackground,
      borderColor: colors.rootBackground,

      hoverTextColor: colors.gray0,
      hoverBgColor: colors.gray4,
      hoverBorderColor: colors.gray4,
    },

    outlined: {
      textColor: colors.gray0,
      bgColor: 'transparent',
      borderColor: colors.gray1,

      hoverTextColor: colors.gray1,
      hoverBgColor: colors.white,
      hoverBorderColor: colors.gray1,
    },
    text: {
      textColor: colors.gray0,
      bgColor: 'transparent',
      borderColor: 'transparent',

      hoverTextColor: 'tranparent',
      hoverBgColor: 'transparent',
      hoverBorderColor: 'transparent',
    },
  },
};

const disabledConfig = {
  filled: {
    textColor: colors.gray0,
    bgColor: colors.gray2,
    borderColor: colors.gray2,

    hoverTextColor: colors.gray0,
    hoverBgColor: colors.gray2,
    hoverBorderColor: colors.gray2,
  },

  outlined: {
    textColor: colors.gray0,
    bgColor: 'transparent',
    borderColor: colors.gray1,

    hoverTextColor: colors.gray0,
    hoverBgColor: 'transparent',
    hoverBorderColor: colors.gray1,
  },
  text: {
    textColor: colors.gray0,
    bgColor: 'transparent',
    borderColor: 'transparent',

    hoverTextColor: 'transparent',
    hoverBgColor: 'transparent',
    hoverBorderColor: 'transparent',
  },
};

const sizeConfig = {
  small: {
    paddingVerticalPx: 4,
    paddingHorizontalPx: 16,
    fontSize: 12,
    borderRadius: 12,
  },
  medium: {
    paddingVerticalPx: 10,
    paddingHorizontalPx: 26,
    fontSize: 14,
    borderRadius: 10,
  },
  large: {
    paddingVerticalPx: 16,
    paddingHorizontalPx: 26,
    fontSize: 14,
    borderRadius: 10,
  },
};

const getDynamicParams = (props: {
  size: 'small' | 'medium' | 'large';
  variant: 'filled' | 'outlined' | 'text';
  color: 'primary' | 'white' | 'secondary';
  disabled?: boolean;
}) => {
  const { size, variant, color, disabled } = props;

  const colorProps = disabled
    ? disabledConfig[variant]
    : colorConfig[color][variant];
  const sizeProps = sizeConfig[size];

  return {
    sizeProps,
    colorProps,
  };
};

export const StyledButton = styled.button<{
  size: 'small' | 'medium' | 'large';
  variant: 'filled' | 'outlined' | 'text';
  block: boolean;
  color: 'primary' | 'white' | 'secondary';
  hasIcon: boolean;
  hasLoader: boolean;
  disabled?: boolean;
  onlyIcon: boolean;
  width?: number | null;
}>`
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: block;
  height: fit-content;

  transition: 0.1s ease;

  ${({ width }) =>
    width &&
    css`
      width: 100%;
      max-width: ${width}px;
    `};

  ${({ hasLoader }) =>
    hasLoader &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    `};

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    `};

  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `};

  ${(props) => {
    const { sizeProps, colorProps } = getDynamicParams(props);

    return css`
      padding: ${sizeProps.paddingVerticalPx}px
        ${sizeProps.paddingHorizontalPx}px;
      border-radius: ${sizeProps.borderRadius}px;
      background-color: ${colorProps.bgColor};
      color: ${colorProps.textColor};
      border-color: ${colorProps.borderColor};
      font-size: ${sizeProps.fontSize}px;
      :hover {
        background-color: ${colorProps.hoverBgColor};
        color: ${colorProps.hoverTextColor};
        border-color: ${colorProps.hoverBorderColor};
      }
    `;
  }};

  ${({ onlyIcon }) =>
    onlyIcon &&
    css`
      padding: 6px;
    `}

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          pointer-events: visible;

          opacity: 0.8;
        `
      : css`
          :active {
            opacity: 0.8;
          }
        `}
`;
