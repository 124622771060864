import React, { FC, memo } from 'react';

import { UploadImageInputFieldAdapter } from '@packages/uiKit/fieldAdapters/UploadImageInputFieldAdapter';
import { InputField } from '@packages/uiKit/fields/InputField';
import { SelectField } from '@packages/uiKit/fields/SelectField';
import { Col, Row } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import { TabsTabItem } from '@components';
import { InputNumberField } from '@packages/uiKit/fields/InputNumberField';
import { TextAreaField } from '@packages/uiKit/fields/TextAreaField';
import { ProductHalalTradingValues } from '../types';
import { StyledNewProductHalalTradingForm } from './styles';

export interface NewProductHalalTradingFormProps {
  className?: string;
  style?: React.CSSProperties;

  categoriesTabs: TabsTabItem<string>[];
}

const _ProductHalalTradingForm: FC<NewProductHalalTradingFormProps> = (
  props,
) => {
  const { categoriesTabs, ...restProps } = props;

  const { control, watch } = useFormContext<ProductHalalTradingValues>();

  const desc = watch('desc');

  const descCharactersLeft = 200 - desc.length;

  const descTip =
    descCharactersLeft >= 0
      ? `Осталось ${descCharactersLeft} символов`
      : undefined;

  return (
    <StyledNewProductHalalTradingForm {...restProps}>
      <Row gutter={[15, 15]}>
        <Col span={12}>
          <Controller
            control={control}
            name={'name'}
            render={({ field, fieldState: { error } }) => {
              return (
                <InputField
                  label="Название"
                  {...field}
                  error={error?.message}
                />
              );
            }}
          />
        </Col>

        <Col span={12}>
          <Controller
            control={control}
            name={'price'}
            render={({ field, fieldState: { error } }) => {
              return (
                <InputNumberField
                  label="Цена товара ₽"
                  {...field}
                  error={error?.message}
                />
              );
            }}
          />
        </Col>
        <Col span={12}>
          <Controller
            control={control}
            name={'barcode'}
            render={({ field, fieldState: { error } }) => {
              return (
                <InputField
                  label="Штрихкод"
                  {...field}
                  error={error?.message}
                />
              );
            }}
          />
        </Col>
        <Col span={12}>
          <Controller
            control={control}
            name={'category'}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
            }) => {
              return (
                <SelectField
                  label="Категория"
                  heightDropdown={250}
                  options={categoriesTabs}
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              );
            }}
          />
        </Col>
      </Row>

      <Col span={24}>
        <Controller
          control={control}
          name="desc"
          render={({ field, fieldState: { error } }) => {
            return (
              <TextAreaField
                tip={descTip}
                value={field.value ? field.value : ''}
                onChange={field.onChange}
                label="Описание товара"
                isAutosize
                error={error?.message}
              />
            );
          }}
        />
      </Col>

      <Row gutter={[15, 15]}>
        <Col span={12}>
          <UploadImageInputFieldAdapter
            control={control}
            name="photo"
            label="Фото товара"
            preview="icon"
            color="secondary"
            aspectRatio={50}
            isCropable>
            Загрузить фото товара
          </UploadImageInputFieldAdapter>
        </Col>
      </Row>
    </StyledNewProductHalalTradingForm>
  );
};

export const ProductHalalTradingForm = memo(_ProductHalalTradingForm);
