import { CheckCircleIcon } from '@packages/icons';
import { Typography } from '@packages/uiKit/Typography';
import { colors } from '@packages/uiKit/utils/colors';
import styled from 'styled-components';

export const StyledModalContent = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const StyledModalContentLabel = styled(Typography).attrs({ size: 12 })`
  transition: all 0.2s;
  border-bottom: 1px solid transparent;
`;

export const StyledModalCheckBox = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid ${colors.gray1};
  border-radius: 50%;
  transition: all 0.2s;
`;

export const StyledModalContentItem = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 1px solid ${colors.gray2};
  transition: all 0.5s;
  gap: 8px;
  cursor: pointer;
  &:hover {
    & ${StyledModalContentLabel} {
      border-bottom: 1px solid ${colors.dark1};
    }
    & ${StyledModalCheckBox} {
      border: 1px solid ${colors.dark1};
    }
  }
`;

export const StyledCheckCircle = styled(CheckCircleIcon).attrs({ size: 14 })``;
