import { MainInfoSection } from './MainInfoSection';
import { OrderForm, OrderFormProps } from './OrderForm';

import {
  halalEdaEstablishmentApi,
  halalEdaOrdersApi,
  HalalEdaOrderStatus,
} from '@features/halalEda';
import { useAuthProtection } from '@hooks/useAuthProtection';
import { MainLayout } from '@layouts/MainLayout';
import { OrderDetailLayout } from '@layouts/OrderDetailLayout';
import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';

export const HalalEdaOrderDetailPage: FC = () => {
  useAuthProtection();

  const params = useParams();

  const id = Number(params.id);

  const [patchOrderM] = halalEdaOrdersApi.usePatchOrderMutation();

  const { data: orderDetailData } = halalEdaOrdersApi.useGetOrderDetailQuery({
    id,
  });

  const { data: establishmentData } =
    halalEdaEstablishmentApi.useGetEstablishmentQuery();

  const patchHandler: OrderFormProps['onPatch'] = useCallback(
    async (data) => {
      await patchOrderM({ id, patch: data }).unwrap();
    },
    [id, patchOrderM],
  );

  const changeStatusHandler = useCallback(
    async (status: HalalEdaOrderStatus) => {
      await patchOrderM({ id, patch: { status } }).unwrap();
    },
    [id, patchOrderM],
  );

  return (
    <MainLayout.Content>
      <MainLayout.Content.BackLink>Все заказы</MainLayout.Content.BackLink>
      <OrderDetailLayout.Content>
        {orderDetailData && establishmentData ? (
          <>
            <MainInfoSection
              mutableRecord={orderDetailData}
              onChangeStatus={changeStatusHandler}
            />
            <OrderForm
              mutableRecord={orderDetailData}
              establishment={establishmentData}
              onPatch={patchHandler}
              onChangeStatus={changeStatusHandler}
            />
          </>
        ) : null}
      </OrderDetailLayout.Content>
    </MainLayout.Content>
  );
};
