import { StyledTabItem, StyledTabs } from './Tabs.styles';

import { TabsTabItem, TabsVariant } from '@components/Tabs/types';
import React, { useCallback, useState } from 'react';

export interface TabsProps<
  ValueType extends string | number = string | number,
> {
  className?: string;
  style?: React.CSSProperties;
  tabs: TabsTabItem<ValueType>[];
  value?: ValueType;
  onChange?: (value: ValueType) => void;
  variant?: TabsVariant;

  isExpandable?: boolean;
  visibleAmount?: number;
}

export const Tabs = <ValueType extends string | number = string | number>(
  props: TabsProps<ValueType>,
) => {
  const {
    tabs,
    value,
    onChange,
    variant = 'default',
    isExpandable,
    visibleAmount = 10,
    ...restProps
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(
    isExpandable ? true : false,
  );

  const expandButtonHandler = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const mutableTabs = isExpandable
    ? isExpanded
      ? tabs.slice(0, visibleAmount)
      : tabs
    : tabs;

  return (
    <StyledTabs variant={variant} {...restProps}>
      {mutableTabs.map((tab) => {
        const isSelected = value ? value === tab.value : false;
        const onClickHandler =
          !isSelected && onChange
            ? () => {
                onChange(tab.value);
              }
            : undefined;

        return (
          <StyledTabItem
            key={tab.value}
            color={isSelected ? 'dark1' : 'gray1'}
            badge={tab.badge}
            onClick={onClickHandler}
            isSelected={isSelected}
            variant={variant}>
            {tab.label}
          </StyledTabItem>
        );
      })}
      {isExpandable && tabs.length > visibleAmount && (
        <StyledTabItem
          variant={'round'}
          isShowMoreTab={true}
          onClick={expandButtonHandler}>
          {isExpanded ? 'показать все' : 'скрыть'}
        </StyledTabItem>
      )}
    </StyledTabs>
  );
};
