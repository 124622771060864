import {
  StyledNavbarNavs,
  StyledNavLinkIconContainer,
  StyledNavLinkLabel,
  StyledNavLink,
  StyledNavButton,
  StyledNavButtonIconContainer,
  StyledNavButtonLabel,
} from './NavbarNavs.styles';

import { FC, PropsWithChildren } from 'react';

export interface NavbarLinkProps {
  to: string;
  label: string;
  icon: React.ReactNode;
}

const NavbarLink: FC<NavbarLinkProps> = (props) => {
  const { to, label, icon } = props;

  return (
    <StyledNavLink to={to}>
      <StyledNavLinkIconContainer>{icon}</StyledNavLinkIconContainer>
      <StyledNavLinkLabel>{label}</StyledNavLinkLabel>
    </StyledNavLink>
  );
};

export interface NavbarButtonProps {
  label: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

const NavbarButton: FC<NavbarButtonProps> = (props) => {
  const { onClick, label, icon } = props;

  return (
    <StyledNavButton onClick={onClick}>
      <StyledNavButtonIconContainer>{icon}</StyledNavButtonIconContainer>
      <StyledNavButtonLabel>{label}</StyledNavButtonLabel>
    </StyledNavButton>
  );
};

export const NavbarNavs: FC<PropsWithChildren> & {
  Link: typeof NavbarLink;
  Button: typeof NavbarButton;
} = (props) => {
  const { children } = props;

  return <StyledNavbarNavs>{children}</StyledNavbarNavs>;
};

NavbarNavs.Link = NavbarLink;
NavbarNavs.Button = NavbarButton;
