import { colors } from '../utils/colors';

import styled, { css } from 'styled-components';

const getDynamicParams = (props: { value: boolean; withTips: boolean }) => {
  const { value, withTips } = props;

  const switchWidthPx = withTips ? 58 : 36;
  const circleLeftOffsetPx = value ? switchWidthPx - 3 - 14 - 1 : 3 + 1;
  const circleBackgroundColor = value ? 'white' : colors.gray1;
  const switchBackgroundColor = value ? colors.primary : 'transparent';
  const switchBorderColor = value ? colors.primary : colors.gray3;

  const tipsColor = value ? 'white' : colors.gray1;
  const tipsLeftOpacity = value ? 1 : 0;
  const tipsRightOpacity = value ? 0 : 1;

  return {
    switchWidthPx,
    switchBackgroundColor,
    switchBorderColor,
    circleLeftOffsetPx,
    circleBackgroundColor,
    tipsColor,
    tipsLeftOpacity,
    tipsRightOpacity,
  };
};

export const StyledSwitchCircle = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);

  transition: 0.3s ease;
`;

const StyledTip = styled.div`
  position: absolute;
  width: 36px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 12px;
  line-height: 120%;
  user-select: none;

  transition: 0.3s ease;
`;

export const StyledLeftTip = styled(StyledTip)`
  left: 1px;
`;

export const StyledRightTip = styled(StyledTip)`
  right: 1px;
`;

export const StyledSwitch = styled.div<{
  value: boolean;
  withTips: boolean;
}>`
  position: relative;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  padding: 3px;
  transition: 0.3s ease;
  cursor: pointer;

  height: 20px;

  ${(props) => {
    const {
      switchBackgroundColor,
      switchBorderColor,
      switchWidthPx,
      circleBackgroundColor,
      circleLeftOffsetPx,
      tipsColor,
      tipsLeftOpacity,
      tipsRightOpacity,
    } = getDynamicParams(props);

    return css`
      width: ${switchWidthPx}px;
      background-color: ${switchBackgroundColor};
      border-color: ${switchBorderColor};

      & ${StyledSwitchCircle} {
        background-color: ${circleBackgroundColor};
        left: ${circleLeftOffsetPx}px;
      }

      & ${StyledTip} {
        color: ${tipsColor};
      }

      & ${StyledLeftTip} {
        opacity: ${tipsLeftOpacity};
      }

      & ${StyledRightTip} {
        opacity: ${tipsRightOpacity};
      }
    `;
  }}
`;
