import { ReviewAnswerFormValidValues, ReviewAnswerFormValues } from './types';

import { yupResolver } from '@hookform/resolvers/yup';
import { useUncontrolledForm } from '@hooks/useUncontrolledForm';
import * as yup from 'yup';
export const validationSchema: yup.SchemaOf<ReviewAnswerFormValues> = yup
  .object({
    reply: yup.string().nullable().required('Заполните поле ваш ответ клиенту'),
  })
  .required();

export type UseReviewAnswerFormParams = {
  onSubmit: (data: ReviewAnswerFormValues) => Promise<void>;
  initValues: ReviewAnswerFormValues;
};
export const useReviewAnswerForm = (params: UseReviewAnswerFormParams) => {
  const { onSubmit, initValues } = params;

  return useUncontrolledForm<
    ReviewAnswerFormValues,
    ReviewAnswerFormValidValues
  >({
    defaultValues: initValues,
    onSubmit,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
};
