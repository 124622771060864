import { colors } from '@packages/uiKit/utils/colors';
import DatePicker from 'antd/lib/date-picker';
import styled, { css } from 'styled-components';

export const StyledDatePicker = styled(DatePicker)<{ error: boolean }>`
  width: 100%;
  background-color: transparent;
  border: none;
  border-width: 0;
  padding: 8px 0;
  border-radius: 0;

  & .ant-picker-input input {
    line-height: 120%;
    font-size: 16px;
  }

  &.ant-picker-focused {
    box-shadow: none;
  }

  &.ant-picker-focused,
  :hover {
    border-right-width: 0;
  }

  ${({ error }) =>
    error
      ? css`
          border-bottom: 1px solid ${colors.danger};

          &.ant-picker-focused,
          :hover {
            border-color: ${colors.danger};
          }
        `
      : css`
          border-bottom: 1px solid ${colors.gray1};

          &.ant-picker-focused,
          :hover {
            border-color: ${colors.gray0};
          }
        `}
`;
