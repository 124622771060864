import {
  StyledButtonsContainer,
  StyledCatalogSection,
  StyledHeader,
  StyledTabs,
} from './styles';

import { TabsTabItem } from '@components';
import { useModalState } from '@hooks/useModalState';
import { useSearchParam } from '@hooks/useSearchParam';
import { Button, Modal } from '@packages/uiKit';
import { FC, memo } from 'react';
import { ProductCardsGrid } from './ProductCardsGrid';

const tabs: TabsTabItem[] = [
  {
    label: 'Все',
    value: 'Все',
  },
  {
    label: 'Женская одежда',
    value: 'Женская одежда',
  },
  {
    label: 'Продукты питания',
    value: 'Продукты питания',
  },
  {
    label: 'Мужская одежда',
    value: 'Мужская одежда',
  },
  {
    label: 'Аксессуары',
    value: 'Аксессуары',
  },
];

const _CatalogSection: FC = () => {
  const [categorySearch, setCategorySearch] = useSearchParam<string>({
    paramName: 'category',
    defaultValue: 'Все',
  });

  const [modalAddProduct, setModalAddProductActions] = useModalState();

  return (
    <StyledCatalogSection>
      <StyledHeader>
        <StyledTabs
          tabs={tabs}
          value={categorySearch}
          onChange={setCategorySearch}
          variant="round"
          isExpandable
          visibleAmount={20}
        />
        <StyledButtonsContainer>
          <Button onClick={setModalAddProductActions.openHandler}>
            Добавить товар
          </Button>
        </StyledButtonsContainer>
      </StyledHeader>
      <ProductCardsGrid />
      <Modal
        title="Добавить товар"
        isOpen={modalAddProduct.isOpen}
        onClose={setModalAddProductActions.closeHandler}>
        Модуль находится в разработке
      </Modal>
      {/* <Pagination pageCount={} pageIndex={} onChangePage={} /> */}
    </StyledCatalogSection>
  );
};

export const CatalogSection = memo(_CatalogSection);
