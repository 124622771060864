import {
  StyledHintStrongText,
  StyledHintText,
  StyledInfoContainer,
  StyledMainContactInfo,
  StyledPhoneInfoContainer,
} from './MainContactInfo.styles';

import { ReactComponent as PhoneIcon } from '../../../assets/svgs/phone.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/svgs/telegram.svg';
import { ExternalLink } from '../../../components/ExternalLink';
import { InfoContainer } from '../../../components/InfoContainer';

import { WhatsAppOutlined } from '@ant-design/icons';
import { FC } from 'react';

export type MainContactInfoProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const MainContactInfo: FC<MainContactInfoProps> = (props) => {
  return (
    <StyledMainContactInfo {...props}>
      <StyledHintText>
        Появились вопросы?{' '}
        <StyledHintStrongText>Ваш менеджер:</StyledHintStrongText>
      </StyledHintText>
      <StyledInfoContainer>
        <ExternalLink href="tel: +7 708 392-6755">
          <StyledPhoneInfoContainer color={'primary'} type={'outlined'}>
            <PhoneIcon />
            <span>+7 708 392-6755</span>
          </StyledPhoneInfoContainer>
        </ExternalLink>

        <ExternalLink
          newFolder
          href="https://api.whatsapp.com/send?phone=77083926755&text=Здравствуйте, мне нужна консультация">
          <InfoContainer color={'primary'} type={'filled'}>
            <WhatsAppOutlined />
          </InfoContainer>
        </ExternalLink>

        <ExternalLink newFolder href="https://t.me/saukend">
          <InfoContainer color={'#5B8CC6'} type={'filled'}>
            <TelegramIcon height={14} width={14} />
          </InfoContainer>
        </ExternalLink>
      </StyledInfoContainer>
    </StyledMainContactInfo>
  );
};
