import { Field } from '@packages/uiKit/Field';
import { TextArea, TextAreaProps } from '@packages/uiKit/TextArea';
import React, { forwardRef } from 'react';

export interface TextAreaFieldProps extends Omit<TextAreaProps, 'error'> {
  className?: string;
  styles?: React.CSSProperties;

  label?: string;
  tip?: string;
  error?: string;
}

export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>((props, ref) => {
  const { label, style, className, error, tip, ...restProps } = props;

  return (
    <Field label={label} error={error} tip={tip}>
      <TextArea ref={ref} error={Boolean(error)} {...restProps} />
    </Field>
  );
});

TextAreaField.displayName = 'TextAreaField';
