import {
  DeleteBookingRequest,
  GetBookingPageRequest,
  GetBookingPageResponse,
  PatchBookingPageRequest,
  PostBookingRequest,
} from './types';

import { ApiRecordId } from '@features/api';
import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const BOOKINGS_TAG = 'BOOKINGS_TAG' as const;

const getBookingItemTag = (id: ApiRecordId) => ({
  type: BOOKINGS_TAG,
  id,
});

export const bookingsApi = createApi({
  reducerPath: 'bookingsApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'bookings' }),
  tagTypes: [BOOKINGS_TAG],
  endpoints: (builder) => {
    return {
      getBookingPage: builder.query<
        GetBookingPageResponse,
        GetBookingPageRequest
      >({
        providesTags: (response) => [
          BOOKINGS_TAG,
          ...(response?.result.map((item) => getBookingItemTag(item.id)) ?? []),
        ],
        query: (params) => ({
          url: '/',
          method: 'GET',
          params,
        }),
      }),

      postBooking: builder.mutation<void, PostBookingRequest>({
        invalidatesTags: [BOOKINGS_TAG],
        query: (request) => ({
          url: `/`,
          method: 'POST',
          body: request,
        }),
      }),

      patchBooking: builder.mutation<void, PatchBookingPageRequest>({
        invalidatesTags: (_, _2, request) => [getBookingItemTag(request.id)],
        query: (request) => ({
          url: `/${request.id}/`,
          method: 'PATCH',
          body: request.patch,
        }),
      }),

      deleteBooking: builder.mutation<void, DeleteBookingRequest>({
        invalidatesTags: (_, _2, request) => [
          BOOKINGS_TAG,
          getBookingItemTag(request.id),
        ],
        query: (request) => ({
          url: `/${request.id}/`,
          method: 'DELETE',
        }),
      }),
    };
  },
});
