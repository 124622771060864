import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';
import {
  StyledCount,
  StyledCountMinusBtn,
  StyledCountNumber,
  StyledCountPlusBtn,
  StyledDeleteArea,
  StyledDeleteBtn,
  StyledImage,
  StyledName,
  StyledOrderItem,
  StyledSum,
} from './OrderItem.styles';

import React, { FC } from 'react';

export interface OrderItemProps {
  className?: string;
  style?: React.CSSProperties;

  photo: string;
  name: string;
  summary: number;
  count: number;

  onChangeCount: (count: number) => void;
  onDelete: () => void;
}

export const OrderItem: FC<OrderItemProps> = (props) => {
  const { photo, name, summary, count, onChangeCount, onDelete, ...restProps } =
    props;

  const onClickMinusHandler = () => {
    const resCount = count - 1;
    if (resCount <= 0) {
      onDelete();
      return;
    }
    onChangeCount(resCount);
  };
  const onClickPlusHandler = () => {
    onChangeCount(count + 1);
  };

  return (
    <StyledOrderItem {...restProps}>
      <StyledImage src={photo} placeholderType="gray" />

      <StyledName>{name}</StyledName>
      <StyledSum>{formatPriceVisualFriendly(summary)} ₸</StyledSum>

      <StyledCount>
        <StyledCountMinusBtn onClick={onClickMinusHandler} />
        <StyledCountNumber>{count}</StyledCountNumber>
        <StyledCountPlusBtn onClick={onClickPlusHandler} />
      </StyledCount>

      <StyledDeleteArea>
        <StyledDeleteBtn onClick={onDelete} />
      </StyledDeleteArea>
    </StyledOrderItem>
  );
};
