import { ReactComponent as CaretLeftSvg } from '@assets/svgs/caretLeft.svg';
import { Typography } from '@packages/uiKit/Typography';
import styled from 'styled-components';

export const StyledBackLink = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-bottom: 24px;
`;

export const StyledCaretLeft = styled(CaretLeftSvg)`
  width: 14px;
  height: 14px;
`;

export const StyledTitle = styled(Typography).attrs({ size: 12 })``;
