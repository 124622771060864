import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledStatusCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledStatusLabel = styled(Typography).attrs({
  nowrap: true,
  ellipsis: true,
})`
  flex: 1;
  min-width: 0;
`;

export const StyledStatusIconContainer = styled.div`
  border-radius: 50%;
  height: 26px;
  width: 26px;
  padding: 5px;
  font-size: 16px;
`;
