import { EstablishmentForm, EstablishmentFormProps } from './EstablishmentForm';

import { StyledLangSelect } from './EstablishmentPage.styles';

import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';

import { establishmentApi } from '@features/establishments';
import { useNotification } from '@features/notifications/hooks/useNotification';

import { useAuthProtection } from '@hooks/useAuthProtection';

import { MainLayout } from '@layouts/MainLayout';
import { SelectOption } from '@packages/uiKit/Select';

import { FC, useCallback, useState } from 'react';

const selectOptions: SelectOption<string>[] = [
  {
    value: 'ru',
    label: 'Языковая версия · Русский',
  },
  { value: 'eng', label: 'Языковая версия · English' },
];

export const EstablishmentPage: FC = () => {
  useAuthProtection();

  const createNotification = useNotification();

  const [langVersion, setLangVersion] = useState<string | null | undefined>(
    'ru',
  );

  const {
    data,
    isLoading: isLoadingQuery,
    isSuccess: isSuccessQuery,
  } = establishmentApi.useGetEstablishmentQuery({});

  const [putEstablishmentM] = establishmentApi.usePutEstablishmentMutation();

  const [putEstablishmentPhotoM] =
    establishmentApi.usePutEstablishmentPhotoMutation();

  const [putEstablishmentLogoM] =
    establishmentApi.usePutEstablishmentLogoMutation();

  const [putEstablishmentPlacePhotosM] =
    establishmentApi.usePutEstablishmentPlacePhotoMutation();

  const onSubmitHandler: EstablishmentFormProps['onSubmit'] = useCallback(
    async (data, changedPhotos) => {
      const { logo, photo, place_photo } = changedPhotos;

      const promises = [putEstablishmentM(data).unwrap()];

      if (photo) {
        promises.push(
          putEstablishmentPhotoM(data.photo as File | null).unwrap(),
        );
      }
      if (logo) {
        promises.push(putEstablishmentLogoM(data.logo as File | null).unwrap());
      }
      if (place_photo) {
        const value = data.place_photo.map((photo) =>
          photo instanceof File ? photo : photo.id,
        );
        promises.push(putEstablishmentPlacePhotosM(value).unwrap());
      }

      try {
        await Promise.all(promises);
        createNotification({
          message: 'Описание заведения изменено',
          type: 'success',
        });
      } catch {
        createNotification({
          message: 'Произошла ошибка при изменении описании заведения',
          type: 'error',
        });
      } finally {
      }
    },
    [
      createNotification,
      putEstablishmentLogoM,
      putEstablishmentM,
      putEstablishmentPhotoM,
      putEstablishmentPlacePhotosM,
    ],
  );

  return (
    <MainLayout.Content>
      <MainLayout.Content.Header
        right={
          <StyledLangSelect<string>
            options={selectOptions}
            value={langVersion}
            onChange={setLangVersion}
            variant="filled"
          />
        }>
        Описание моего заведения
      </MainLayout.Content.Header>

      {isLoadingQuery ? (
        <Loader />
      ) : (
        isSuccessQuery && (
          <>
            <EstablishmentForm initValues={data} onSubmit={onSubmitHandler} />
          </>
        )
      )}
      {!isSuccessQuery && !isLoadingQuery && <TextPlaceholder />}
    </MainLayout.Content>
  );
};
