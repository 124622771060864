import { StyledLayout } from './AnalyticPage.styles';

import { Loader } from '@components/Loader';

import { InfoCard, SearchesAndRequestDynamicCard } from '@features/analytics';
import { analyticsApi } from '@features/analytics/store';
import { useAuthProtection } from '@hooks/useAuthProtection';
import { MainLayout } from '@layouts/MainLayout';
import {
  ChatTeardropTextIcon,
  EyeIcon,
  InstagramIcon,
  PhoneCallIcon,
  ShareIcon,
  TruckIcon,
  UserCirclePlusIcon,
} from '@packages/icons';
import { Col, Row } from 'antd';
import { FC } from 'react';

export const AnalyticsPage: FC = () => {
  useAuthProtection();

  // const [searchSource, setSearchSource] = useSearchParam<AnalyticPageSource>({
  //   paramName: 'source',
  //   defaultValue: AnalyticPageSource.All,
  // });

  // const [searchPeriod, setSearchPeriod] = useSearchParam<string>({
  //   paramName: 'period',
  //   defaultValue: 'year',
  // });

  const { data: analyticsData } = analyticsApi.useGetAnalyticsQuery({});

  return (
    <MainLayout.Content>
      <MainLayout.Content.Header>
        Аналитика по заведению
      </MainLayout.Content.Header>
      {/* <Tabs tabs={sourceTabs} value={searchSource} onChange={setSearchSource} />
      <Tabs
        variant="round"
        tabs={periodTabs}
        value={searchPeriod}
        onChange={setSearchPeriod}
      /> */}
      {analyticsData ? (
        <>
          <StyledLayout>
            <Row gutter={[10, 10]}>
              <Col span={6}>
                <InfoCard
                  value={analyticsData?.total_hits}
                  icon={<EyeIcon size={36} />}
                  description="Просмотров заведения всего"
                  color={'primary'}
                />
              </Col>
              <Col span={6}>
                <InfoCard
                  value={analyticsData?.tel_hits}
                  icon={<PhoneCallIcon size={36} />}
                  description="Просмотр контактов"
                  color={'primary'}
                />
              </Col>
              <Col span={6}>
                <InfoCard
                  value={analyticsData?.hits}
                  icon={<UserCirclePlusIcon size={36} />}
                  description="Просмотрено через приложение"
                  color={'primary'}
                />
              </Col>
              <Col span={6}>
                <InfoCard
                  value={0}
                  icon={<ShareIcon size={36} />}
                  description="Поделились заведением"
                  color={'primary'}
                />
              </Col>
              <Col span={6}>
                <InfoCard
                  value={analyticsData.review_count}
                  icon={<ChatTeardropTextIcon />}
                  description="Оставили отзыв"
                  color={'primary'}
                />
              </Col>
              <Col span={6}>
                <InfoCard
                  value={analyticsData.route_hits}
                  icon={<TruckIcon />}
                  description="Проложено маршрутов"
                  color={'primary'}
                />
              </Col>
              <Col span={6}>
                <InfoCard
                  value={analyticsData.website_hits}
                  icon={<EyeIcon size={36} />}
                  description="Переходы на сайт с HalalGuide"
                  color={'primary'}
                />
              </Col>
              <Col span={6}>
                <InfoCard
                  value={analyticsData.web_hits}
                  icon={<EyeIcon size={36} />}
                  description="Просмотров заведения на HalalGuide"
                  color={'primary'}
                />
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <SearchesAndRequestDynamicCard analyticsData={analyticsData} />
              </Col>
              <Col span={6}>
                <InfoCard
                  value={analyticsData.instagram_hits}
                  icon={<InstagramIcon size={32} />}
                  description="Просмотров заведения в Instagram"
                  color={'primary'}
                />
              </Col>
            </Row>

            {/* <SiteRequestionsCard value={2576} percent={15} /> */}
          </StyledLayout>
        </>
      ) : (
        <Loader />
      )}
    </MainLayout.Content>
  );
};
