import { ApiImageUrl } from '@features/api';
import { fileValidation } from '@features/forms/validation';
import * as yup from 'yup';

export const pushNotificationFormSchema = yup
  .object({
    title: yup.string().required('Заполните поле заголовка'),

    mainText: yup.string().nullable().required('Заполните поле сообщения'),
    link: yup.string().nullable().required('Заполните поле ссылки'),
    file: fileValidation({
      mime: ['image/jpeg', 'image/png'],
    }).required('Загрузите файл'),
    buttonTitle: yup.string().optional(),
  })
  .required();

export type PushNotificationFormValues = {
  title: string;
  mainText: string;
  link: string;
  file: ApiImageUrl | File | null;
};
