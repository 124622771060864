import React, { FC, memo } from 'react';

import { StyledListAsTableRowItemValue } from './styles';

export interface ListAsTableRowItemValueProps {
  className?: string;
  style?: React.CSSProperties;

  value: string | number | null;
}

const _ListAsTableRowItemValue: FC<ListAsTableRowItemValueProps> = (props) => {
  const { value, ...restProps } = props;

  return (
    <StyledListAsTableRowItemValue {...restProps}>
      {value}
    </StyledListAsTableRowItemValue>
  );
};

export const ListAsTableRowItemValue = memo(_ListAsTableRowItemValue);
