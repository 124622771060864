import React, { FC } from 'react';

import { Column, ColumnConfig } from '@ant-design/plots';
import { Section } from '@components';
import { ChartCard } from '@features/analytics/components/common/ChartCard';
import { MainLayout } from '@layouts/MainLayout';
import { Field, Typography, colors } from '@packages/uiKit';
import { employees } from '@pages/HalalBonusPage/sections/EmployeesSection/mock';
import { Col, Row } from 'antd';
import { StyledHalalBonusEmployeeDetailPage } from './HalalBonusEmployeeDetailPage.styles';

export interface HalalBonusEmployeeDetailPageProps {
  className?: string;
  style?: React.CSSProperties;
}

type DataItem = {
  period: string;
  amount: number;
  isTheBest?: boolean;
};

const data: DataItem[] = [
  {
    period: 'Ноя. 2024',
    amount: 1020,
  },
  {
    period: 'Дек. 2024',
    amount: 1250,
  },
  {
    period: 'Янв. 2024',
    amount: 1400,
  },
  {
    period: 'Фев. 2024',
    amount: 2500,
  },
  {
    period: 'Мар. 2024',
    amount: 7500,
    isTheBest: true,
  },
  {
    period: 'Апр. 2024',
    amount: 2000,
  },
  {
    period: 'Май 2024',
    amount: 3500,
  },
  {
    period: 'Июнь 2024',
    amount: 1000,
  },
  {
    period: 'Июль 2024',
    amount: 3200,
  },
  {
    period: 'Авг. 2024',
    amount: 2100,
  },
  {
    period: 'Сен. 2024',
    amount: 1100,
  },
];

const config: ColumnConfig = {
  data,

  height: 250,
  xField: 'period',
  yField: 'amount',
  legend: false as const,
  yAxis: {
    maxLimit: 10000,
    label: {
      formatter: (value) => `${value} ₽`, // Customize the label format
    },
  },

  seriesField: 'isTheBest',

  color: (item) => {
    if ((item as DataItem).isTheBest) {
      return colors.primaryDark3;
    }

    return colors.primary;
  },
  tooltip: {
    fields: ['amount'],
  },

  meta: {
    amount: {
      alias: 'Продажи в ₽ ',
    },
  },
};

export const HalalBonusEmployeeDetailPage: FC<
  HalalBonusEmployeeDetailPageProps
> = (props) => {
  const { ...restProps } = props;

  return (
    <StyledHalalBonusEmployeeDetailPage {...restProps}>
      <MainLayout.Content.BackLink>Сотрудники</MainLayout.Content.BackLink>
      <Section title="Информация о сотруднике">
        <Row gutter={[10, 25]}>
          <Col span={4}>
            <Field label={'id'} variant="filled-white">
              <Typography size={16} nowrap ellipsis lineHeight={140}>
                {employees[0].id}
              </Typography>
            </Field>
          </Col>
          <Col span={4}>
            <Field label={'Имя'} variant="filled-white">
              <Typography size={16} nowrap ellipsis lineHeight={140}>
                {employees[0].name}
              </Typography>
            </Field>
          </Col>
          <Col span={4}>
            <Field label={'Должность'} variant="filled-white">
              <Typography size={16} nowrap ellipsis lineHeight={140}>
                {employees[0].jobTitle}
              </Typography>
            </Field>
          </Col>
          <Col span={4}>
            <Field label={'Скачиваний'} variant="filled-white">
              <Typography size={16} nowrap ellipsis lineHeight={140}>
                {employees[0].downloads}
              </Typography>
            </Field>
          </Col>
          <Col span={4}>
            <Field label={'Бонусы'} variant="filled-white">
              <Typography size={16} nowrap ellipsis lineHeight={140}>
                {employees[0].points}
              </Typography>
            </Field>
          </Col>
          <Col span={4}>
            <Field label={'Транзакции'} variant="filled-white">
              <Typography size={16} nowrap ellipsis lineHeight={140}>
                {employees[0].transactions}
              </Typography>
            </Field>
          </Col>
        </Row>
        <ChartCard title="Количество продаж сотрудником за год">
          <Column {...config} />
        </ChartCard>
      </Section>
    </StyledHalalBonusEmployeeDetailPage>
  );
};
