import {
  StyledButton,
  StyledDropdown,
  StyledDropdownIconButton,
} from './styles';

import { OutsideClick } from '../OutsideClick';

import { Placement } from '@popperjs/core';
import React, { FC, memo, ReactNode, useCallback, useState } from 'react';
import { usePopper } from 'react-popper';

export interface DropdownIconButtonProps {
  className?: string;
  style?: React.CSSProperties;

  dropdown: ReactNode;
  placement?: Placement;

  setIsVisible: (value: boolean) => void;

  isVisible: boolean;
}

const _DropdownIconButton: FC<DropdownIconButtonProps> = (props) => {
  const { dropdown, placement, isVisible, setIsVisible, ...restProps } = props;

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [8, 8],
        },
      },
    ],
  });

  const clickOutsideHandler = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  return (
    <StyledDropdownIconButton {...restProps}>
      <OutsideClick onClickOutside={clickOutsideHandler} isEnabled={isVisible}>
        <StyledButton ref={setReferenceElement} />

        {isVisible && (
          <StyledDropdown
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}>
            {dropdown}
          </StyledDropdown>
        )}
      </OutsideClick>
    </StyledDropdownIconButton>
  );
};

export const DropdownIconButton = memo(_DropdownIconButton);
