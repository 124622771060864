import React, { FC, memo } from 'react';

import { CitiesModal } from '@features/cities/components/CitiesModal';
import { pushNotificationApi } from '@features/halalBonus/clients';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { useModalState } from '@hooks/useModalState';
import { Button, Field, TextArea } from '@packages/uiKit';
import { AccordionExpandable } from '@packages/uiKit/AccordionExpandable';
import { UploadImageInputFieldAdapter } from '@packages/uiKit/fieldAdapters/UploadImageInputFieldAdapter';
import { InputField } from '@packages/uiKit/fields/InputField';
import { Col, Row } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { PushNotificationPreview } from '../PushNotificationPreview';
import {
  StyledButtonContainer,
  StyledPreviewAccordionTitle,
  StyledPushModalContent,
} from './styles';
import { PushNotificationFormValues } from './types';

export interface PushModalContentProps {
  className?: string;
  style?: React.CSSProperties;

  onCloseModal: () => void;
}

const kirilToken =
  'eaRCBwkoyUhXrltEUpc61I:APA91bHF77K84dshOf1RjUVDUqxfHB5CQqjr8NvgPAl6t7TdiUIKPbrZgjFx8XWOtZNSm6eeThKs6U9sSaY5xm098Noi0SBaMKqvtRh4yxHI7-htw52eDGwz7PnmpOXZUe1azMG8Amll';

const _PushModalContent: FC<PushModalContentProps> = (props) => {
  const { onCloseModal, ...restProps } = props;

  const createNotification = useNotification();

  const { control, handleSubmit, reset } =
    useFormContext<PushNotificationFormValues>();

  const [sendPushNotificationM] =
    pushNotificationApi.useSendPushNotificationMutation();

  const onSubmit = async (data: PushNotificationFormValues) => {
    const { title, mainText, link, file } = data;

    try {
      await sendPushNotificationM({
        body: mainText,
        href: link,
        pic: file as File | null,
        title: title,
        push_token: kirilToken,
      }).unwrap();

      //console.log(res);

      createNotification({
        message: 'Пуш-уведомление успешно отправлено',
        type: 'success',
      });
      onCloseModal();
    } catch (e) {
      //@ts-ignore
      //console.log(e.data[0].msg[0]);
      createNotification({
        //@ts-ignore
        message: `${e.data[0].msg[0]}`,
        type: 'error',
      });
    }
  };

  const onCloseHandler = () => {
    reset();
    onCloseModal();
  };

  const [modalCities, modalCitiesActions] = useModalState();

  return (
    <StyledPushModalContent {...restProps}>
      <Controller
        control={control}
        name="title"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <InputField
              value={value}
              onChange={onChange}
              error={error?.message}
              label="Заголовок уведомления"
            />
          );
        }}
      />
      <Controller
        control={control}
        name="mainText"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Field label="Текст сообщения" error={error?.message}>
              <TextArea
                value={value}
                onChange={onChange}
                isAutosize
                error={error ? true : false}
              />
            </Field>
          );
        }}
      />

      <Controller
        control={control}
        name="link"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <InputField
              value={value}
              onChange={onChange}
              label="Ссылка"
              error={error?.message}
            />
          );
        }}
      />

      <Row gutter={25}>
        <Col span={24}>
          <UploadImageInputFieldAdapter
            control={control}
            name="file"
            label="Загрузка креатива"
            isCropable
            aspectRatio={1}
            cropType="image/jpeg"
            preview="card">
            Загрузить файл
          </UploadImageInputFieldAdapter>
        </Col>
        {/*  <Col span={8}>
          <Field label="Выберите город">
            <Button
              icon={<EyeIcon />}
              variant="outlined"
              color="secondary"
              onClick={modalCitiesActions.openHandler}
              size="large">
              Москва
            </Button>
          </Field>
        </Col>
        <Col span={8}>
          <Field label="Выберите страну">
            <Button
              icon={<PlusCircleIcon />}
              variant="outlined"
              color="secondary"
              size="large">
              Россия
            </Button>
          </Field>
        </Col> */}
      </Row>

      <AccordionExpandable
        customTitle={
          <StyledPreviewAccordionTitle>
            Предпросмотр
          </StyledPreviewAccordionTitle>
        }>
        <PushNotificationPreview />
      </AccordionExpandable>
      <StyledButtonContainer>
        <div style={{ display: 'flex', gap: '15px' }}>
          <Button onClick={handleSubmit(onSubmit)} size="large">
            Отправить
          </Button>
        </div>
        <Button onClick={onCloseHandler} size="large" variant="outlined">
          Отменить
        </Button>
      </StyledButtonContainer>
      <CitiesModal
        isOpen={modalCities.isOpen}
        onClose={modalCitiesActions.closeHandler}
      />
    </StyledPushModalContent>
  );
};

export const PushModalContent = memo(_PushModalContent);
