import { BackLink } from './BackLink';
import { StyledContent } from './Content.styles';
import { ContentHeader } from './ContentHeader';

import { FC, PropsWithChildren } from 'react';

export interface ContentProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}

export const Content: FC<ContentProps> & {
  Header: typeof ContentHeader;
  BackLink: typeof BackLink;
} = (props) => {
  const { children, ...restProps } = props;

  return <StyledContent {...restProps}>{children}</StyledContent>;
};

Content.Header = ContentHeader;
Content.BackLink = BackLink;
