import {
  StyledContent,
  StyledSidebarForm,
  StyledSwitchListContainer,
} from './styles';

import { SwitchFieldAdapter } from './SwitchFieldAdapter';

import { CuisinesMultiselectFieldAdapter } from '@features/cuisines';
import { useUncontrolledFormContext } from '@hooks/useUncontrolledFromContext';
import { Sidebar } from '@layouts/MainLayout/Sidebar';
import { InputNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputNumberFieldAdapter';
import { EstablishmentEditFormValues } from '@pages/EstablishmentPage/EstablishmentForm/types';

import { Col, Row } from 'antd';
import React, { FC } from 'react';

export const SidebarForm: FC = React.memo(() => {
  const { control } = useUncontrolledFormContext<EstablishmentEditFormValues>();

  return (
    <StyledSidebarForm width={500}>
      <Sidebar.Header>Особенности</Sidebar.Header>
      <StyledContent>
        <StyledSwitchListContainer>
          <SwitchFieldAdapter
            control={control}
            label="Курение в вашем заведении"
            name="cafe.smoking"
          />
          <SwitchFieldAdapter
            control={control}
            label="Наличие сети Wi-Fi"
            name="cafe.wifi"
          />
          {/* <SwitchFieldAdapter
            control={control}
            label="Спиртное в заведении"
            name="cafe.drink"
          /> */}
          <SwitchFieldAdapter
            control={control}
            label="Оплата банковской картой"
            name="cafe.card"
          />
          <SwitchFieldAdapter
            control={control}
            label="Наличие парковки"
            name="parking"
          />
          <SwitchFieldAdapter
            control={control}
            label="Бронирование столиков"
            name="has_booking"
          />
          <SwitchFieldAdapter
            control={control}
            label="Сертификат халяль"
            name="cafe.certificate"
          />
          <SwitchFieldAdapter
            control={control}
            label="Проведение банкетов"
            name="cafe.holding_banquets"
          />
          <SwitchFieldAdapter
            control={control}
            label="Сертификат халяль на мясо"
            name="cafe.halal_meat"
          />
          <SwitchFieldAdapter
            control={control}
            label="Владелец заведения - мусульманин"
            name="cafe.muslim_owner"
          />
          {/*<SwitchFieldAdapter
            control={control}
            label="Склад"
            name="cafe.storage"
          /> */}
          <SwitchFieldAdapter
            control={control}
            label="Главный повар - мусульманин"
            name="cafe.muslim_cook"
          />
          <SwitchFieldAdapter
            control={control}
            label="Алкоголь в блюдах"
            name="cafe.alcohol_in_meat"
          />
          <SwitchFieldAdapter
            control={control}
            label="Халяль десерты"
            name="cafe.halal_dessert"
          />
          <SwitchFieldAdapter
            control={control}
            label="Есть свинина"
            name="cafe.has_pork"
          />
        </StyledSwitchListContainer>

        <CuisinesMultiselectFieldAdapter
          control={control}
          name="cafe.cuisines"
          modalTitle="Выберите кухни"
          label="Стили кухни в вашем заведении"
        />

        <Row gutter={16}>
          <Col span={24}>
            <InputNumberFieldAdapter
              control={control}
              name="cafe.avarege_order"
              label="Средний чек заказа"
              placeholder="0"
              right={'₸'}
            />
          </Col>
        </Row>
      </StyledContent>
    </StyledSidebarForm>
  );
});
