import {
  StyledDescription,
  StyledHead,
  StyledIconContainer,
  StyledInfo,
  StyledInfoCard,
  StyledMainValue,
} from './InfoCard.styles';

import { Badge, colors } from '@packages/uiKit';
import React, { FC } from 'react';

export interface InfoCardProps {
  className?: string;
  style?: React.CSSProperties;

  value: number | string;
  percent?: number;
  description: string;
  icon: React.ReactNode;
  color: 'primary' | 'danger';
}

export const InfoCard: FC<InfoCardProps> = (props) => {
  const {
    value: mainValue,
    percent,
    description,
    icon,
    color,
    ...restProps
  } = props;

  return (
    <StyledInfoCard {...restProps}>
      <StyledInfo>
        <StyledHead>
          <StyledMainValue>{mainValue.toLocaleString()}</StyledMainValue>
          {percent && <Badge color={color}>{percent} %</Badge>}
        </StyledHead>
        <StyledDescription>{description}</StyledDescription>
      </StyledInfo>
      <StyledIconContainer style={{ color: colors[color] }}>
        {icon}
      </StyledIconContainer>
    </StyledInfoCard>
  );
};
