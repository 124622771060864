import { ApiDateString, ApiRecordId } from '@features/api';

export enum DaysOfTheWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export type DiscountExceptionsRecord = {
  day_of_week: DaysOfTheWeek;
  id: number;
  end_time: string | null;
  start_time: string | null;
  value: number | null;
  active: boolean;
  temp_id: number;
};

export type DiscountExceptions = {
  1: DiscountExceptionsRecord[] | null;
  2: DiscountExceptionsRecord[] | null;
  3: DiscountExceptionsRecord[] | null;
  4: DiscountExceptionsRecord[] | null;
  5: DiscountExceptionsRecord[] | null;
  6: DiscountExceptionsRecord[] | null;
  7: DiscountExceptionsRecord[] | null;
};

export type HalalBonusEstablishmentRecord = {
  id: ApiRecordId;
  contract: string | null;
  place_category: ApiRecordId;
  contact_name: string | null;
  contact_phone: number | null;
  contact_whatsapp: string | null;
  contact_telegram: string | null;
  silver: number | null;
  notification_limit: number;
  place_description: string | null;
  created_at: ApiDateString;
  discount_exceptions: DiscountExceptions;
};

export type EditableHalalBonusEstablishmentRecord = Omit<
  HalalBonusEstablishmentRecord,
  'id' | 'contract' | 'silver' | 'created_at'
>;

export type GetHalalBonusEstablishmentResponse = HalalBonusEstablishmentRecord;

export type PutHalalBonusEstablishmentRequest =
  EditableHalalBonusEstablishmentRecord;
