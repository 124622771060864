import { CompanyFormValidValues, CompanyFormValues } from './types';

import { ApiDateString } from '@features/api';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useUncontrolledForm,
  UseUncontrolledFormParams,
} from '@hooks/useUncontrolledForm';
import { useCallback } from 'react';
import * as yup from 'yup';
export const validationSchema =
  // : yup.SchemaOf<
  //   Omit<CompanyFormValues, 'certificate'>
  // >
  yup
    .object({
      name: yup.string().required('Заполните поле наименование организации'),
      bank_name: yup.string().nullable(),
      // .required('Заполните поле наименование банка')
      inn: yup.string().nullable(),
      // .required('Заполните поле ИНН')
      kpp: yup.string().nullable(),
      // .required('Заполните поле КПП')
      account: yup.string().nullable(),
      // .required('Заполните поле номер счета')
      bik: yup.string().nullable(),
      // .required('Заполните поле БИК')
      ogrn: yup.string().nullable(),
      // .required('Заполните поле ОГРН')
      account_of_bank: yup.string().nullable(),
      // .required('Заполните поле корреспондентский счет')
      okato: yup.string().nullable(),
      // .required('Заполните поле ОКАТО')
      okved: yup.string().nullable(),
      // .required('Заполните поле ОКВЭД (основной)')
      director: yup.string().nullable(),
      // .required('Заполните поле генеральный директор')
      address: yup.string().nullable(),
      // .required('Заполните поле юридический адрес')
      accountant: yup.string().nullable(),
      // .required('Заполните поле главный бухгалтер')
      certificate: yup.object({
        center: yup.number().nullable(),
        number: yup.string().nullable(),
        confirmation_link: yup.string().nullable(),
        valid_from: yup
          .date()
          .transform((dateLike) => (dateLike ? new Date(dateLike) : null))
          .nullable(),
        valid_to: yup
          .date()
          .transform((dateLike) => (dateLike ? new Date(dateLike) : null))
          .when('valid_from', {
            is: (valid_from: Date | ApiDateString | null) => {
              return Boolean(valid_from);
            },
            then: (schema) =>
              schema.min(
                yup.ref('valid_from'),
                'Поле действительно до не может быть раньше даты выдачи',
              ),
          })
          .nullable(),
      }),

      // halalCertificateNumber: yup
      //   .string()
      //   .required('Заполните поле номер сертификата'),
      // certificationAuthority: yup
      //   .string()
      //   .nullable()
      //   .required('Выберите центр сертификации'),
      // certificateDateOfIssue: yup
      //   .date()
      //   .nullable()
      //   .required('Заполните поле дата выдачи'),
      // certificateValidUntil: yup
      //   .date()
      //   .nullable()
      //   .required('Заполните поле действителен до'),
      // certificateConfirmationLink: yup.string().nullable(),

      // certificateDocument: yup
      //   .mixed()
      //   .required('Загрузите скан или фото сертификата халяль'),
    })
    .required();

export type UseCompanyFormParams = {
  initValues: CompanyFormValues;
  onSubmit: (
    data: CompanyFormValidValues,
    isScanChanged: boolean,
  ) => Promise<void>;
};
export const useCompanyForm = (params: UseCompanyFormParams) => {
  const { initValues, onSubmit } = params;

  const onSubmitHandler: UseUncontrolledFormParams<
    CompanyFormValues,
    CompanyFormValidValues
  >['onSubmit'] = useCallback(
    async (data, formState) => {
      const dirtyFields = formState.dirtyFields;
      const isScanChanged = dirtyFields.certificate?.scan ?? false;

      await onSubmit(data, isScanChanged);
    },
    [onSubmit],
  );

  return useUncontrolledForm<CompanyFormValues, CompanyFormValidValues>({
    defaultValues: initValues,
    onSubmit: onSubmitHandler,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
};
