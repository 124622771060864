import {
  allDays,
  dayDict,
  emptyWorkTime,
  endTimeOptions,
  startTimeOptions,
} from './constants';
import {
  StyledDayCell,
  StyledDayTitle,
  StyledHeader,
  StyledRow,
  StyledRowList,
  StyledTimeCell,
  StyledTimeSelect,
  StyledTimeTitle,
  StyledTimeWeekendsCell,
  StyledWorkTime,
} from './WorkTime.styles';

import {
  EstablishmentWorkTime,
  WorkTimeDayValue,
} from '@features/establishments';
import { SelectProps } from '@packages/uiKit/Select';
import { Switch, SwitchProps } from '@packages/uiKit/Switch';
import { Typography } from '@packages/uiKit/Typography';
import React, { FC } from 'react';

export interface WorkTimeProps {
  className?: string;
  styles?: React.CSSProperties;

  value: EstablishmentWorkTime | null;
  onChange: (value: EstablishmentWorkTime) => void;
}

export const WorkTime: FC<WorkTimeProps> = (props) => {
  const { value, onChange, ...restProps } = props;

  return (
    <StyledWorkTime {...restProps}>
      <StyledHeader>
        <StyledDayTitle>День недели</StyledDayTitle>
        <StyledTimeTitle>Режим работы</StyledTimeTitle>
      </StyledHeader>

      <StyledRowList>
        {allDays.map((day) => {
          const dayFullName = dayDict[day];

          const dayValue = value?.[day];
          const enabled = value?.[day] !== undefined;

          const onChangeSwitch: SwitchProps['onChange'] = (sValue) => {
            if (sValue) {
              const newValue: EstablishmentWorkTime = value
                ? { ...value }
                : { ...emptyWorkTime };
              newValue[day] = { start: '9:00', end: '10:00' };
              onChange(newValue);
            } else {
              const newValue = value ? { ...value } : { ...emptyWorkTime };
              delete newValue[day];
              onChange(newValue);
            }
          };

          const onChangeStart: SelectProps<string>['onChange'] = (sValue) => {
            const newDayValue: WorkTimeDayValue = dayValue
              ? { ...dayValue }
              : { start: null, end: null };
            newDayValue.start = sValue;
            const newValue = value ? { ...value } : { ...emptyWorkTime };
            newValue[day] = newDayValue;

            onChange(newValue);
          };

          const onChangeEnd: SelectProps<string>['onChange'] = (sValue) => {
            const newDayValue: WorkTimeDayValue = dayValue
              ? { ...dayValue }
              : { start: null, end: null };
            newDayValue.end = sValue;
            const newValue = value ? { ...value } : { ...emptyWorkTime };
            newValue[day] = newDayValue;

            onChange(newValue);
          };
          return (
            <StyledRow key={day}>
              <StyledDayCell>
                <Typography color={enabled ? 'dark1' : 'gray1'}>
                  {dayFullName}
                </Typography>
                <Switch value={enabled} onChange={onChangeSwitch} />
              </StyledDayCell>

              {enabled ? (
                <StyledTimeCell>
                  <StyledTimeSelect
                    options={startTimeOptions}
                    value={dayValue?.start}
                    onChange={onChangeStart}
                  />

                  <StyledTimeSelect
                    options={endTimeOptions}
                    value={dayValue?.end}
                    onChange={onChangeEnd}
                  />
                </StyledTimeCell>
              ) : (
                <StyledTimeWeekendsCell>Выходной</StyledTimeWeekendsCell>
              )}
            </StyledRow>
          );
        })}
      </StyledRowList>
    </StyledWorkTime>
  );
};
