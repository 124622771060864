import {
  MultiselectField,
  MultiselectFieldProps,
} from '@packages/uiKit/fields/MultiselectField';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import React from 'react';
import { Controller, FieldValues, Path } from 'react-hook-form';

const _MultiselectFieldAdapter: FieldAdapterFCComponent<
  MultiselectFieldProps
> = (props) => {
  const {
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState: { error } }) => {
        return (
          <MultiselectField {...inputProps} {...field} error={error?.message} />
        );
      }}
    />
  );
};

const MultiselectFieldAdapterMemo = React.memo(_MultiselectFieldAdapter);

MultiselectFieldAdapterMemo.displayName = 'MultiselectFieldAdapter';

export const MultiselectFieldAdapter =
  MultiselectFieldAdapterMemo as typeof _MultiselectFieldAdapter;

export type MultiselectFieldAdapterProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> = Parameters<typeof MultiselectFieldAdapter<TFieldValues, TName>>[0];
