import { useProductForm, UseProductFormParams } from './hooks';
import { StyledContainer, StyledProductForm } from './styles';

import { StyledCost, StyledCostFieldContentContainer } from '../styles';

import { Section } from '@components';
import { FormSection } from '@components/FormSection';
import { SwitchWithValueLabel } from '@components/SwitchWithValueLabel';
import { Button, Field } from '@packages/uiKit';
import { InputFieldAdapter } from '@packages/uiKit/fieldAdapters/InputFieldAdapter';
import { MultiselectFieldAdapter } from '@packages/uiKit/fieldAdapters/MultiselectFieldAdapter';
import { SelectFieldAdapter } from '@packages/uiKit/fieldAdapters/SelectFieldAdapter';
import { TextAreaFieldAdapter } from '@packages/uiKit/fieldAdapters/TextAreaFieldAdapter';
import { Col, Row } from 'antd';

import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';
import { shopProductsEndpoints } from '@features/halalShop/store/apiSerive';
import {
  ProductDetailRecord,
  ProductGenderStringEnum,
} from '@features/halalShop/store/types';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { UploadImageInputFieldAdapter } from '@packages/uiKit/fieldAdapters/UploadImageInputFieldAdapter';
import { InputField } from '@packages/uiKit/fields/InputField';
import { InputNumberField } from '@packages/uiKit/fields/InputNumberField';
import React, { FC, memo, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Params, useParams } from 'react-router-dom';
import { IS_DISABLED_TO_CHANGE, seasonSelectOptions } from './constants';
import { PhotoGalleryForm } from './PhotoGalleryForm';

export interface ProductFormProps {
  className?: string;
  style?: React.CSSProperties;
}

const temp_initialValues: ProductDetailRecord = {
  brand: null,
  category: null,
  certificate: null,
  consist: '',
  description: '',
  ext_id: '',
  gender: ProductGenderStringEnum.Male,

  id: 0,
  image: null,
  is_active: true,
  is_new: true,
  manufacturer: '',
  name: '111',
  offers: [],
  params: null,
  photos: [],
  price: { currency: 'RUB', value: 1000 },
  price_old: null,
  product_color: null,
  quantity: 1,
  season: null,
  shipping: false,
  show_always: true,
  sku: '',
  video: '',
};

const _ProductForm: FC<ProductFormProps> = (props) => {
  const { ...restProps } = props;

  const { id } = useParams<Params>();

  const createNotification = useNotification();

  const [isChangesLoading, setChangesLoading] = useState<boolean>(false);

  const {
    data: fetchedData,
    isSuccess,
    isLoading,
  } = shopProductsEndpoints.useGetProductDetailQuery({
    currency: 'RUB',
    id: Number(id),
  });

  const [changeProductM] =
    shopProductsEndpoints.useChangeProductDetailMutation();

  const [postProductImageM] =
    shopProductsEndpoints.usePostProductImageMutation();

  const [putProductImageM] = shopProductsEndpoints.usePutProductImageMutation();

  const [deleteProductImageM] =
    shopProductsEndpoints.useDeleteProductImageMutation();

  const submitHandler: UseProductFormParams['onSubmit'] = async (data) => {
    setChangesLoading(true);
    const { image } = data;

    const isImageDirty = dirtyFields.image;

    const isImageToCreate =
      isImageDirty && isSuccess && fetchedData.image === null;

    const isImageToDelete = isImageDirty && image === null;

    const isImageToChange = isImageDirty && (image as File | null);

    console.log(data, isImageToDelete, isImageToChange);

    const promises = [
      changeProductM({
        ...data,
        category: 1760,
        //category: 1617,
        price: { currency: 'RUB', value: 15 },
      }).unwrap(),
    ];

    if (isImageToCreate) {
      promises.push(
        postProductImageM({
          image: image as File | null,
          product_id: data.id,
          is_main: true,
        }).unwrap(),
      );
    }

    if (isImageToChange) {
      promises.push(
        putProductImageM({
          image: image as File | null,
          id: data.id,
          is_main: true,
        }).unwrap(),
      );
    }

    if (isImageToDelete) {
      promises.push(
        deleteProductImageM({
          id: data.id,
          is_main: true,
        }).unwrap(),
      );
    }

    try {
      await Promise.all(promises);

      createNotification({
        message: 'Изменения успешно добавлены',
        type: 'success',
      });
    } catch (e) {
      createNotification({
        message: 'Произошла ошибка при сохранении изменений',
        type: 'error',
      });
    } finally {
      setChangesLoading(false);
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useProductForm({
    initialValues: fetchedData ? fetchedData : temp_initialValues,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    if (fetchedData) {
      reset({ ...fetchedData });
    }
  }, [fetchedData, reset]);

  return (
    <>
      {isLoading && (
        <StyledContainer>
          <Loader />
        </StyledContainer>
      )}
      {!isSuccess && !isLoading && (
        <StyledContainer>
          <TextPlaceholder variant="noRightsProductDetail" />
        </StyledContainer>
      )}
      {isSuccess && !isLoading && (
        <StyledProductForm {...restProps}>
          <Row gutter={47}>
            <Col span={16}>
              <Section title="Основное">
                <Row gutter={15}>
                  <Col span={16}>
                    <InputFieldAdapter
                      control={control}
                      name="name"
                      label="Название товара"
                    />
                  </Col>
                  <Col span={8}>
                    <InputFieldAdapter
                      control={control}
                      name="sku"
                      label="Код товара"
                    />
                  </Col>

                  <Col span={8}>
                    <SelectFieldAdapter
                      control={control}
                      name="category"
                      label="Основная категория"
                      options={[]}
                    />
                  </Col>
                  <Col span={8}>
                    {/* <SelectFieldAdapter
                control={control}
                name="subCategory"
                label="Подкатегория товара"
                options={[]}
              /> */}
                  </Col>
                  <Col span={8}>
                    <SelectFieldAdapter
                      control={control}
                      name="season"
                      label="Сезон товара"
                      options={seasonSelectOptions}
                    />
                  </Col>
                  <Col span={24}>
                    <MultiselectFieldAdapter
                      control={control}
                      name="offers"
                      label="Доступные размеры"
                      modalTitle="Доступные размеры"
                      options={[]}
                    />
                  </Col>
                  <Col span={24}>
                    <MultiselectFieldAdapter
                      control={control}
                      name="product_color"
                      label="Доступные цвета"
                      modalTitle="Доступные цвета"
                      options={[]}
                    />
                  </Col>
                  <Col span={24}>
                    <TextAreaFieldAdapter
                      control={control}
                      isAutosize
                      isResizable
                      name="description"
                      label="Краткое описание"
                    />
                  </Col>
                </Row>
                <FormSection title="Стоимость товара">
                  <Row gutter={15} align="bottom">
                    <Col span={8}>
                      <Controller
                        control={control}
                        name={'price.value'}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <InputNumberField
                              label="Основная цена"
                              error={error?.message}
                              value={field.value}
                              onChange={field.onChange}
                            />
                          );
                        }}
                      />
                    </Col>

                    <Col span={8}>
                      <SelectFieldAdapter
                        control={control}
                        name="is_new"
                        label="Скидка на товар"
                        options={[]}
                      />
                    </Col>
                    <Col span={8}>
                      <Field label="Итоговая цена" variant="filled-white">
                        <StyledCostFieldContentContainer>
                          <StyledCost>2 320 ₸</StyledCost>
                          <Controller
                            control={control}
                            name="is_active"
                            render={({ field: { value, onChange } }) => {
                              return (
                                <SwitchWithValueLabel
                                  trueLabel="Продается"
                                  falseLabel="Не продается"
                                  value={value}
                                  onChange={onChange}
                                />
                              );
                            }}
                          />
                        </StyledCostFieldContentContainer>
                      </Field>
                    </Col>
                  </Row>
                </FormSection>
                <FormSection title="Характеристики">
                  <Controller
                    control={control}
                    name="params"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <InputField
                          label="Параметры"
                          value={value}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="consist"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <InputField
                          label="Состав"
                          value={value}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                </FormSection>
                <FormSection title="Видео обзор товара">
                  <InputFieldAdapter
                    control={control}
                    name="video"
                    label="Добавьте ссылку на YouTube, если есть видео обзор товара"
                    placeholder="youtube.com"
                  />
                </FormSection>
              </Section>
            </Col>

            <Col span={8}>
              <Section title="Главное фото">
                <UploadImageInputFieldAdapter
                  control={control}
                  name="image"
                  label="Фото в общем каталоге"
                  preview="card">
                  Загрузить фото
                </UploadImageInputFieldAdapter>
              </Section>
              <PhotoGalleryForm />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={4}>
              <Button
                size="large"
                onClick={handleSubmit}
                block
                loading={isChangesLoading}
                disabled={isChangesLoading || IS_DISABLED_TO_CHANGE}>
                Сохранить
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                variant="outlined"
                disabled={IS_DISABLED_TO_CHANGE}>
                Отменить изменения
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                color="white"
                disabled={IS_DISABLED_TO_CHANGE}>
                Удалить товар
              </Button>
            </Col>
          </Row>
        </StyledProductForm>
      )}
    </>
  );
};

export const ProductForm = memo(_ProductForm);
