import { scrollbarStylesLightVariant } from '@utils/scrollBarStyles';
import styled from 'styled-components';

export const StyledUserReviewsSection = styled.div`
  max-height: 562px;
  height: 100%;
  padding-right: 15px;
  border-radius: 4px;
  overflow: auto;

  ${scrollbarStylesLightVariant}
`;
