import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';
import { getProductFormData } from '../utils/getProductFormData';
import {
  HalalTradingGetCategoriesResponse,
  HalalTradingGetProductRecord,
  HalalTradingGetProductsRequest,
  HalalTradingGetProductsResponse,
  HalalTradingGetProviderResponse,
  HalalTradingProductMutationRequest,
} from './types';

export const HALAL_TRADING_PRODUCTS_TAG = 'HALAL_TRADING_TAG' as const;

export const HALAL_TRADING_CATEGORY_TAG = 'HALAL_TRADING_TAG' as const;

export const halalTradingApi = createApi({
  reducerPath: 'halalTradingApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'trading' }),
  tagTypes: [HALAL_TRADING_PRODUCTS_TAG, HALAL_TRADING_CATEGORY_TAG],
  endpoints: () => ({}),
});

export const halalTradingEndpoints = halalTradingApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getCategories: builder.query<HalalTradingGetCategoriesResponse, void>({
        providesTags: (response) => {
          return [HALAL_TRADING_CATEGORY_TAG];
        },
        query: () => ({
          url: '/category',
          method: 'GET',
        }),
      }),

      getProducts: builder.query<
        HalalTradingGetProductsResponse,
        HalalTradingGetProductsRequest
      >({
        providesTags: (response) => {
          return response
            ? [
                ...response?.results.map((item, index) => {
                  return { type: HALAL_TRADING_PRODUCTS_TAG, id: item.id };
                }),
                HALAL_TRADING_PRODUCTS_TAG,
              ]
            : [HALAL_TRADING_PRODUCTS_TAG];
        },
        query: (params) => ({
          url: '/product',
          method: 'GET',
          params,
        }),
      }),

      addProduct: builder.mutation<any, HalalTradingProductMutationRequest>({
        invalidatesTags: [HALAL_TRADING_PRODUCTS_TAG],

        query: (data) => {
          const formData = getProductFormData(data);

          return {
            url: '/product/',
            method: 'POST',
            body: formData,
          };
        },
      }),

      getProductDetail: builder.query<
        HalalTradingGetProductRecord,
        { id: number }
      >({
        providesTags: (response) => {
          return [HALAL_TRADING_PRODUCTS_TAG];
        },
        query: (params) => {
          return {
            url: `/product/${params.id}`,
            method: 'GET',
            params,
          };
        },
      }),

      editProductDetail: builder.mutation<
        any,
        { id: number; data: HalalTradingProductMutationRequest }
      >({
        invalidatesTags: [HALAL_TRADING_PRODUCTS_TAG],
        query: (request) => {
          const formData = getProductFormData(request.data);

          return {
            url: `/product/${request.id}`,
            method: 'PATCH',
            body: formData,
          };
        },
      }),

      getProvider: builder.query<HalalTradingGetProviderResponse, any>({
        query: () => {
          return {
            url: `/provider`,
            method: 'GET',
          };
        },
      }),
    };
  },
});
