export enum Breakpoints {
  xxs = 320,
  xs = 375,
  sm = 576,
  md = 768,
  lg = 1024,
  xl = 1280,
  xxl = 1920,
  xxxl = 2600,
}

export const media = {
  breakpoints: Breakpoints,
  up: (value: keyof typeof Breakpoints | number): string => {
    const breakpoint = typeof value === 'number' ? value : Breakpoints[value];

    return `@media screen and (min-width: ${breakpoint}px)`;
  },
  down: (value: keyof typeof Breakpoints | number): string => {
    const breakpoint = typeof value === 'number' ? value : Breakpoints[value];

    return `@media screen and (max-width: ${breakpoint}px)`;
  },
};
