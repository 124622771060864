import { TransactionStatusCell } from './TransactionStatusCell';

import { DateCell } from '@components/table';
import { HalalBonusTransactionRecord } from '@features/halalBonus/transactions';
import { TableColumn, Typography } from '@packages/uiKit';
import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';

export const columns: TableColumn<HalalBonusTransactionRecord>[] = [
  {
    Header: 'Статус',
    accessor: 'status',
    minWidth: 160,
    Cell: ({ value }) => <TransactionStatusCell status={value} />,
  },
  {
    Header: 'Клиент',

    accessor: (record) => record.user.full_name,
    minWidth: 180,
    Cell: ({ value }: { value: string }) => (
      <Typography nowrap ellipsis title={value} weight={700}>
        {value}
      </Typography>
    ),
  } as TableColumn<HalalBonusTransactionRecord>,
  {
    Header: 'Номер телефона',
    accessor: (record) => record.user.tel,
    minWidth: 180,

    Cell: ({ value }: { value: number | null }) =>
      value ? (
        <Typography nowrap ellipsis>
          {value}
        </Typography>
      ) : null,
  },
  {
    Header: 'Сумма',
    accessor: 'amount',
    Cell: ({ value }) => (
      <Typography nowrap ellipsis weight={700}>
        {formatPriceVisualFriendly(value)} ₸
      </Typography>
    ),
  },
  {
    Header: 'Начислено',
    accessor: 'points',
    Cell: ({ value }) => (
      <Typography nowrap ellipsis weight={700} color={'primary'}>
        {formatPriceVisualFriendly(value)} ₸
      </Typography>
    ),
  },

  {
    Header: 'Время',
    accessor: 'created_at',
    minWidth: 140,

    Cell: ({ value }) => <DateCell date={value} format="dateTime" />,
  },
  // {
  //   Header: 'Номер QR-кода',
  //   id: 'qrCode',
  //   minWidth: 200,
  //   Cell: () => <QrCodeCell>9794916670058</QrCodeCell>,
  // },
  {
    Header: 'Текущий баланс клиента',
    accessor: 'balance',
    Cell: ({ value }) =>
      value ? (
        <Typography nowrap ellipsis>
          {value} ₸
        </Typography>
      ) : null,
  },
];
