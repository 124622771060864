import React, { FC, memo } from 'react';

import { FormSection } from '@components/FormSection';
import { getCurrencySymbol } from '@features/currency/hooks/getCurrencySymbol';
import { Field, InputPhoneNumber, Typography } from '@packages/uiKit';
import { InputField } from '@packages/uiKit/fields/InputField';
import { ReturnFormClientValues } from '@pages/HalalShopProductReturnDetailPage/type';
import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';
import { Col, Row } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledClientSection } from './styles';

export interface ClientSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

const _ClientSection: FC<ClientSectionProps> = (props) => {
  const { ...restProps } = props;

  const { control } = useFormContext<ReturnFormClientValues>();

  return (
    <StyledClientSection {...restProps}>
      <FormSection title="Информация" style={{ marginTop: '25px' }}>
        <Row gutter={[15, 0]}>
          <Col span={8}>
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Field
                    label="Имя"
                    error={error?.message}
                    marginBottom={0}
                    isCustomMargin>
                    <InputField value={field.value} onChange={field.onChange} />
                  </Field>
                );
              }}
            />
          </Col>
          <Col span={8}>
            <Controller
              control={control}
              name="middleName"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Field
                    label="Фамилия"
                    error={error?.message}
                    marginBottom={0}
                    isCustomMargin>
                    <InputField value={field.value} onChange={field.onChange} />
                  </Field>
                );
              }}
            />
          </Col>
          <Col span={8}>
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Field
                    label="Отчество"
                    error={error?.message}
                    marginBottom={0}
                    isCustomMargin>
                    <InputField value={field.value} onChange={field.onChange} />
                  </Field>
                );
              }}
            />
          </Col>
          <Col span={8}>
            <Controller
              control={control}
              name="phone"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Field
                    label="Номер телефона"
                    error={error?.message}
                    marginBottom={0}
                    isCustomMargin>
                    <InputPhoneNumber
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </Field>
                );
              }}
            />
          </Col>
          <Col span={8}>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Field
                    label="Электронная почта"
                    error={error?.message}
                    marginBottom={0}
                    isCustomMargin>
                    <InputField value={field.value} onChange={field.onChange} />
                  </Field>
                );
              }}
            />
          </Col>
          <Col span={8}>
            <Controller
              control={control}
              name="postCode"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Field
                    label="Индекс"
                    error={error?.message}
                    marginBottom={0}
                    isCustomMargin>
                    <InputField value={field.value} onChange={field.onChange} />
                  </Field>
                );
              }}
            />
          </Col>
        </Row>
      </FormSection>

      <FormSection title="Адрес доставки">
        <Row gutter={15}>
          <Col span={12}>
            <Controller
              control={control}
              name="street"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Field
                    label="Улица и дом"
                    error={error?.message}
                    marginBottom={0}
                    isCustomMargin>
                    <InputField value={field.value} onChange={field.onChange} />
                  </Field>
                );
              }}
            />
          </Col>
          <Col span={6}>
            <Controller
              control={control}
              name="house"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Field
                    label="Квартира"
                    error={error?.message}
                    marginBottom={0}
                    isCustomMargin>
                    <InputField value={field.value} onChange={field.onChange} />
                  </Field>
                );
              }}
            />
          </Col>
          <Col span={6}>
            <Controller
              control={control}
              name="apt"
              render={({ field, fieldState: { error } }) => {
                return (
                  <Field
                    label="Подъезд"
                    error={error?.message}
                    marginBottom={0}
                    isCustomMargin>
                    <InputField value={field.value} onChange={field.onChange} />
                  </Field>
                );
              }}
            />
          </Col>
        </Row>
      </FormSection>

      <Row gutter={15}>
        <Col span={8}>
          <Controller
            control={control}
            name="deliveryData.company"
            render={({ field, fieldState: { error } }) => {
              return (
                <Field variant="filled-white" label="Компания">
                  <Typography nowrap ellipsis>
                    {field.value}
                  </Typography>
                </Field>
              );
            }}
          />
        </Col>
        <Col span={8}>
          <Controller
            control={control}
            name="deliveryData.price"
            render={({ field, fieldState: { error } }) => {
              return (
                <Field variant="filled-white" label="Стоимость доставки">
                  <Typography nowrap ellipsis>
                    {formatPriceVisualFriendly(Number(field.value))}
                    {getCurrencySymbol('KZT')}
                  </Typography>
                </Field>
              );
            }}
          />
        </Col>
        <Col span={8}>
          <Controller
            control={control}
            name="deliveryData.postId"
            render={({ field, fieldState: { error } }) => {
              return (
                <Field label="Номер отслеживания">
                  <InputField value={field.value} />
                </Field>
              );
            }}
          />
        </Col>
      </Row>
    </StyledClientSection>
  );
};

export const ClientSection = memo(_ClientSection);
