import { css } from 'styled-components';

export const scrollbarStylesLightVariant = css`
  ::-webkit-scrollbar {
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #8a8a8a;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbbbbb;
  }
`;

export const scrollbarStylesDefaultVariant = css`
  ::-webkit-scrollbar {
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #17171a;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbbbbb;
  }
`;

export const scrollbarStylesDefaultVariantGlobal = css`
  body::-webkit-scrollbar {
    width: 16px;
  }

  body::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px;
  }

  body::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #17171a;
  }

  body::-webkit-scrollbar-thumb:hover {
    background-color: #bbbbbb;
  }
`;
