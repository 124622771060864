import { notification } from 'antd';
import { useCallback } from 'react';

export type CreateNotificationParams = {
  message: string;
  type: 'success' | 'info' | 'error' | 'warning';
};

export const useNotification = () => {
  const createNotification = useCallback((params: CreateNotificationParams) => {
    const { message, type } = params;
    notification.open({
      message,
      type,
    });
  }, []);

  return createNotification;
};
