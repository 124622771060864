import { ApiDateString, ApiRecordId } from '@features/api';

export enum HalalShopOrderStatus {
  New = 'new',
  Assembly = 'assembly',
  AwaitingShipment = 'awaitingShipment',
  Delivering = 'delivering',
  Canceled = 'canceled',
  Delivered = 'delivered',
  Unknown = 'unknown',
}

export type HalalShopOrderMockRecord = {
  id: ApiRecordId;
  status: HalalShopOrderStatus;
  amount: number;
  items: HalalShopCartItem[];
  created: ApiDateString;
  address: string;
  client: string;
  phone: string;
};

export type HalalShopCartItem = {
  id: ApiRecordId;
  name: string;
  cost: number;
  count: number;
  photo: string;
};
