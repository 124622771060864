import {
  StyledFieldLabel,
  StyledSwitchFieldAdapter,
} from './SwitchFieldAdapter.styles';

import { Switch } from '@packages/uiKit/Switch';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import React from 'react';
import { Controller } from 'react-hook-form';

const _SwitchFieldAdapter: FieldAdapterFCComponent<{ label: string }> = (
  props,
) => {
  const { control, name, defaultValue, rules, shouldUnregister, label } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field: { value, onChange } }) => {
        return (
          <StyledSwitchFieldAdapter>
            <StyledFieldLabel>{label}</StyledFieldLabel>
            <Switch value={value} onChange={onChange} withTips />
          </StyledSwitchFieldAdapter>
        );
      }}
    />
  );
};

export const SwitchFieldAdapter = React.memo(
  _SwitchFieldAdapter,
) as typeof _SwitchFieldAdapter;
