import { StatusCell, StatusCellProps } from '@components/table/StatusCell';
import { StatusCellConfig } from '@components/table/StatusCell/types';
import { HalalShopFinanceStatus } from '@features/halalShop/finances/types';
import { AlarmIcon, CheckCircleOutlinedIcon } from '@packages/icons';
import { FC, memo } from 'react';

export interface FinanceStatusCellProps
  extends Omit<StatusCellProps<HalalShopFinanceStatus>, 'config'> {}
const config: StatusCellConfig<HalalShopFinanceStatus> = [
  {
    value: HalalShopFinanceStatus.Payment,
    label: 'Пополнение',
    icon: <AlarmIcon />,
  },
  {
    value: HalalShopFinanceStatus.Replenishment,
    label: 'Счет оплачен',
    icon: <CheckCircleOutlinedIcon />,
  },
];

const _FinanceStatusCell: FC<FinanceStatusCellProps> = (props) => {
  const { ...restProps } = props;

  return <StatusCell config={config} {...restProps} />;
};

export const FinanceStatusCell = memo(_FinanceStatusCell);
