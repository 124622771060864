import { Field } from '@packages/uiKit/Field';
import {
  InputPhoneNumber,
  InputPhoneNumberProps,
} from '@packages/uiKit/InputPhoneNumber';
import React, { forwardRef } from 'react';

export interface InputPhoneNumberFieldProps
  extends Omit<InputPhoneNumberProps, 'error'> {
  className?: string;
  styles?: React.CSSProperties;

  label?: string;
  tip?: string;
  error?: string;
}

export const InputPhoneNumberField = forwardRef<
  HTMLInputElement,
  InputPhoneNumberFieldProps
>((props, ref) => {
  const { label, style, className, error, tip, ...restProps } = props;

  return (
    <Field label={label} error={error} tip={tip}>
      <InputPhoneNumber ref={ref} error={Boolean(error)} {...restProps} />
    </Field>
  );
});

InputPhoneNumberField.displayName = 'InputPhoneNumberField';
