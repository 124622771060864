import { StyledTopProductsCard } from './styles';

import { Bar, BarConfig } from '@ant-design/plots';
import { colors } from '@packages/uiKit';
import React, { FC, memo } from 'react';

export interface TopClientsCardProps {
  className?: string;
  style?: React.CSSProperties;
}

type DataItem = {
  clientName: string;
  sumAmount: number;
};

const data: DataItem[] = [
  {
    clientName: 'Гузель Галиулина',
    sumAmount: 105_000,
  },
  {
    clientName: 'Миша Бурлацкий',
    sumAmount: 22_000,
  },
  {
    clientName: 'Виктория Ильина',
    sumAmount: 14_000,
  },
  {
    clientName: 'Егор Матвеевский',
    sumAmount: 12_000,
  },
  {
    clientName: 'Алинур Загрутдинова',
    sumAmount: 9_000,
  },
  {
    clientName: 'Михаил Воробьев',
    sumAmount: 7_000,
  },
  {
    clientName: 'Дмитрий Шулин',
    sumAmount: 5_000,
  },
];
const config: BarConfig = {
  data,
  height: 250,
  color: colors.primary,
  xField: 'sumAmount',
  yField: 'clientName',
  seriesField: 'clientName',
  legend: false as const,
  meta: {
    sumAmount: {
      formatter: (value: number) => `${(value / 1000).toFixed(0)} тыс.`,
    },
  },
};

const _TopProductsCard: FC<TopClientsCardProps> = (props) => {
  const { ...restProps } = props;

  return (
    <StyledTopProductsCard title="Топ 7 товаров" {...restProps}>
      <Bar {...config} />
    </StyledTopProductsCard>
  );
};

export const TopProductsCard = memo(_TopProductsCard);
