import {
  SelectField,
  SelectFieldProps,
} from '@packages/uiKit/fields/SelectField';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import { Controller, FieldValues, Path } from 'react-hook-form';

export const SelectFieldAdapter: FieldAdapterFCComponent<SelectFieldProps> = (
  props,
) => {
  const {
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field: { onBlur, ...field }, fieldState: { error } }) => {
        return (
          <SelectField {...inputProps} {...field} error={error?.message} />
        );
      }}
    />
  );
};

export type SelectFieldAdapterProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> = Parameters<typeof SelectFieldAdapter<TFieldValues, TName>>[0];
