import styled from 'styled-components';

export const StyledStatisticsSection = styled.div``;

export const StyledStatisticsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 100px);
  grid-template-areas:
    'topDishes topDishes orderStructure orderStructure orderStructure searches'
    'topDishes topDishes orderStructure orderStructure orderStructure ordersIssued'
    'topDishes topDishes orderStructure orderStructure orderStructure halalEdaCommission'
    'orderDynamic orderDynamic orderDynamic orderDynamic orderDynamic ordersDelivered'
    'orderDynamic orderDynamic orderDynamic orderDynamic orderDynamic sumAllOrders'
    'orderDynamic orderDynamic orderDynamic orderDynamic orderDynamic -';
  gap: 15px;
`;
