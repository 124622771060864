import { TabsTabItem } from '@components';
import { OrderDetailTab } from './types';

export const tabs: TabsTabItem<OrderDetailTab>[] = [
  {
    value: OrderDetailTab.Client,
    label: 'Клиент',
  },
  {
    value: OrderDetailTab.Menu,
    label: 'Меню',
  },
  {
    value: OrderDetailTab.History,
    label: 'История',
  },
];
