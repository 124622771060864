import { ReviewRatingCell } from './ReviewRatingCell';
import { ReviewTextCell } from './ReviewTextCell';

import { BoldTextCell, DateCell } from '@components/table';
import { ReviewRecord } from '@features/reviews/store/types';
import { TableColumn } from '@packages/uiKit';

type GetColumnsParams = {
  onClickMakeAnswer: (item: ReviewRecord) => void;
};

export const getColumns = (
  params: GetColumnsParams,
): TableColumn<ReviewRecord>[] => [
  {
    Header: 'Статус',
    accessor: 'rating',
    width: 220,
    minWidth: 220,

    canResize: false,
    Cell: ({ value }) => <ReviewRatingCell rating={value} />,
  },
  {
    Header: 'Клиент',
    accessor: 'username',
    width: 180,
    minWidth: 180,
    canResize: false,
    Cell: ({ value }) => <BoldTextCell>{value}</BoldTextCell>,
  },
  {
    Header: 'Номер телефона',
    accessor: 'client_phone',
    width: 180,
    minWidth: 180,
    canResize: false,
  },
  {
    Header: 'Время отзыва',
    accessor: 'create',
    width: 150,
    minWidth: 150,
    canResize: false,
    Cell: ({ value }) => <DateCell date={value} format="dateTime" />,
  },
  {
    Header: 'Отзыв клиента',
    accessor: 'msg',
    minWidth: 200,

    Cell: ({ value, row: { original } }) => {
      const onMakeAnswerClickHandler = () => {
        params.onClickMakeAnswer(original);
      };
      return (
        <ReviewTextCell
          isAnswered={original.is_replied}
          onMakeAnswerClick={onMakeAnswerClickHandler}>
          {value}
        </ReviewTextCell>
      );
    },
  },
];
