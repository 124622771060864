import { BoldTextCell } from '@components/table';
import { ElipsisTextCell } from '@components/table/ElipsisTextCell';
import { ToDetailCell } from '@components/table/ToDetailCell';
import { GetClientsListRecord } from '@features/halalBonus/clients/store';
import { TableColumn } from '@packages/uiKit';
import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';

type GetColumnsParams = {};

export const getColumns = (
  params?: GetColumnsParams,
): TableColumn<GetClientsListRecord>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    width: 100,
    Cell: ({ value }) => <ElipsisTextCell>{value}</ElipsisTextCell>,
  },
  {
    Header: 'Имя клиента',
    accessor: 'name',
    Cell: ({ value }) => <ElipsisTextCell>{value}</ElipsisTextCell>,
  },
  {
    Header: 'Телефон',
    accessor: 'tel',
    Cell: ({ value }) => <ElipsisTextCell>{value}</ElipsisTextCell>,
  },
  {
    Header: 'Потрачено денег',
    accessor: 'spent_amount',
    Cell: ({ value }) => (
      <BoldTextCell>{formatPriceVisualFriendly(value)} ₸</BoldTextCell>
    ),
  },
  {
    Header: 'Накоплено бонусов',
    accessor: 'bonus_points',
    Cell: ({ value }) => (
      <BoldTextCell>{formatPriceVisualFriendly(value)}</BoldTextCell>
    ),
  },
  {
    Header: 'Количество транзакций',
    accessor: 'transaction_count',
    Cell: ({ value }) => <ToDetailCell>{value}</ToDetailCell>,
    width: 100,
  },
];
