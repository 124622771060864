import { HalalBonusEmployeesRecord } from './table/types';

export const employees: HalalBonusEmployeesRecord[] = [
  {
    id: 1,
    name: 'Альберт',
    jobTitle: 'Менеджер',
    points: 100,
    transactions: 210,
    downloads: 10,
  },
  {
    id: 2,
    name: 'Роберт',
    jobTitle: 'Менеджер',
    points: 230,
    transactions: 2200,
    downloads: 10,
  },
];
