import { Button, Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledReviewTextCell = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  cursor: pointer;
`;

export const StyledText = styled(Typography).attrs({
  size: 14,
})`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledButton = styled(Button).attrs({ size: 'small' })`
  width: 90px;
`;
