import {
  StyledCloseButton,
  StyledImage,
  StyledModalContainer,
  StyledOverlay,
  StyledPopover,
} from './ImageModal.styles';

import { useDisableBodyScroll } from '../hooks';

import { PopoverPortal } from '../PopoverPortal';

import React, { FC, memo } from 'react';

export type ImageModalProps = {
  className?: string;
  style?: React.CSSProperties;

  isOpen: boolean;

  onClose?: () => void;
} & ({ isOpen: false; image?: string } | { isOpen: true; image: string });

const _ImageModal: FC<ImageModalProps> = (props) => {
  const { isOpen, onClose, image, ...restProps } = props;

  useDisableBodyScroll(isOpen);

  if (!isOpen) return null;

  return (
    <PopoverPortal>
      <StyledPopover>
        <StyledOverlay onClick={onClose} />
        <StyledModalContainer {...restProps}>
          <StyledCloseButton onClick={onClose} />
          <StyledImage src={image} />
        </StyledModalContainer>
      </StyledPopover>
    </PopoverPortal>
  );
};

export const ImageModal = memo(_ImageModal);
