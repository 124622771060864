import {
  StyledCloseButton,
  StyledCode,
  StyledCost,
  StyledCountTag,
  StyledHalalShopProductRow,
  StyledImage,
  StyledMiddle,
  StyledName,
  StyledRight,
} from './styles';

import React, { FC, memo } from 'react';

export interface HalalShopCartItemRowProps {
  className?: string;
  style?: React.CSSProperties;
  image: string;
  name: string;
  id: number;
  cost: number;
  count: number;
  onDelete?: () => void;
  isDelete?: boolean;
}

const _HalalShopCartItemRow: FC<HalalShopCartItemRowProps> = (props) => {
  const { image, name, id, cost, count, onDelete, isDelete, ...restProps } =
    props;

  return (
    <StyledHalalShopProductRow {...restProps}>
      <StyledImage src={image} placeholderType="gray" />
      <StyledMiddle>
        <StyledName>{name}</StyledName>
        <StyledCode>Код товара: {id}</StyledCode>
        <StyledCost>{cost.toLocaleString()} ₸</StyledCost>
      </StyledMiddle>
      <StyledRight>
        {isDelete && <StyledCloseButton onClick={onDelete} />}

        <StyledCountTag>{count} шт.</StyledCountTag>
      </StyledRight>
    </StyledHalalShopProductRow>
  );
};

export const HalalShopCartItemRow = memo(_HalalShopCartItemRow);
