import { StyledFileCell } from './FileCell.styles';

import { ExternalLink } from '@components';
import { FC, memo, PropsWithChildren } from 'react';

export interface FileCellProps extends PropsWithChildren {
  href: string;
}

const _FileCell: FC<FileCellProps> = (props) => {
  const { children, href } = props;

  return (
    <ExternalLink href={href} newFolder>
      <StyledFileCell>{children}</StyledFileCell>
    </ExternalLink>
  );
};

export const FileCell = memo(_FileCell);
