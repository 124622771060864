import { colors } from './colors';

import { css } from 'styled-components';

const getDynamicStyles = (params: { error: boolean }) =>
  params.error
    ? css`
        border-bottom: 1px solid ${colors.danger};

        :focus,
        :focus-visible :active {
          outline: none;
          border-color: ${colors.danger};
        }
      `
    : css`
        border-bottom: 1px solid ${colors.gray1};

        :focus,
        :focus-visible :active {
          outline: none;
          border-color: ${colors.gray0};
        }
      `;

export const inputStyles = (params: { error: boolean }) => css`
  width: 100%;
  display: block;
  border: none;
  border-bottom: 1px solid ${colors.gray1};
  padding: 8px 0;
  background-color: transparent;
  font-size: 16px;
  line-height: 120%;

  ${getDynamicStyles(params)};
`;
