import React, { FC, memo } from 'react';

import { StyledMiscImages } from './styles';

import img1 from '@assets/imgs/mocks/screenshot_ban_en.png';
import img2 from '@assets/imgs/mocks/screenshot_ban_kk.png';
import img3 from '@assets/imgs/mocks/screenshot_ban_ru.png';
import img4 from '@assets/imgs/mocks/screenshot_ban_tr.png';

export interface MiscImagesProps {
  className?: string;
  style?: React.CSSProperties;
}

const _MiscImages: FC<MiscImagesProps> = () => {
  return (
    <StyledMiscImages>
      <a href={img1} target="_blank" rel="noopener noreferrer">
        screenshot_ban_en
      </a>
      <a href={img2} target="_blank" rel="noopener noreferrer">
        screenshot_ban_kk
      </a>
      <a href={img3} target="_blank" rel="noopener noreferrer">
        screenshot_ban_ru
      </a>
      <a href={img4} target="_blank" rel="noopener noreferrer">
        screenshot_ban_tr
      </a>
    </StyledMiscImages>
  );
};

export const MiscImages = memo(_MiscImages);
