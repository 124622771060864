import {
  StyledButton,
  StyledReviewTextCell,
  StyledText,
} from './ReviewTextCell.styles';

import React, { FC, PropsWithChildren } from 'react';

export interface ReviewTextCellProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;

  isAnswered: boolean;
  onMakeAnswerClick: () => void;
}

export const ReviewTextCell: FC<ReviewTextCellProps> = (props) => {
  const { children, isAnswered, onMakeAnswerClick, ...restProps } = props;

  return (
    <StyledReviewTextCell {...restProps} onClick={onMakeAnswerClick}>
      <StyledText>{children}</StyledText>
      {isAnswered ? (
        <StyledButton
          variant="outlined"
          color="secondary"
          onClick={onMakeAnswerClick}>
          Отвечен
        </StyledButton>
      ) : (
        <StyledButton variant="filled">Ответить</StyledButton>
      )}
    </StyledReviewTextCell>
  );
};
