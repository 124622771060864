import { useCreateBookingForm, UseCreateBookingFormParams } from './hooks';

import { BookingFormFields } from '../BookingFormFields';

import { bookingsApi } from '@features/booking/store';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { UncontrolledFormProvider } from '@hooks/useUncontrolledFromContext';
import { Button, Modal } from '@packages/uiKit';
import React, { FC, memo, useCallback } from 'react';

export interface CreateBookingModalProps {
  className?: string;
  style?: React.CSSProperties;
  isOpen: boolean;
  onClose: () => void;
}

const CreateBookingModalInner: FC<{ onClose: () => void }> = ({ onClose }) => {
  const createNotification = useNotification();

  const [postBookingM] = bookingsApi.usePostBookingMutation();

  const onSubmitHandler: UseCreateBookingFormParams['onSubmit'] = useCallback(
    async (data) => {
      try {
        await postBookingM({
          ...data,
          note: data.note ?? undefined,
          phone: data.phone ?? undefined,
          time: data.time.toISOString(),
        }).unwrap();

        onClose();

        createNotification({
          message: 'Бронь успешно создана',
          type: 'success',
        });
      } catch {
        createNotification({
          message: 'Произошла ошибка при создании брони',
          type: 'error',
        });
      }
    },
    [createNotification, onClose, postBookingM],
  );

  const form = useCreateBookingForm({ onSubmit: onSubmitHandler });

  return (
    <UncontrolledFormProvider {...form}>
      <BookingFormFields />
      <Button onClick={form.handleSubmit} size="large">
        Создать
      </Button>
    </UncontrolledFormProvider>
  );
};

const _CreateBookingModal: FC<CreateBookingModalProps> = (props) => {
  const { onClose, ...restProps } = props;

  return (
    <Modal title="Создать бронь" onClose={onClose} {...restProps}>
      <CreateBookingModalInner onClose={onClose} />
    </Modal>
  );
};

export const CreateBookingModal = memo(_CreateBookingModal);
