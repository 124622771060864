import {
  SwitchField,
  SwitchFieldProps,
} from '@packages/uiKit/fields/SwitchField';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import { Controller } from 'react-hook-form';

export const SwitchFieldAdapter: FieldAdapterFCComponent<SwitchFieldProps> = (
  props,
) => {
  const {
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({
        field: { onBlur: _, ref: _2, ...restField },
        fieldState: { error },
      }) => {
        return (
          <SwitchField error={error?.message} {...restField} {...inputProps} />
        );
      }}
    />
  );
};
