import { ContentForm } from './ContentForm';
import {
  useEstablishmentEditForm,
  UseEstablishmentEditFormParams,
} from './hooks';

import { SidebarForm } from './SidebarForm';

import { UncontrolledFormProvider } from '@hooks/useUncontrolledFromContext';
import { FC } from 'react';

export interface EstablishmentFormProps {
  initValues: UseEstablishmentEditFormParams['initValues'];
  onSubmit: UseEstablishmentEditFormParams['onSubmit'];
}

export const EstablishmentForm: FC<EstablishmentFormProps> = (props) => {
  const { initValues, onSubmit } = props;

  const form = useEstablishmentEditForm({
    initValues,
    onSubmit,
  });

  return (
    <UncontrolledFormProvider {...form}>
      <ContentForm />
      <SidebarForm />
    </UncontrolledFormProvider>
  );
};
