import styled from 'styled-components';

export const StyledClientsSection = styled.div``;

export const StyledPushNotificationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 50px;
`;

export const StyledButton = styled.button`
  margin-right: 50px;
`;
