import {
  HalalEdaEstablishmentFormValidValues,
  HalalEdaEstablishmentFormValues,
} from './types';

import { yupResolver } from '@hookform/resolvers/yup';
import { useUncontrolledForm } from '@hooks/useUncontrolledForm';
import * as yup from 'yup';

export const validationSchema = yup
  .object({
    contract: yup.string().required('Заполните поле номер договора'),
    payment_card: yup.boolean().required(),
    time_delivery: yup
      .string()
      .required('Заполните поле среднее время доставки заказа'),
    categories: yup.array().of(yup.number().required()),
    min_cost: yup.number().nullable(),
    zone_delivery: yup.string().nullable(),
    cost_delivery: yup
      .number()
      .nullable()
      .required('Заполните поле стоимость доставки'),
    free_delivery_from: yup.number().nullable(),
    contact_name: yup.string().nullable(),
    contact_phone: yup.string().nullable(),
    contact_whatsApp: yup.string().nullable(),
    contact_telegram: yup.string().nullable(),
  })
  .required();

export type UseHalalEdaEstablishmentFormParams = {
  initValues: HalalEdaEstablishmentFormValues;
  onSubmit: (data: HalalEdaEstablishmentFormValidValues) => Promise<void>;
};
export const useHalalEdaEstablishmentForm = (
  params: UseHalalEdaEstablishmentFormParams,
) => {
  const { initValues, onSubmit } = params;

  return useUncontrolledForm<
    HalalEdaEstablishmentFormValues,
    HalalEdaEstablishmentFormValidValues
  >({
    defaultValues: initValues,
    onSubmit,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
};
