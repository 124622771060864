import { MainLayout } from '@layouts/MainLayout';
import { OrderDetailLayout } from '@layouts/OrderDetailLayout';
import React, { FC, memo } from 'react';

import photo from '@assets/imgs/imageMockProduct1.png';
import { Space } from '@components/Space';
import { HalalShopCartItemRow } from '@features/halalShop/orders/components/HalalShopCartItemRow';
import { Button } from '@packages/uiKit';
import {
  StyledButtonContainer,
  StyledInfoSummaryItemLabel,
  StyledInfoSummaryItemValue,
  StyledInfoTextItem,
  StyledInfoTextItemLabel,
  StyledInfoTextItemValue,
} from './styles';

export interface SidebarSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

const _SidebarSection: FC<SidebarSectionProps> = (props) => {
  return (
    <OrderDetailLayout.Sidebar width={400}>
      <MainLayout.Sidebar.Header>Товар к возврату</MainLayout.Sidebar.Header>
      <Space size={10} />
      <HalalShopCartItemRow
        cost={2250}
        count={1}
        id={590910979}
        image={photo}
        name="Рубашка/мусульманская одежда"
      />

      <Space size={45} />

      <StyledInfoTextItem>
        <StyledInfoTextItemLabel>Всего товаров</StyledInfoTextItemLabel>
        <StyledInfoTextItemValue>{2250} ₸</StyledInfoTextItemValue>
      </StyledInfoTextItem>

      <StyledInfoTextItem>
        <StyledInfoTextItemLabel>Доставка</StyledInfoTextItemLabel>
        <StyledInfoTextItemValue>{450} ₸</StyledInfoTextItemValue>
      </StyledInfoTextItem>

      <StyledInfoTextItem>
        <StyledInfoSummaryItemLabel>Итого</StyledInfoSummaryItemLabel>
        <StyledInfoSummaryItemValue>{2700} ₸</StyledInfoSummaryItemValue>
      </StyledInfoTextItem>
      <StyledButtonContainer>
        <Button size="large" block>
          Сохранить
        </Button>
        <Button size="large" variant="filled" color="secondary">
          Отменить
        </Button>
      </StyledButtonContainer>
    </OrderDetailLayout.Sidebar>
  );
};

export const SidebarSection = memo(_SidebarSection);
