import { StyledListAsTableRow } from './ListAsTableRow.styles';
import { ListAsTableRowItem } from './ListAsTableRowItem';

import React, { FC, PropsWithChildren } from 'react';
import { ListAsTableRowItemLabel } from './ListAsTableRowItemLabel';
import { ListAsTableRowItemValue } from './ListAsTableRowItemValue';

export interface ListAsTableRowProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}

export const ListAsTableRow: FC<ListAsTableRowProps> & {
  Item: typeof ListAsTableRowItem;
  Label: typeof ListAsTableRowItemLabel;
  Value: typeof ListAsTableRowItemValue;
} = (props) => {
  const { ...restProps } = props;

  return <StyledListAsTableRow {...restProps} />;
};

ListAsTableRow.Item = ListAsTableRowItem;
ListAsTableRow.Label = ListAsTableRowItemLabel;
ListAsTableRow.Value = ListAsTableRowItemValue;
