import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledHalalShopProductDetailPage = styled.div``;

export const StyledCostFieldContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCost = styled(Typography).attrs({ nowrap: true })`
  margin-right: 8px;
`;

export const StyledPhotosLabel = styled(Typography).attrs({
  size: 12,
  color: 'gray1',
})`
  margin-bottom: 11px;
`;
