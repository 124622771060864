import { AppBaseQueryParams } from './types';

import { API_URL } from '@features/api';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

export const appBaseQuery = (params?: AppBaseQueryParams) => {
  const { subpath, baseUrl, ...restParams } = params ?? {};
  return fetchBaseQuery({
    baseUrl: baseUrl ? baseUrl : `${API_URL}/${subpath}`,
    ...restParams,
  });
};
