import { DotsThreeCirclesNoPadding } from '@packages/icons';
import { Button } from '@packages/uiKit/Button';
import { Tooltip } from '@packages/uiKit/Tooltip';
import styled from 'styled-components';

export const StyledDeliveryCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const StyledButton = styled(Button).attrs({
  size: 'small',
  color: 'primary',
  variant: 'text',
  icon: <DotsThreeCirclesNoPadding size={14} />,
})`
  padding: 0px;
  border-width: 0;
`;

export const StyledTooltip = styled(Tooltip).attrs({
  position: 'right',
})``;

export const StyledTextContainer = styled.div``;
