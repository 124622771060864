import { PAGE_SIZE, sectionToComponentDict, tabs } from './constants';

import { HalalBonusPageTab } from './types';

import { Tabs } from '@components';
import { greenBonusTransactionsApi } from '@features/halalBonus/transactions';
import { useAuthProtection } from '@hooks/useAuthProtection';
import { useSearchParam } from '@hooks/useSearchParam';
import { MainLayout } from '@layouts/MainLayout';
import { FC, useMemo } from 'react';

export const HalalBonusPage: FC = () => {
  useAuthProtection();

  const [tabSearch, setTabSearch] = useSearchParam<HalalBonusPageTab>({
    paramName: 'tab',
    defaultValue: HalalBonusPageTab.Transactions,
  });

  const tabContent = useMemo(() => {
    return sectionToComponentDict[tabSearch];
  }, [tabSearch]);

  const {
    data: transactionPageData,
    isLoading,
    isSuccess,
  } = greenBonusTransactionsApi.useGetTransactionPageQuery({
    page: 1,
    perPage: PAGE_SIZE,
  });

  const tabsItem = tabs.map((item) => {
    return {
      value: item.value,
      label:
        item.value === HalalBonusPageTab.Transactions
          ? isLoading
            ? 'Транзакции · · ·'
            : !isSuccess
            ? 'Транзакции · :('
            : `Транзакции · ${transactionPageData?.pagination.totalRows}`
          : item.label,
    };
  }, []);

  return (
    <MainLayout.Content>
      <MainLayout.Content.Header>HalalBonus</MainLayout.Content.Header>
      <Tabs tabs={tabsItem} value={tabSearch} onChange={setTabSearch} />
      {tabContent}
    </MainLayout.Content>
  );
};
