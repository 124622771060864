import { colors } from '../utils';
import { placeholderStyles } from '../utils/placeholderStyles';

import styled, { css } from 'styled-components';

const getDynamicStyles = (params: { error: boolean }) =>
  params.error
    ? css`
        border-bottom: 1px solid ${colors.danger};

        :focus,
        :focus-within {
          outline: none;
          border-color: ${colors.danger};
        }
      `
    : css`
        border-bottom: 1px solid ${colors.gray1};

        :focus,
        :focus-within {
          outline: none;
          border-color: ${colors.gray0};
        }
      `;

export const StyledInput = styled.input`
  flex: 1;
  min-width: 0;
  display: block;
  border: none;
  font-size: 16px;
  line-height: 120%;
  background: transparent;
  padding: 0;

  :focus,
  :focus-visible :active {
    outline: none;
  }

  ::placeholder {
    ${placeholderStyles}
  }
`;

export const StyledInputContainer = styled.div<{ $error: boolean }>`
  border-bottom: 1px solid ${colors.gray1};
  padding: 8px 0;

  display: flex;

  ${({ $error }) => getDynamicStyles({ error: $error })}
`;

export const StyledRightContainer = styled.div``;
