import { StyledTypography } from './Typography.styles';

import { AppColor, colors } from '@packages/uiKit/utils/colors';
import { memo, PropsWithChildren } from 'react';

export type TypographyProps = PropsWithChildren &
  React.PropsWithoutRef<JSX.IntrinsicElements['p']> & {
    size?: 10 | 12 | 14 | 16 | 18 | 22 | 28 | 32;
    color?: AppColor;
    weight?: 300 | 400 | 700;
    lineHeight?: 120 | 140;
    nowrap?: boolean;
    ellipsis?: boolean;
    as?: keyof JSX.IntrinsicElements;
    title?: string;
    maxLines?: number;
  };

const _Typography = (props: TypographyProps) => {
  const {
    size = 14,
    color,
    weight = 400,
    lineHeight = 120,
    nowrap = false,
    ellipsis = false,
    maxLines,
    ...restProps
  } = props;

  const _color = color && colors[color];

  return (
    <StyledTypography
      $size={size}
      $color={_color}
      $weight={weight}
      $lineHeight={lineHeight}
      $nowrap={nowrap}
      $ellipsis={ellipsis}
      $maxLines={maxLines}
      {...restProps}
    />
  );
};

export const Typography = memo(_Typography);
