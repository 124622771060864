import {
  useHalalBonusEstablishmentForm,
  UseHalalBonusEstablishmentFormParams,
} from './hooks';
import { StyledHalalBonusEstablishmentForm } from './styles';

import { Space } from '@components/Space';
import { EstablishmentCategoriesSelectFieldAdapter } from '@features/establishmentCategories';
import { HalalBonusEstablishmentRecord } from '@features/halalBonus/establishment';
import { Button, Field, InputNumber } from '@packages/uiKit';
import { InputFieldAdapter } from '@packages/uiKit/fieldAdapters/InputFieldAdapter';
import { InputNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputNumberFieldAdapter';
import { InputPhoneNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputPhoneNumberFieldAdapter';
import { InputField } from '@packages/uiKit/fields/InputField';
import { TextAreaField } from '@packages/uiKit/fields/TextAreaField';
import { convertHTMLToText } from '@utils/convertHTMLToText';
import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { BonusTime } from './BonusTime';

export interface HalalBonusEstablishmentFormProps {
  className?: string;
  style?: React.CSSProperties;
  initValues: UseHalalBonusEstablishmentFormParams['initValues'];
  onSubmit: UseHalalBonusEstablishmentFormParams['onSubmit'];
  mutableRecord: HalalBonusEstablishmentRecord;
}

export const HalalBonusEstablishmentForm: FC<
  HalalBonusEstablishmentFormProps
> = (props) => {
  const { initValues, onSubmit, mutableRecord, ...restProps } = props;

  const {
    control,
    handleSubmit,
    handleReset,
    formState: { isSubmitting },
  } = useHalalBonusEstablishmentForm({ initValues, onSubmit });

  //const [value, setValues] = useState<BonusWorkTime>(mock);

  return (
    <StyledHalalBonusEstablishmentForm {...restProps}>
      <Row gutter={60}>
        <Col span={12}>
          <InputField
            readOnly
            name="contract"
            label="Номер договора"
            value={mutableRecord.contract}
          />
        </Col>
        <Col span={12}>
          <InputFieldAdapter
            control={control}
            name="contact_name"
            label="Менеджер по GreenBonus · Имя и фамилия"
          />
        </Col>
      </Row>

      <Row gutter={60}>
        <Col span={12}>
          <EstablishmentCategoriesSelectFieldAdapter
            control={control}
            name="place_category"
            label="Категория заведения"
          />
        </Col>
        <Col span={12}>
          <InputPhoneNumberFieldAdapter
            control={control}
            name="contact_phone"
            label="Номер телефона менеджера"
          />
        </Col>
      </Row>

      <Row gutter={60}>
        <Col span={12}>
          <Row gutter={14}>
            <Col span={12}>
              <Controller
                control={control}
                name="silver"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div>
                      <Field
                        label="Размер бонуса "
                        variant="filled-white"
                        error={error?.message}>
                        <InputNumber
                          value={field.value}
                          onChange={field.onChange}
                          right="%"
                        />
                        {/*  <Typography weight={700} size={16}>
                        {mutableRecord.silver}%
                      </Typography> */}
                      </Field>
                    </div>
                  );
                }}
              />
            </Col>
            <Col span={12}>
              <Space size={7} />
              <InputNumberFieldAdapter
                control={control}
                name="notification_limit"
                label="Уведомления от суммы"
                right="₸"
              />
            </Col>
            <Col span={24}>
              <Controller
                control={control}
                name="place_description"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextAreaField
                      label="Зона и стоимость доставки"
                      placeholder=""
                      value={convertHTMLToText(field.value ?? '') ?? ''}
                      isAutosize
                      error={error?.message}
                      onChange={field.onChange}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={14}>
            <Col span={12}>
              <InputPhoneNumberFieldAdapter
                control={control}
                name="contact_whatsapp"
                label="WhatsApp менеджера"
              />
            </Col>
            <Col span={12}>
              <InputFieldAdapter
                control={control}
                name="contact_telegram"
                label="Telegram менеджера"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Space size={25} />
      {/* ыыыыы */}
      <Row gutter={60}>
        <Col span={24}>
          {/* <BonusTime data={value} onChange={setValues} /> */}
          <Controller
            control={control}
            name="discount_exceptions"
            render={({ field, fieldState: { error } }) => {
              return <BonusTime data={field.value} onChange={field.onChange} />;
            }}
          />
        </Col>
      </Row>
      <Space size={65} />

      <Row gutter={15}>
        <Col>
          <Button onClick={handleSubmit} size="large" loading={isSubmitting}>
            Сохранить изменения
          </Button>
        </Col>
        <Col>
          <Button
            onClick={handleReset}
            size="large"
            variant="outlined"
            disabled={isSubmitting}>
            Отменить изменения
          </Button>
        </Col>
      </Row>
    </StyledHalalBonusEstablishmentForm>
  );
};
