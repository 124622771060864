import { StyledListAsTableRowItem } from './ListAsTableRowItem.styles';

import React, { FC, PropsWithChildren } from 'react';

export interface ListAsTableRowItemProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}

export const ListAsTableRowItem: FC<ListAsTableRowItemProps> = (props) => {
  const { ...restProps } = props;

  return <StyledListAsTableRowItem {...restProps} />;
};
