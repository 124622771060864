import { HalalTradingGetProviderResponse } from '@features/halalTrading/store/types';
import { companyInfoInitValuesPlaceholder } from '../constants';
import { HalalTradingCompanyInfoValues } from '../types';

export const getInitCompanyInfoValues = (
  data: HalalTradingGetProviderResponse | undefined | null,
) => {
  if (!data) return companyInfoInitValuesPlaceholder;

  const res: HalalTradingCompanyInfoValues = {
    account: data.account,
    comission: data.comission,
    date_from: data.date_from,
    id: data.id,
    managerName: data.manager.name,
    number: data.number,
  };

  return res;
};
