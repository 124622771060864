import { HalalShopFinanceRecord, HalalShopFinanceStatus } from './types';

export const halalShopFinancesMockDataList: HalalShopFinanceRecord[] = [
  {
    id: 1,
    status: HalalShopFinanceStatus.Payment,
    created: '2022-12-18T05:39:20Z',
    amount: 1480,
    orderIds: [595456, 678006, 977217, 595456],
    document: '/404',
  },
  {
    id: 2,
    status: HalalShopFinanceStatus.Replenishment,
    created: '2022-12-18T05:39:20Z',
    amount: 1480,
    orderIds: [595456, 678006, 977217, 595456],
    document: '/404',
  },
];
