import { Button } from '../Button';

import { colors } from '@packages/uiKit/utils/colors';
import styled from 'styled-components';

export const StyledUploadButtonContainer = styled.div`
  padding: 5px;
  border: 1px dashed ${colors.gray1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
`;

export const StyledUploadButton = styled(Button)`
  width: 100%;
  height: 100%;
`;

export const StyledInvisibleInput = styled.input`
  display: none;
`;
