import { HalalBonusTransactionsTable } from './HalalBonusTransactionsTable';

import { Loader } from '@components/Loader';

import { greenBonusTransactionsApi } from '@features/halalBonus/transactions';
import { PAGE_SIZE } from '@pages/HalalBonusPage/constants';
import { FC, useState } from 'react';

export interface TransactionsSectionProps {}

export const TransactionsSection: FC<TransactionsSectionProps> = () => {
  const [page, setPage] = useState(1);

  const { data: transactionPageData, isLoading } =
    greenBonusTransactionsApi.useGetTransactionPageQuery({
      page: page,
      perPage: PAGE_SIZE,
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <HalalBonusTransactionsTable
          data={transactionPageData?.result || []}
          pagination={{
            pageIndex: page,
            onChangePage: setPage,
            pageCount: transactionPageData?.pagination.pageCount ?? 0,
          }}
        />
      )}
    </>
  );
};
