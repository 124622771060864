import {
  StyledPageButton,
  StyledPagesContainer,
  StyledPagination,
  StyledPrevButton,
} from './Pagination.styles';

import { Button } from '@packages/uiKit/Button';
import { FC, memo } from 'react';

export interface PaginationProps {
  pageIndex: number;
  pageCount: number;
  onChangePage: (pageIndex: number) => void;
}

const getAroundPages = (pageIndex: number, pageCount: number) => {
  const pages = [];

  const start = Math.max(pageIndex - 1, 1);
  const end = Math.min(pageIndex + 1, pageCount);

  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
};

const _Pagination: FC<PaginationProps> = (props) => {
  const { pageIndex, pageCount, onChangePage, ...restProps } = props;

  const canNextPage = pageIndex < pageCount;
  const canPrevPage = pageIndex > 1 && pageCount > 0;

  const onNextPage = () => {
    onChangePage(pageIndex + 1);
  };

  const onPrevPage = () => {
    onChangePage(pageIndex - 1);
  };

  const aroundPages = getAroundPages(pageIndex, pageCount);

  return (
    <StyledPagination {...restProps}>
      {canPrevPage && <StyledPrevButton onClick={onPrevPage} />}
      <StyledPagesContainer>
        {aroundPages.map((page) => {
          const isCurrent = page === pageIndex;

          const onClickHandler = !isCurrent
            ? () => {
                onChangePage(page);
              }
            : undefined;
          return (
            <StyledPageButton
              key={page}
              onClick={onClickHandler}
              variant={isCurrent ? 'filled' : 'outlined'}>
              {page.toLocaleString('ru', {
                minimumIntegerDigits: 2,
              })}
            </StyledPageButton>
          );
        })}
      </StyledPagesContainer>

      {canNextPage && (
        <Button onClick={onNextPage} variant="filled">
          Далее
        </Button>
      )}
    </StyledPagination>
  );
};

export const Pagination = memo(_Pagination);
