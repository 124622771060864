import { Field } from '@packages/uiKit/Field';
import { Multiselect, MultiselectProps } from '@packages/uiKit/Multiselect';
import React, { ForwardedRef, forwardRef } from 'react';

export interface MultiselectFieldProps<
  TValue extends string | number = string | number,
> extends Omit<MultiselectProps<TValue>, 'error'> {
  className?: string;
  styles?: React.CSSProperties;

  label?: string;
  tip?: string;
  error?: string;
}

export const _MultiselectField = <
  TValue extends string | number = string | number,
>(
  props: MultiselectFieldProps<TValue>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { label, styles, className, error, tip, ...restProps } = props;

  return (
    <Field
      style={styles}
      className={className}
      label={label}
      error={error}
      tip={tip}>
      <Multiselect ref={ref} {...restProps} />
    </Field>
  );
};

export const MultiselectField = forwardRef(_MultiselectField);

MultiselectField.displayName = 'MultiselectField';
