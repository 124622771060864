import React, { FC, memo, useCallback } from 'react';

import { TableProps } from '@packages/uiKit/Table';
import { HalalBonusPageTab } from '@pages/HalalBonusPage/types';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { employees } from './mock';
import {
  StyledEmployeesSection,
  StyledTable,
  StyledTablesScrollX,
} from './styles';
import { columns } from './table/constants';
import { HalalBonusEmployeesRecord } from './table/types';

export interface EmployeesSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

const _EmployeesSection: FC<EmployeesSectionProps> = (props) => {
  const { ...restProps } = props;

  const navigate = useNavigate();

  const getRowProps: TableProps<HalalBonusEmployeesRecord>['getRowProps'] =
    useCallback(
      (row: Row<HalalBonusEmployeesRecord>) => {
        const { id } = row.original;

        const onClickHandler = () => {
          navigate(`${HalalBonusPageTab.Employees}/detail/${id}`);
        };

        return {
          className: 'row',
          onClick: onClickHandler,
        };
      },

      [navigate],
    );

  return (
    <StyledEmployeesSection {...restProps}>
      <StyledTablesScrollX>
        <StyledTable
          data={employees}
          columns={columns}
          scroll={false}
          getRowProps={getRowProps}
        />
      </StyledTablesScrollX>
    </StyledEmployeesSection>
  );
};

export const EmployeesSection = memo(_EmployeesSection);
