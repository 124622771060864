import { establishmentFormSchema } from './schema';
import {
  EstablishmentEditFormValidValues,
  EstablishmentEditFormValues,
} from './types';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  useUncontrolledForm,
  UseUncontrolledFormParams,
} from '@hooks/useUncontrolledForm';
import { useCallback } from 'react';

export type UseEstablishmentEditFormParams = {
  initValues: EstablishmentEditFormValues;
  onSubmit: (
    data: EstablishmentEditFormValues,
    changedImages: { photo: boolean; logo: boolean; place_photo: boolean },
  ) => Promise<void>;
};

export const useEstablishmentEditForm = (
  params: UseEstablishmentEditFormParams,
) => {
  const { initValues, onSubmit } = params;

  const onSubmitHandler: UseUncontrolledFormParams<
    EstablishmentEditFormValues,
    EstablishmentEditFormValidValues
  >['onSubmit'] = useCallback(
    async (data, formState) => {
      const {
        dirtyFields: { photo, logo, place_photo },
      } = formState;

      await onSubmit(data, {
        photo: Boolean(photo),
        logo: Boolean(logo),
        place_photo: Boolean(place_photo),
      });
    },
    [onSubmit],
  );

  return useUncontrolledForm<
    EstablishmentEditFormValues,
    EstablishmentEditFormValidValues
  >({
    defaultValues: initValues,
    onSubmit: onSubmitHandler,
    mode: 'onChange',
    resolver: yupResolver(establishmentFormSchema),
    shouldFocusError: true,
  });
};
