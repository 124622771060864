import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledLegendItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledLegendValue = styled(Typography).attrs({
  size: 22,
  weight: 700,
  nowrap: true,
  ellipsis: true,
})``;

export const StyledLegendLine = styled.div`
  height: 1px;
  border-radius: 4px;
`;

export const StyledLegendDesc = styled(Typography).attrs({
  nowrap: true,
  ellipsis: true,
})``;
