import { FC, PropsWithChildren, useEffect, useRef } from 'react';

export interface OutsideClickProps {
  onClickOutside: () => void;
  isEnabled?: boolean;
}

export const OutsideClick: FC<PropsWithChildren<OutsideClickProps>> = ({
  onClickOutside,
  children,
  isEnabled,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };

    if (isEnabled) {
      document.addEventListener('click', handleClickOutside, true);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isEnabled, onClickOutside, ref]);

  return <div ref={ref}>{children}</div>;
};
