import { LoginRequest, LoginResponse, RefreshResponse } from './types';

import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'auth' }),
  endpoints: (builder) => {
    return {
      login: builder.mutation<LoginResponse, LoginRequest>({
        query: (body) => ({
          url: 'login/',
          method: 'POST',
          body,
        }),
      }),

      logout: builder.mutation<void, void>({
        query: (body) => ({
          url: 'logout/',
          method: 'GET',
          body,
        }),
      }),

      refresh: builder.mutation<RefreshResponse, void>({
        query: (body) => ({
          url: 'refresh/',
          method: 'GET',
          body,
        }),
      }),
    };
  },
});
