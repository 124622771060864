import { columns } from './constants';

import {
  StyledHalalBonusTable,
  StyledScrollXContainer,
} from './HalalBonusTable.styles';

import { TextPlaceholder } from '@components/TextPlaceholder';

import { HalalBonusTransactionRecord } from '@features/halalBonus/transactions';
import { Pagination, PaginationProps, Table } from '@packages/uiKit';
import { FC } from 'react';

export interface HalalBonusTransactionsTableProps {
  data: HalalBonusTransactionRecord[];
  pagination: PaginationProps;
}

export const HalalBonusTransactionsTable: FC<
  HalalBonusTransactionsTableProps
> = (props) => {
  const { data, pagination } = props;

  return (
    <StyledHalalBonusTable>
      {data.length === 0 ? (
        <TextPlaceholder variant="noData" />
      ) : (
        <>
          <StyledScrollXContainer>
            <Table data={data} columns={columns} scroll={false} />
          </StyledScrollXContainer>
          <Pagination {...pagination} />
        </>
      )}
    </StyledHalalBonusTable>
  );
};
