import styled, { css } from 'styled-components';

export const StyledTypography = styled.p<{
  $size: 10 | 12 | 14 | 16 | 18 | 22 | 28 | 32;
  $color?: string;
  $weight: 300 | 400 | 700;
  $nowrap: boolean;
  $ellipsis: boolean;
  $lineHeight: 120 | 140;
  $maxLines?: number;
}>`
  ${({ $lineHeight }) =>
    css`
      line-height: ${$lineHeight}%;
    `}

  ${({ $size: size, $color: color, $weight: weight }) => css`
    font-size: ${size}px;
    color: ${color ?? 'inherit'};
    font-weight: ${weight};
  `}

  ${({ $nowrap }) =>
    $nowrap &&
    css`
      white-space: nowrap;
    `}

    ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}

    ${({ $maxLines }) =>
    $maxLines &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${$maxLines};
    `}
`;
