import {
  StyledBonusTitle,
  StyledDayCell,
  StyledDayFullTitle,
  StyledDayTitle,
  StyledHeader,
  StyledInputNumber,
  StyledRow,
  StyledRowList,
  StyledSubDayRow,
  StyledTimeCell,
  StyledTimeContainer,
  StyledTimeSelect,
  StyledTimeTitle,
  StyledWorkTime,
} from './BonusTime.styles';
import {
  allDays,
  dayDict,
  endTimeOptions,
  startTimeOptions,
} from './constants';

import { Switch } from '@packages/uiKit/Switch';
import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { BonusWorkTime } from './types';

export interface WorkTimeProps {
  className?: string;
  styles?: React.CSSProperties;

  data: BonusWorkTime;
  onChange: (value: BonusWorkTime) => void;
}

export const BonusTime: FC<WorkTimeProps> = (props) => {
  const { data, onChange, ...restProps } = props;

  //console.log(data, mock);

  if (!data) return <></>;

  return (
    <StyledWorkTime {...restProps}>
      <Row gutter={60}>
        <Col span={12}>
          <StyledRow>
            <StyledHeader>
              <StyledDayTitle>День недели</StyledDayTitle>
              <StyledTimeTitle>Режим работы</StyledTimeTitle>
              <StyledBonusTitle>Бонусы %</StyledBonusTitle>
            </StyledHeader>
          </StyledRow>
        </Col>
        <Col span={12}>
          <StyledRow>
            <StyledHeader>
              <StyledDayTitle>Дополнительное время</StyledDayTitle>
              <StyledTimeTitle>Режим работы</StyledTimeTitle>
              <StyledTimeTitle>Бонусы %</StyledTimeTitle>
            </StyledHeader>
          </StyledRow>
        </Col>
      </Row>

      <StyledRowList>
        {allDays.map((day) => {
          const dayFullName = dayDict[day];

          const dayData = data && data[day];

          return (
            <StyledRow key={day}>
              {dayData &&
                dayData.map((subDay, subIndex) => {
                  const isActive = subDay.active;

                  const onChangeSwitch = (active: boolean) => {
                    // add to subDay
                    const newData = { ...subDay, active };

                    // deleting the subDay we need to update
                    const updatedArray = dayData.map((item) => {
                      return item.temp_id === newData.temp_id ? newData : item;
                    });

                    // add to Week
                    const newWeekData = { ...data, [day]: updatedArray };

                    onChange(newWeekData);
                  };

                  const onChangePMTime = (value: string) => {
                    const newData = { ...subDay, start_time: value };

                    const updatedArray = dayData.map((item) =>
                      item.temp_id === newData.temp_id ? newData : item,
                    );

                    const newWeekData = { ...data, [day]: updatedArray };

                    onChange(newWeekData);
                  };

                  const onChangeAMTime = (value: string) => {
                    const newData = { ...subDay, end_time: value };

                    const updatedArray = dayData.map((item) =>
                      item.temp_id === newData.temp_id ? newData : item,
                    );

                    const newWeekData = { ...data, [day]: updatedArray };

                    onChange(newWeekData);
                  };

                  const onChangeAmount = (value: number | null | undefined) => {
                    const newData = { ...subDay, value };

                    const updatedArray = dayData.map((item) =>
                      item.temp_id === newData.temp_id ? newData : item,
                    );

                    const newWeekData = { ...data, [day]: updatedArray };

                    onChange(newWeekData);
                  };

                  return (
                    <StyledSubDayRow key={subIndex}>
                      <StyledDayCell>
                        <StyledDayFullTitle
                          color={isActive ? 'dark1' : 'gray1'}>
                          {dayFullName}
                        </StyledDayFullTitle>
                        <Switch value={isActive} onChange={onChangeSwitch} />
                      </StyledDayCell>
                      <StyledTimeCell>
                        <StyledTimeContainer>
                          <StyledTimeSelect
                            options={startTimeOptions}
                            value={subDay?.start_time}
                            onChange={onChangePMTime}
                          />
                        </StyledTimeContainer>
                        <StyledTimeContainer>
                          <StyledTimeSelect
                            options={endTimeOptions}
                            value={subDay?.end_time}
                            onChange={onChangeAMTime}
                          />
                        </StyledTimeContainer>
                        <StyledTimeContainer>
                          <StyledInputNumber
                            value={subDay.value}
                            onChange={onChangeAmount}
                            maxLength={2}
                          />
                        </StyledTimeContainer>
                      </StyledTimeCell>
                    </StyledSubDayRow>
                  );
                })}
            </StyledRow>
          );
        })}
      </StyledRowList>
    </StyledWorkTime>
  );
};
