import { Tabs } from '@components';
import styled from 'styled-components';

export const StyledDiscountsSection = styled.div``;

export const StyledHeader = styled.div`
  display: flex;

  justify-content: space-between;
  gap: 16px;

  align-items: center;
`;

export const StyledTabs = styled(Tabs)`
  margin-bottom: 0px;
` as typeof Tabs;

export const StyledStocksPostionListLayout = styled.div``;
