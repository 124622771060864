import {
  ChangeProductDetailRequest,
  ProductDetailRecord,
} from '@features/halalShop/store/types';

import { useUncontrolledForm } from '@hooks/useUncontrolledForm';

export type UseProductFormParams = {
  initialValues: ProductDetailRecord;
  onSubmit: (data: ChangeProductDetailRequest) => void | Promise<void>;
};
export const useProductForm = (params: UseProductFormParams) => {
  const { initialValues, onSubmit } = params;
  return useUncontrolledForm<ProductDetailRecord, ChangeProductDetailRequest>({
    defaultValues: initialValues,
    onSubmit,
  });
};
