import { hideScrollbarStyles } from '@packages/uiKit/utils/hideScrollbarStyles';
import { scrollbarStylesLightVariant } from '@utils/scrollBarStyles';
import styled, { css } from 'styled-components';

export const StyledImageListWithUpload = styled.div<{
  $direction?: 'row' | 'column';
}>`
  display: flex;
  ${({ $direction }) =>
    $direction === 'row'
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: column;
        `}

  gap: 8px;
  overflow: auto;
  //width: 602px;

  padding-bottom: 10px;

  //${scrollbarStylesLightVariant}
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  height: 15px;
  background-color: red;
`;

export const StyledImageList = styled.div<{
  maxListSizeVisible?: number;
  $direction?: 'row' | 'column';
}>`
  display: flex;
  ${({ $direction }) =>
    $direction === 'row'
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: column;
        `}

  gap: 8px;

  width: fit-content;

  //${hideScrollbarStyles}
  ${({ maxListSizeVisible }) =>
    maxListSizeVisible &&
    css`
      max-height: ${116 * maxListSizeVisible +
      (maxListSizeVisible - 1) * 8 +
      20}px;
    `};
`;
