import { StyledProjectCell } from './BoldTextCell.styles';

import { FC, memo, PropsWithChildren } from 'react';

export interface BoldTextCellProps extends PropsWithChildren {}

const _BoldTextCell: FC<BoldTextCellProps> = (props) => {
  const { children } = props;

  return <StyledProjectCell>{children}</StyledProjectCell>;
};

export const BoldTextCell = memo(_BoldTextCell);
