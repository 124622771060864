import styled from 'styled-components';

export const StyledHalalBonusClientDetailPage = styled.div``;

export const StyledTopRow = styled.div`
  display: flex;
  gap: 25px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const StyledBottomRow = styled.div`
  height: 100%;
`;

export const StyledTopLeftContainer = styled.div`
  width: 100%;
`;

export const StyledTopRightContainer = styled.div`
  flex: 1;
  min-width: 460px;
`;
