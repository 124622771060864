import {
  GetHalalEdaOrderDetailRequest,
  GetHalalEdaOrderDetailResponse,
  GetHalalEdaOrderPageRequest,
  GetHalalEdaOrderPageResponse,
  GetHalalEdaStatsPageRequest,
  GetHalalEdaStatsPageResponse,
  PatchHalalEdaOrderRequest,
} from './types';

import { ApiRecordId } from '@features/api';
import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const HALAL_EDA_ORDERS_TAG = 'HALAL_EDA_ORDERS_TAG' as const;

const LIST_TAG = { type: HALAL_EDA_ORDERS_TAG, id: 'list' };

const getDetailTag = (id: ApiRecordId) => ({
  type: HALAL_EDA_ORDERS_TAG,
  id: `detail_${id}`,
});

export const halalEdaOrdersApi = createApi({
  reducerPath: 'halalEdaOrdersApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'halal-eda' }),
  tagTypes: [HALAL_EDA_ORDERS_TAG],
  endpoints: (builder) => {
    return {
      getOrderPage: builder.query<
        GetHalalEdaOrderPageResponse,
        GetHalalEdaOrderPageRequest
      >({
        providesTags: (response) => [
          LIST_TAG,
          ...(response?.result.map((order) => getDetailTag(order.id)) ?? []),
        ],
        query: (params) => ({
          url: '/orders',
          method: 'GET',
          params,
        }),
      }),

      getStatsPage: builder.query<
        GetHalalEdaStatsPageResponse,
        GetHalalEdaStatsPageRequest
      >({
        providesTags: () => [LIST_TAG],
        query: (params) => ({
          url: '/stats',
          method: 'GET',
          params,
        }),
      }),

      getOrderDetail: builder.query<
        GetHalalEdaOrderDetailResponse,
        GetHalalEdaOrderDetailRequest
      >({
        providesTags: (_, _2, { id }) => [getDetailTag(id)],

        query: ({ id }) => ({
          url: `/orders/${id}/`,
          method: 'GET',
        }),
      }),

      patchOrder: builder.mutation<void, PatchHalalEdaOrderRequest>({
        invalidatesTags: (_, _2, { id }) => [getDetailTag(id)],
        query: (request) => ({
          url: `/orders/${request.id}/`,
          method: 'PATCH',
          body: request.patch,
        }),
      }),
    };
  },
});
