import { Field } from '@packages/uiKit/Field';
import { InputNumber, InputNumberProps } from '@packages/uiKit/InputNumber';
import React, { forwardRef } from 'react';

export interface InputNumberFieldProps extends Omit<InputNumberProps, 'error'> {
  className?: string;
  styles?: React.CSSProperties;

  label?: string;
  tip?: string;
  error?: string;
}

export const InputNumberField = forwardRef<
  HTMLInputElement,
  InputNumberFieldProps
>((props, ref) => {
  const { label, style, className, error, tip, ...restProps } = props;

  return (
    <Field label={label} error={error} tip={tip}>
      <InputNumber ref={ref} error={Boolean(error)} {...restProps} />
    </Field>
  );
});

InputNumberField.displayName = 'InputNumberField';
