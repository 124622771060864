import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';
import setDefaultOptions from 'date-fns/setDefaultOptions';
export type DateFormat = 'dateTime' | 'time' | 'date' | 'date2';

setDefaultOptions({
  locale: ruLocale,
});

export const formatDate = (
  date: string | number | Date,
  dateFormat: DateFormat,
) => {
  try {
    const dateObj = new Date(date);

    switch (dateFormat) {
      case 'dateTime': {
        return format(dateObj, 'd MMM HH:mm');
      }
      case 'date': {
        return format(dateObj, 'd.M.u');
      }
      case 'date2': {
        return format(dateObj, 'M.d.u');
      }
      case 'time': {
        return format(dateObj, 'HH:mm');
      }
    }
  } catch (e) {
    return 'invalid';
  }
};
