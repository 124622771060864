import { HalalShopOrderDetailResponse } from '@features/halalShop/store/types';
import { HalalShopOrderFormValues } from '@pages/HalalShopOrderDetail/HalalShopOrderForm/types';
import {
  halalShopCartItemMockList,
  initHalalShopDetailOrderValuesPlaceholder,
} from '../mock/data';

export const getInitValues = (order: HalalShopOrderDetailResponse | null) => {
  if (!order) return initHalalShopDetailOrderValuesPlaceholder;

  const res: HalalShopOrderFormValues = {
    street: order.address.street,
    apt: order.address.apt,
    house: order.address.house,
    email: order.user.email,
    firstName: order.user.name,
    items: halalShopCartItemMockList,
    lastName: order.user.surname,
    middleName: order.user.patronymic,
    phone: order.user.tel,
    postCode: order.address.postcode,
    postId: null,
  };

  return res;
};
