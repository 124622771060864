import { Field } from '@packages/uiKit/Field';
import {
  UploadImageInput,
  UploadImageInputProps,
} from '@packages/uiKit/UploadImageInput';

export interface UploadImageInputFieldProps
  extends Omit<UploadImageInputProps, 'error'> {
  label?: string;
  tip?: string;
  error?: string;
}

export const UploadImageInputField: React.FC<UploadImageInputFieldProps> = (
  props,
) => {
  const { label, styles, className, error, tip, ...restProps } = props;

  return (
    <Field label={label} error={error} tip={tip}>
      <UploadImageInput {...restProps} />
    </Field>
  );
};
