import { useAuthProtection } from '@hooks/useAuthProtection';
import { ProductForm } from './ProductForm';

import { MainLayout } from '@layouts/MainLayout';
import { HalalShopPageSection } from '@pages/HalalShopPage/types';
import { FC, memo } from 'react';

const _HalalShopProductDetailPage: FC = () => {
  useAuthProtection();
  return (
    <MainLayout.Content>
      <MainLayout.Content.BackLink
        to={`/halalShop?section=${HalalShopPageSection.Catalog}`}>
        Все товары
      </MainLayout.Content.BackLink>
      <ProductForm />
    </MainLayout.Content>
  );
};

export const HalalShopProductDetailPage = memo(_HalalShopProductDetailPage);
