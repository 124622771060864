import {
  ApiDateString,
  ApiImageUrl,
  ApiPaginationMainResponse,
  ApiPaginationParams,
  ApiRecordId,
} from '@features/api';

// COMPLAINT_STATUS = (
//   (0, _(u'новый')),
//   (1, _(u'отказ')),
//   (2, _(u'подтвержден')),
// )

export enum HalalBonusComplaintStatus {
  New = 0,
  Canceled = 1,
  Confirmed = 2,
}

export type HalalBonusComplaintRecord = {
  id: ApiRecordId;
  status: HalalBonusComplaintStatus;
  user: {
    full_name: string;
    tel: number | null;
  };
  created_at: ApiDateString;
  amount: number;
  amount_inc: number;
  photo: ApiImageUrl;
};

export type PatchHalalBonusComplaintRecord = {
  status?: HalalBonusComplaintStatus;
};

export type GetHalalBonusComplaintPageRequest = ApiPaginationParams;
export type GetHalalBonusComplaintPageResponse =
  ApiPaginationMainResponse<HalalBonusComplaintRecord>;

export type PatchHalalBonusComplaintRequest = {
  id: ApiRecordId;
  patch: PatchHalalBonusComplaintRecord;
};
