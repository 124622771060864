import { ReactComponent as NoteSvg } from '@assets/svgs/note.svg';
import { Button } from '@packages/uiKit/Button';
import styled from 'styled-components';

export const StyledFileCell = styled(Button).attrs({
  size: 'small',
  variant: 'outlined',
  icon: <NoteSvg />,
})`
  white-space: nowrap;
`;
