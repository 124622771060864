import { MainLayout } from '@layouts/MainLayout';
import styled, { css } from 'styled-components';

export const StyledSidebar = styled(MainLayout.Sidebar)<{ $width?: number }>`
  padding: 70px;
  padding-left: 50px;
  padding-right: 50px;

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `}
`;
