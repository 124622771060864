import { TextPlaceholder } from '@components/TextPlaceholder';
import {
  HalalBonusEstablishmentForm,
  HalalBonusEstablishmentFormProps,
} from './HalalBonusEstablishmentForm';
import { StyledEstablishmentSection } from './styles';

import { Loader } from '@components/Loader';

import { formatPhoneToNumber } from '@features/api/utils';
import { greenBonusEstablishmentApi } from '@features/halalBonus/establishment';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { FC, useCallback, useMemo } from 'react';

export interface EstablishmentSectionProps {}

export const EstablishmentSection: FC<EstablishmentSectionProps> = () => {
  const createNotification = useNotification();

  const [putEstablishmentM] =
    greenBonusEstablishmentApi.usePutEstablishmentMutation();

  const {
    data: establishmentData,
    isLoading,
    isSuccess,
  } = greenBonusEstablishmentApi.useGetEstablishmentQuery();

  const onSubmitHandler: HalalBonusEstablishmentFormProps['onSubmit'] =
    useCallback(
      async (data) => {
        try {
          await putEstablishmentM({
            ...data,
            contact_phone: data.contact_phone
              ? formatPhoneToNumber(data.contact_phone)
              : null,
          }).unwrap();

          createNotification({
            message: 'HalalBonus заведение успешно изменено',
            type: 'success',
          });
        } catch {
          createNotification({
            message: 'Произошла ошибка при изменении HalalBonus заведения',
            type: 'error',
          });
        }
      },
      [createNotification, putEstablishmentM],
    );

  const initValues: HalalBonusEstablishmentFormProps['initValues'] | null =
    useMemo(() => {
      if (!establishmentData) {
        return null;
      }

      const { id, created_at, ...changableFields } = establishmentData;

      return {
        ...changableFields,
        contact_phone: changableFields.contact_phone
          ? changableFields.contact_phone.toString()
          : null,
      };
    }, [establishmentData]);

  return (
    <StyledEstablishmentSection>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess &&
        initValues &&
        establishmentData && (
          <HalalBonusEstablishmentForm
            initValues={initValues}
            onSubmit={onSubmitHandler}
            mutableRecord={establishmentData}
          />
        )
      )}
      {!isSuccess && !isLoading && <TextPlaceholder />}
    </StyledEstablishmentSection>
  );
};
