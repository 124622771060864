import { StyledCodeNumber, StyledQrCodeCell } from './QrCodeCell.styles';

import { QrCodeIcon } from '@packages/icons';
import React, { FC, memo, PropsWithChildren } from 'react';

export interface QrCodeCellProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}

const _QrCodeCell: FC<QrCodeCellProps> = (props) => {
  const { children, ...restProps } = props;

  return (
    <StyledQrCodeCell {...restProps}>
      <StyledCodeNumber>{children}</StyledCodeNumber>
      <QrCodeIcon size={16} />
    </StyledQrCodeCell>
  );
};

export const QrCodeCell = memo(_QrCodeCell);
