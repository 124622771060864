import { ApiDateString, ApiRecordId } from '@features/api';
import { HalalShopCartItem } from '@features/halalShop/orders/mock/types';

export enum HalalShopReturnStatus {
  Processed = 'На рассмотрении', // Оформлен
  Accepted = 'Принят', //Принят
  Rejected = 'Отклонен', // Отказано
  Delivered = 'Возвращен', //Принято
}
export type HalalShopReturnMockRecord = {
  id: ApiRecordId;
  status: HalalShopReturnStatus;
  amount: number;
  order: HalalShopCartItem[];
  created: ApiDateString;
  cause: string;
  client: string;
  phone: string;
};
