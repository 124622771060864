import { ChartCard } from '../common/ChartCard';

import styled from 'styled-components';

export const StyledOrdersStructure = styled(ChartCard)`
  grid-area: orderStructure;
`;

export const StyledLegendLayout = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  > * {
    min-width: 0px;
  }
`;

export const StyledPieContainer = styled.div``;
