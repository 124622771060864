import { zIndexes } from '../utils';
import { colors } from '../utils/colors';
import { placeholderStyles } from '../utils/placeholderStyles';

import { scrollbarStylesLightVariant } from '@utils/scrollBarStyles';
import styled, { css } from 'styled-components';

import { ReactComponent as CaretDownSvg } from '@/assets/svgs/caretDown.svg';

export const StyledSelectContainer = styled.div`
  position: relative;
`;

export const StyledButton = styled.button<{
  $variant: 'default' | 'filled' | 'compact';
  $isVisible: boolean;
}>`
  width: 100%;
  line-height: 120%;
  min-height: 36.19px;

  ${({ $variant }) =>
    $variant === 'filled' &&
    css`
      border: none;
      border-radius: 10px;
      font-size: 14px;
      background-color: white;
      padding: 8px 16px;
      transition: 0.3s ease;
      &:hover {
        background-color: ${colors.gray2};
      }
    `}

  ${({ $variant }) =>
    $variant === 'default' &&
    css`
      background-color: transparent;
      border: none;
      border-bottom: 1px solid ${colors.gray1};
      font-size: 16px;
      padding: 6.4px 0;
      line-height: 22.4px;
      transition: 0.3s ease;

      &:hover {
        background-color: ${colors.gray2};
      }
    `}

    ${({ $variant }) =>
    $variant === 'compact' &&
    css`
      border-radius: 4px;
      font-size: 14px;
      padding: 4px 6px;
      background-color: transparent;
      border: 1px solid transparent;
    `}

    ${({ $variant, $isVisible }) =>
    $variant === 'compact' &&
    $isVisible &&
    css`
      background-color: white;
      border: 1px solid ${colors.gray2};
      border-bottom: 1px solid transparent;
      border-radius: 4px 4px 0px 0px;
      transition: none;
    `}
  


  color: ${colors.dark1};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
`;

export const StyledCaretDownIcon = styled(CaretDownSvg)<{
  $isVisible: boolean;
}>`
  ${({ $isVisible }) =>
    $isVisible
      ? css`
          transition: 0.3s ease;
          transform: rotate(180deg);
        `
      : css`
          transition: 0.3s ease;
          transform: rotate(0deg);
        `}
  min-width: 18px;
  min-height: 18px;
`;

export const StyledLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledPlaceholder = styled.div`
  ${placeholderStyles}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledDropdown = styled.div<{
  $variant: 'default' | 'filled' | 'compact';
  $height?: number;
}>`
  z-index: ${zIndexes.dropdown};
  min-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${scrollbarStylesLightVariant}
  transition: max-height 0.3s ease;
  background: white;
  max-height: 300px;
  overflow-x: hidden;

  ${({ $height }) =>
    $height &&
    css`
      height: ${$height}px;
    `}

  ${({ $variant }) =>
    $variant === 'compact' &&
    css`
      top: 100%;
      border-radius: 0px 0px 4px 4px;
      border: 1px solid ${colors.gray2};
      border-top: none;
    `}
  ${({ $variant }) =>
    $variant === 'filled' &&
    css`
      top: calc(100% + 4px);
      border: 1px solid ${colors.gray2};
      border-radius: 10px;
    `}

    ${({ $variant }) =>
    $variant === 'default' &&
    css`
      top: calc(100% + 4px);
      border: 1px solid ${colors.gray2};
      border-radius: 3px;
      font-size: 14px;
      padding-left: 4px;
      margin-top: 3px;
    `}
`;

export const DropdownItem = styled.div<{
  $isSelected: boolean;
  $variant: 'default' | 'filled' | 'compact';
}>`
  cursor: pointer;
  white-space: nowrap;

  ${({ $variant }) =>
    $variant === 'filled' &&
    css`
      padding: 8px 16px;
      font-size: 14px;
    `}

  ${({ $variant }) =>
    $variant === 'compact' &&
    css`
      font-size: 12px;
      padding: 8px 7px;
      font-weight: 700;
      color: ${colors.gray0};
    `}

  ${({ $variant }) =>
    $variant === 'default' &&
    css`
      padding: 8px 0px;
      color: ${colors.dark1};
    `}
  
  ${({ $isSelected }) =>
    $isSelected
      ? css`
          cursor: default;
          background-color: ${colors.gray2};
        `
      : css`
          &:hover,
          :focus,
          :focus:hover {
            color: ${colors.dark1};
            text-decoration: underline;
          }
        `}
`;
