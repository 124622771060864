import { HalalTradingProductMutationRequest } from '@features/halalTrading/store/types';

export const initProductValuesPlaceholder: HalalTradingProductMutationRequest =
  {
    name: '',
    desc: '',
    category: '1',
    photo: null,
    price: null,
    barcode: null,
  };
