import { StyledImage } from './Image.styles';

import placeHolderImgDark from '@assets/imgs/placeholderImageDark.jpg';
import placeHolderImgGray from '@assets/imgs/placeholderImageGray.png';
import placeHolderImgWhite from '@assets/imgs/placeholderImageWhite.jpg';

import { FC, PropsWithoutRef } from 'react';

type ImgProps = JSX.IntrinsicElements['img'];

type PlaceHolderItems = 'dark' | 'gray' | 'white';

export interface ImageProps extends PropsWithoutRef<ImgProps> {
  size?: number;
  src: string;

  placeholderType?: PlaceHolderItems;
}

function getPlaceholderType(placeholderType: PlaceHolderItems) {
  switch (placeholderType) {
    case 'dark': {
      return placeHolderImgDark;
    }
    case 'white': {
      return placeHolderImgWhite;
    }
    case 'gray': {
      return placeHolderImgGray;
    }
  }
}

export const Image: FC<ImageProps> = (props) => {
  const { src, size, placeholderType = 'white', ...restProps } = props;

  // const darkImg = placeHolderImgDark;
  // const grayImg = placeHolderImgGray;
  const placeholderImage = getPlaceholderType(placeholderType);

  return (
    <StyledImage
      style={{ width: size, height: size, minWidth: size, minHeight: size }}
      src={src ? src : placeholderImage}
      {...restProps}
    />
  );
};
