import { CatalogSection } from './sections/CatalogSection';
import { EstablishmentSection } from './sections/EstablishmentSection';
import { FinancesSection } from './sections/FinancesSection';
import { OrdersSection } from './sections/OrdersSection';
import { ReturnsSection } from './sections/ReturnsSection';
import { StatisticSection } from './sections/StatisticSection';
import { HalalShopPageSection } from './types';

import { ReactNode } from 'react';

export const sectionToComponentDict: Record<HalalShopPageSection, ReactNode> = {
  orders: <OrdersSection />,
  catalog: <CatalogSection />,
  establishment: <EstablishmentSection />,
  returns: <ReturnsSection />,
  finances: <FinancesSection />,
  statistic: <StatisticSection />,
};
