import React, { FC, memo } from 'react';

import { useFormContext } from 'react-hook-form';
import { PushNotificationFormValues } from '../../PushModalContent/types';
import {
  StyledLogoImg,
  StyledMainText,
  StyledOnScreenSaverPreview,
  StyledTextContainer,
  StyledTimeTitle,
  StyledTitle,
} from './styles';

import logo from '@assets/svgs/logo.svg';

export interface OnScreenSaverPreviewProps {
  className?: string;
  style?: React.CSSProperties;
}

const _OnScreenSaverPreview: FC<OnScreenSaverPreviewProps> = (props) => {
  const { ...restProps } = props;

  const { watch } = useFormContext<PushNotificationFormValues>();

  const title = watch('title');
  const mainText = watch('mainText');

  return (
    <StyledOnScreenSaverPreview {...restProps}>
      <StyledLogoImg src={logo} alt="Logo" />
      <StyledTextContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledMainText>{mainText}</StyledMainText>
      </StyledTextContainer>
      <StyledTimeTitle>сейчас</StyledTimeTitle>
    </StyledOnScreenSaverPreview>
  );
};

export const OnScreenSaverPreview = memo(_OnScreenSaverPreview);
