import { ProductSeasonEnum } from '@features/halalShop/store/types';

export const IS_DISABLED_TO_CHANGE = false;

export const seasonSelectOptions = [
  {
    label: 'Межсезон',
    value: ProductSeasonEnum.DemiSeason,
  },
  {
    label: 'Лето',
    value: ProductSeasonEnum.Summer,
  },
  {
    label: 'Зима',
    value: ProductSeasonEnum.Winter,
  },
];
