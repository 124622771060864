import { useReviewAnswerForm, UseReviewAnswerFormParams } from './hooks';

import { StyledReviewAnswerModalContent } from './ReviewAnswerModal.styles';

import { useNotification } from '@features/notifications/hooks/useNotification';
import { reviewsApi } from '@features/reviews/store';
import { ReviewRecord } from '@features/reviews/store/types';
import { Button, Field, Modal, RatingLabel, Typography } from '@packages/uiKit';
import { TextAreaFieldAdapter } from '@packages/uiKit/fieldAdapters/TextAreaFieldAdapter';
import { formatDate } from '@utils/dateFormat';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import React, { FC, memo, useCallback } from 'react';

export type CreateBookingModalProps = {
  className?: string;
  style?: React.CSSProperties;

  onClose: () => void;
} & (
  | {
      isOpen: false;
      reviewItem?: ReviewRecord;
    }
  | {
      isOpen: true;
      reviewItem: ReviewRecord;
    }
);

const ReviewAnswerModalInner: FC<{
  reviewItem: ReviewRecord;
  onClose: () => void;
}> = ({ reviewItem, onClose }) => {
  const createNotification = useNotification();

  const isReplied = reviewItem.is_replied;

  const [putReviewM] = reviewsApi.usePutReviewMutation();
  const onSubmitHandler: UseReviewAnswerFormParams['onSubmit'] = useCallback(
    async (data) => {
      try {
        await putReviewM({
          id: reviewItem.id,
          put: {
            reply: data.reply,
          },
        }).unwrap();
        onClose();
        createNotification({
          message: 'Ответ на отзыв отправлен',
          type: 'success',
        });
      } catch {
        createNotification({
          message: 'Произошла ошибка при отправке ответа на отзыв',
          type: 'error',
        });
      }
    },
    [createNotification, onClose, putReviewM, reviewItem.id],
  );
  const { control, handleSubmit } = useReviewAnswerForm({
    onSubmit: onSubmitHandler,
    initValues: { reply: reviewItem.reply },
  });

  const formatedCreateTime = formatDate(reviewItem.create, 'dateTime');

  return (
    <StyledReviewAnswerModalContent>
      <Row gutter={20}>
        <Col span={6}>
          <Field label={'Имя клиента'} variant="filled-gray">
            <Typography size={16} nowrap ellipsis lineHeight={140}>
              {reviewItem.username}
            </Typography>
          </Field>
        </Col>

        <Col span={6}>
          <Field label={'Время отзыва'} variant="filled-gray">
            <Typography
              size={16}
              nowrap
              ellipsis
              lineHeight={140}
              title={formatedCreateTime}>
              {formatedCreateTime}
            </Typography>
          </Field>
        </Col>

        <Col span={6}>
          <Field label={'Номер телефона'} variant="filled-gray">
            <Typography
              size={16}
              nowrap
              ellipsis
              lineHeight={140}
              title={reviewItem.client_phone?.toString()}>
              {reviewItem.client_phone}
            </Typography>
          </Field>
        </Col>

        <Col span={6}>
          <Field label={'Отзыв'} variant="filled-gray">
            <RatingLabel rating={reviewItem.rating} />
          </Field>
        </Col>
      </Row>

      <Field label={'Отзыв клиента'} variant="filled-gray">
        <Typography size={16} lineHeight={140}>
          {reviewItem.msg}
        </Typography>
      </Field>

      <TextAreaFieldAdapter
        control={control}
        name="reply"
        label="Ваш ответ клиенту"
        readOnly={isReplied}
        isAutosize
      />

      {isReplied ? null : (
        <Button onClick={handleSubmit} size="large">
          Отправить ответ
        </Button>
      )}
    </StyledReviewAnswerModalContent>
  );
};

const _ReviewAnswerModal: FC<CreateBookingModalProps> = (props) => {
  const { isOpen, reviewItem, ...restProps } = props;

  return (
    <Modal title="Отзыв клиента" isOpen={isOpen} width={920} {...restProps}>
      {isOpen && (
        <ReviewAnswerModalInner
          reviewItem={reviewItem}
          onClose={restProps.onClose}
        />
      )}
    </Modal>
  );
};

export const ReviewAnswerModal = memo(_ReviewAnswerModal);
