import { MenuPositionCategory } from './types';

import { SelectOption } from '@packages/uiKit';

export const menuPositionCategoryListMockData: MenuPositionCategory[] = [
  {
    id: 1,
    name: 'Поке',
  },
  {
    id: 2,
    name: 'Закуски',
  },
  {
    id: 3,
    name: 'Супы',
  },
  {
    id: 4,
    name: 'Горячие блюда',
  },
  {
    id: 5,
    name: 'Напитки',
  },
];

export const menuPositionCategoryOptions: SelectOption<number>[] =
  menuPositionCategoryListMockData.map((category) => ({
    value: category.id,
    label: category.name,
  }));
