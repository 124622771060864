import styled, { css } from 'styled-components';

export const StyledSidebar = styled.div<{ $width?: number }>`
  padding: 124px 36px 36px 60px;
  background-color: white;
  height: 100%;

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `}
`;
