import { SelectOption } from '../Select';

type GetBooleanOptionsParams = {
  trueLabel?: string;
  falseLabel?: string;
};

export const getBooleanOptions = (
  params: GetBooleanOptionsParams,
): SelectOption<number>[] => {
  const { trueLabel = 'Да', falseLabel = 'Нет' } = params;

  return [
    {
      value: 1,
      label: trueLabel,
    },
    { value: 0, label: falseLabel },
  ];
};
