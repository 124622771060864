import { config } from './constants';

import { StatusCell } from '@components/table/StatusCell';
import { HalalBonusTransactionStatus } from '@features/halalBonus/transactions';
import React, { FC, memo } from 'react';

export interface StatusCellProps {
  className?: string;
  style?: React.CSSProperties;
  status: HalalBonusTransactionStatus;
}

const _TransactionStatusCell: FC<StatusCellProps> = (props) => {
  const { status, ...restProps } = props;

  return <StatusCell value={status} config={config} {...restProps} />;
};

export const TransactionStatusCell = memo(_TransactionStatusCell);
