import { scrollbarStylesDefaultVariantGlobal } from './scrollBarStyles';

import onestBold from '../assets/fonts/onest/OnestBold1602-hint.woff';

// import onestBlack from '../assets/fonts/onest/OnestBlack1602-hint.woff';
// import onestExtraBold from '../assets/fonts/onest/OnestExtraBold1602-hint.woff';
import onestLight from '../assets/fonts/onest/OnestLight1602-hint.woff';
// import onestMedium from '../assets/fonts/onest/OnestMedium1602-hint.woff';
import onestRegular from '../assets/fonts/onest/OnestRegular1602-hint.woff';

import { colors } from '@packages/uiKit/utils/colors';

import { createGlobalStyle } from 'styled-components';

// import onestThin from '../assets/fonts/onest/OnestThin1602-hint.woff';

//Other Fonts

/* 
  @font-face {
        font-family: 'Onest';
        src: url(${onestBlack}) format('woff');
        font-weight: 900;
        font-style: normal;
  }
  @font-face {
        font-family: 'Onest';
        src: url(${onestExtraBold}) format('woff');
        font-weight: 800;
        font-style: normal;
  } 

  @font-face {
        font-family: 'Onest';
        src: url(${onestMedium}) format('woff');
        font-weight: 500;
        font-style: normal;
  } 

 @font-face {
        font-family: 'Onest';
        src: url(${onestLight}) format('woff');
        font-weight: 300;
        font-style: normal;
  } 
 @font-face {
        font-family: 'Onest';
        src: url(${onestThin}) format('woff');
        font-weight: 100;
        font-style: normal;
  } 

*/

export const GlobalStyle = createGlobalStyle`
  
  ${scrollbarStylesDefaultVariantGlobal}


  @font-face {
        font-family: 'Onest';
        src: url(${onestBold}) format('woff');
        font-weight: 700;
        font-style: normal;
  }
  
  @font-face {
        font-family: 'Onest';
        src: url(${onestRegular}) format('woff');
        font-weight: 400;
        font-style: normal;
  }

  @font-face {
        font-family: 'Onest';
        src: url(${onestLight}) format('woff');
        font-weight: 300;
        font-style: normal;
  } 
  

  body {
    margin: 0;
    padding: 0;
    font-family: Onest, Sans-Serif;
    color: ${colors.dark1};
  }

  //Normalize
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      vertical-align: baseline;
  }
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
      display: block;
  }
  html {
      height: 100%;
  }
  body {
      line-height: 1;
  }
  ol, ul {
      list-style: none;
  }
  blockquote, q {
      quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
      content: '';
      content: none;
  }
  table {
      border-collapse: collapse;
      border-spacing: 0;
  }
`;
