import { getColumns, GetColumnsParams } from './utils';

import { TextPlaceholder } from '@components/TextPlaceholder';

import { HalalBonusComplaintRecord } from '@features/halalBonus/complaints';
import { Table, TableProps } from '@packages/uiKit';
import { FC, useMemo } from 'react';

export interface ComplaintsTableProps {
  data: HalalBonusComplaintRecord[];
  pagination: TableProps['pagination'];
  onChangeStatus: GetColumnsParams['onChangeStatus'];
}

export const ComplaintsTable: FC<ComplaintsTableProps> = (props) => {
  const { data, pagination, onChangeStatus } = props;

  const columns = useMemo(
    () =>
      getColumns({
        onChangeStatus,
      }),
    [onChangeStatus],
  );

  return (
    <>
      {data.length === 0 ? (
        <TextPlaceholder variant="noData" />
      ) : (
        <Table<HalalBonusComplaintRecord>
          columns={columns}
          data={data}
          pagination={pagination}
        />
      )}
    </>
  );
};
