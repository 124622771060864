import {
  HalalBonusEstablishmentFormValidValues,
  HalalBonusEstablishmentFormValues,
} from './types';

import { yupResolver } from '@hookform/resolvers/yup';
import { useUncontrolledForm } from '@hooks/useUncontrolledForm';
import * as yup from 'yup';

export const validationSchema = yup
  .object({
    notification_limit: yup
      .number()
      .nullable()
      .required('Заполните поле уведомления от суммы'),
    silver: yup
      .number()
      .nullable()
      .min(0, 'Не меньше 0')
      .max(100, 'Не больше 100')
      .required('Заполните поле'),
  })
  .required();

export type UseHalalBonusEstablishmentFormParams = {
  initValues: HalalBonusEstablishmentFormValues;
  onSubmit: (data: HalalBonusEstablishmentFormValues) => Promise<void>;
};
export const useHalalBonusEstablishmentForm = (
  params: UseHalalBonusEstablishmentFormParams,
) => {
  const { initValues, onSubmit } = params;

  return useUncontrolledForm<
    HalalBonusEstablishmentFormValues,
    HalalBonusEstablishmentFormValidValues
  >({
    defaultValues: initValues,
    onSubmit,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
};
