import {
  GetHalalEdaEstablishmentResponse,
  PutHalalEdaEstablishmentRequest,
  PutHalalEdaEstablishmentResponse,
} from './types';

import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const HALAL_EDA_ESTABLISHMENT_TAG =
  'HALAL_EDA_ESTABLISHMENT_TAG' as const;

export const halalEdaEstablishmentApi = createApi({
  reducerPath: 'halalEdaEstablishmentApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'halal-eda/establishment' }),
  tagTypes: [HALAL_EDA_ESTABLISHMENT_TAG],
  endpoints: (builder) => {
    return {
      getEstablishment: builder.query<GetHalalEdaEstablishmentResponse, void>({
        providesTags: [HALAL_EDA_ESTABLISHMENT_TAG],
        query: () => ({
          url: '/',
          method: 'GET',
        }),
      }),

      putEstablishment: builder.mutation<
        PutHalalEdaEstablishmentResponse,
        PutHalalEdaEstablishmentRequest
      >({
        query: (data) => ({
          url: '/',
          method: 'PUT',
          body: data,
        }),
        async onQueryStarted(_, { queryFulfilled }) {
          const { data } = await queryFulfilled;
          halalEdaEstablishmentApi.util.updateQueryData(
            'getEstablishment',
            undefined,
            () => data,
          );
        },
      }),
    };
  },
});
