import { AuthData, AuthState } from './types';

import { localStorageService } from '@features/localStorageService';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

//Инициализация стора при загрузке из localStorage
const lsAuthKey = localStorageService.getAuthKey();

const initialState: AuthState =
  lsAuthKey === null
    ? {
        isAuth: false,
        token: null,
      }
    : {
        isAuth: true,
        token: lsAuthKey,
      };

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setAuthDataInStorage: (_: AuthState, action: PayloadAction<AuthData>) => {
      return { isAuth: true, token: action.payload.token } as AuthState;
    },

    clearAuthDataInStorage: () => {
      return {
        isAuth: false,
        token: null,
      } as AuthState;
    },
  },
});
