import {
  SelectCellWithIcon,
  SelectCellWithIconConfig,
  SelectCellWithIconProps,
} from '@components/table';
import { HalalShopReturnStatus } from '@features/halalShop/returns/types';

import {
  CheckCircleOutlinedIcon,
  PackageIcon,
  ProhibitIcon,
  SmileySadIcon,
} from '@packages/icons';
import { colors } from '@packages/uiKit';
import { FC, memo } from 'react';

export interface ReturnStatusCellProps
  extends Omit<SelectCellWithIconProps<HalalShopReturnStatus>, 'config'> {}

const config: SelectCellWithIconConfig<HalalShopReturnStatus> = [
  {
    value: HalalShopReturnStatus.Processed,
    label: 'Оформлен',
    bgColor: colors.red1,
    color: colors.white,
    icon: <SmileySadIcon />,
  },
  {
    value: HalalShopReturnStatus.Accepted,
    label: 'Принят',
    bgColor: colors.primary,
    color: colors.white,
    icon: <PackageIcon />,
  },
  {
    value: HalalShopReturnStatus.Rejected,
    label: 'Отказано',
    bgColor: colors.gray6,
    color: colors.white,
    icon: <ProhibitIcon />,
  },
  {
    value: HalalShopReturnStatus.Delivered,
    label: 'Доставлен',
    borderColor: colors.gray1,
    color: colors.gray1,
    icon: <CheckCircleOutlinedIcon />,
  },
];

const _ReturnStatusCell: FC<ReturnStatusCellProps> = (props) => {
  const { ...restProps } = props;

  return <SelectCellWithIcon config={config} {...restProps} />;
};

export const ReturnStatusCell = memo(_ReturnStatusCell);
