import { StyledSearchesCard } from './OrdersIssuedCard.styles';

import { InfoCardProps } from '../common/InfoCard';

import { CoinIcon } from '@packages/icons';
import React, { FC, memo } from 'react';

export interface OrdersIssuedCardProps
  extends Pick<InfoCardProps, 'value' | 'percent'> {
  className?: string;
  style?: React.CSSProperties;
}

const _OrdersIssuedCard: FC<OrdersIssuedCardProps> = (props) => {
  const { ...restProps } = props;

  return (
    <StyledSearchesCard
      {...restProps}
      icon={<CoinIcon />}
      description={'Заказов оформили'}
      color="primary"
    />
  );
};

export const OrdersIssuedCard = memo(_OrdersIssuedCard);
