import { StyledSidebar } from './Sidebar.styles';

import { SidebarHeader } from '@layouts/MainLayout/Sidebar/SidebarHeader';
import { MainLayoutOutletContext } from '@layouts/MainLayout/types';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

export interface SidebarProps extends PropsWithChildren {
  className?: string;
  styles?: React.CSSProperties;
  width?: number;
}

export const _Sidebar: FC<SidebarProps> = (props) => {
  const { width, ...restProps } = props;

  const { setSidebar } = useOutletContext<MainLayoutOutletContext>();

  useEffect(() => {
    setSidebar(<StyledSidebar $width={width} {...restProps} />);

    return () => {
      setSidebar(null);
    };
  }, [restProps, setSidebar, width]);

  return null;
};

export const Sidebar = _Sidebar as FC<SidebarProps> & {
  Header: typeof SidebarHeader;
};

Sidebar.Header = SidebarHeader;
