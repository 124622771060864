import {
  StyledStatisticsLayout,
  StyledStatisticsSection,
} from './StatisticsSection.styles';

import { Tabs } from '@components';
import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';
import {
  ComplaintsCard,
  InfoCard,
  TopClientsCard,
  TransactionsCard,
  TransactionsDynamicCard,
} from '@features/analytics';
import { greenBonusTransactionsApi } from '@features/halalBonus/transactions';
import { useSearchParam } from '@hooks/useSearchParam';
import { EyeIcon, PhoneCallIcon } from '@packages/icons';
import { periodTabs } from '@utils/types';
import React, { FC } from 'react';

export interface StatisticsSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

export const StatisticsSection: FC<StatisticsSectionProps> = (props) => {
  const { ...restProps } = props;

  const [searchPeriod, setSearchPeriod] = useSearchParam<string>({
    paramName: 'period',
    defaultValue: 'year',
  });

  const {
    data: statsData,
    isLoading,
    isSuccess,
  } = greenBonusTransactionsApi.useGetStatsPageQuery({
    period: searchPeriod,
  });

  const transactionsChartLength = statsData?.transactions_chart.length;

  return (
    <StyledStatisticsSection {...restProps}>
      <Tabs
        tabs={periodTabs}
        value={searchPeriod}
        onChange={setSearchPeriod}
        variant="round"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isSuccess ? (
            <StyledStatisticsLayout>
              <InfoCard
                value={statsData.total_hits}
                icon={<EyeIcon size={36} />}
                description="Просмотров заведения"
                color={'primary'}
              />
              {/* <UnicSearchesCard value={10760} percent={38} /> */}
              <TransactionsCard
                value={statsData.green_transactions.count}
                percent={statsData.green_transactions.ratio}
              />
              <InfoCard
                value={statsData.tel_hits}
                icon={<PhoneCallIcon size={36} />}
                description="Просмотр контактов"
                color={'primary'}
              />
              {/*  <ShareCard value={710} percent={8} /> */}
              <ComplaintsCard
                value={statsData?.complaints.count}
                percent={statsData?.complaints.ratio}
              />
              <TopClientsCard clients={statsData.top_users} />
              {transactionsChartLength !== 0 && (
                <TransactionsDynamicCard
                  chartData={statsData.transactions_chart}
                />
              )}
            </StyledStatisticsLayout>
          ) : (
            <TextPlaceholder variant="error" />
          )}
        </>
      )}
    </StyledStatisticsSection>
  );
};
