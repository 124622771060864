import {
  StyledLabel,
  StyledPayedIcon,
  StyledPayIcon,
  StyledStatusCell,
} from './StatusCell.styles';

import { AccountingItemRecord } from '@features/accounting';
import { FC, memo } from 'react';

export interface StatusCellProps {
  paid: AccountingItemRecord['paid'];
}

export const _StatusCell: FC<StatusCellProps> = (props) => {
  const { paid: status } = props;

  return (
    <StyledStatusCell>
      <StyledLabel>{status ? 'Оплатить счет' : 'Счет оплачен'}</StyledLabel>

      {status ? <StyledPayIcon /> : <StyledPayedIcon />}
    </StyledStatusCell>
  );
};

export const StatusCell = memo(_StatusCell);
