import { Tabs } from '@components';
import { TabsTabItem } from '@components/Tabs/types';
import { accountingApi } from '@features/accounting';
import { useAuthProtection } from '@hooks/useAuthProtection';
import { MainLayout } from '@layouts/MainLayout';
import { AccountingTable } from '@pages/AccountingPage/AccountingTable';
import { FC, useState } from 'react';

const tabs: TabsTabItem<string>[] = [
  { value: 'all', label: 'Все' },
  { value: 'pay', label: 'Оплатить', badge: true },
  { value: 'payed', label: 'Оплачен' },
];

const ITEMS_PER_PAGE = 12;

export const AccountingPage: FC = () => {
  useAuthProtection();

  const [currentTab, setCurrentTab] = useState('all');

  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, isSuccess } =
    accountingApi.useGetAccountingPageQuery({
      page: currentPage,
      perPage: ITEMS_PER_PAGE,
      paid: currentTab === 'all' ? undefined : currentTab === 'pay' ? 1 : 0,
    });

  const pageCount = data?.pagination.pageCount ?? 0;

  return (
    <MainLayout.Content>
      <MainLayout.Content.Header>
        Отчётность по проектам
      </MainLayout.Content.Header>

      <Tabs tabs={tabs} value={currentTab} onChange={setCurrentTab} />

      <AccountingTable
        data={data?.result || []}
        isLoading={isLoading}
        isSuccess={isSuccess}
        pagination={{
          pageIndex: currentPage,
          onChangePage: setCurrentPage,
          pageCount,
        }}
      />
    </MainLayout.Content>
  );
};
