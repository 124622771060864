import { StyledInfoContainer } from './InfoContainer.styles';

import { colors } from '@packages/uiKit/utils/colors';

import { FC, PropsWithChildren } from 'react';

export interface InfoContainerProps {
  color: keyof typeof colors | string;
  type: 'filled' | 'outlined';
}

export const InfoContainer: FC<PropsWithChildren<InfoContainerProps>> = (
  props,
) => {
  const color = Object.hasOwn(colors, props.color)
    ? colors[props.color as keyof typeof colors]
    : props.color;

  return <StyledInfoContainer {...props} color={color} />;
};
