import { bookingFormSchema } from '../BookingFormFields';
import {
  BookingFormValidValues,
  BookingFormValues,
} from '../BookingFormFields/types';

import { yupResolver } from '@hookform/resolvers/yup';
import { useUncontrolledForm } from '@hooks/useUncontrolledForm';

const initValues: BookingFormValues = {
  name: null,
  time: null,
  phone: null,
  note: null,
  seats: 1,
};

export type UseCreateBookingFormParams = {
  onSubmit: (data: BookingFormValidValues) => Promise<void>;
};
export const useCreateBookingForm = (params: UseCreateBookingFormParams) => {
  const { onSubmit } = params;

  return useUncontrolledForm<BookingFormValues, BookingFormValidValues>({
    defaultValues: initValues,
    onSubmit,
    mode: 'onBlur',
    resolver: yupResolver(bookingFormSchema),
  });
};
