import React, { FC, memo, useState } from 'react';

import { ListAsTableRow, Section } from '@components';
import { HalalShopReturnStatusCell } from '@features/halalShop/returns/components/OrderStatusCell';
import { HalalShopReturnStatus } from '@features/halalShop/returns/types';
import { formatDate } from '@utils/dateFormat';
import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';

export interface MainInfoSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

const _MainInfoSection: FC<MainInfoSectionProps> = (props) => {
  const [status, setStatus] = useState<HalalShopReturnStatus>(
    HalalShopReturnStatus.Processed,
  );

  return (
    <Section title={`Возврат · 1414`}>
      <ListAsTableRow>
        <ListAsTableRow.Item>
          <HalalShopReturnStatusCell status={status} onChange={setStatus} />
        </ListAsTableRow.Item>

        <ListAsTableRow.Item>
          <ListAsTableRow.Label label="Сумма" />
          <ListAsTableRow.Value
            value={`${formatPriceVisualFriendly(2700)} ₸`}
          />
        </ListAsTableRow.Item>

        <ListAsTableRow.Item>
          <ListAsTableRow.Label label="Время" />
          <ListAsTableRow.Value
            value={`${formatDate(
              'Sat Sep 13 275760 00:00:00 GMT+0000 (Coordinated Universal Time)',
              'dateTime',
            )}`}
          />
        </ListAsTableRow.Item>

        <ListAsTableRow.Item>
          <ListAsTableRow.Label label="Адрес доставки" />
          <ListAsTableRow.Value value={'Казань, ул. Меликова 16'} />
        </ListAsTableRow.Item>

        <ListAsTableRow.Item>
          <ListAsTableRow.Label label="Клиент" />
          <ListAsTableRow.Value value={'Камилла Фатыховна'} />
        </ListAsTableRow.Item>

        <ListAsTableRow.Item>
          <ListAsTableRow.Label label="Номер телефона" />
          <ListAsTableRow.Value value={'+7 (869) 595-95-95'} />
        </ListAsTableRow.Item>
      </ListAsTableRow>
    </Section>
  );
};

export const MainInfoSection = memo(_MainInfoSection);
