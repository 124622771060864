import styled, { css } from 'styled-components';

export const StyledGrid = styled.div<{
  $cols: number;
  $columnGap?: number;
  $rowGap?: number;
}>`
  ${({ $cols, $columnGap, $rowGap }) => css`
    display: grid;
    grid-template-columns: repeat(${$cols}, 1fr);

    ${$columnGap
      ? css`
          column-gap: ${$columnGap}px;
        `
      : ''}

    ${$rowGap
      ? css`
          row-gap: ${$rowGap}px;
        `
      : ''}
  `}
`;
