import { StyledStatisticSection, StyledStatisticsLayout } from './styles';

import { Tabs } from '@components';
import { InfoCard } from '@features/analytics';
import { AddToBagCard } from '@features/analytics/components/AddToBagCard';
import { HalalEdaCommissionCard } from '@features/analytics/components/HalalEdaCommissionCard';
import { HalalShopDynamicCard } from '@features/analytics/components/HalalShopDynamicCard';
import { HalalShopStructureCard } from '@features/analytics/components/HalalShopStructureCard/HalalShopStructureCard';
import { OrdersDeliveredCard } from '@features/analytics/components/OrdersDeliveredCard';
import { OrdersIssuedCard } from '@features/analytics/components/OrdersIssuedCard';
import { SumAllOrdersCard } from '@features/analytics/components/SumAllOrdersCard';
import { TopProductsCard } from '@features/analytics/components/TopProductsCard';
import { useSearchParam } from '@hooks/useSearchParam';
import { EyeIcon } from '@packages/icons';
import { periodTabs } from '@utils/types';

import { FC, memo } from 'react';

const _StatisticSection: FC = () => {
  const [searchPeriod, setSearchPeriod] = useSearchParam<string>({
    paramName: 'period',
    defaultValue: 'year',
  });

  <Tabs
    tabs={periodTabs}
    value={searchPeriod}
    onChange={setSearchPeriod}
    variant="round"
  />;

  return (
    <StyledStatisticSection>
      <StyledStatisticsLayout>
        <TopProductsCard />
        <HalalShopStructureCard />
        <HalalShopDynamicCard />
        <InfoCard
          value={222}
          icon={<EyeIcon size={36} />}
          description="Просмотров заведения"
          color={'primary'}
        />
        <AddToBagCard value={4308} percent={43} />
        <OrdersIssuedCard value={2567} percent={25} />
        <OrdersDeliveredCard value={2305} percent={23} />
        <SumAllOrdersCard value={1580770} />
        <HalalEdaCommissionCard value={84000} />
      </StyledStatisticsLayout>
    </StyledStatisticSection>
  );
};

export const StatisticSection = memo(_StatisticSection);
