import { StyledBadge } from './Badge.styles';

import { BadgeColor } from './types';

import { Typography } from '../Typography';

import React, { FC, PropsWithChildren } from 'react';

export interface BadgeProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
  color: BadgeColor;
}

export const Badge: FC<BadgeProps> = (props) => {
  const { color, children, ...restProps } = props;

  return (
    <StyledBadge $color={color} {...restProps}>
      <Typography weight={700} size={10} nowrap>
        {children}
      </Typography>
    </StyledBadge>
  );
};
