import { StatusCellConfig } from '@components/table/StatusCell/types';
import { HalalBonusTransactionStatus } from '@features/halalBonus/transactions';
import { MinusCircleIcon, PlusCircleIcon } from '@packages/icons';
import { colors } from '@packages/uiKit';

export const config: StatusCellConfig<HalalBonusTransactionStatus> = [
  {
    value: HalalBonusTransactionStatus.Accrual,
    label: 'Начисление',
    bgColor: colors.primary,
    color: colors.white,
    icon: <PlusCircleIcon />,
  },
  {
    value: HalalBonusTransactionStatus.Withdrawal,
    label: 'Списание',
    bgColor: colors.dark1,
    color: colors.white,
    icon: <MinusCircleIcon />,
  },
];
