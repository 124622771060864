import { CompanyFormValues } from './CompanyForm/types';

import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';

import { companyApi } from '@features/company';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { useAuthProtection } from '@hooks/useAuthProtection';
import { MainLayout } from '@layouts/MainLayout';
import { CompanyForm } from '@pages/CompanyPage/CompanyForm';
import { UseCompanyFormParams } from '@pages/CompanyPage/CompanyForm/hooks';
import { FC, useCallback } from 'react';

export const CompanyPage: FC = () => {
  useAuthProtection();

  const createNotification = useNotification();

  const { data, isLoading, isSuccess } = companyApi.useGetCompanyQuery();
  const [putCompanyM] = companyApi.usePutCompanyMutation();
  const [putCompanyScan] = companyApi.usePutCompanyScanMutation();

  const onSubmitHandler: UseCompanyFormParams['onSubmit'] = useCallback(
    async (data, isScanChanged) => {
      const promises: Promise<any>[] = [putCompanyM(data).unwrap()];

      if (isScanChanged) {
        const scan = data.certificate.scan;
        promises.push(putCompanyScan(scan as File | null).unwrap());
      }

      try {
        await Promise.all(promises);

        createNotification({
          message: 'Изменена информация о компании',
          type: 'success',
        });
      } catch {
        createNotification({
          message: 'Произошла ошибка при изменении информации о компании',
          type: 'error',
        });
      }
    },
    [createNotification, putCompanyM, putCompanyScan],
  );

  const initValues: CompanyFormValues | null = data
    ? {
        ...data,
        certificate: {
          ...data.certificate,
        },
        okved: null,
      }
    : null;

  return (
    <MainLayout.Content>
      <MainLayout.Content.Header>
        Информация о компании
      </MainLayout.Content.Header>

      {!isLoading && initValues ? (
        <CompanyForm initValues={initValues} onSubmit={onSubmitHandler} />
      ) : (
        <Loader />
      )}
      {!isSuccess && !isLoading && <TextPlaceholder />}
    </MainLayout.Content>
  );
};
