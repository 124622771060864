import {
  StyledDescription,
  StyledIconContainer,
  StyledMessageWithIcon,
  StyledRight,
  StyledTitle,
} from './styles';

import React, { FC, memo, ReactNode } from 'react';

export interface MessageWithIconProps {
  className?: string;
  style?: React.CSSProperties;
  icon: ReactNode;
  title: string;
  description: string;
}

const _MessageWithIcon: FC<MessageWithIconProps> = (props) => {
  const { icon, title, description, ...restProps } = props;

  return (
    <StyledMessageWithIcon {...restProps}>
      <StyledIconContainer>{icon}</StyledIconContainer>
      <StyledRight>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </StyledRight>
    </StyledMessageWithIcon>
  );
};

export const MessageWithIcon = memo(_MessageWithIcon);
