import {
  StyledDescription,
  StyledTextPlaceholder,
  StyledTitle,
} from './styles';

import { Space } from '@components/Space';

import React, { FC, memo } from 'react';

type VariantItems = 'error' | 'noData' | 'noRightsProductDetail';

export interface TextPlaceholderProps {
  className?: string;
  style?: React.CSSProperties;

  variant?: VariantItems;
}

const errorTypes: { type: VariantItems; title: string; description: string }[] =
  [
    {
      type: 'error',
      title: 'Ошибка',
      description: 'Возникла ошибка при загрузке данных.',
    },
    {
      type: 'noData',
      title: 'Информация отсутствует',
      description: 'В базе данных ничего не найдено',
    },
    {
      type: 'noRightsProductDetail',
      title: 'Ошибка',
      description:
        'У вас нет доступа для просмотра / редактирования данного товара',
    },
  ];

const _TextPlaceholder: FC<TextPlaceholderProps> = (props) => {
  const { variant = 'error', ...restProps } = props;

  const { title, description } =
    errorTypes.find((type) => type.type === variant) || {};

  return (
    <StyledTextPlaceholder {...restProps}>
      <StyledTitle>{title}</StyledTitle>
      <Space size={15} />
      <StyledDescription>{description}</StyledDescription>
    </StyledTextPlaceholder>
  );
};

export const TextPlaceholder = memo(_TextPlaceholder);
