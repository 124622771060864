import { TabsTabItem } from '@components';
import { HalalTradingGetCategoryRecord } from '@features/halalTrading/store/types';

export const getCategoriesTabs = (
  data: HalalTradingGetCategoryRecord[] | null | undefined,
) => {
  if (!data) return [];

  // добавить useMemo на тот случай если появиться функционал добавления новой категории.
  const res: TabsTabItem<string>[] = data.map((item) => {
    return { value: item.id.toString(), label: item.title };
  });

  return res;
};
