import { InfoContainer } from '../../../components/InfoContainer';

import { colors } from '@packages/uiKit/utils/colors';

import styled from 'styled-components';

export const StyledMainContactInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  flex-wrap: wrap;
`;

export const StyledHintText = styled.div`
  color: ${colors.gray1};
  font-size: 15px;
`;

export const StyledHintStrongText = styled.span`
  font-weight: 700;
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledPhoneInfoContainer = styled(InfoContainer).attrs({})`
  display: flex;
  align-items: center;
  gap: 10px;
`;
