import {
  StyledField,
  StyledFieldControlContainer,
  StyledFieldError,
  StyledFieldHeader,
  StyledFieldLabel,
  StyledFieldTip,
} from './Field.styles';

import { FieldVariant } from './types';

import React, { FC, PropsWithChildren } from 'react';

export interface FieldProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;

  label?: string;
  tip?: string;
  error?: string;
  variant?: FieldVariant;

  marginBottom?: number;
  isCustomMargin?: boolean;
}

export const Field: FC<FieldProps> = (props) => {
  const {
    label,
    error,
    tip,
    children,
    marginBottom = 28,
    isCustomMargin = false,
    variant = 'default',
  } = props;

  const isRenderHeader = label || tip || error;

  return (
    <StyledField
      variant={variant}
      $marginBottom={marginBottom}
      $isCustomMargin={isCustomMargin}>
      {isRenderHeader && (
        <StyledFieldHeader>
          {error ? (
            <StyledFieldError title={error}>{error}</StyledFieldError>
          ) : (
            label && <StyledFieldLabel title={label}>{label}</StyledFieldLabel>
          )}

          {tip && <StyledFieldTip title={tip}>{tip}</StyledFieldTip>}
        </StyledFieldHeader>
      )}

      <StyledFieldControlContainer>{children}</StyledFieldControlContainer>
    </StyledField>
  );
};
