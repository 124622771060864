import { Typography, colors } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledOnScreenSaverPreview = styled.div`
  background: ${colors.white};
  border-radius: 15px;
  width: 270px;
  height: fit-content;

  position: absolute;
  right: 30%;
  top: 30%;

  display: flex;
  align-items: center;
  gap: 12px;

  padding: 10px;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  position: relative;

  width: 200px;
`;

export const StyledTitle = styled(Typography).attrs({
  size: 12,
  weight: 700,
  maxLines: 2,
  ellipsis: true,
})`
  padding-right: 25px;
`;

export const StyledMainText = styled(Typography).attrs({
  size: 12,
  weight: 400,
  maxLines: 3,
  ellipsis: true,
})``;

export const StyledTimeTitle = styled(Typography).attrs({
  size: 10,
  weight: 400,

  color: 'gray6',
})`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const StyledLogoImg = styled.img`
  width: 25px;
`;
