import {
  StyledBackLink,
  StyledCaretLeft,
  StyledTitle,
} from './BackLink.styles';

import React, { FC, memo, PropsWithChildren } from 'react';
import { To, useNavigate } from 'react-router-dom';

export interface BackLinkProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;

  to?: To;
}

const _BackLink: FC<BackLinkProps> = (props) => {
  const { children, to, ...restProps } = props;

  const navigate = useNavigate();

  const onClickHandler = () => {
    if (to) {
      navigate(to);
    } else {
      navigate(-1);
    }
  };

  return (
    <StyledBackLink role="link" onClick={onClickHandler} {...restProps}>
      <StyledCaretLeft />
      <StyledTitle>{children}</StyledTitle>
    </StyledBackLink>
  );
};

export const BackLink = memo(_BackLink);
