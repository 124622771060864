import {
  StyledButtonsContainer,
  StyledCatalogSection,
  StyledHeader,
  StyledTabs,
} from './styles';

import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';
import { getCategoriesTabs } from '@features/halalTrading/categories/utils/getCategoriesTabs';

import { GridAdaptive } from '@components/GridAdaptive';
import { HalalTradingProductCard } from '@features/halalTrading/components/HalalTradingProductCard';
import { halalTradingEndpoints } from '@features/halalTrading/store/apiService';
import { useModalState } from '@hooks/useModalState';
import { useSearchParam } from '@hooks/useSearchParam';
import { Button, Modal } from '@packages/uiKit';
import { FC, memo, useState } from 'react';
import { CreateNewProductPositionModal } from './modals/CreateNewProductPositionModal';
import { EditProductPositionModal } from './modals/EditProductPositionModal';
import { getInitProductValues } from './modals/utils/getInitProductValues';

const _CatalogSection: FC = () => {
  //const navigate = useNavigate();

  const [categorySearch, setCategorySearch] = useSearchParam<string>({
    paramName: 'category',
    defaultValue: '1',
  });

  const { data: categoriesData, isLoading: isLoadingCategories } =
    halalTradingEndpoints.useGetCategoriesQuery();

  const {
    data: productsData,
    isSuccess: isSuccessProducts,
    isLoading: isLoadingProducts,
  } = halalTradingEndpoints.useGetProductsQuery({
    limit: 1000,
    offset: 0,
  });

  const [selectedProductId, setSelectedProductId] = useState<number>(0);

  const {
    data: productData,
    isSuccess: isProductDataSuccess,
    isLoading: isProductDataLoading,
    isFetching: isProductDataFetching,
  } = halalTradingEndpoints.useGetProductDetailQuery({
    id: selectedProductId,
  });

  const productDataInitValues = getInitProductValues(productData);

  const isAllLoading = isLoadingCategories && isLoadingProducts;

  const categoriesTabs = getCategoriesTabs(categoriesData?.results);

  const [modalAddProduct, setModalAddProductActions] = useModalState();

  const [modalEditProduct, setModalEditProductActions] = useModalState();

  return (
    <StyledCatalogSection>
      {isAllLoading ? (
        <Loader />
      ) : (
        <>
          <StyledHeader>
            <StyledTabs
              tabs={categoriesTabs}
              value={categorySearch}
              onChange={setCategorySearch}
              variant="round"
              isExpandable
              visibleAmount={20}
            />
            <StyledButtonsContainer>
              <Button onClick={setModalAddProductActions.openHandler}>
                Добавить товар
              </Button>
            </StyledButtonsContainer>
          </StyledHeader>
          {isLoadingProducts ? (
            <Loader />
          ) : isSuccessProducts ? (
            productsData.results.length > 0 ? (
              <GridAdaptive cols={{ desktop: 4 }} columnGap={20} rowGap={30}>
                {productsData.results.map((item, index) => {
                  const onClickHandler = () => {
                    setSelectedProductId(item.id);
                    setModalEditProductActions.openHandler();
                  };

                  return (
                    <HalalTradingProductCard
                      key={item.id}
                      name={item.name}
                      id={item.id}
                      image={item.photo}
                      cost={item.price}
                      isActive={true}
                      onEditClick={onClickHandler}
                    />
                  );
                })}
              </GridAdaptive>
            ) : (
              <TextPlaceholder variant="noData" />
            )
          ) : (
            <TextPlaceholder variant="error" />
          )}
        </>
      )}
      <Modal
        title="Добавить товар"
        isOpen={modalAddProduct.isOpen}
        width={600}
        onClose={setModalAddProductActions.closeHandler}>
        <CreateNewProductPositionModal
          categoriesTabs={categoriesTabs}
          onClose={setModalAddProductActions.closeHandler}
        />
      </Modal>
      <Modal
        title="Редактировать товар"
        isOpen={modalEditProduct.isOpen}
        width={600}
        onClose={setModalEditProductActions.closeHandler}>
        {isProductDataLoading || isProductDataFetching ? (
          <Loader />
        ) : isProductDataSuccess ? (
          <EditProductPositionModal
            categoriesTabs={categoriesTabs}
            productId={selectedProductId}
            initValues={productDataInitValues}
            onClose={setModalEditProductActions.closeHandler}
          />
        ) : (
          <TextPlaceholder />
        )}
      </Modal>
    </StyledCatalogSection>
  );
};

export const CatalogSection = memo(_CatalogSection);
