import { colors } from '../utils/colors';

import styled, { css } from 'styled-components';

const positionConfig = {
  right: {
    posTransform: 'translate(0%, -50%)',
    posRight: 'auto',
    posLeft: 'calc(100% + 14px)',
    posTop: '50%',
    posBottom: 'auto',
  },

  left: {
    posTransform: 'translate(0%, -50%)',
    posRight: 'calc(100% + 14px)',
    posLeft: 'auto',
    posTop: '50%',
    posBottom: 'auto',
  },

  top: {
    posTransform: 'translate(-50%, -50%)',
    posRight: 'auto',
    posLeft: '50%',
    posTop: 'auto',
    posBottom: 'calc(60% + 14px)',
  },

  bottom: {
    posTransform: 'translate(-50%, 0%)',
    posRight: 'auto',
    posLeft: '50%',
    posTop: 'calc(100% + 14px)',
    posBottom: 'auto',
  },
};

const getDynamicParams = (props: {
  position: 'right' | 'left' | 'top' | 'bottom';
}) => {
  const { position } = props;
  const posProps = positionConfig[position];

  return { posProps };
};

export const StyledTooltip = styled.div`
  position: relative;
`;

export const StyledTooltipWrapper = styled.div``;

export const StyledTooltipContent = styled.div<{
  position: 'left' | 'right' | 'top' | 'bottom';
  isVisible: boolean;
}>`
  position: absolute;
  color: ${colors.dark1};

  /* isVisible dependencies */
  ${({ isVisible }) =>
    isVisible &&
    css`
      display: block;
    `}
  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}
    /* Position dependencies */
    ${({ position }) => {
    const props = getDynamicParams({ position });
    return css`
      right: ${props.posProps.posRight};
      left: ${props.posProps.posLeft};
      top: ${props.posProps.posTop};
      bottom: ${props.posProps.posBottom};
      transform: ${props.posProps.posTransform};
    `;
  }}
`;
