import { StyledRightContainer } from './styles';

import { Button } from '../Button';
import { Input } from '../Input/Input';

import { EyeIcon, EyeSlashIcon } from '@packages/icons';
import React, { ReactNode, useCallback, useState } from 'react';

export interface InputPasswordProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value'
  > {
  value?: string | null;
  onChange?: (value: string) => void;
  error?: boolean;
  right?: ReactNode;
}

export const InputPassword = React.forwardRef<
  HTMLInputElement,
  InputPasswordProps
>((props, ref) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const toggleShow = useCallback(() => {
    setIsShowPassword((prev) => !prev);
  }, []);

  return (
    <Input
      ref={ref}
      {...props}
      type={isShowPassword ? 'text' : 'password'}
      right={
        <StyledRightContainer>
          <Button
            onClick={toggleShow}
            variant="text"
            color="secondary"
            icon={isShowPassword ? <EyeSlashIcon /> : <EyeIcon />}
          />
          {props.right}
        </StyledRightContainer>
      }
    />
  );
});

Input.displayName = 'InputPassword';
