import styled from 'styled-components';

export const StyledLoader = styled.div<{
  $margin?: [number, number, number, number];
}>`
  display: flex;
  flex-direction: column;
  gap: 15px;

  margin: ${({ $margin }) =>
    $margin
      ? `${$margin[0]}px ${$margin[1]}px ${$margin[2]}px ${$margin[3]}px`
      : '0'};
`;
