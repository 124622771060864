import { ChartCard } from '../common/ChartCard';

import styled from 'styled-components';

export const StyledOrderDynamicCard = styled(ChartCard)`
  grid-area: orderDynamic;
  padding-right: 36px;
`;

export const StyledLegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
