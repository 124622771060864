import { Button } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledLoginForm = styled.div``;

export const StyledValuesLayout = styled.div``;

export const StyledSubmitButton = styled(Button).attrs({
  variant: 'filled',
  size: 'large',
  block: true,
})`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;
