import {
  StyledButtonsArea,
  StyledCertificateSection,
  StyledCompanyForm,
  StyledDocumentSection,
  StyledRequisitesSection,
} from './CompanyForm.styles';

import { useCompanyForm, UseCompanyFormParams } from './hooks';

import { CertificateCentersSelectFieldAdapter } from '@features/certificateCenters/components/CertificateCentersSelectFieldAdapter';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { Button } from '@packages/uiKit/Button';
import { InputDateFieldAdapter } from '@packages/uiKit/fieldAdapters/InputDateFieldAdapter';
import { InputFieldAdapter } from '@packages/uiKit/fieldAdapters/InputFieldAdapter';
import { UploadImageInputFieldAdapter } from '@packages/uiKit/fieldAdapters/UploadImageInputFieldAdapter';

import { InputDateFieldProps } from '@packages/uiKit/fields/InputDateField';
import { Col, Row } from 'antd';
import { FC, useCallback } from 'react';

export interface CompanyFormProps {
  initValues: UseCompanyFormParams['initValues'];
  onSubmit: UseCompanyFormParams['onSubmit'];
}

export const CompanyForm: FC<CompanyFormProps> = (props) => {
  const { initValues, onSubmit, ...restProps } = props;

  const { control, handleSubmit, handleReset, watch } = useCompanyForm({
    initValues,
    onSubmit,
  });

  const notification = useNotification();

  const resetHandler = useCallback(() => {
    handleReset();
    notification({ type: 'info', message: 'Форма отчищена от изменений' });
  }, [handleReset, notification]);

  const certificateValidFrom = watch('certificate.valid_from');
  const certificateValidTo = watch('certificate.valid_to');

  const getDisabledFrom: NonNullable<InputDateFieldProps['disabledDate']> =
    useCallback(
      (date) => {
        if (!certificateValidTo) {
          return false;
        }
        return date.isAfter(certificateValidTo);
      },
      [certificateValidTo],
    );

  const getDisabledTo: NonNullable<InputDateFieldProps['disabledDate']> =
    useCallback(
      (date) => {
        if (!certificateValidFrom) {
          return false;
        }
        return date.isBefore(certificateValidFrom);
      },
      [certificateValidFrom],
    );

  return (
    <StyledCompanyForm {...restProps}>
      <StyledRequisitesSection title="Реквизиты">
        <Row gutter={15}>
          <Col span={12}>
            <InputFieldAdapter
              control={control}
              name="name"
              label="Наименование организации"
              placeholder="Наименование организации"
            />
          </Col>
          <Col span={12}>
            <InputFieldAdapter
              control={control}
              name="bank_name"
              label="Наименование банка"
              placeholder="Наименование банка"
            />
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={6}>
            <InputFieldAdapter
              control={control}
              name="inn"
              label="ИНН"
              placeholder="ИНН"
            />
          </Col>
          <Col span={6}>
            <InputFieldAdapter
              control={control}
              name="kpp"
              label="КПП"
              placeholder="КПП"
            />
          </Col>
          <Col span={12}>
            <InputFieldAdapter
              control={control}
              name="account"
              label="Номер счета"
              placeholder="Номер счета"
            />
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={6}>
            <InputFieldAdapter
              control={control}
              name="bik"
              label="БИК"
              placeholder="БИК"
            />
          </Col>
          <Col span={6}>
            <InputFieldAdapter
              control={control}
              name="ogrn"
              label="ОГРН"
              placeholder="ОГРН"
            />
          </Col>
          <Col span={12}>
            <InputFieldAdapter
              control={control}
              name="account_of_bank"
              label="Корреспондентский счет"
              placeholder="Корреспондентский счет"
            />
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={6}>
            <InputFieldAdapter
              control={control}
              name="okato"
              label="ОКАТО"
              placeholder="ОКАТО"
            />
          </Col>
          <Col span={6}>
            <InputFieldAdapter
              control={control}
              name="okved"
              label="ОКВЭД (основной)"
              placeholder="ОКВЭД"
            />
          </Col>
          <Col span={12}>
            <InputFieldAdapter
              control={control}
              name="director"
              label="Генеральный директор"
              placeholder="Генеральный директор"
            />
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={12}>
            <InputFieldAdapter
              control={control}
              name="address"
              label="Юридический адрес"
              placeholder="Юридический адрес"
            />
          </Col>
          <Col span={12}>
            <InputFieldAdapter
              control={control}
              name="accountant"
              label="Главный бухгалтер"
              placeholder="Главный бухгалтер"
            />
          </Col>
        </Row>
      </StyledRequisitesSection>

      <StyledCertificateSection title="Сертификат халяль">
        <InputFieldAdapter
          control={control}
          name="certificate.number"
          label="Номер сертификата"
          placeholder="Номер сертификата"
        />
        <CertificateCentersSelectFieldAdapter
          control={control}
          name="certificate.center"
          label="Центр сертификации"
        />
        <InputDateFieldAdapter
          control={control}
          name="certificate.valid_from"
          label="Дата выдачи"
          type="date"
          disabledDate={getDisabledFrom}
        />
        <InputDateFieldAdapter
          control={control}
          name="certificate.valid_to"
          label="Действителен до"
          disabledDate={getDisabledTo}
          type="date"
        />
        <InputFieldAdapter
          control={control}
          name="certificate.confirmation_link"
          label="Ссылка на подтверждение (если есть)"
          placeholder="https://"
        />
      </StyledCertificateSection>

      <StyledDocumentSection title="Документ">
        <UploadImageInputFieldAdapter
          control={control}
          name="certificate.scan"
          label="Загрузите скан или фото сертификата халяль"
          preview="card">
          Загрузить скан
        </UploadImageInputFieldAdapter>
      </StyledDocumentSection>

      <StyledButtonsArea>
        <Button size="large" variant="filled" onClick={handleSubmit}>
          Сохранить изменения
        </Button>
        <Button size="large" variant="outlined" onClick={resetHandler}>
          Отменить изменения
        </Button>
      </StyledButtonsArea>
    </StyledCompanyForm>
  );
};
