import {
  StyledActiveSwitchTitle,
  StyledFireIcon,
  StyledHandbagIcon,
  StyledPreviewImage,
  StyledPreviewTextWrapped,
  StyledPreviewTip,
  StyledPreviewTitle,
  StyledPreviewWrapper,
  StyledStatsCard,
  StyledStatsCardAmount,
  StyledStatsCardAmountInfo,
  StyledStockPositionCard,
  StyledStockPositionCardWrapper,
  StyledSwitcher,
  StyledTargetIcon,
} from './DiscountPositionCardForm.styles';

import { StockPositionCardFormValues } from '../../types';

import { fileValidation } from '@features/forms/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Field, Switch } from '@packages/uiKit';
import { InputField } from '@packages/uiKit/fields/InputField';
import { InputNumberField } from '@packages/uiKit/fields/InputNumberField';
import { SelectField } from '@packages/uiKit/fields/SelectField';
import { UploadImageInputField } from '@packages/uiKit/fields/UploadImageInputField';
import { Col, Row, Space } from 'antd';
import React, { FC, PropsWithChildren } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface DiscountPositionCardFormProps
  extends StockPositionCardFormValues,
    PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}

export const discountPositionCardFormSchema = yup
  .object({
    title: yup.string().required('Введите название заведения'),
    photo: fileValidation({
      mime: ['image/jpeg'],
    }),

    stockVariantSelected: yup.string().nullable().optional(),
    fromSumOrder: yup.string().nullable().optional(),
    discountPercentage: yup
      .number()
      .nullable()
      .optional()
      .max(100, 'Скидка не может быть больше 100%'),
  })
  .required();

export const DiscountPositionCardForm: FC<DiscountPositionCardFormProps> = (
  props,
) => {
  const {
    id,
    title,
    photo,
    stockVariantSelected,
    stockVariantOptions,
    fromSumOrder,
    discountPercentage,
    statsOne,
    statsTwo,
    ...restProps
  } = props;

  const initValues = {
    id,
    title,
    stockVariantSelected,
    stockVariantOptions,
    fromSumOrder,
    photo,
    discountPercentage,
  };

  const { control, handleSubmit } = useForm({
    defaultValues: initValues,
    resolver: yupResolver(discountPositionCardFormSchema),
  });

  const onSubmitHandler = handleSubmit((data) => {
    console.log(data);
  });

  return (
    <StyledStockPositionCard {...restProps}>
      <StyledStockPositionCardWrapper>
        <Row gutter={30}>
          <Col span={7}>
            <Controller
              control={control}
              name={'title'}
              defaultValue={initValues.title}
              render={({ field, fieldState: { error } }) => {
                return (
                  <InputField
                    label="Заголовок акции"
                    value={field.value}
                    error={error?.message}
                    onChange={field.onChange}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={'stockVariantSelected'}
              defaultValue={initValues.stockVariantSelected}
              render={({ field, fieldState: { error } }) => {
                return (
                  <SelectField
                    label="Тип акции"
                    options={initValues.stockVariantOptions}
                    value={field.value}
                    error={error?.message}
                    onChange={field.onChange}
                  />
                );
              }}
            />

            <Row gutter={[20, 10]} align="middle">
              <Col>
                <Row gutter={10}>
                  <Col>
                    <Button
                      size="medium"
                      variant="filled"
                      onClick={onSubmitHandler}>
                      Сохранить
                    </Button>
                  </Col>
                  <Col>
                    <Button size="medium" variant="filled" color="secondary">
                      Удалить
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Space align="center">
                  <StyledSwitcher>
                    <Switch value={true} />
                    <StyledActiveSwitchTitle>
                      Акция активна
                    </StyledActiveSwitchTitle>
                  </StyledSwitcher>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row gutter={10}>
              <Col span={12}>
                <Controller
                  control={control}
                  name={'fromSumOrder'}
                  defaultValue={initValues.fromSumOrder}
                  rules={{}}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <InputNumberField
                        onChange={field.onChange}
                        error={error?.message}
                        label="При заказе от · Рублей"
                        value={field.value}
                      />
                    );
                  }}
                />
              </Col>
              <Col span={12}>
                <Controller
                  control={control}
                  name={'discountPercentage'}
                  defaultValue={initValues.discountPercentage}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <InputNumberField
                        onChange={field.onChange}
                        error={error?.message}
                        label="Процент скидки · %"
                        value={field.value}
                      />
                    );
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Controller
                  control={control}
                  name={'photo'}
                  defaultValue={initValues.photo}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <UploadImageInputField
                        value={value}
                        onChange={onChange}
                        color="secondary"
                        error={error?.message}>
                        Загрузить фото акции
                      </UploadImageInputField>
                    );
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={5}>
            <Field label="Предпросмотр акции">
              <StyledPreviewWrapper>
                <Controller
                  control={control}
                  name={'photo'}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    const resPhoto =
                      value instanceof File
                        ? URL.createObjectURL(value)
                        : value;
                    return <StyledPreviewImage src={resPhoto} />;
                  }}
                />

                <StyledPreviewTextWrapped>
                  <StyledPreviewTitle>{title}</StyledPreviewTitle>
                  <StyledPreviewTip>
                    <StyledFireIcon />
                    При заказе от {fromSumOrder}₸
                  </StyledPreviewTip>
                </StyledPreviewTextWrapped>
              </StyledPreviewWrapper>
            </Field>
          </Col>
          <Col span={6}>
            <Field label="Статистика по акции">
              <Row gutter={15}>
                <Col span={12}>
                  <StyledStatsCard>
                    <StyledTargetIcon />
                    <StyledStatsCardAmount>{statsOne}</StyledStatsCardAmount>
                    <StyledStatsCardAmountInfo>
                      Перешли в заведение из общего каталога
                    </StyledStatsCardAmountInfo>
                  </StyledStatsCard>
                </Col>
                <Col span={12}>
                  <StyledStatsCard>
                    <StyledHandbagIcon />
                    <StyledStatsCardAmount>{statsTwo}</StyledStatsCardAmount>
                    <StyledStatsCardAmountInfo>
                      Применили акцию к своему заказу
                    </StyledStatsCardAmountInfo>
                  </StyledStatsCard>
                </Col>
              </Row>
            </Field>
          </Col>
        </Row>
      </StyledStockPositionCardWrapper>
    </StyledStockPositionCard>
  );
};
