import { HalalTradingProductMutationRequest } from '@features/halalTrading/store/types';

import { fileValidation, stringValidation } from '@features/forms/validation';
import * as yup from 'yup';

export const validationSchema: yup.SchemaOf<
  Omit<ProductHalalTradingValues, 'manufacturer_logo' | 'photo'>
> = yup
  .object({
    name: yup
      .string()
      .required('Название товара')
      .max(60, 'Максимальное кол-во символов - 60'),
    desc: yup
      .string()
      .required('Добавьте описание')
      .max(200, 'Максимальное кол-во символов - 200'),

    category: yup.number().required('Выберите категорию'),
    barcode: stringValidation(),

    // manufacturer_name: yup.string().required('Название произв-ля'),

    // manufacturer_logo: fileValidation({
    //   requiredMessage: 'Необходимо фото произв-ля',
    // }),
    photo: fileValidation({
      required: true,
      requiredMessage: 'Необходимо фото товара',
    }),
    price: yup.number().nullable().required('Заполните цену товара'),
  })
  .required();

export type ProductHalalTradingValues = HalalTradingProductMutationRequest;
