import { Field } from '@packages/uiKit/Field';
import { InputDate, InputDateProps } from '@packages/uiKit/InputDate';

export interface InputDateFieldProps extends Omit<InputDateProps, 'error'> {
  label?: string;
  tip?: string;
  error?: string;
}

export const InputDateField: React.FC<InputDateFieldProps> = (props) => {
  const { label, style, className, error, tip, ...restProps } = props;

  return (
    <Field
      style={style}
      className={className}
      label={label}
      error={error}
      tip={tip}>
      <InputDate {...restProps} error={Boolean(error)} />
    </Field>
  );
};

InputDateField.displayName = 'InputDateField';
