import {
  StyledCheckCircle,
  StyledModalCheckBox,
  StyledModalContent,
  StyledModalContentItem,
  StyledModalContentLabel,
} from './OptionsModal.styles';

import { Modal, ModalProps } from '@packages/uiKit/Modal';
import { MultiselectOption } from '@packages/uiKit/Multiselect/types';
import React, { useMemo } from 'react';

export interface OptionsModalProps<TValue extends string | number>
  extends ModalProps {
  className?: string;
  styles?: React.CSSProperties;

  options: MultiselectOption<TValue>[];
  value?: TValue[];

  onChange?: (value: TValue[]) => void;
}

export const OptionsModal = <TValue extends string | number>(
  props: OptionsModalProps<TValue>,
) => {
  const { options, value, onChange, ...restProps } = props;

  const selectedOptions = useMemo(() => {
    return value && options.filter((opt) => value.includes(opt.value));
  }, [options, value]);

  return (
    <Modal {...restProps}>
      <StyledModalContent>
        {options.map((opt) => {
          const isSelected = selectedOptions?.includes(opt) ?? false;

          const onClickHandler =
            onChange &&
            (() => {
              if (!isSelected) {
                onChange([...(value ?? []), opt.value]);
              } else {
                onChange(value?.filter((val) => val !== opt.value) ?? []);
              }
            });
          return (
            <StyledModalContentItem key={opt.value} onClick={onClickHandler}>
              {isSelected ? <StyledCheckCircle /> : <StyledModalCheckBox />}

              <StyledModalContentLabel>{opt.label}</StyledModalContentLabel>
            </StyledModalContentItem>
          );
        })}
      </StyledModalContent>
    </Modal>
  );
};
