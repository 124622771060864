import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledFormSection = styled.div``;

export const StyledTitle = styled(Typography).attrs({ size: 14, weight: 700 })`
  margin-bottom: 21px;
`;

export const StyledContent = styled.div``;
