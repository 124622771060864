import { StyledSearchesCard } from './AddToBagCard.styles';

import { InfoCardProps } from '../common/InfoCard';

import { HandbagIcon } from '@packages/icons';
import React, { FC, memo } from 'react';

export interface AddToBagCardProps
  extends Pick<InfoCardProps, 'value' | 'percent'> {
  className?: string;
  style?: React.CSSProperties;
}

const _AddToBagCard: FC<AddToBagCardProps> = (props) => {
  const { ...restProps } = props;

  return (
    <StyledSearchesCard
      {...restProps}
      icon={<HandbagIcon />}
      description={'Добавлений в корзину'}
      color="primary"
    />
  );
};

export const AddToBagCard = memo(_AddToBagCard);
