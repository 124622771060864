import styled from 'styled-components';

export const StyledMenuSection = styled.div``;

export const StyledMenuPositionListLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  row-gap: 24px;

  > * {
    min-width: 0;
  }
`;
