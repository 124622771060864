import { StyledSwitchWithValueLabel, StyledValueLabel } from './styles';

import { Switch, SwitchProps } from '@packages/uiKit';
import { FC, memo } from 'react';

export interface SwitchWithValueLabelProps extends SwitchProps {
  trueLabel: string;
  falseLabel: string;
}

const _SwitchWithValueLabel: FC<SwitchWithValueLabelProps> = (props) => {
  const { trueLabel, falseLabel, className, styles, value, ...restProps } =
    props;

  return (
    <StyledSwitchWithValueLabel className={className} style={styles}>
      <StyledValueLabel>{value ? trueLabel : falseLabel}</StyledValueLabel>
      <Switch value={value} {...restProps} />
    </StyledSwitchWithValueLabel>
  );
};

export const SwitchWithValueLabel = memo(_SwitchWithValueLabel);
