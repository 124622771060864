import { GetCuisineListResponse } from './types';

import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const CUISINES_TAG = 'CUISINES_TAG' as const;

const LIST_TAG = { type: CUISINES_TAG, id: 'list' };

export const cuisinesApi = createApi({
  reducerPath: 'cuisinesApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'cuisines' }),
  tagTypes: [CUISINES_TAG],
  endpoints: (builder) => {
    return {
      getCuisines: builder.query<GetCuisineListResponse, void>({
        providesTags: [LIST_TAG],
        query: () => ({
          url: '/',
          method: 'GET',
        }),
      }),
    };
  },
});
