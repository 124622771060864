import { Tabs } from '@components';
import styled from 'styled-components';

export const StyledMenuSection = styled.div``;

export const StyledHeader = styled.div`
  display: flex;

  justify-content: space-between;
  gap: 25px;

  margin-bottom: 30px;
  align-items: flex-start;
`;

export const StyledTabs = styled(Tabs)`` as typeof Tabs;

export const StyledMenuPositionListLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 15px;
  row-gap: 24px;

  > * {
    min-width: 0;
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
  min-width: 180px;
  gap: 10px;
`;
