import {
  StyledButtons,
  StyledCropperContainer,
  StyledCropperModalContenet,
} from './styles';

import 'cropperjs/dist/cropper.css';
import { Button } from '../Button';
import { Modal, ModalProps } from '../Modal';

import mime from 'mime';
import { FC, memo, useRef } from 'react';
import { Cropper, ReactCropperElement } from 'react-cropper';

export type CropperModalProps = Omit<ModalProps, 'isOpen'> &
  (
    | {
        isOpen: false;
        imageUrl?: string;
      }
    | { isOpen: true; imageUrl?: string }
  ) & {
    aspectRatio?: number;
    cropType?: string;
    onCrop?: (file: File) => void;
  };

const _CropperModal: FC<CropperModalProps> = (props) => {
  const {
    aspectRatio,
    isOpen,
    onCrop,
    onClose,
    imageUrl,
    cropType,
    ...restProps
  } = props;

  const cropperRef = useRef<ReactCropperElement>(null);

  const cropClickHandler = () => {
    cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
      if (blob && onCrop) {
        const newFile = new File(
          [blob],
          `cropped-${Date.now()}.${mime.getExtension(blob.type)}`,
          {
            type: blob.type,
          },
        );

        onCrop?.(newFile);
      }

      onClose?.();
    }, cropType);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...restProps}>
      {isOpen ? (
        <StyledCropperModalContenet>
          <StyledCropperContainer>
            <Cropper
              ref={cropperRef}
              src={imageUrl}
              aspectRatio={aspectRatio}
              viewMode={3}
              guides={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              checkOrientation={false}
            />
          </StyledCropperContainer>
          <StyledButtons>
            <Button color="primary" size="large" onClick={cropClickHandler}>
              Обрезать
            </Button>
            <Button color="secondary" size="large" onClick={onClose}>
              Отмена
            </Button>
          </StyledButtons>
        </StyledCropperModalContenet>
      ) : null}
    </Modal>
  );
};

export const CropperModal = memo(_CropperModal);
