import { colors, Image, Typography } from '@packages/uiKit';
import { DropdownIconButton } from '@packages/uiKit/DropdownIconButton';
import styled from 'styled-components';

export const StyledHalalShopProductCard = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledImage = styled(Image)`
  height: 105px;
  width: 86px;
  min-width: 86px;
  object-fit: cover;
  border-radius: 10px;
`;

export const StyledRight = styled.div`
  border-top: 1px solid ${colors.gray7};
  padding-top: 10px;
  width: 100%;
`;

export const StyledName = styled(Typography).attrs({
  maxLines: 2,
  ellipsis: true,
})`
  margin-bottom: 5px;
  grid-area: name;
`;

export const StyledCode = styled(Typography).attrs({
  nowrap: true,
  size: 12,
  color: 'gray1',
})`
  grid-area: code;
`;

export const StyledDropdownIconButton = styled(DropdownIconButton)`
  grid-area: actions;
`;

export const StyledTop = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 20px;
  grid-template-areas:
    'name actions'
    'code code';
  margin-bottom: 14px;
`;
export const StyledBottom = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
`;

export const StyledBottomLeft = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const StyledCost = styled(Typography).attrs({
  nowrap: true,
  size: 16,
  weight: 700,
  ellipsis: true,
  lines: 1,
})`
  width: 70px;
`;
