import { Field } from '@packages/uiKit/Field';
import {
  InputPassword,
  InputPasswordProps,
} from '@packages/uiKit/InputPassword';
import { forwardRef } from 'react';

export interface InputPasswordFieldProps
  extends Omit<InputPasswordProps, 'error'> {
  label?: string;
  tip?: string;
  error?: string;
}

export const InputPasswordField = forwardRef<
  HTMLInputElement,
  InputPasswordFieldProps
>((props, ref) => {
  const { label, style, className, error, tip, ...restProps } = props;

  return (
    <Field
      style={style}
      className={className}
      label={label}
      error={error}
      tip={tip}>
      <InputPassword ref={ref} error={Boolean(error)} {...restProps} />
    </Field>
  );
});

InputPasswordField.displayName = 'InputPasswordField';
