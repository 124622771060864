import {
  StyledContent,
  StyledGraphWrapper,
  StyledSummaryWrapper,
  StyledTransactionsDynamicCard,
} from './TransactionsDynamicCard.styles';

import { LegendItem } from '../common/LegendItem';

import { Column, ColumnConfig } from '@ant-design/plots';
import { TransactionsChart } from '@features/halalBonus/transactions';
import { colors } from '@packages/uiKit';
import React, { FC } from 'react';

export interface TransactionsDynamicCardProps {
  className?: string;
  style?: React.CSSProperties;
  chartData: TransactionsChart[];
}

type DataItem = {
  period: string;
  amount: number;
  type: 'accural' | 'withdraw' | 'all';
};

// const dataMock: DataItem[] = [
//   {
//     period: 'Ноя. 2021',
//     amount: 20,
//     type: 'accural',
//   },
//   {
//     period: 'Ноя. 2021',
//     amount: 80,
//     type: 'withdraw',
//   },
//   {
//     period: 'Ноя. 2021',
//     amount: 100,
//     type: 'all',
//   },
// ];

export const TransactionsDynamicCard: FC<TransactionsDynamicCardProps> = (
  props,
) => {
  const { chartData, ...restProps } = props;

  const data = chartData
    .map((item) => {
      return [
        {
          period: item.month === 1 ? 'Все время' : item.month,
          amount: item.poins_take,
          type: 'accural',
        },
        {
          period: item.month === 1 ? 'Все время' : item.month,
          amount: item.points_give,
          type: 'withdraw',
        },
        {
          period: item.month === 1 ? 'Все время' : item.month,
          amount: item.transaction_count,
          type: 'all',
        },
      ];
    })
    .flat();

  const config: ColumnConfig = {
    data,
    isGroup: true,
    height: 250,
    color: [colors.primaryLight, colors.primaryDark3, colors.primary],
    xField: 'period',
    yField: 'amount',
    seriesField: 'type',
    legend: false as const,
    meta: {
      type: {
        formatter: (value: DataItem['type']) =>
          value === 'accural'
            ? 'Бонусов начислено'
            : value === 'withdraw'
            ? 'Бонусов списано'
            : 'Количество транзакций',
      },
    },
  };

  const isEmptyArray = chartData.length === 0;

  return (
    <StyledTransactionsDynamicCard
      title="Динамика по транзакциям"
      {...restProps}>
      {!isEmptyArray && (
        <StyledContent>
          <StyledGraphWrapper>
            <Column {...config} />
          </StyledGraphWrapper>
          <StyledSummaryWrapper>
            <LegendItem
              value={Number(chartData[0].transaction_count).toFixed(0)}
              color={colors.primary}
              description="Всего транзакций"
            />
            <LegendItem
              value={Number(chartData[0].poins_take).toFixed(0)}
              color={colors.primaryLight}
              description="Начислений бонусов"
            />
            <LegendItem
              value={Number(chartData[0].points_give).toFixed(0)}
              color={colors.primaryDark3}
              description="Списаний бонусов"
            />
          </StyledSummaryWrapper>
        </StyledContent>
      )}
    </StyledTransactionsDynamicCard>
  );
};
