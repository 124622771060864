import { ReactComponent as CaretRightSvg } from '@assets/svgs/caretRight.svg';
import { Button } from '@packages/uiKit/Button';
import styled from 'styled-components';

export const StyledToDetailCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const StyledContentContainer = styled.div``;

export const StyledButton = styled(Button).attrs({
  size: 'small',
  color: 'primary',
  variant: 'filled',
  icon: <CaretRightSvg />,
})`
  padding: 6px 2px;
`;
