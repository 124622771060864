import {
  StyledLabel,
  StyledOperationCheckCell,
} from './OperationCheckCell.styles';

import { useModalState } from '@hooks/useModalState';
import { Image, ImageModal } from '@packages/uiKit';
import React, { FC, useCallback } from 'react';

export interface OperationCheckCellProps {
  className?: string;
  style?: React.CSSProperties;
  number: string;
  photo: string;
}

export const OperationCheckCell: FC<OperationCheckCellProps> = (props) => {
  const { number, photo, ...restProps } = props;

  const [modalState, modalActions] = useModalState<{ image: string }>();

  const imageClickHandler = useCallback(() => {
    modalActions.openHandler({ image: photo });
  }, [modalActions, photo]);

  return (
    <StyledOperationCheckCell {...restProps}>
      <StyledLabel title={number}>{number}</StyledLabel>
      <Image size={28} src={photo} onClick={imageClickHandler} />

      <ImageModal {...modalState} onClose={modalActions.closeHandler} />
    </StyledOperationCheckCell>
  );
};
