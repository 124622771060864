import { colors } from '@packages/uiKit/utils/colors';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledNavbarNavs = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const StyledNavLinkIconContainer = styled.div`
  transition: all 0.1s;
  margin-right: 36px;
  font-size: 22px;
`;
export const StyledNavLinkLabel = styled.p`
  transition: all 0.1s;
  font-size: 14px;
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: inherit;

  &:hover {
    color: ${colors.primaryLight};
  }

  &.active {
    color: ${colors.primary};
  }

  &.active {
    color: ${colors.primary};
  }
`;

export const StyledNavButtonIconContainer = styled.div`
  transition: all 0.3s;
  margin-right: 36px;
`;
export const StyledNavButtonLabel = styled.p`
  transition: all 0.3s;
  font-size: 14px;
`;

export const StyledNavButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: ${colors.gray1};

  &:hover,
  &:active {
    color: ${colors.gray0};
  }

  &:hover,
  &:active {
    color: ${colors.gray0};
  }
`;
