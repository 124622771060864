import { EstablishmentWorkTime } from '@features/establishments';
import { SelectOption } from '@packages/uiKit';

export const allDays: Array<keyof EstablishmentWorkTime> = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
];

export const dayDict = {
  mon: 'Понедельник',
  tue: 'Вторник',
  wed: 'Среда',
  thu: 'Четверг',
  fri: 'Пятница',
  sat: 'Суббота',
  sun: 'Воскресенье',
};

export const emptyWorkTime: EstablishmentWorkTime = {
  fri: null,
  mon: null,
  sat: null,
  sun: null,
  thu: null,
  tue: null,
  wed: null,
};

const getLabelTimeOptions = (variant: 'start' | 'end', value: string) =>
  `${variant === 'start' ? 'c' : 'до'} ${value}`;

const timeValues = [
  ...Array.from({ length: 24 })
    .map((_, index) => [`${index}:00`, `${index}:30`])
    .flat(),
  '24:00',
];

const getTimeOptions = (variant: 'start' | 'end'): SelectOption<string>[] =>
  timeValues.map((value) => ({
    value,
    label: getLabelTimeOptions(variant, value),
  }));

export const startTimeOptions = getTimeOptions('start');
export const endTimeOptions = getTimeOptions('end');
