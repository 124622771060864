import { ShopOrdersRecord } from '@features/halalShop/store/types';
import { StyledTable } from './styles';
import { getColumns, GetColumnsParams } from './utils';

import { TableProps } from '@packages/uiKit';
import { HalalShopPageSection } from '@pages/HalalShopPage/types';
import { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

export type OrderTableProps = Omit<
  TableProps<ShopOrdersRecord>,
  'columns' | 'getRowProps'
> &
  GetColumnsParams;

const getRowId: TableProps<ShopOrdersRecord>['getRowId'] = (item) =>
  item.id.toString();

const _OrderTable: FC<OrderTableProps> = (props) => {
  const { onChangeStatus, ...restProps } = props;

  const columns = useMemo(
    () => getColumns({ onChangeStatus }),
    [onChangeStatus],
  );

  const navigate = useNavigate();

  const getRowProps: TableProps<ShopOrdersRecord>['getRowProps'] = useCallback(
    (row: Row<ShopOrdersRecord>) => {
      const { id } = row.original;

      const onClickHandler = () => {
        navigate(`${HalalShopPageSection.Orders}/${id}`);
      };

      return {
        className: 'row',
        onClick: onClickHandler,
      };
    },

    [navigate],
  );

  return (
    <StyledTable
      columns={columns}
      getRowId={getRowId}
      getRowProps={getRowProps}
      {...restProps}
    />
  );
};

export const OrderTable = memo(_OrderTable);
