import {
  StyledRatingText,
  StyledReviewRatingCell,
} from './ReviewRatingCell.styles';

import { RatingLabel } from '@packages/uiKit';
import React, { FC } from 'react';

export interface ReviewRatingCellProps {
  className?: string;
  style?: React.CSSProperties;

  rating: number;
}

const getRatingText = (rating: number) => {
  if (rating <= 3) {
    return 'Негативный';
  }

  if (rating < 4 && rating > 3) {
    return 'Средний';
  }

  return 'Позитивный';
};

export const ReviewRatingCell: FC<ReviewRatingCellProps> = (props) => {
  const { rating, ...restProps } = props;

  const ratingText = getRatingText(rating);

  return (
    <StyledReviewRatingCell {...restProps}>
      <StyledRatingText>{ratingText}</StyledRatingText>

      <RatingLabel rating={rating} />
    </StyledReviewRatingCell>
  );
};
