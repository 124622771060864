import {
  StyledCropButton,
  StyledHorizontalContainer,
  StyledPreview,
  StyledUploadImageButton,
  StyledUploadImageInput,
} from './UploadImageInput.styles';

import { CropperModal } from '../CropperModal';
import { ImageModal } from '../ImageModal';

import { ReactComponent as CropSvg } from '@assets/svgs/crop.svg';
import { useModalState } from '@hooks/useModalState';
import { ImagePreviewCard } from '@packages/uiKit/ImagePreviewCard';
import { UploadImageButtonProps } from '@packages/uiKit/UploadImageButton';
import { FC, useCallback, useMemo, useState } from 'react';

export interface UploadImageInputProps
  extends Omit<UploadImageButtonProps, 'onUpload'> {
  isCropable?: boolean;
  preview?: 'icon' | 'card';

  value?: string | File | null;
  onChange?: (value: File | null) => void;
  aspectRatio?: number;
  cropType?: string;
}

export const UploadImageInput: FC<UploadImageInputProps> = (props) => {
  const {
    isCropable,
    preview,
    styles,
    className,
    value,
    onChange,
    color = 'white',
    aspectRatio,
    cropType,
    ...restProps
  } = props;

  const previewSrc = useMemo(() => {
    if (value instanceof File) {
      return URL.createObjectURL(value);
    }
    return value;
  }, [value]);

  const onUploadHandler: UploadImageButtonProps['onUpload'] = onChange
    ? (file) => {
        onChange(file);
      }
    : undefined;

  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);

  const onOpenPreviewModalHandler = useCallback(() => {
    setIsOpenPreviewModal(true);
  }, []);

  const onClosePreviewModalHandler = useCallback(() => {
    setIsOpenPreviewModal(false);
  }, []);

  const onDeleteHandler = useCallback(() => {
    onChange?.(null);
  }, [onChange]);

  const [cropModalState, cropModalActions] = useModalState();
  const cropHandler = useCallback(
    (file: File) => {
      onChange?.(file);
    },
    [onChange],
  );

  const isValueString = typeof value === 'string';

  return (
    <StyledUploadImageInput
      style={styles}
      className={className}
      preview={preview}>
      {previewSrc && preview === 'card' && (
        <ImagePreviewCard
          src={previewSrc}
          onClick={onOpenPreviewModalHandler}
          onDelete={onDeleteHandler}
        />
      )}

      <StyledHorizontalContainer>
        <StyledUploadImageButton
          color={color}
          onUpload={onUploadHandler}
          {...restProps}
        />
        {isCropable && previewSrc && !isValueString ? (
          <>
            <StyledCropButton
              onClick={cropModalActions.openHandler}
              color={color}>
              <CropSvg />
            </StyledCropButton>
            <CropperModal
              {...cropModalState}
              title="Обрезка"
              imageUrl={previewSrc}
              onCrop={cropHandler}
              aspectRatio={aspectRatio}
              onClose={cropModalActions.closeHandler}
              cropType={cropType}
            />
          </>
        ) : null}

        {previewSrc && preview === 'icon' && (
          <StyledPreview src={previewSrc} onClick={onOpenPreviewModalHandler} />
        )}
      </StyledHorizontalContainer>

      {previewSrc && (
        <ImageModal
          isOpen={isOpenPreviewModal}
          image={previewSrc}
          onClose={onClosePreviewModalHandler}
        />
      )}
    </StyledUploadImageInput>
  );
};
