import { statusTabs } from './constants';

import {
  StyledDiscountsSection,
  StyledHeader,
  StyledTabs,
} from './DiscountsSection.styles';

import { DiscountPositionCardForm } from '@features/halalEda/stocksPositions/components/DiscountPositionCardForm';
import { stockPositionMockData } from '@features/halalEda/stocksPositions/mockData';
import { useSearchParam } from '@hooks/useSearchParam';
import React, { FC } from 'react';

export interface DiscountsSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

export const DiscountsSection: FC<DiscountsSectionProps> = (props) => {
  const { ...restProps } = props;

  const [orderStatus, setOrderStatus] = useSearchParam<string>({
    paramName: 'status',
    defaultValue: 'active',
  });

  return (
    <StyledDiscountsSection {...restProps}>
      <StyledHeader>
        <StyledTabs
          tabs={statusTabs}
          value={orderStatus}
          onChange={setOrderStatus}
          variant="round"
        />
        {/* <Button onClick={createModalActions.openHandler}>Добавить акцию</Button> */}
      </StyledHeader>
      {stockPositionMockData.map((position) => {
        return <DiscountPositionCardForm key={position.id} {...position} />;
      })}
    </StyledDiscountsSection>
  );
};
