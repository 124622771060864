import { sectionToComponentDict } from './constants';
import { StyledSectionContainer } from './styles';
import { HalalShopPageSection } from './types';

import { Tabs, TabsTabItem } from '@components';
import { useAuthProtection } from '@hooks/useAuthProtection';
import { useSearchParam } from '@hooks/useSearchParam';
import { MainLayout } from '@layouts/MainLayout';
import { FC, useMemo } from 'react';

export const HalalShopPage: FC = () => {
  useAuthProtection();

  const tabs: TabsTabItem<HalalShopPageSection>[] = useMemo(() => {
    return [
      {
        value: HalalShopPageSection.Orders,
        label: `Заказы`,
      },
      {
        value: HalalShopPageSection.Catalog,
        label: `Каталог`,
      },
      {
        value: HalalShopPageSection.Establishment,
        label: `Мой магазин`,
      },
      {
        value: HalalShopPageSection.Returns,
        label: `Возвраты`,
      },
      {
        value: HalalShopPageSection.Finances,
        label: `Финансы`,
      },
      // {
      //   value: HalalShopPageSection.Statistic,
      //   label: `Статистика`,
      // },
    ];
  }, []);

  const [section, setSection] = useSearchParam<HalalShopPageSection>({
    paramName: 'section',
    defaultValue: HalalShopPageSection.Orders,
  });

  return (
    <MainLayout.Content>
      <MainLayout.Content.Header>
        Мой магазин HalalShop
      </MainLayout.Content.Header>
      <Tabs tabs={tabs} value={section} onChange={setSection} />
      <StyledSectionContainer>
        {sectionToComponentDict[section]}
      </StyledSectionContainer>
    </MainLayout.Content>
  );
};
