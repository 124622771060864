export const colors = {
  primaryLight: '#7ece8a',
  primary: '#4DBC5E',
  primaryDark1: '#46A155',
  primaryDark2: '#40894C',
  primaryDark3: '#345B3C',
  danger: '#F02C2C',
  warning: '#FFEB3C',

  red1: '#ED3535',

  dark1: '#17171A',
  dark2: '#46454E',

  gray0: '#8c8c8c',
  gray1: '#BBBBBB',
  gray2: '#E8E8E8',
  gray3: '#efefef',
  gray4: '#f7f7f7',
  gray5: '#f9f9f9',
  gray6: '#CBCBCB',
  gray7: '#D7D7D7',

  blueGray: '#E3E3EF',

  rootBackground: '#F4F4F4',

  white: '#fff',
};

export type AppColor = keyof typeof colors;
