import {
  StyledContentHeader,
  StyledContentTitle,
  StyledRightContainer,
} from './ContentHeader.styles';

import { FC, PropsWithChildren } from 'react';

export interface ContentHeaderProps {
  right?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const ContentHeader: FC<PropsWithChildren<ContentHeaderProps>> = (
  props,
) => {
  const { children, right, ...restProps } = props;

  return (
    <StyledContentHeader {...restProps}>
      <StyledContentTitle>{children}</StyledContentTitle>
      {right ? <StyledRightContainer>{right}</StyledRightContainer> : null}
    </StyledContentHeader>
  );
};
