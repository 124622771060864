import {
  StyledLegendLayout,
  StyledOrdersStructure,
} from './HalalShopStructureCard.styles';
import { LegendItem } from './LegendItem';

import { Pie, PieConfig } from '@ant-design/plots';
import { colors } from '@packages/uiKit';
import React, { FC, memo } from 'react';

export interface HalalShopStructureCardProps {
  className?: string;
  style?: React.CSSProperties;
}

type DataType = {
  type: 'delivery' | 'pickup' | 'canceled' | 'undelivered';
  value: number;
};

const typeToColorDict: Record<DataType['type'], string> = {
  delivery: colors.primary,
  pickup: colors.primaryDark1,
  canceled: colors.primaryDark2,
  undelivered: colors.primaryDark3,
};

const data: DataType[] = [
  {
    type: 'delivery',
    value: 33,
  },
  {
    type: 'pickup',
    value: 28,
  },
  {
    type: 'canceled',
    value: 33,
  },
  {
    type: 'undelivered',
    value: 6,
  },
];
const config: PieConfig = {
  // appendPadding: 10,
  data,
  angleField: 'value',
  // autoFit: true,
  innerRadius: 0.4,
  radius: 1,
  height: 250,
  width: 250,
  legend: false as const,
  colorField: 'type',
  color: ({ type }) =>
    typeToColorDict[type as 'delivery' | 'pickup' | 'canceled' | 'undelivered'],
  label: {
    type: 'inner',
    offset: '-30%',
    content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    style: {
      fontSize: 14,
      textAlign: 'center',
    },
  },
  interactions: [
    {
      type: 'element-active',
    },
  ],
  statistic: {
    title: false as const,
    content: false as const,
  },
};

const _HalalShopStructureCard: FC<HalalShopStructureCardProps> = (props) => {
  const { ...restProps } = props;

  return (
    <StyledOrdersStructure
      title="Структура"
      {...restProps}
      right={<Pie {...config} />}>
      <StyledLegendLayout>
        <LegendItem
          color={colors.primary}
          percent={33}
          amount={305_050}
          orderCount={234}
          description="Доставка"
        />
        <LegendItem
          color={colors.primaryDark1}
          percent={28}
          amount={284_950}
          orderCount={210}
          description="Самовывоз"
        />
        <LegendItem
          color={colors.primaryDark2}
          percent={33}
          amount={304_200}
          orderCount={233}
          description="Отмененные"
        />
        <LegendItem
          color={colors.primaryDark3}
          percent={6}
          amount={10_580}
          orderCount={13}
          description="Недоставленные"
        />
      </StyledLegendLayout>
    </StyledOrdersStructure>
  );
};

export const HalalShopStructureCard = memo(_HalalShopStructureCard);
