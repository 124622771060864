import { GridBorder } from '../types';

import { colors } from '@packages/uiKit';
import styled, { css } from 'styled-components';

export const StyledItem = styled.div<{
  $isFirstRow: boolean;
  $isLastRow: boolean;
  $border: GridBorder;
}>`
  min-width: 0;
  ${({ $border, $isFirstRow, $isLastRow }) =>
    $border === 'both'
      ? css`
          border-bottom: 1px solid ${colors.gray2};

          ${$isFirstRow
            ? css`
                border-top: 1px solid ${colors.gray2};
              `
            : ''}
        `
      : $border === 'bottom'
      ? css`
          border-bottom: 1px solid ${colors.gray2};
        `
      : $border === 'between'
      ? css`
          ${$isLastRow
            ? ''
            : css`
                border-bottom: 1px solid ${colors.gray2};
              `};
        `
      : ''}
`;
