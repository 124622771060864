import { StyledSiteRequestionsCard } from './SiteRequestionsCard.styles';

import { InfoCardProps } from '../common/InfoCard';

import { PaperPlaneTiltIcon } from '@packages/icons';
import React, { FC, memo } from 'react';

export interface SiteRequestionsCardProps
  extends Pick<InfoCardProps, 'value' | 'percent'> {
  className?: string;
  style?: React.CSSProperties;
}

const _SiteRequestionsCard: FC<SiteRequestionsCardProps> = (props) => {
  const { ...restProps } = props;

  return (
    <StyledSiteRequestionsCard
      {...restProps}
      icon={<PaperPlaneTiltIcon />}
      description={'Заявок на сайте'}
      color="primary"
    />
  );
};

export const SiteRequestionsCard = memo(_SiteRequestionsCard);
