import {
  StyledSection,
  StyledSectionContent,
  StyledSectionTitle,
} from './Section.styles';

import React, { FC, PropsWithChildren } from 'react';

export interface SectionProps extends PropsWithChildren {
  className?: string;
  styles?: React.CSSProperties;

  title: string;
  level?: 1 | 2;
}

const getTitleProps = (level: 1 | 2) => {
  switch (level) {
    case 1: {
      return {
        size: 22 as const,
        forwardedAs: 'h4' as const,
        weight: 700 as const,
      };
    }

    case 2: {
      return {
        size: 14 as const,
        forwardedAs: 'h5' as const,
        weight: 700 as const,
      };
    }
  }
};

export const Section: FC<SectionProps> = (props) => {
  const { title, children, level = 1, ...restProps } = props;

  const titleProps = getTitleProps(level);

  return (
    <StyledSection {...restProps}>
      <StyledSectionTitle {...titleProps} level={level}>
        {title}
      </StyledSectionTitle>
      <StyledSectionContent>{children}</StyledSectionContent>
    </StyledSection>
  );
};
