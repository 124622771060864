import {
  GetReviewPageRequest,
  GetReviewPageResponse,
  PutReviewPageRequest,
} from './types';

import { ApiRecordId } from '@features/api';
import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const REVIEWS_TAG = 'HALAL_EDA_ESTABLISHMENT_TAG' as const;
const getReviewItemTag = (id: ApiRecordId) => ({
  type: REVIEWS_TAG,
  id,
});

export const reviewsApi = createApi({
  reducerPath: 'reviewsApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'reviews' }),
  tagTypes: [REVIEWS_TAG],
  endpoints: (builder) => {
    return {
      getReviewPage: builder.query<GetReviewPageResponse, GetReviewPageRequest>(
        {
          providesTags: (response) => [
            REVIEWS_TAG,
            ...(response?.result.map((item) => getReviewItemTag(item.id)) ??
              []),
          ],
          query: (params) => ({
            url: '/',
            method: 'GET',
            params,
          }),
        },
      ),
      putReview: builder.mutation<void, PutReviewPageRequest>({
        invalidatesTags: (_, _2, request) => [getReviewItemTag(request.id)],
        query: (request) => ({
          url: `/${request.id}/`,
          method: 'PUT',
          body: request.put,
        }),
      }),
    };
  },
});
