import styled from 'styled-components';

export const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  column-gap: 60px;

  > * {
    min-width: 0;
  }
`;
