import {
  StyledCenterContainter,
  StyledContentContainter,
  StyledIdentityLayout,
  StyledMainContactInfo,
} from './IdentityLayout.styles';

import { MainLogo } from '@layouts/components/MainLogo';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

export const IdentityLayout: FC = () => {
  return (
    <StyledIdentityLayout>
      <StyledCenterContainter>
        <StyledContentContainter>
          <MainLogo />
          <Outlet />
          <StyledMainContactInfo />
        </StyledContentContainter>
      </StyledCenterContainter>
    </StyledIdentityLayout>
  );
};
