import { currencyOptions } from '../constants';

export const getCurrencySymbol = (currencyId: string) => {
  switch (currencyId) {
    case currencyOptions[0].value:
      return '₽';

    case currencyOptions[1].value:
      return '₸';

    case currencyOptions[2].value:
      return '$';

    default:
      return '-';
  }
};
