import { HalalShopReturnMockRecord } from '@features/halalShop/returns/types';
import { StyledTable } from './styles';
import { getColumns, GetColumnsParams, getRowId } from './utils';

import { TableProps } from '@packages/uiKit';
import { HalalShopPageSection } from '@pages/HalalShopPage/types';
import { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

export type ReturnsTableProps = Omit<
  TableProps<HalalShopReturnMockRecord>,
  'columns' | 'getRowId' | 'getRowProps'
> &
  GetColumnsParams;

const _ReturnsTable: FC<ReturnsTableProps> = (props) => {
  const { onChangeStatus, onClickToDetail, ...restProps } = props;

  const navigate = useNavigate();

  const columns = useMemo(
    () => getColumns({ onChangeStatus, onClickToDetail }),
    [onChangeStatus, onClickToDetail],
  );

  const getRowProps: TableProps<HalalShopReturnMockRecord>['getRowProps'] =
    useCallback(
      (row: Row<HalalShopReturnMockRecord>) => {
        const { id } = row;

        const onClickHandler = () => {
          navigate(`${HalalShopPageSection.Returns}/${id}`);
        };

        return {
          className: 'row',
          onClick: onClickHandler,
        };
      },
      [navigate],
    );

  return (
    <StyledTable
      columns={columns}
      getRowId={getRowId}
      getRowProps={getRowProps}
      {...restProps}
    />
  );
};

export const RetrunsTable = memo(_ReturnsTable);
