import { colors, Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 18px;
  border-bottom: 1px solid ${colors.gray7};
  margin-bottom: 15px;
`;

export const StyledTitle = styled(Typography).attrs({
  size: 22,
  weight: 700,
})``;
