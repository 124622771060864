import { useGetActionButtons } from './hooks';
import {
  StyledHalalShopCartItemRow,
  StyledHeader,
  StyledInfoSummaryItemLabel,
  StyledInfoSummaryItemValue,
  StyledInfoTextItem,
  StyledInfoTextItemLabel,
  StyledInfoTextItemValue,
  StyledTitle,
} from './styles';

import { HalalShopOrderFormValues } from '../types';

import { Grid } from '@components/Grid';
import { HalalShopOrderStatus } from '@features/halalShop/orders/mock/types';
import { MainLayout } from '@layouts/MainLayout';
import React, { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface SidebarProps {
  onConfirm: () => void;
  onCancel: () => void;
  onShippingConfirm: () => void;
  onSave: () => void;

  orderStatus: HalalShopOrderStatus;
  className?: string;
  style?: React.CSSProperties;
}

const _Sidebar: FC<SidebarProps> = (props) => {
  const {
    onConfirm,
    onCancel,
    onShippingConfirm,
    orderStatus,
    onSave,
    ...restProps
  } = props;

  const { control, watch } = useFormContext<HalalShopOrderFormValues>();

  const cartWatch = watch('items');

  const totalCartCount = cartWatch.reduce(
    (acc, cartItem) => acc + cartItem.cost * cartItem.count,
    0,
  );

  const actionsButtons = useGetActionButtons({
    status: orderStatus,
    onConfirm,
    onCancel,
    onShippingConfirm,
    onSave,
  });

  const temp_delleveryCost = 405;

  const summaryCount = totalCartCount - -temp_delleveryCost;

  return (
    <MainLayout.Sidebar {...restProps} width={500}>
      <StyledHeader>
        <StyledTitle>Позиции заказа</StyledTitle>
      </StyledHeader>

      <Grid cols={1} border="between">
        <Controller
          control={control}
          name="items"
          render={({ field: { value, onChange } }) => {
            return (
              <>
                {value.map((orderItem) => {
                  const deleteHandler = () => {
                    const newValue = value.filter((x) => x.id === orderItem.id);
                    onChange(newValue);
                  };
                  return (
                    <StyledHalalShopCartItemRow
                      key={orderItem.id}
                      id={orderItem.id}
                      name={orderItem.name}
                      cost={orderItem.cost}
                      image={orderItem.photo}
                      count={orderItem.count}
                      onDelete={deleteHandler}
                    />
                  );
                })}
              </>
            );
          }}
        />

        <StyledInfoTextItem>
          <StyledInfoTextItemLabel>Всего товаров</StyledInfoTextItemLabel>
          <StyledInfoTextItemValue>
            {totalCartCount.toLocaleString()} ₸
          </StyledInfoTextItemValue>
        </StyledInfoTextItem>

        <StyledInfoTextItem>
          <StyledInfoTextItemLabel>Доставка</StyledInfoTextItemLabel>
          <StyledInfoTextItemValue>
            {temp_delleveryCost.toLocaleString()} ₸
          </StyledInfoTextItemValue>
        </StyledInfoTextItem>

        <StyledInfoTextItem>
          <StyledInfoSummaryItemLabel>Итого</StyledInfoSummaryItemLabel>
          <StyledInfoSummaryItemValue>
            {summaryCount.toLocaleString()} ₸
          </StyledInfoSummaryItemValue>
        </StyledInfoTextItem>
      </Grid>

      {actionsButtons}
    </MainLayout.Sidebar>
  );
};

export const Sidebar = memo(_Sidebar);
