import { ReactComponent as AirplaneTiltSvg } from './assets/svgs/airplaneTilt.svg';
import { ReactComponent as AlarmSvg } from './assets/svgs/alarm.svg';
import { ReactComponent as AlignBottomSvg } from './assets/svgs/alignBottom.svg';
import { ReactComponent as ArrowUUpLeftSvg } from './assets/svgs/arrowUUpLeft.svg';
import { ReactComponent as CaretDownSvg } from './assets/svgs/caretDown.svg';
import { ReactComponent as CaretLeftSvg } from './assets/svgs/caretLeft.svg';
import { ReactComponent as CaretRightSvg } from './assets/svgs/caretRight.svg';
import { ReactComponent as ChartBarSvg } from './assets/svgs/chartBar.svg';
import { ReactComponent as ChartPieSliceSvg } from './assets/svgs/chartPieSlice.svg';
import { ReactComponent as ChatSvg } from './assets/svgs/chat.svg';
import { ReactComponent as ChatTeardropTextSvg } from './assets/svgs/chatTeardropText.svg';
import { ReactComponent as CheckSvg } from './assets/svgs/check.svg';
import { ReactComponent as CheckCircleSvg } from './assets/svgs/checkCircle.svg';
import { ReactComponent as CheckCircleOutlinedSvg } from './assets/svgs/checkCircleOutlined.svg';
import { ReactComponent as CircleSvg } from './assets/svgs/circle.svg';
import { ReactComponent as ClockSvg } from './assets/svgs/clock.svg';
import { ReactComponent as CloseSvg } from './assets/svgs/close.svg';
import { ReactComponent as CoinSvg } from './assets/svgs/coin.svg';
import { ReactComponent as DiamondSvg } from './assets/svgs/diamond.svg';
import { ReactComponent as DotsThreeCircleNoPadding } from './assets/svgs/dotsThreeCircleNoPadding.svg';
import { ReactComponent as EnvelopeSimpleSvg } from './assets/svgs/envelopeSimple.svg';
import { ReactComponent as EyeSvg } from './assets/svgs/eye.svg';
import { ReactComponent as EyeSlashSvg } from './assets/svgs/eyeSlash.svg';
import { ReactComponent as FaceBookSvg } from './assets/svgs/Facebook.svg';
import { ReactComponent as FireSvg } from './assets/svgs/fireSign.svg';
import { ReactComponent as ForkKnifeSvg } from './assets/svgs/forkKnife.svg';
import { ReactComponent as GearSixSvg } from './assets/svgs/gearSix.svg';
import { ReactComponent as GridMenuSvg } from './assets/svgs/gridMenu.svg';
import { ReactComponent as HandbagSvg } from './assets/svgs/handbag.svg';
import { ReactComponent as HeartStraightSvg } from './assets/svgs/heartStraight.svg';
import { ReactComponent as InstagramSvg } from './assets/svgs/Instagram.svg';
import { ReactComponent as ListSvg } from './assets/svgs/list.svg';
import { ReactComponent as LockOpenSvg } from './assets/svgs/lockOpen.svg';
import { ReactComponent as MagnifyingGlassSvg } from './assets/svgs/magnifyingGlass.svg';
import { ReactComponent as MapPinSvg } from './assets/svgs/mapPin.svg';
import { ReactComponent as MinusSvg } from './assets/svgs/minus.svg';
import { ReactComponent as MinusCircleSvg } from './assets/svgs/minusCircle.svg';
import { ReactComponent as NavigationArrowSvg } from './assets/svgs/navigationArrow.svg';
import { ReactComponent as NotePencilSvg } from './assets/svgs/notePencil.svg';
import { ReactComponent as PackageSvg } from './assets/svgs/package.svg';
import { ReactComponent as PaperPlaneTiltSvg } from './assets/svgs/paperPlaneTilt.svg';
import { ReactComponent as PathSvg } from './assets/svgs/path.svg';
import { ReactComponent as PercentCircleSvg } from './assets/svgs/percentCircle.svg';
import { ReactComponent as PhoneSvg } from './assets/svgs/Phone.svg';
import { ReactComponent as PhoneCallSvg } from './assets/svgs/phoneCall.svg';
import { ReactComponent as PlaneSvg } from './assets/svgs/plane.svg';
import { ReactComponent as PlusSvg } from './assets/svgs/plus.svg';
import { ReactComponent as PlusCircleSvg } from './assets/svgs/plusCircle.svg';
import { ReactComponent as ProhibitSvg } from './assets/svgs/prohibit.svg';
import { ReactComponent as QrCodeSvg } from './assets/svgs/qrCode.svg';
import { ReactComponent as ReceiptSvg } from './assets/svgs/receipt.svg';
import { ReactComponent as ShareSvg } from './assets/svgs/share.svg';
import { ReactComponent as ShieldCheckSvg } from './assets/svgs/ShieldCheck.svg';
import { ReactComponent as ShieldSlashSvg } from './assets/svgs/ShieldSlash.svg';
import { ReactComponent as ShoppingCartSvg } from './assets/svgs/shoppingCart.svg';
import { ReactComponent as SignOutSvg } from './assets/svgs/signOut.svg';
import { ReactComponent as SmileySvg } from './assets/svgs/smiley.svg';
import { ReactComponent as SmileyNervousSvg } from './assets/svgs/smileyNervous.svg';
import { ReactComponent as SmileySadSvg } from './assets/svgs/smileySad.svg';
import { ReactComponent as StackSimpleSvg } from './assets/svgs/stackSimple.svg';
import { ReactComponent as StarSvg } from './assets/svgs/star.svg';
import { ReactComponent as StarFilledSvg } from './assets/svgs/starFilled.svg';
import { ReactComponent as StarOutlinedSvg } from './assets/svgs/starOutlined.svg';
import { ReactComponent as StorefrontSvg } from './assets/svgs/storefront.svg';
import { ReactComponent as TagSvg } from './assets/svgs/tag.svg';
import { ReactComponent as TargetSvg } from './assets/svgs/target.svg';
import { ReactComponent as TelegramSvg } from './assets/svgs/Telegram.svg';
import { ReactComponent as ThumbsUpSvg } from './assets/svgs/thumbsUp.svg';
import { ReactComponent as TrashSvg } from './assets/svgs/trash.svg';
import { ReactComponent as TruckSvg } from './assets/svgs/truck.svg';
import { ReactComponent as UserSvg } from './assets/svgs/user.svg';
import { ReactComponent as UserCirclePlusSvg } from './assets/svgs/userCirclePlus.svg';
import { ReactComponent as VideoPlaySvg } from './assets/svgs/videoPlay.svg';
import { ReactComponent as VkSvg } from './assets/svgs/VK.svg';
import { ReactComponent as WalletSvg } from './assets/svgs/wallet.svg';
import { ReactComponent as WarningOctagonSvg } from './assets/svgs/warningOctagon.svg';
import { ReactComponent as WhatsAppSvg } from './assets/svgs/whatsApp.svg';

import { createIconFromSvg } from '@packages/uiKit/utils/createIconFromSvg';

export const TargetIcon = createIconFromSvg(TargetSvg);
export const FireIcon = createIconFromSvg(FireSvg);
export const CloseIcon = createIconFromSvg(CloseSvg);
export const PlusIcon = createIconFromSvg(PlusSvg);
export const MinusIcon = createIconFromSvg(MinusSvg);
export const UserIcon = createIconFromSvg(UserSvg);
export const LockOpenIcon = createIconFromSvg(LockOpenSvg);
export const DotsThreeCirclesNoPadding = createIconFromSvg(
  DotsThreeCircleNoPadding,
);
export const ChatTeardropTextIcon = createIconFromSvg(ChatTeardropTextSvg);
export const StarFilledIcon = createIconFromSvg(StarFilledSvg);
export const StarOutlinedIcon = createIconFromSvg(StarOutlinedSvg);
export const DiamondIcon = createIconFromSvg(DiamondSvg);
export const PlusCircleIcon = createIconFromSvg(PlusCircleSvg);
export const MinusCircleIcon = createIconFromSvg(MinusCircleSvg);
export const QrCodeIcon = createIconFromSvg(QrCodeSvg);
export const AlarmIcon = createIconFromSvg(AlarmSvg);
export const ProhibitIcon = createIconFromSvg(ProhibitSvg);
export const CheckCircleIcon = createIconFromSvg(CheckCircleSvg);
export const CheckCircleOutlinedIcon = createIconFromSvg(
  CheckCircleOutlinedSvg,
);
export const NotePencilIcon = createIconFromSvg(NotePencilSvg);
export const EyeIcon = createIconFromSvg(EyeSvg);
export const UserCirclePlusIcon = createIconFromSvg(UserCirclePlusSvg);
export const PhoneCallIcon = createIconFromSvg(PhoneCallSvg);
export const ShareIcon = createIconFromSvg(ShareSvg);
export const SmileyNervousIcon = createIconFromSvg(SmileyNervousSvg);
export const ChartPieSliceIcon = createIconFromSvg(ChartPieSliceSvg);
export const HandbagIcon = createIconFromSvg(HandbagSvg);
export const CoinIcon = createIconFromSvg(CoinSvg);
export const SmileyIcon = createIconFromSvg(SmileySvg);
export const WalletIcon = createIconFromSvg(WalletSvg);
export const ReceiptIcon = createIconFromSvg(ReceiptSvg);
export const PaperPlaneTiltIcon = createIconFromSvg(PaperPlaneTiltSvg);
export const EyeSlashIcon = createIconFromSvg(EyeSlashSvg);
export const TrashIcon = createIconFromSvg(TrashSvg);
export const ForkKnifeIcon = createIconFromSvg(ForkKnifeSvg);
export const NavigationArrowIcon = createIconFromSvg(NavigationArrowSvg);
export const GridMenuIcon = createIconFromSvg(GridMenuSvg);
export const ListIcon = createIconFromSvg(ListSvg);
export const CaretDownIcon = createIconFromSvg(CaretDownSvg);
export const MagnifyingGlassIcon = createIconFromSvg(MagnifyingGlassSvg);
export const HeartStraightIcon = createIconFromSvg(HeartStraightSvg);
export const AlignBottomIcon = createIconFromSvg(AlignBottomSvg);
export const ShoppingCartIcon = createIconFromSvg(ShoppingCartSvg);
export const PercentCircleIcon = createIconFromSvg(PercentCircleSvg);
export const CaretRightIcon = createIconFromSvg(CaretRightSvg);
export const CaretLeftIcon = createIconFromSvg(CaretLeftSvg);
export const ThumbsUpIcon = createIconFromSvg(ThumbsUpSvg);
export const MapPinIcon = createIconFromSvg(MapPinSvg);
export const TruckIcon = createIconFromSvg(TruckSvg);
export const TagIcon = createIconFromSvg(TagSvg);
export const ArrowUUpLeftIcon = createIconFromSvg(ArrowUUpLeftSvg);
export const CheckIcon = createIconFromSvg(CheckSvg);
export const PlaneIcon = createIconFromSvg(PlaneSvg);
export const StarIcon = createIconFromSvg(StarSvg);
export const ChatIcon = createIconFromSvg(ChatSvg);
export const GearSixIcon = createIconFromSvg(GearSixSvg);
export const SignOutIcon = createIconFromSvg(SignOutSvg);
export const PlayIcon = createIconFromSvg(VideoPlaySvg);
export const CircleIcon = createIconFromSvg(CircleSvg);
export const StackSimpleIcon = createIconFromSvg(StackSimpleSvg);
export const StorefrontIcon = createIconFromSvg(StorefrontSvg);
export const ChartBarIcon = createIconFromSvg(ChartBarSvg);
export const PackageIcon = createIconFromSvg(PackageSvg);
export const EnvelopeSimpleIcon = createIconFromSvg(EnvelopeSimpleSvg);
export const ClockIcon = createIconFromSvg(ClockSvg);
export const PathIcon = createIconFromSvg(PathSvg);
export const WarningOctagonIcon = createIconFromSvg(WarningOctagonSvg);
export const FaceBookIcon = createIconFromSvg(FaceBookSvg);
export const InstagramIcon = createIconFromSvg(InstagramSvg);
export const TelegramIcon = createIconFromSvg(TelegramSvg);
export const VkIcon = createIconFromSvg(VkSvg);
export const WhatsAppIcon = createIconFromSvg(WhatsAppSvg);
export const ShieldCheckIcon = createIconFromSvg(ShieldCheckSvg);
export const ShieldSlashIcon = createIconFromSvg(ShieldSlashSvg);
export const PhoneIcon = createIconFromSvg(PhoneSvg);
export const AirplaneTiltIcon = createIconFromSvg(AirplaneTiltSvg);
export const SmileySadIcon = createIconFromSvg(SmileySadSvg);
