import {
  ApiDateString,
  ApiPaginationMainResponse,
  ApiPaginationParams,
  ApiRecordId,
} from '@features/api';

export enum BookingStatus {
  New = 1, //Новый
  Completed = 2, //Обработано
  Canceled = 3, //Закрыто
}

export type BookingRecord = {
  id: ApiRecordId;
  status: BookingStatus;
  name: string;
  phone?: string;
  seats: number;
  created: ApiDateString;
  note?: string;
  time: ApiDateString;
};

export type CreateBookingRecord = Omit<
  BookingRecord,
  'id' | 'created' | 'status'
>;
export type PutBookingRecord = Omit<BookingRecord, 'id' | 'created'>;
export type PatchBookingRecord = Partial<PutBookingRecord>;

export type GetBookingPageRequest = ApiPaginationParams & {
  time?: 'today' | 'tomorrow';
  status?: BookingStatus;
};
export type GetBookingPageResponse = ApiPaginationMainResponse<BookingRecord>;

export type PostBookingRequest = CreateBookingRecord;

export type PatchBookingPageRequest = {
  id: ApiRecordId;
  patch: PatchBookingRecord;
};

export type DeleteBookingRequest = { id: ApiRecordId };
