import {
  useHalalEdaEstablishmentForm,
  UseHalalEdaEstablishmentFormParams,
} from './hooks';

import { StyledEstablishmentForm } from './styles';

import { HalalEdaEstablishmentRecord } from '@features/halalEda';
import { MenuCategoryMultiselectFieldAdapter } from '@features/menuCategories';
import { Button } from '@packages/uiKit';
import { InputFieldAdapter } from '@packages/uiKit/fieldAdapters/InputFieldAdapter';
import { InputNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputNumberFieldAdapter';
import { InputPhoneNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputPhoneNumberFieldAdapter';
import { SwitchFieldAdapter } from '@packages/uiKit/fieldAdapters/SwitchFieldAdapter';
import { TextAreaFieldAdapter } from '@packages/uiKit/fieldAdapters/TextAreaFieldAdapter';
import { Col, Row } from 'antd';
import { FC, memo } from 'react';

export interface EstablishmentFormProps {
  initValues: HalalEdaEstablishmentRecord;
  onSubmit: UseHalalEdaEstablishmentFormParams['onSubmit'];
}

const _EstablishmentForm: FC<EstablishmentFormProps> = (props) => {
  const { initValues, onSubmit } = props;

  const { control, handleSubmit, handleReset } = useHalalEdaEstablishmentForm({
    initValues,
    onSubmit,
  });

  return (
    <StyledEstablishmentForm>
      <Row gutter={60}>
        <Col span={8}>
          <InputFieldAdapter
            control={control}
            name="contract"
            label="Номер договора"
          />
          <MenuCategoryMultiselectFieldAdapter
            control={control}
            name="categories"
            label="Категории меню заведения"
            modalTitle={'Категории меню заведения'}
          />
          <TextAreaFieldAdapter
            control={control}
            name="zone_delivery"
            label="Зона и стоимость доставки"
            isAutosize
          />
        </Col>
        <Col span={8}>
          <SwitchFieldAdapter
            control={control}
            name="payment_card"
            label="Оплата заказа"
            valueLabel="Онлайн оплата заказа"
            withTips
          />
          <InputNumberFieldAdapter
            control={control}
            name="min_cost"
            label="Минимальная сумма заказа"
            right={'₸'}
          />
          <InputNumberFieldAdapter
            control={control}
            name="cost_delivery"
            label="Стоимость доставки"
            right={'₸'}
          />
          <InputNumberFieldAdapter
            control={control}
            name="free_delivery_from"
            label="Бесплатная доставка от"
            right={'₸'}
          />
        </Col>
        <Col span={8}>
          <InputFieldAdapter
            control={control}
            name="time_delivery"
            label="Среднее время доставки заказа"
          />
          <InputFieldAdapter
            control={control}
            name="contact_name"
            label="Менеджер по доставке · Имя и фамилия"
          />
          <InputPhoneNumberFieldAdapter
            control={control}
            name="contact_phone"
            label="Номер телефона менеджера"
          />
          <Row gutter={15}>
            <Col span={12}>
              <InputFieldAdapter
                control={control}
                name="contact_whatsApp"
                label="WhatsApp менеджера"
              />
            </Col>
            <Col span={12}>
              <InputFieldAdapter
                control={control}
                name="contact_telegram"
                label="Telegram менеджера"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={15}>
        <Col>
          <Button size="large" variant="filled" onClick={handleSubmit}>
            Сохранить изменения
          </Button>
        </Col>
        <Col>
          <Button size="large" variant="outlined" onClick={handleReset}>
            Отменить изменения
          </Button>
        </Col>
      </Row>
    </StyledEstablishmentForm>
  );
};

export const EstablishmentForm = memo(_EstablishmentForm);
