import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledQrCodeCell = styled.div`
  display: flex;
  align-item: center;
  gap: 8px;
  justify-content: space-between;
`;

export const StyledCodeNumber = styled(Typography).attrs({
  nowrap: true,
  ellipsis: true,
})`
  flex: 1;
  min-width: 0;
`;
