import { ReturnFormClientValues } from './type';

export const returnFormClientMocks: ReturnFormClientValues = {
  firstName: 'Камилла',
  lastName: 'Меджидова',
  middleName: 'Фатыховна',

  street: 'Казань, ул. Меликова 16',
  house: '652',
  apt: '9',

  phone: 78695959595,
  postCode: '420014',
  email: 'alexgd@mail.com',

  deliveryData: {
    company: 'СДЕК',
    price: '1500',
    postId: '15115',
  },

  product: {
    name: 'Японский зеленый порошковый чай матча Matsu, 20 г',
    code: '590910979',
    price: 3585,
  },

  returnInfo: {
    description:
      'При попытке запуска Jest через PowerShell возникает ошибка, связанная с политикой выполнения сценариев. Ошибка выглядит следующим образом. При попытке запуска Jest через PowerShell возникает ошибка, связанная с политикой выполнения сценариев. Ошибка выглядит следующим образом',
    reason: 'Не подошел размер',
  },
};
