import { ApiDateString, ApiRecordId } from '@features/api';

export enum HalalShopFinanceStatus {
  Replenishment = 'replenishment',
  Payment = 'payment',
}
export type HalalShopFinanceRecord = {
  id: ApiRecordId;
  status: HalalShopFinanceStatus;
  created: ApiDateString;
  amount: number;
  orderIds: number[];
  document: string;
};
