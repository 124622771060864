import { FinancesTable } from './FinancesTable';
import { StyledFinancesSection } from './styles';

import { halalShopFinancesMockDataList } from '@features/halalShop/finances/data';
import { FC, memo } from 'react';

const _FinancesSection: FC = () => {
  return (
    <StyledFinancesSection>
      <FinancesTable data={halalShopFinancesMockDataList} />
    </StyledFinancesSection>
  );
};

export const FinancesSection = memo(_FinancesSection);
