import { RetrunsTable } from './RetrunsTable';
import { StyledReturnsSection } from './styles';

import { Tabs, TabsTabItem } from '@components';
import { halalShopReturnMockDataList } from '@features/halalShop/returns/mock/data';
import { HalalShopReturnStatus } from '@features/halalShop/returns/types';

import { useSearchParam } from '@hooks/useSearchParam';
import { FC, memo } from 'react';

const tabs: TabsTabItem<HalalShopReturnStatus | 'all'>[] = [
  {
    label: 'Все',
    value: 'all',
  },
  {
    label: 'Возврат оформлен',
    value: HalalShopReturnStatus.Processed,
  },
  {
    label: 'Возврат принят',
    value: HalalShopReturnStatus.Accepted,
  },
  {
    label: 'Отказано',
    value: HalalShopReturnStatus.Rejected,
  },
  {
    label: 'Доставлен',
    value: HalalShopReturnStatus.Delivered,
  },
];

const _ReturnsSection: FC = () => {
  const [statusSearch, setStatusSearch] = useSearchParam<
    HalalShopReturnStatus | 'all'
  >({
    paramName: 'status',
    defaultValue: 'all',
  });
  return (
    <StyledReturnsSection>
      <Tabs
        tabs={tabs}
        value={statusSearch}
        onChange={setStatusSearch}
        variant="round"
      />
      <RetrunsTable
        data={halalShopReturnMockDataList}
        onChangeStatus={() => {}}
        onClickToDetail={() => {}}
      />
    </StyledReturnsSection>
  );
};

export const ReturnsSection = memo(_ReturnsSection);
