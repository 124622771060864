import { MenuCategory } from './types';

export const menuCategoriesMockData: MenuCategory[] = [
  {
    id: 1,
    name: 'Поке',
  },
  {
    id: 2,
    name: 'Закуски',
  },
  {
    id: 3,
    name: 'Супы',
  },
  {
    id: 4,
    name: 'Горячие блюда',
  },
  {
    id: 5,
    name: 'Напитки',
  },
];
