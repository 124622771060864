import { NotePencilIcon } from '@packages/icons';
import { Button, Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledCommentCell = styled.div`
  display: flex;
  align-item: center;
  gap: 8px;
  justify-content: space-between;
`;

export const StyledCommentText = styled(Typography).attrs({
  nowrap: true,
  ellipsis: true,
})`
  flex: 1;
  min-width: 0;
`;

export const StyledEditButton = styled(Button).attrs({
  icon: <NotePencilIcon size={16} />,
  variant: 'text',
})`
  padding: 0;
`;
