import styled, { css } from 'styled-components';

export const StyledRadioGroup = styled.div<{ $cols: number }>`
  display: grid;

  row-gap: 20px;
  width: 100%;

  justify-content: space-between;
  padding-right: 20px;

  ${({ $cols }) =>
    css`
      grid-template-columns: repeat(${$cols}, max-content);
    `}
`;
