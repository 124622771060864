import { useGetActionButtons } from './hooks';
import {
  StyledBonusControl,
  StyledInfoList,
  StyledInfoSummaryItem,
  StyledInfoSummaryItemLabel,
  StyledInfoSummaryItemValue,
  StyledInfoTextItem,
  StyledInfoTextItemLabel,
  StyledInfoTextItemValue,
  StyledOrderItemsTitle,
  StyledOrderSidebar,
  StyledSidebarContent,
} from './OrderSidebar.styles';

import { OrderFormValues } from '../OrderForm/types';

import { Section } from '@components/Section';
import { ApiRecordId } from '@features/api';
import {
  HalalEdaEstablishmentRecord,
  HalalEdaOrderRecord,
} from '@features/halalEda';
import { MainLayout } from '@layouts/MainLayout';
import { Field, Switch, Typography } from '@packages/uiKit';
import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { OrderItem } from './OrderItem';

export type OrderSidebarProps = {
  mutableRecord: HalalEdaOrderRecord;
  establishment: HalalEdaEstablishmentRecord;
  onConfirm: () => void;
  onCancel: () => void;
  onComplete: () => void;
  onSave: () => void;
};

const _OrderSidebar: FC<OrderSidebarProps> = (props) => {
  const {
    mutableRecord: {
      status,
      client_type,
      //client: { balance },
      cost,
    },
    establishment: { cost_delivery, free_delivery_from },
    onConfirm,
    onCancel,
    onComplete,
    onSave,
  } = props;

  const { control, watch } = useFormContext<OrderFormValues>();

  const cartWatch = watch('cart');
  const useBalanceWatch = watch('change_sum');

  const totalCartCount = cartWatch.reduce(
    (acc, cartItem) => acc + cartItem.cost * cartItem.quantity,
    0,
  );

  const countFromBalance = useBalanceWatch
    ? Math.min(totalCartCount, cost ?? 0)
    : null;

  const costDelivery =
    free_delivery_from !== null && totalCartCount >= free_delivery_from
      ? null
      : cost_delivery;

  const summaryCount =
    totalCartCount - (countFromBalance ?? 0) + (costDelivery ?? 0);

  const actionsButtons = useGetActionButtons({
    status,
    onConfirm,
    onCancel,
    onComplete,
    onSave,
  });

  return (
    <StyledOrderSidebar>
      <MainLayout.Sidebar.Header>Подтвердить</MainLayout.Sidebar.Header>
      <StyledSidebarContent>
        <Row gutter={15}>
          {client_type === 'account' && cost && (
            <Col span={24}>
              <Section
                level={2}
                title={`${formatPriceVisualFriendly(cost)} бонусов доступно`}>
                <Controller
                  control={control}
                  name="useBalance"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Field label="Списать бонусы" error={error?.message}>
                        <StyledBonusControl>
                          <Typography size={16}>
                            {countFromBalance} ₸
                          </Typography>

                          <Switch withTips value={value} onChange={onChange} />
                        </StyledBonusControl>
                      </Field>
                    );
                  }}
                />
              </Section>
            </Col>
          )}

          {/* TODO исправить поле  <Col span={12}>
            <Section level={2} title="Промокод к заказу">
            
              <SelectField
                label="Применить акцию"
                options={promotionOptions}
                placeholder="Без акции"
              />
            </Section>
          </Col> */}
        </Row>

        <StyledOrderItemsTitle>Позиции заказа</StyledOrderItemsTitle>

        <StyledInfoList>
          <Controller
            control={control}
            name="cart"
            render={({ field: { value, onChange } }) => {
              const deleteItemHandler = (id: ApiRecordId) => {
                onChange(value.filter((x) => x.id !== id));
              };
              const changeCountItemHandler = (
                id: ApiRecordId,
                count: number,
              ) => {
                const newValue = value;
                const item = newValue.find((x) => x.id === id);
                if (item) {
                  item.quantity = count;
                  onChange(newValue);
                }
              };
              return (
                <>
                  {value.map((cartItem) => {
                    return (
                      <OrderItem
                        key={cartItem.id}
                        name={cartItem.name}
                        photo={cartItem.photo}
                        summary={cartItem.quantity * cartItem.cost}
                        count={cartItem.quantity}
                        onChangeCount={(count) =>
                          changeCountItemHandler(cartItem.id, count)
                        }
                        onDelete={() => deleteItemHandler(cartItem.id)}
                      />
                    );
                  })}
                </>
              );
            }}
          />

          {countFromBalance && (
            <StyledInfoTextItem>
              <StyledInfoTextItemLabel>Бонусы</StyledInfoTextItemLabel>
              <StyledInfoTextItemValue>
                {formatPriceVisualFriendly(countFromBalance)} ₸
              </StyledInfoTextItemValue>
            </StyledInfoTextItem>
          )}

          {costDelivery && (
            <StyledInfoTextItem>
              <StyledInfoTextItemLabel>Доставка</StyledInfoTextItemLabel>
              <StyledInfoTextItemValue>
                {formatPriceVisualFriendly(costDelivery)} ₸
              </StyledInfoTextItemValue>
            </StyledInfoTextItem>
          )}

          <StyledInfoSummaryItem>
            <StyledInfoSummaryItemLabel>Итого</StyledInfoSummaryItemLabel>
            <StyledInfoSummaryItemValue>
              {formatPriceVisualFriendly(summaryCount)} ₸
            </StyledInfoSummaryItemValue>
          </StyledInfoSummaryItem>
        </StyledInfoList>

        {actionsButtons}
      </StyledSidebarContent>
    </StyledOrderSidebar>
  );
};

export const OrderSidebar = memo(_OrderSidebar);
