import {
  StyledInput,
  StyledInputContainer,
  StyledRightContainer,
} from './Input.styles';

import React, { ReactNode, useMemo } from 'react';

export interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value'
  > {
  value?: string | null;
  onChange?: (value: string) => void;
  error?: boolean;
  right?: ReactNode;
  isDisabled?: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const {
      onChange,
      error = false,
      right,
      value,
      isDisabled = false,
      ...restProps
    } = props;

    const onChangeHandler:
      | React.ChangeEventHandler<HTMLInputElement>
      | undefined = useMemo(() => {
      return onChange
        ? (event) => {
            onChange(event.target.value);
          }
        : undefined;
    }, [onChange]);

    return (
      <StyledInputContainer $error={error}>
        <StyledInput
          disabled={isDisabled}
          ref={ref}
          onChange={onChangeHandler}
          value={value === null ? '' : value}
          {...restProps}
        />
        {right && <StyledRightContainer>{right}</StyledRightContainer>}
      </StyledInputContainer>
    );
  },
);

Input.displayName = 'Input';
