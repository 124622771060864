import {
  CreateMenuPositionFormValidValues,
  CreateMenuPositionFormValues,
} from './types';

import { fileValidation } from '@features/forms/validation';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  useUncontrolledForm,
  UseUncontrolledFormParams,
} from '@hooks/useUncontrolledForm';
import { useCallback } from 'react';
import * as yup from 'yup';

export const validationSchema: yup.SchemaOf<
  Omit<CreateMenuPositionFormValues, 'image'>
> = yup
  .object({
    name: yup
      .string()
      .required('Заполните поле название блюда')
      .max(60, 'Максимальное кол-во символов - 60'),
    weight: yup.number().nullable().required('Заполните поле вес блюда · Гр'),
    cost: yup.number().nullable().required('Заполните поле стоимость · Рубли'),
    category: yup.number().nullable().required('Выберите категорию блюда'),
    ingredients: yup
      .string()
      .required('Заполните поле состав блюда')
      .max(200, 'Максимальное кол-во символов - 200'),
    image: fileValidation({
      required: true,
      requiredMessage: 'Необходимо загрузить фото блюда',
    }),
  })
  .required();

const initValues: CreateMenuPositionFormValues = {
  name: '',
  weight: null,
  cost: null,
  category: null,
  ingredients: '',
  image: null,
};

export type UseCreateMenuPositionFormParams = {
  onSubmit: (
    data: CreateMenuPositionFormValidValues,
    isUploadImage: boolean,
  ) => Promise<void>;
};

export const useCreateMenuPositionForm = (
  params: UseCreateMenuPositionFormParams,
) => {
  const { onSubmit } = params;

  const submitHandler: UseUncontrolledFormParams<
    CreateMenuPositionFormValues,
    CreateMenuPositionFormValidValues
  >['onSubmit'] = useCallback(
    async (data, formState) => {
      const isEditedImage = Boolean(formState.dirtyFields.image);

      await onSubmit(data, isEditedImage);
    },
    [onSubmit],
  );

  return useUncontrolledForm<
    CreateMenuPositionFormValues,
    CreateMenuPositionFormValidValues
  >({
    defaultValues: initValues,
    onSubmit: submitHandler,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
};
