import { TabsVariant } from './types';

import { Typography } from '@packages/uiKit/Typography';
import { colors } from '@packages/uiKit/utils/colors';
import styled, { css } from 'styled-components';

export const StyledTabs = styled.div<{ variant: TabsVariant }>`
  display: flex;
  flex-wrap: wrap;

  ${({ variant }) =>
    variant === 'default'
      ? css`
          gap: 22px;
        `
      : css`
          gap: 8px;
        `}

  margin-bottom: 20px;
`;

const getDynamicStyles = (props: {
  isSelected?: boolean;
  variant: TabsVariant;
  isShowMoreTab?: boolean;
}) => {
  const { isSelected, variant, isShowMoreTab } = props;

  switch (variant) {
    case 'default': {
      return css`
        font-size: 22px;
        ${isSelected
          ? css`
              color: ${colors.dark1};
            `
          : css`
              color: ${colors.gray1};
            `}
      `;
    }

    case 'round': {
      return css`
        border-radius: 12px;
        border-width: 1px;
        border-style: solid;
        font-size: 12px;
        font-weight: 400;
        padding: 4px 10px;
        ${isSelected
          ? css`
              color: ${colors.white};
              background-color: ${colors.primary};
              border-color: ${colors.primary};
            `
          : css`
              color: ${colors.dark1};
              background-color: transparent;
              border-color: ${colors.blueGray};
            `}
        ${isShowMoreTab &&
        css`
          background-color: transparent;
          border: none;
          font-weight: 700;
          border-color: ${colors.gray1};
          color: ${colors.dark1};
          :hover {
            color: ${colors.gray0};
          }
        `}
      `;
    }
  }
};

export const StyledTabItem = styled(Typography).attrs({
  weight: 700,
})<{
  badge?: boolean;
  isSelected?: boolean;
  variant: TabsVariant;
  isShowMoreTab?: boolean;
}>`
  position: relative;
  transition: 0.3s ease;

  ${({ isSelected }) =>
    !isSelected &&
    css`
      cursor: pointer;
    `}

  ${(props) => getDynamicStyles(props)}

  ${({ badge }) =>
    badge &&
    css`
      ::after {
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        top: -4px;
        right: -8px;
        content: '';

        border-radius: 50%;
        background-color: ${colors.danger};
      }
    `}
`;
