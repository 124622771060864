import { LegendItem } from './LegendItem';
import {
  StyledLegendLayout,
  StyledOrdersStructure,
} from './OrdersStructureCard.styles';

import { Pie, PieConfig } from '@ant-design/plots';
import { GetHalalEdaStatsPageResponse } from '@features/halalEda';
import { colors } from '@packages/uiKit';
import React, { FC, memo } from 'react';

export interface OrdersStructureCardProps {
  className?: string;
  style?: React.CSSProperties;

  dataItems: GetHalalEdaStatsPageResponse;
}

type DataType = {
  type:
    | 'доставка'
    | 'самовывоз'
    | 'отменено'
    | 'неДоставлено'
    | 'оставленоНаЭтапеЗаказа';
  value: number;
  color: 'colorOne' | 'colorTwo' | 'colorThree' | 'colorFour' | 'colorFive';
};

const typeToColorDict: Record<DataType['type'], string> = {
  доставка: colors.primary,
  самовывоз: colors.primaryDark1,
  отменено: colors.primaryDark2,
  неДоставлено: colors.primaryDark3,
  оставленоНаЭтапеЗаказа: colors.primaryDark3,
};

// const data: DataType[] = [
//   {
//     type: 'delivery',
//     value: 33,
//   },
//   {
//     type: 'pickup',
//     value: 28,
//   },
//   {
//     type: 'canceled',
//     value: 33,
//   },
//   {
//     type: 'undelivered',
//     value: 6,
//   },
//   {
//     type: 'draft',
//     value: 6,
//   },
// ];

const _OrdersStructureCard: FC<OrdersStructureCardProps> = (props) => {
  const { dataItems, ...restProps } = props;

  const {
    orders_not_delivered,
    orders_pickup,
    orders_courier,
    orders_draft,
    orders_cancelled,
  } = dataItems;

  const data = [
    {
      type: 'доставка',
      value: orders_courier.ratio === 0 ? null : orders_courier.ratio,
      color: 'colorOne',
    },
    {
      type: 'самовывоз',
      value: orders_pickup.ratio === 0 ? null : orders_pickup.ratio,
      color: 'colorTwo',
    },
    {
      type: 'оставленоНаЭтапеЗаказа',
      value: orders_draft.ratio === 0 ? null : orders_draft.ratio,
      color: 'colorThree',
    },
    {
      type: 'отменено',
      value: orders_cancelled.ratio === 0 ? null : orders_cancelled.ratio,
      color: 'colorFour',
    },
    {
      type: 'неДоставлено',
      value: orders_not_delivered.ratio === 0 ? null : orders_cancelled.ratio,
      color: 'colorFive',
    },
  ];

  const config: PieConfig = {
    // appendPadding: 10,
    data,
    angleField: 'value',
    // autoFit: true,
    innerRadius: 0.3,
    radius: 0.7,
    height: 250,
    width: 250,
    legend: false as const,
    colorField: 'type',
    color: ({ type }) =>
      typeToColorDict[
        type as
          | 'доставка'
          | 'самовывоз'
          | 'отменено'
          | 'неДоставлено'
          | 'оставленоНаЭтапеЗаказа'
      ],
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false as const,
      content: false as const,
    },
  };

  return (
    <StyledOrdersStructure
      title="Структура"
      {...restProps}
      right={<Pie {...config} />}>
      <StyledLegendLayout>
        <LegendItem
          color={colors.primary}
          percent={orders_pickup.ratio}
          amount={orders_pickup.sum}
          orderCount={orders_pickup.count}
          description="Самовывоз"
        />
        <LegendItem
          color={colors.primaryDark1}
          percent={orders_courier.ratio}
          amount={orders_courier.sum}
          orderCount={orders_courier.count}
          description="Доставка"
        />
        <LegendItem
          color={colors.primaryDark1}
          percent={orders_draft.ratio}
          amount={orders_draft.sum}
          orderCount={orders_draft.count}
          description="Оставлено на этапе заказа"
        />
        <LegendItem
          color={colors.primaryDark2}
          percent={orders_cancelled.ratio}
          amount={orders_cancelled.sum}
          orderCount={orders_cancelled.count}
          description="Отмененные"
        />
        <LegendItem
          color={colors.primaryDark3}
          percent={orders_not_delivered.ratio}
          amount={orders_not_delivered.sum}
          orderCount={orders_not_delivered.count}
          description="Недоставленные"
        />
      </StyledLegendLayout>
    </StyledOrdersStructure>
  );
};

export const OrdersStructureCard = memo(_OrdersStructureCard);
