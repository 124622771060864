import { MainContactInfo } from '@layouts/components/MainContactInfo';
import { colors } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledIdentityLayout = styled.div`
  background-color: ${colors.rootBackground};
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledCenterContainter = styled.div`
  flex: 1;
  min-height: 0;
  max-width: 500px;
  background: white;

  display: flex;
  align-items: center;
  padding: 80px;
`;

export const StyledContentContainter = styled.div``;

export const StyledMainContactInfo = styled(MainContactInfo)`
  margin-top: 100px;
`;
