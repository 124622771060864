import { HalalShopOrderFormValues } from '@pages/HalalShopOrderDetail/HalalShopOrderForm/types';

import { DeliveryRecord } from '@features/halalShop/store/types';
import {
  HalalShopCartItem,
  HalalShopOrderMockRecord,
  HalalShopOrderStatus,
} from './types';

export const halalShopCartItemMockList: HalalShopCartItem[] = [
  {
    id: 1,
    name: 'Японский зеленый порошковый чай матча Matsu, 20 г',
    cost: 3585,
    count: 4,
    photo: '',
  },
  {
    id: 2,
    name: 'Тестовый товар - Placeholder',
    cost: 2250,
    count: 1,
    photo: '',
  },
];

export const initHalalShopDetailOrderDeliveryValuesPlaceholder: DeliveryRecord =
  {
    name: '',
    price: { currency: '', value: 0 },
  };

export const initHalalShopDetailOrderValuesPlaceholder: HalalShopOrderFormValues =
  {
    street: '',
    apt: '',
    house: '',
    firstName: '',
    lastName: '',
    middleName: '',
    postCode: '',
    items: halalShopCartItemMockList,
    phone: '',
    email: '',
    postId: null,
  };

export const halalShopOrderMockList: HalalShopOrderMockRecord[] = [
  {
    id: 890,
    status: HalalShopOrderStatus.Delivering,
    amount: 12001,
    created: '2022-10-13T22:09:50Z',
    address: '6 Trailsway Center',
    client: 'Larine Oldale',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 2,
    status: HalalShopOrderStatus.New,
    amount: 9715,
    created: '2022-11-08T21:06:31Z',
    address: '9569 New Castle Way',
    client: 'Ronnie Klageman',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 3,
    status: HalalShopOrderStatus.Canceled,
    amount: 18326,
    created: '2022-03-22T14:24:06Z',
    address: '29 Knutson Crossing',
    client: 'Tremain Harrison',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 4,
    status: HalalShopOrderStatus.AwaitingShipment,
    amount: 4992,
    created: '2022-12-22T15:43:57Z',
    address: '6 Alpine Street',
    client: 'Wald Borge',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 5,
    status: HalalShopOrderStatus.Delivering,
    amount: 2916,
    created: '2022-12-10T17:23:50Z',
    address: '65 Crest Line Center',
    client: 'Cyrus Szanto',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 6,
    status: HalalShopOrderStatus.Delivered,
    amount: 16917,
    created: '2022-03-18T22:17:26Z',
    address: '40050 6th Hill',
    client: 'Tamarra Bernette',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 7,
    status: HalalShopOrderStatus.AwaitingShipment,
    amount: 12331,
    created: '2022-11-13T11:06:50Z',
    address: '3 Waxwing Way',
    client: 'Gwenora Lowey',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 8,
    status: HalalShopOrderStatus.Canceled,
    amount: 9160,
    created: '2022-05-20T03:19:40Z',
    address: '1206 Clove Junction',
    client: 'Fredric Ellen',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 9,
    status: HalalShopOrderStatus.Delivering,
    amount: 1655,
    created: '2022-09-06T04:41:12Z',
    address: '2337 Colorado Park',
    client: 'Berny Garmon',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 10,
    status: HalalShopOrderStatus.Assembly,
    amount: 1883,
    created: '2022-05-10T20:53:17Z',
    address: '9323 Nancy Hill',
    client: 'Eberto Booy',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 11,
    status: HalalShopOrderStatus.Canceled,
    amount: 4136,
    created: '2022-01-24T18:42:17Z',
    address: '834 Hallows Court',
    client: 'Albertine Kynastone',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 12,
    status: HalalShopOrderStatus.Assembly,
    amount: 12855,
    created: '2022-12-18T05:39:20Z',
    address: '43648 Waxwing Avenue',
    client: 'Mary Iannini',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
  {
    id: 13,
    status: HalalShopOrderStatus.AwaitingShipment,
    amount: 7321,
    created: '2022-11-25T23:25:21Z',
    address: '8 Duke Hill',
    client: 'Hedvig Drewery',
    phone: '+7 932 765-64-54',
    items: halalShopCartItemMockList,
  },
];
