import {
  InputNumberField,
  InputNumberFieldProps,
} from '@packages/uiKit/fields/InputNumberField';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import { Controller } from 'react-hook-form';

export const InputNumberFieldAdapter: FieldAdapterFCComponent<
  InputNumberFieldProps
> = (props) => {
  const {
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    ...InputNumberProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputNumberField
            {...InputNumberProps}
            {...field}
            error={error?.message}
          />
        );
      }}
    />
  );
};
