import { StyledButtonsContainer } from './OrderSidebar.styles';

import { HalalEdaOrderStatus } from '@features/halalEda';
import { Button } from '@packages/uiKit';
import { useMemo } from 'react';

const statusButtonsDict: Record<
  HalalEdaOrderStatus,
  ('confirm' | 'paid' | 'unpaid' | 'cancel' | 'save')[]
> = {
  [HalalEdaOrderStatus.Paid]: ['paid', 'cancel'],
  [HalalEdaOrderStatus.UnPaid]: ['unpaid', 'cancel'],
  [HalalEdaOrderStatus.InProgress]: ['paid', 'cancel'],
  [HalalEdaOrderStatus.Cancelled]: ['save'],
  [HalalEdaOrderStatus.Completed]: ['save'],
};

export type UseGetActionButtonsParams = {
  status: HalalEdaOrderStatus;
  onConfirm: () => void;
  onCancel: () => void;
  onComplete: () => void;
  onSave: () => void;
};

export const useGetActionButtons = (params: UseGetActionButtonsParams) => {
  const { status, onConfirm, onComplete, onCancel, onSave } = params;

  const actionsButtons = useMemo(() => {
    const buttons = statusButtonsDict[status];

    return (
      <StyledButtonsContainer>
        {buttons.map((btn) => {
          switch (btn) {
            case 'paid': {
              return (
                <Button variant="filled" size="large" onClick={onConfirm}>
                  Подтвердить заказ
                </Button>
              );
            }
            case 'cancel': {
              return (
                <Button
                  variant="filled"
                  size="large"
                  color="secondary"
                  onClick={onCancel}>
                  Отменить заказ
                </Button>
              );
            }
            case 'unpaid': {
              return (
                <Button
                  variant="filled"
                  size="large"
                  color="primary"
                  onClick={onComplete}>
                  Пометить как выполненный
                </Button>
              );
            }
            case 'save': {
              return (
                <Button
                  variant="filled"
                  size="large"
                  color="primary"
                  onClick={onSave}>
                  Сохранить изменения
                </Button>
              );
            }
            default:
              return null;
          }
        })}
      </StyledButtonsContainer>
    );
  }, [onCancel, onComplete, onConfirm, onSave, status]);

  return actionsButtons;
};
