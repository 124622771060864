import { StyledElipsisTextCell } from './ElipsisTextCell.styles';

import React, { FC, PropsWithChildren } from 'react';

export interface ElipsisTextCellProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}

export const ElipsisTextCell: FC<ElipsisTextCellProps> = (props) => {
  const { ...restProps } = props;

  return <StyledElipsisTextCell {...restProps} />;
};
