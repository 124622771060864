import { StyledComplaintsCard } from './ComplaintsCard.styles';

import { InfoCardProps } from '../common/InfoCard';

import { SmileyNervousIcon } from '@packages/icons';
import React, { FC } from 'react';

export interface ComplaintsCardProps
  extends Pick<InfoCardProps, 'value' | 'percent'> {
  className?: string;
  style?: React.CSSProperties;
}

export const ComplaintsCard: FC<ComplaintsCardProps> = (props) => {
  const { ...restProps } = props;

  return (
    <StyledComplaintsCard
      {...restProps}
      icon={<SmileyNervousIcon size={36} />}
      description={'Оставили жалобу'}
      color="danger"
    />
  );
};
