import { columns } from './constants';

import { Loader } from '@components/Loader';

import { TextPlaceholder } from '@components/TextPlaceholder';

import { AccountingItemRecord } from '@features/accounting';
import { Table, TableProps } from '@packages/uiKit/Table';
import { colors } from '@packages/uiKit/utils/colors';
import { FC } from 'react';

export interface AccountingTableProps
  extends Omit<
    TableProps<AccountingItemRecord>,
    'columns' | 'getRowId' | 'getRowProps'
  > {
  isLoading: boolean;
  isSuccess: boolean;
}

const getRowId: TableProps<AccountingItemRecord>['getRowId'] = (row) =>
  row.id.toString();

const getRowProps: TableProps<AccountingItemRecord>['getRowProps'] = (row) => {
  if (!row.original.paid) {
    return {
      style: {
        color: colors.gray1,
      },
    };
  }

  return {};
};

export const AccountingTable: FC<AccountingTableProps> = (props) => {
  const { data, isLoading, isSuccess, ...restProps } = props;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : data.length > 0 ? (
        <Table
          data={data}
          columns={columns}
          getRowId={getRowId}
          getRowProps={getRowProps}
          {...restProps}
        />
      ) : (
        <TextPlaceholder variant="noData" />
      )}

      {!isLoading && !isSuccess && <TextPlaceholder />}
    </>
  );
};
