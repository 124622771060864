import React, { FC, useState } from 'react';

import { Loader } from '@components/Loader';
import { TablesScrollX } from '@components/TablesScrollX';
import { TextPlaceholder } from '@components/TextPlaceholder';
import { greenBonusClientsApi } from '@features/halalBonus/clients/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { useModalState } from '@hooks/useModalState';
import { Button, Modal } from '@packages/uiKit';
import { PaginationLimitOffsetAdapter } from '@packages/uiKit/PaginationOffset';
import { FormProvider, useForm } from 'react-hook-form';
import { ClientsTable } from './ClientsTable';
import { PushModalContent } from './PushModalContent';
import { pushFormDefaultValues } from './PushModalContent/constants';
import { pushNotificationFormSchema } from './PushModalContent/types';
import {
  StyledClientsSection,
  StyledPushNotificationContainer,
} from './styles';

export interface ClientsSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

export const ClientsSection: FC<ClientsSectionProps> = (props) => {
  const { ...restProps } = props;

  const perPage = 12;

  const [offset, setOffset] = useState(0);

  const { data, isSuccess, isLoading } =
    greenBonusClientsApi.useGetClientsQuery({
      limit: perPage,
      offset: offset,
    });

  const isNoData = !isLoading && isSuccess && data.results.length === 0;

  const isPaginationVisible = isSuccess && data.count > perPage;

  const [modal, modalActions] = useModalState();

  const pushModalClickHandler = () => {
    modalActions.openHandler();
  };

  const form = useForm({
    defaultValues: pushFormDefaultValues,
    resolver: yupResolver(pushNotificationFormSchema),
  });

  return (
    <StyledClientsSection {...restProps}>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess && (
          <>
            {isNoData ? (
              <TextPlaceholder variant="noData" />
            ) : (
              <>
                <StyledPushNotificationContainer>
                  <Button onClick={pushModalClickHandler}>
                    Пуш-уведомление
                  </Button>
                </StyledPushNotificationContainer>

                <TablesScrollX>
                  <ClientsTable data={data?.results} />
                </TablesScrollX>
              </>
            )}
            {isPaginationVisible && (
              <PaginationLimitOffsetAdapter
                offset={offset}
                perPage={perPage}
                onChangeOffset={setOffset}
                totalElementsCount={data.count}
              />
            )}
          </>
        )
      )}
      {!isSuccess && !isLoading && <TextPlaceholder variant="error" />}
      <Modal
        isOpen={modal.isOpen}
        title="Создать пуш-уведомление"
        onClose={modalActions.closeHandler}
        width={750}>
        <FormProvider {...form}>
          <PushModalContent onCloseModal={modalActions.closeHandler} />
        </FormProvider>
      </Modal>
    </StyledClientsSection>
  );
};
