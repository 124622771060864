import { CommentCell } from './CommentCell';

import { statusConfig } from './constants';

import { SelectCellWithIcon } from '../../../components/table/SelectCellWithIcon';

import { DateCell } from '@components/table';
import { BookingRecord, BookingStatus } from '@features/booking/store/types';
import { TableColumn, Typography } from '@packages/uiKit';

export type GetColumnsParams = {
  onEditClick: (item: BookingRecord) => void;
  onEditStatus: (item: BookingRecord, status: BookingStatus) => void;
};
export const getColumns = (
  params: GetColumnsParams,
): TableColumn<BookingRecord>[] => [
  {
    Header: 'Статус',
    accessor: 'status',
    width: 220,
    canResize: false,
    overflow: false,
    Cell: ({ value, row: { original } }) => {
      const changeHandler = (status: BookingStatus) => {
        params.onEditStatus(original, status);
      };
      return (
        <SelectCellWithIcon
          config={statusConfig}
          value={value}
          onChange={changeHandler}
        />
      );
    },
  },
  {
    Header: 'Клиент',
    accessor: 'name',
    width: 180,
    canResize: false,
    Cell: ({ value }) => (
      <Typography nowrap ellipsis title={value} weight={700}>
        {value}
      </Typography>
    ),
  },
  {
    Header: 'Номер телефона',
    accessor: 'phone',
    width: 180,
    canResize: false,

    Cell: ({ value }) => (
      <Typography nowrap ellipsis>
        {value}
      </Typography>
    ),
  },
  {
    Header: 'Гостей',
    accessor: 'seats',
    width: 100,
    canResize: false,
    Cell: ({ value }) => (
      <Typography nowrap ellipsis weight={700}>
        {value} чел.
      </Typography>
    ),
  },
  {
    Header: 'Время',
    accessor: 'time',
    width: 140,
    canResize: false,

    Cell: ({ value }) => <DateCell date={value} format="dateTime" />,
  },
  {
    Header: 'Комментарий',
    accessor: 'note',
    Cell: ({ value, row: { original } }) => {
      const onEditClickHandler = () => {
        params.onEditClick(original);
      };

      return <CommentCell value={value} onEditClick={onEditClickHandler} />;
    },
    maxWidth: 900,
  },
];
