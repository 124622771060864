import { HalalShopOrderFormValues } from './types';

import { isNeedPostCode } from './utils';

import {
  getRequiredErrorMessage,
  wrongEmailFormatErrorMessage,
} from '@features/forms/errorMessages';
import { phoneValidation, stringValidation } from '@features/forms/validation';
import { HalalShopOrderStatus } from '@features/halalShop/orders/mock/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export type UseHalalShopOrderFormParams = {
  status: HalalShopOrderStatus;
  initialValues: HalalShopOrderFormValues;
};

export const getValidationSchema = (
  status: HalalShopOrderStatus,
): yup.SchemaOf<Omit<HalalShopOrderFormValues, 'items'>> =>
  yup
    .object({
      street: stringValidation().required(getRequiredErrorMessage('улица')),
      house: stringValidation().required(getRequiredErrorMessage('дом')),
      apt: stringValidation().required(getRequiredErrorMessage('квартира')),
      firstName: stringValidation().required(getRequiredErrorMessage('имя')),
      lastName: stringValidation().required(getRequiredErrorMessage('фамилия')),
      middleName: stringValidation().required(
        getRequiredErrorMessage('отчество'),
      ),
      phone: phoneValidation().required(
        getRequiredErrorMessage('номер телефона'),
      ),
      postCode: stringValidation().required(getRequiredErrorMessage('индекс')),
      email: stringValidation()
        .email(wrongEmailFormatErrorMessage)
        .required(getRequiredErrorMessage('электронная почта')),
      postId: isNeedPostCode(status)
        ? stringValidation().required(
            getRequiredErrorMessage('номер отслеживания'),
          )
        : stringValidation(),
      items: yup.array().required(),
    })
    .required();

export const useHalalShopOrderForm = (params: UseHalalShopOrderFormParams) => {
  return useForm<HalalShopOrderFormValues>({
    defaultValues: params.initialValues,
    resolver: yupResolver(getValidationSchema(params.status)),
  });
};
