import {
  wrongEmailFormatErrorMessage,
  wrongUrlFormatErrorMessage,
} from './errorMessages';

import { ApiImageUrl } from '@features/api';
import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export const stringValidation = () =>
  yup
    .string()
    .nullable()
    .defined('Значение должно быть определено') as yup.StringSchema<
    string | null,
    AnyObject,
    string | null
  >;

export const phoneValidation = () =>
  stringValidation().matches(
    /^(\+7|7|8)?[\s-]?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}$/,
    'Неверный формат телефонного номера',
  );

export type FileValidationMimeType =
  | 'image/png'
  | 'image/jpeg'
  | 'image/svg+xml';
export type FileValidationParams = {
  maxFileSize?: number;
  maxResolution?: {
    width: number;
    height: number;
  };
  required?: true;
  requiredMessage?: string;
  mime?: FileValidationMimeType[];
};
export const fileValidation = (params: FileValidationParams) => {
  const { maxFileSize, required, requiredMessage, maxResolution, mime } =
    params;

  let result = yup.mixed<File | ApiImageUrl>();
  if (!required) {
    result = result.nullable().optional();
  } else {
    result = result.required(requiredMessage ?? 'Заполните поле');
  }

  if (maxFileSize) {
    result = result.test({
      test: (value) => {
        if (value instanceof File) {
          return value.size <= maxFileSize;
        }

        return true;
      },
      message: `Максимальный размер файла - ${maxFileSize / 10 ** 3} Kb`,
    });
  }

  if (maxResolution) {
    result = result.test({
      test: async (value) => {
        if (value instanceof File) {
          let img = new Image();
          img.src = URL.createObjectURL(value);
          await img.decode();
          let width = img.width;
          let height = img.height;

          return width <= maxResolution.width && height <= maxResolution.height;
        }

        return true;
      },
      message: `Расширение файла не должно превышать ${maxResolution.width}x${maxResolution.height}`,
    });
  }

  if (mime) {
    result = result.test({
      test: async (value) => {
        if (value instanceof File) {
          if (!mime.some((m) => m === value.type)) {
            return false;
          }
        }

        return true;
      },
      message: `Доступны файлы только следующих типов: ${mime.join(',')}`,
    });
  }
  return result;
};

export type UrlValidationParams = {
  urlMessage?: string;
};

export const urlValidation = (params?: UrlValidationParams) =>
  stringValidation().url(params?.urlMessage ?? wrongUrlFormatErrorMessage);

export type EmailValidationParams = {
  emailMessage?: string;
};
export const emailValidation = (params?: EmailValidationParams) =>
  stringValidation().email(
    params?.emailMessage ?? wrongEmailFormatErrorMessage,
  );
