import { ChartCard } from '../common/ChartCard';

import styled from 'styled-components';

export const StyledTransactionsDynamicCard = styled(ChartCard)`
  grid-area: transDynamic;
`;

export const StyledContent = styled.div`
  display: flex;
  gap: 35px;
`;

export const StyledGraphWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

export const StyledSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
`;
