import { Tabs } from '@components';
import styled from 'styled-components';

export const StyledCatalogSection = styled.div``;

export const StyledHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 25px;
`;

export const StyledTabs = styled(Tabs)`
  margin-bottom: 0;
` as typeof Tabs;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 180px;
  min-width: 180px;
  gap: 10px;
`;
