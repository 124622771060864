import { media } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledStatisticsSection = styled.div``;

export const StyledStatisticsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 15px;

  > * {
    min-width: 0;
  }

  grid-template-areas:
    'searches unicSearches transactions contactsSearches transactions complaints'
    'topClients transDynamic transDynamic transDynamic transDynamic transDynamic';

  ${media.down('xxl')} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'searches transactions transactions'
      'contactsSearches complaints complaints'
      'topClients transDynamic transDynamic ';
  }

  ${media.down('xl')} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'searches'
      'unicSearches'
      'transactions'
      'contactsSearches'
      'share'
      'complaints'
      'topClients'
      'transDynamic';
  }
`;
