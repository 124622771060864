import { OrderStatusCell } from './OrderStatusCell';

import { CartCell } from '../../../../../components/table/CartCell';

import { ToDetailCell } from '@components/table/ToDetailCell';
import { getCurrencySymbol } from '@features/currency/hooks/getCurrencySymbol';
import { HalalShopOrderMockRecord } from '@features/halalShop/orders/mock/types';

import {
  HalalShopOrderStatus,
  ShopOrdersRecord,
} from '@features/halalShop/store/types';
import { TableColumn, TableProps, Typography, colors } from '@packages/uiKit';
import { formatDate } from '@utils/dateFormat';

export type GetColumnsParams = {
  onChangeStatus: (
    record: ShopOrdersRecord,
    status: HalalShopOrderStatus,
  ) => void;
};
export const getColumns = (
  params: GetColumnsParams,
): TableColumn<ShopOrdersRecord>[] => [
  {
    Header: 'Статус',
    accessor: 'status',
    overflow: false,
    width: 250,
    Cell: ({ value, row: { original } }) => {
      const changeHandler = (status: HalalShopOrderStatus) => {
        params.onChangeStatus(original, status);
      };
      return <OrderStatusCell status={value} onChange={changeHandler} />;
    },
  },
  {
    Header: 'Номер заказа',
    accessor: 'id',
    Cell: ({ value }) => <Typography weight={700}>{value}</Typography>,
  },
  {
    Header: 'Сумма',
    accessor: 'price',
    Cell: ({ value }) => (
      <Typography weight={700}>
        {value.value.toLocaleString()} {getCurrencySymbol(value.currency)}
      </Typography>
    ),
  },

  {
    Header: 'Заказ',
    accessor: 'data',
    Cell: ({ value }) => <CartCell items={value} />,
  },

  {
    Header: 'Время',
    accessor: 'created_at',
    Cell: ({ value }) => (
      <Typography>{formatDate(value, 'dateTime')}</Typography>
    ),
  },

  {
    Header: 'Адрес доставки',
    accessor: 'address',
    width: 400,
    Cell: ({ value }) => (
      <Typography nowrap ellipsis>
        {value}
      </Typography>
    ),
  },
  {
    Header: 'Клиент',
    accessor: 'name',
  },
  {
    Header: 'Номер телефона',
    accessor: 'phone',
    minWidth: 200,
    Cell: ({ value, row: { original } }) => (
      <ToDetailCell>
        <Typography nowrap>{value}</Typography>
      </ToDetailCell>
    ),
  },
];

export const getRowId = (item: HalalShopOrderMockRecord) => item.id.toString();

export const getRowProps: TableProps<HalalShopOrderMockRecord>['getRowProps'] =
  (item) => {
    if (
      item.original.status === HalalShopOrderStatus.Delivered ||
      item.original.status === HalalShopOrderStatus.Canceled
    ) {
      return {
        style: {
          color: colors.gray1,
        },
      };
    }
  };
