import {
  DeliveryRecord,
  HalalShopOrderDetailResponse,
} from '@features/halalShop/store/types';
import { initHalalShopDetailOrderDeliveryValuesPlaceholder } from '../mock/data';

export const getDeliveryValues = (
  order: HalalShopOrderDetailResponse | null,
) => {
  if (!order) return initHalalShopDetailOrderDeliveryValuesPlaceholder;

  const res: DeliveryRecord = {
    name: order.delivery.name,
    price: order.delivery.price,
  };

  return res;
};
