import { getColumns } from './utils';

import { TextPlaceholder } from '@components/TextPlaceholder';

import { ReviewRecord } from '@features/reviews/store/types';
import { Table, TableProps } from '@packages/uiKit';
import { FC, useMemo } from 'react';

export interface ReviewTableProps {
  data: ReviewRecord[];
  pagination: TableProps['pagination'];
  onClickMakeAnswer: (item: ReviewRecord) => void;
}

export const ReviewTable: FC<ReviewTableProps> = (props) => {
  const { data, pagination, onClickMakeAnswer } = props;

  const columns = useMemo(() => {
    return getColumns({
      onClickMakeAnswer,
    });
  }, [onClickMakeAnswer]);

  return (
    <>
      {data.length === 0 ? (
        <TextPlaceholder variant="noData" />
      ) : (
        <Table columns={columns} data={data} pagination={pagination} />
      )}
    </>
  );
};
