import { Typography } from '@packages/uiKit/Typography';
import styled, { css } from 'styled-components';

export const StyledSection = styled.div``;

const levelToMB = {
  1: 24,
  2: 20,
};

export const StyledSectionTitle = styled(Typography).attrs({
  nowrap: true,
  ellipsis: true,
})<{ level: 1 | 2 }>`
  ${({ level }) => css`
    margin-bottom: ${levelToMB[level]}px;
  `}
`;

export const StyledSectionContent = styled.div``;
