import { ListAsTableRow } from '@components/ListAsTableRow';
import { Section } from '@components/Section';
import {
  HalalEdaOrderRecord,
  HalalEdaOrderStatus,
} from '@features/halalEda/orders';
import { OrderStatusCell } from '@features/halalEda/orders/components/OrderStatusCell';
import { formatDate } from '@utils/dateFormat';
import { formatPriceVisualFriendly } from '@utils/formatPriceVisualFriendly';
import { FC, memo } from 'react';

export type MainInfoSectionProps = {
  mutableRecord: HalalEdaOrderRecord;
  onChangeStatus: (status: HalalEdaOrderStatus) => Promise<void> | void;
};

export const _MainInfoSection: FC<MainInfoSectionProps> = (props) => {
  const { mutableRecord, onChangeStatus } = props;

  const { id, status, cost, cart, created, address, client } = mutableRecord;

  console.log(mutableRecord);

  return (
    <Section title={`Заказ · ${id}`}>
      <ListAsTableRow>
        <ListAsTableRow.Item>
          <OrderStatusCell status={status} onChange={onChangeStatus} />
        </ListAsTableRow.Item>

        <ListAsTableRow.Item>
          <ListAsTableRow.Label label="Сумма" />
          <ListAsTableRow.Value
            value={`${formatPriceVisualFriendly(cost)} ₸`}
          />
        </ListAsTableRow.Item>

        <ListAsTableRow.Item>
          <ListAsTableRow.Label label="Заказ" />
          <ListAsTableRow.Value value={`${cart.length} · позиций`} />
        </ListAsTableRow.Item>

        <ListAsTableRow.Item>
          <ListAsTableRow.Label label="Время" />
          <ListAsTableRow.Value value={`${formatDate(created, 'dateTime')}`} />
        </ListAsTableRow.Item>

        <ListAsTableRow.Item>
          <ListAsTableRow.Label label="Адрес доставки" />
          <ListAsTableRow.Value value={address} />
        </ListAsTableRow.Item>

        <ListAsTableRow.Item>
          <ListAsTableRow.Label label="Клиент" />
          <ListAsTableRow.Value value={client.full_name} />
        </ListAsTableRow.Item>

        <ListAsTableRow.Item>
          <ListAsTableRow.Label label="Номер телефона" />
          <ListAsTableRow.Value value={client.tel} />
        </ListAsTableRow.Item>
      </ListAsTableRow>
    </Section>
  );
};

export const MainInfoSection = memo(_MainInfoSection);
