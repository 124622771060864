import { StyledTopClientsCard } from './TopClientsCard.styles';

import { Bar, BarConfig } from '@ant-design/plots';
import { TopUser } from '@features/halalBonus/transactions';
import { colors } from '@packages/uiKit';
import React, { FC, memo } from 'react';

export interface TopClientsCardProps {
  className?: string;
  style?: React.CSSProperties;

  clients: TopUser[];
}

type DataItem = {
  clientName: string;
  sumAmount: number;
};

// const dataMock: DataItem[] = [
//   {
//     clientName: 'Гузель Галиулина',
//     sumAmount: 105_000,
//   },
//   {
//     clientName: 'Миша Бурлацкий',
//     sumAmount: 22_000,
//   },
//   {
//     clientName: 'Виктория Ильина',
//     sumAmount: 14_000,
//   },
//   {
//     clientName: 'Егор Матвеевский',
//     sumAmount: 12_000,
//   },
//   {
//     clientName: 'Алинур Загрутдинова',
//     sumAmount: 9_000,
//   },
//   {
//     clientName: 'Михаил Воробьев',
//     sumAmount: 7_000,
//   },
//   {
//     clientName: 'Дмитрий Шулин',
//     sumAmount: 5_000,
//   },
// ];

const _TopClientsCard: FC<TopClientsCardProps> = (props) => {
  const { clients, ...restProps } = props;

  const data: DataItem[] = clients.map((item) => {
    return { clientName: item.full_name, sumAmount: item.total_points };
  });

  const config: BarConfig = {
    data,
    height: 250,
    color: colors.primary,
    xField: 'sumAmount',
    yField: 'clientName',
    seriesField: 'clientName',
    legend: false as const,
    meta: {
      sumAmount: {
        formatter: (value: number) => `${(value / 1).toFixed(0)} ед.`,
      },
    },
  };

  return (
    <StyledTopClientsCard title="Топ 7 клиентов" {...restProps}>
      <Bar {...config} />
    </StyledTopClientsCard>
  );
};

export const TopClientsCard = memo(_TopClientsCard);
