import React, { FC, memo } from 'react';

import { InputField } from '@packages/uiKit/fields/InputField';
import { Col, Row } from 'antd';
import { Controller, useForm } from 'react-hook-form';

import { StyledMyCompanyForm } from './styles';
import { HalalTradingCompanyInfoValues } from './types';

export interface MyCompanyFormProps {
  className?: string;
  style?: React.CSSProperties;

  initValues: HalalTradingCompanyInfoValues;
}

const _CompanyInformationSectionForm: FC<MyCompanyFormProps> = (props) => {
  const { initValues, ...restProps } = props;

  const { control } = useForm<HalalTradingCompanyInfoValues>({
    defaultValues: initValues,
  });

  const colSpan = 8;

  return (
    <StyledMyCompanyForm {...restProps}>
      <Row gutter={[15, 15]}>
        <Col span={colSpan}>
          <Controller
            control={control}
            name="account"
            render={({ field, fieldState: { error } }) => {
              return (
                <InputField
                  isDisabled
                  label="Тип аккаунта"
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </Col>
        <Col span={colSpan}>
          <Controller
            control={control}
            name="number"
            render={({ field, fieldState: { error } }) => {
              return (
                <InputField
                  isDisabled
                  label="Номер договора"
                  value={field.value?.toString()}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </Col>
        <Col span={colSpan}>
          <Controller
            control={control}
            name="date_from"
            render={({ field, fieldState: { error } }) => {
              return (
                <InputField
                  isDisabled
                  label="Начало работы по договору"
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </Col>
        <Col span={colSpan}>
          <Controller
            control={control}
            name="managerName"
            render={({ field, fieldState: { error } }) => {
              return (
                <InputField
                  isDisabled
                  label="Ответственный менеджер"
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </Col>
        <Col span={colSpan}>
          <Controller
            control={control}
            name="comission"
            render={({ field, fieldState: { error } }) => {
              return (
                <InputField
                  isDisabled
                  label="Комиссия HG"
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
        </Col>
      </Row>
    </StyledMyCompanyForm>
  );
};

export const CompanyInformationSectionForm = memo(
  _CompanyInformationSectionForm,
);
