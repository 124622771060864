import { Select } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledSelectCellWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledSelect = styled(Select).attrs({
  variant: 'compact',
})`
  flex: 1;
  min-width: 0;
` as typeof Select;

export const StyledStatusIconContainer = styled.div`
  border-radius: 50%;
  padding: 5px;
  font-size: 16px;
  height: 28px;
  width: 28px;
  border-width: 1px;
  border-style: solid;
`;

export const StyledIconPlaceholder = styled.div`
  width: 16px;
  height: 16px;
`;
