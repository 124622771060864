import { StyledBookingFormFields } from './BookingFormFields.styles';

import { BookingFormValues } from './types';

import { useUncontrolledFormContext } from '@hooks/useUncontrolledFromContext';
import { InputDateFieldAdapter } from '@packages/uiKit/fieldAdapters/InputDateFieldAdapter';
import { InputFieldAdapter } from '@packages/uiKit/fieldAdapters/InputFieldAdapter';
import { InputNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputNumberFieldAdapter';
import { InputPhoneNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputPhoneNumberFieldAdapter';
import { TextAreaFieldAdapter } from '@packages/uiKit/fieldAdapters/TextAreaFieldAdapter';
import { formatDate } from '@utils/dateFormat';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { FC, memo } from 'react';

const _BookingFormFields: FC = () => {
  const { control } = useUncontrolledFormContext<BookingFormValues>();

  return (
    <StyledBookingFormFields>
      <Row gutter={20}>
        <Col span={12}>
          <InputFieldAdapter
            control={control}
            name="name"
            label="Имя клиента"
          />
        </Col>

        <Col span={12}>
          <InputPhoneNumberFieldAdapter
            control={control}
            name="phone"
            label="Номер телефона"
          />
        </Col>
        <Col span={12}>
          <InputDateFieldAdapter
            control={control}
            name="time"
            label="Время брони"
            showSecond={false}
            showTime
            format={(date) => {
              return formatDate(date.toDate(), 'dateTime');
            }}
          />
        </Col>
        <Col span={12}>
          <InputNumberFieldAdapter
            control={control}
            name="seats"
            label="Кол-во мест"
          />
        </Col>
      </Row>

      <TextAreaFieldAdapter
        control={control}
        name="note"
        label="Комментарий"
        isAutosize
      />
    </StyledBookingFormFields>
  );
};

export const BookingFormFields = memo(_BookingFormFields);
