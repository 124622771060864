import { Section } from '@components/Section';
import styled from 'styled-components';

export const StyledCompanyForm = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr auto;
  grid-template-areas:
    'requisites certificate document'
    'buttons    buttons     buttons';

  grid-row-gap: 40px;
  grid-column-gap: 60px;

  & > * {
    min-width: 0;
  }
`;

export const StyledRequisitesSection = styled(Section)`
  grid-area: requisites;
`;

export const StyledCertificateSection = styled(Section)`
  grid-area: certificate;
`;

export const StyledDocumentSection = styled(Section)`
  grid-area: document;
`;

export const StyledButtonsArea = styled.div`
  grid-area: buttons;
  display: flex;
  gap: 15px;
`;
