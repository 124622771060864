import { StyledRatingLabel } from './RatingLabel.styles';

import { StarFilled, StarOutlined } from '@ant-design/icons';
import React, { FC } from 'react';

export interface RatingLabelProps {
  className?: string;
  style?: React.CSSProperties;
  rating: number;
}

const breakpoints = [0.5, 1.5, 2.5, 3.5, 4.5];

export const RatingLabel: FC<RatingLabelProps> = (props) => {
  const { rating, ...restProps } = props;

  return (
    <StyledRatingLabel rating={rating} {...restProps}>
      {breakpoints.map((br) => {
        if (br <= rating) {
          return <StarFilled key={br} size={14} />;
        } else {
          return <StarOutlined key={br} size={14} />;
        }
      })}
    </StyledRatingLabel>
  );
};
