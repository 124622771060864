import { colors, Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledTitle = styled(Typography).attrs({
  weight: 700,
  size: 12,
})``;

const StyledTableCell = styled.div`
  padding: 10px 0;
`;

export const StyledTitleCell = styled(StyledTableCell)`
  width: 150px;
`;

export const StyledQuantity = styled(StyledTableCell)`
  width: 50px;
`;

export const StyledPrice = styled(StyledTableCell)`
  width: 50px;
`;

export const StyledItems = styled.div`
  display: flex;
  gap: 8px;

  font-size: 12px;

  :not(:last-child) {
    ${StyledTableCell} {
      border-bottom: 1px solid ${colors.gray2};
    }
  }
`;
