import { getColumns, GetColumnsParams, getRowId, getRowProps } from './utils';

import { HalalShopFinanceRecord } from '@features/halalShop/finances/types';
import { Table, TableProps } from '@packages/uiKit';
import { FC, memo } from 'react';

export type FinancesTableProps = Omit<
  TableProps<HalalShopFinanceRecord>,
  'columns' | 'getRowId' | 'getRowProps'
> &
  GetColumnsParams;

const columns = getColumns({});

const _FinancesTable: FC<FinancesTableProps> = (props) => {
  const { ...restProps } = props;

  return (
    <Table
      columns={columns}
      getRowId={getRowId}
      getRowProps={getRowProps}
      {...restProps}
    />
  );
};

export const FinancesTable = memo(_FinancesTable);
