import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';
import { halalTradingEndpoints } from '@features/halalTrading/store/apiService';
import { FC, memo } from 'react';
import { CompanyInformationSectionForm } from './CompanyInformationSectionForm';
import { getInitCompanyInfoValues } from './CompanyInformationSectionForm/utils/getInitCompanyInfoValues';
import { StyledCompanyInformationSection } from './styles';

const _CompanyInformationSection: FC = () => {
  const { data, isSuccess, isLoading } =
    halalTradingEndpoints.useGetProviderQuery({});

  const initValues = getInitCompanyInfoValues(data);

  return (
    <StyledCompanyInformationSection>
      {isLoading ? (
        <Loader />
      ) : isSuccess ? (
        <CompanyInformationSectionForm initValues={initValues} />
      ) : (
        <TextPlaceholder variant="error" />
      )}
    </StyledCompanyInformationSection>
  );
};

export const CompanyInformationSection = memo(_CompanyInformationSection);
