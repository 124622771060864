import {
  DropdownItem,
  StyledButton,
  StyledDropdown,
  StyledLabel,
  StyledPlaceholder,
  StyledSelectContainer,
} from './Select.styles';
import { SelectOption, SelectValue } from './types';

import { OutsideClick } from '@packages/uiKit/OutsideClick';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { usePopper } from 'react-popper';

export interface SelectProps<ValueType extends SelectValue = SelectValue> {
  options: SelectOption<ValueType>[];
  value?: ValueType | null;
  onChange?: (value: ValueType) => void;
  placeholder?: string;
  variant?: 'default' | 'filled' | 'compact';
  heightDropdown?: number;

  className?: string;
  styles?: React.CSSProperties;
}

const _Select = <ValueType extends SelectValue>(
  props: SelectProps<ValueType>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    options,
    value,
    onChange,
    placeholder,
    heightDropdown,
    variant = 'default',
    ...restProps
  } = props;

  const [isVisible, setIsVisible] = useState(false);

  const selectedOption = useMemo(() => {
    return value !== undefined
      ? options.find((opt) => opt.value === value)
      : undefined;
  }, [options, value]);

  const handleClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  const onButtonClickHandler = useCallback(() => {
    setIsVisible((prev) => !prev);
  }, []);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  return (
    <StyledSelectContainer ref={ref} {...restProps}>
      <OutsideClick isEnabled={isVisible} onClickOutside={handleClose}>
        <StyledButton
          ref={setReferenceElement}
          onClick={onButtonClickHandler}
          $variant={variant}
          $isVisible={isVisible}>
          {selectedOption ? (
            <StyledLabel>{selectedOption.label}</StyledLabel>
          ) : (
            <StyledPlaceholder>{placeholder}</StyledPlaceholder>
          )}
        </StyledButton>
        {isVisible && (
          <StyledDropdown
            $height={heightDropdown}
            ref={setPopperElement}
            $variant={variant}
            style={styles.popper}
            {...attributes.popper}>
            {options.length > 0
              ? options.map((option) => {
                  const isSelected = selectedOption
                    ? selectedOption.value === option.value
                    : false;
                  const onClickHandler =
                    onChange && !isSelected
                      ? () => {
                          onChange(option.value);
                          setIsVisible(false);
                        }
                      : undefined;
                  return (
                    <DropdownItem
                      key={option.value}
                      $isSelected={isSelected}
                      onClick={onClickHandler}
                      $variant={variant}>
                      {option.label}
                    </DropdownItem>
                  );
                })
              : 'Нет опций'}
          </StyledDropdown>
        )}
      </OutsideClick>
    </StyledSelectContainer>
  );
};

const SelectMemo = forwardRef(_Select);

SelectMemo.displayName = 'Select';

export const Select = SelectMemo as <T extends SelectValue>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof _Select>;
