import {
  StyledBookingTable,
  StyledScrollXContainer,
} from './BookingTable.styles';

import { getColumns, GetColumnsParams } from './utils';

import { TextPlaceholder } from '@components/TextPlaceholder';

import { BookingRecord } from '@features/booking/store/types';
import { Pagination, PaginationProps, Table } from '@packages/uiKit';
import React, { FC, useMemo } from 'react';

export type BookingTableProps = {
  className?: string;
  style?: React.CSSProperties;
  data: BookingRecord[];
  pagination: PaginationProps;
} & GetColumnsParams;

export const BookingTable: FC<BookingTableProps> = (props) => {
  const { data, pagination, onEditClick, onEditStatus, ...restProps } = props;

  const columns = useMemo(() => {
    return getColumns({ onEditClick, onEditStatus });
  }, [onEditClick, onEditStatus]);

  return (
    <StyledBookingTable {...restProps}>
      {data.length === 0 ? (
        <TextPlaceholder variant="noData" />
      ) : (
        <>
          <StyledScrollXContainer>
            <Table columns={columns} data={data} />
            {/* <TableDivider /> */}
            {/* <Table columns={columns} data={data} /> */}
          </StyledScrollXContainer>
          <Pagination {...pagination} />
        </>
      )}
    </StyledBookingTable>
  );
};
