import {
  StyledLegendDesc,
  StyledLegendItem,
  StyledLegendLine,
  StyledLegendValue,
} from './LegendItem.styles';

import React, { FC, ReactNode } from 'react';

export interface LegendItemProps {
  className?: string;
  style?: React.CSSProperties;
  value: ReactNode;
  color: string;
  description: ReactNode;
}

export const LegendItem: FC<LegendItemProps> = (props) => {
  const { value, color, description, ...restProps } = props;

  return (
    <StyledLegendItem {...restProps}>
      <StyledLegendValue>{value}</StyledLegendValue>
      <StyledLegendLine style={{ backgroundColor: color }} />
      <StyledLegendDesc>{description}</StyledLegendDesc>
    </StyledLegendItem>
  );
};
