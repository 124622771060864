import styled from 'styled-components';

export const StyledCropperModalContenet = styled.div``;
export const StyledCropperContainer = styled.div`
  margin-bottom: 30px;
`;

export const StyledButtons = styled.div`
  display: flex;
  gap: 15px;
`;
