import { StyledCard } from './Card.styles';

import React, { FC, PropsWithChildren } from 'react';

export interface CardProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
}

export const Card: FC<CardProps> = (props) => {
  const { ...restProps } = props;

  return <StyledCard {...restProps} />;
};
