import { HalalShopCartItemRow } from '@features/halalShop/orders/components/HalalShopCartItemRow';
import { colors, Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 18px;
  border-bottom: 1px solid ${colors.gray7};
  margin-bottom: 15px;
`;

export const StyledTitle = styled(Typography).attrs({
  size: 22,
  weight: 700,
})``;

export const StyledHalalShopCartItemRow = styled(HalalShopCartItemRow)`
  padding: 16px 0;
`;

export const StyledInfoTextItem = styled.div`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  gap: 8px;
`;

export const StyledInfoTextItemLabel = styled(Typography)``;
export const StyledInfoTextItemValue = styled(Typography)``;

export const StyledInfoSummaryItemLabel = styled(Typography).attrs({
  weight: 700,
  size: 18,
})``;
export const StyledInfoSummaryItemValue = styled(Typography).attrs({
  weight: 700,
  size: 18,
})``;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;
