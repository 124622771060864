import {
  SelectCellWithIcon,
  SelectCellWithIconConfig,
  SelectCellWithIconProps,
} from '@components/table';
import { HalalShopOrderStatus } from '@features/halalShop/store/types';

import {
  AirplaneTiltIcon,
  AlarmIcon,
  CheckCircleOutlinedIcon,
  PackageIcon,
  ProhibitIcon,
} from '@packages/icons';
import { colors } from '@packages/uiKit';
import { FC, memo, useCallback } from 'react';

export interface OrderStatusCellProps
  extends Omit<
    SelectCellWithIconProps<HalalShopOrderStatus>,
    'config' | 'value'
  > {
  status: HalalShopOrderStatus;
  onChange: (value: HalalShopOrderStatus) => void;
}

const config: SelectCellWithIconConfig<HalalShopOrderStatus> = [
  {
    value: HalalShopOrderStatus.New,
    label: 'Новый',
    bgColor: colors.red1,
    color: colors.white,
    icon: <AlarmIcon />,
  },
  {
    value: HalalShopOrderStatus.Assembly,
    label: 'Сборка',
    bgColor: colors.primary,
    color: colors.white,
    icon: <PackageIcon />,
  },
  {
    value: HalalShopOrderStatus.AwaitingShipment,
    label: 'Ожидает отправки',
    bgColor: colors.primary,
    color: colors.white,
    icon: <PackageIcon />,
  },
  {
    value: HalalShopOrderStatus.Delivering,
    label: 'Доставляется',
    bgColor: colors.dark1,
    color: colors.white,
    icon: <AirplaneTiltIcon />,
  },
  {
    value: HalalShopOrderStatus.Canceled,
    label: 'Отменен',
    bgColor: colors.gray6,
    color: colors.white,
    icon: <ProhibitIcon />,
  },
  {
    value: HalalShopOrderStatus.Delivered,
    label: 'Доставлен',
    borderColor: colors.gray1,
    color: colors.gray1,
    icon: <CheckCircleOutlinedIcon />,
  },
];

const _OrderStatusCell: FC<OrderStatusCellProps> = (props) => {
  const { status, onChange, ...restProps } = props;

  const clickHandler: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
    },
    [],
  );

  return (
    <SelectCellWithIcon
      config={config}
      value={status}
      onClick={clickHandler}
      onChange={onChange}
      {...restProps}
    />
  );
};

export const OrderStatusCell = memo(_OrderStatusCell);
