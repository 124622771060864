import { Button } from '../Button';

import { DotsThreeCirclesNoPadding } from '@packages/icons';
import styled from 'styled-components';

export const StyledDropdownIconButton = styled.div``;

export const StyledButton = styled(Button).attrs({
  size: 'small',
  color: 'primary',
  variant: 'text',
  icon: <DotsThreeCirclesNoPadding size={14} />,
})`
  padding: 0px;
  border-width: 0;
`;

export const StyledDropdown = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 8px 10px;
  box-shadow: 4px 4px 34px rgba(0, 0, 0, 0.14);
`;
