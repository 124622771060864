import { Input } from '../Input/Input';

import styled from 'styled-components';

export const StyledInputNumber = styled(Input)`
  /*Hide arrows */

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  -moz-appearance: textfield; /* Firefox */
`;
