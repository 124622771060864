import React, { FC, PropsWithChildren, memo } from 'react';

import { StyledSidebar } from './styles';

export interface SidebarProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;

  width?: number;
}

const _Sidebar: FC<SidebarProps> = (props) => {
  const { children, width = 400, ...restProps } = props;

  return (
    <StyledSidebar $width={width} {...restProps}>
      {children}
    </StyledSidebar>
  );
};

export const Sidebar = memo(_Sidebar);
