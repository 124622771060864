import { GetAnalyticsRequest, GetAnalyticsResponse } from './types';

import { getQueryWithNoRefresh } from '@features/queryClient';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const ANALYTICS_TAG = 'ANALYTICS_TAG' as const;

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'analytics' }),
  tagTypes: [ANALYTICS_TAG],

  endpoints: (builder) => {
    return {
      getAnalytics: builder.query<GetAnalyticsResponse, GetAnalyticsRequest>({
        providesTags: () => [ANALYTICS_TAG],
        query: (params) => ({
          url: '/',
          method: 'GET',
          params,
        }),
      }),
    };
  },
});
