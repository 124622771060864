import {
  StyledButton,
  StyledContentContainer,
  StyledToDetailCell,
} from './styles';

import { FC, PropsWithChildren } from 'react';

export interface ToDetailCellProps extends PropsWithChildren {
  onClickToDetail?: () => void;
}

export const ToDetailCell: FC<ToDetailCellProps> = (props) => {
  const { children, onClickToDetail } = props;

  return (
    <StyledToDetailCell>
      <StyledContentContainer>{children}</StyledContentContainer>
      <StyledButton onClick={onClickToDetail} />
    </StyledToDetailCell>
  );
};
