import {
  DeliveryRecord,
  HalalShopOrderStatus,
} from '@features/halalShop/store/types';
import { useHalalShopOrderForm } from './hooks';
import { StyledHalalShopOrderForm } from './styles';

import { HalalShopOrderFormValues } from './types';

import React, { FC, memo, useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { Main } from './Main';
import { Sidebar } from './Sidebar';

export type HalalShopOrderFormProps = {
  className?: string;
  style?: React.CSSProperties;

  initialValues: HalalShopOrderFormValues;

  deliveryData: DeliveryRecord;
  orderStatus: HalalShopOrderStatus;
};

const _HalalShopOrderForm: FC<HalalShopOrderFormProps> = (props) => {
  const {
    initialValues,
    deliveryData,
    orderStatus,

    ...restProps
  } = props;

  const form = useHalalShopOrderForm({
    initialValues,
    status: orderStatus,
  });

  const changeStatus = useCallback((status: HalalShopOrderStatus) => {
    console.log('change status', status);
  }, []);
  const save = useCallback((data: HalalShopOrderFormValues) => {
    console.log('save', data);
  }, []);

  const saveHandler = form.handleSubmit(async (data) => {
    await save(data);
  });

  const cancelHandler = form.handleSubmit(async (data) => {
    await save(data);
    await changeStatus(HalalShopOrderStatus.Canceled);
  });

  const confirmHandler = form.handleSubmit(async (data) => {
    await save(data);
    await changeStatus(HalalShopOrderStatus.Assembly);
  });

  const shippingConfirmHandler = form.handleSubmit(async (data) => {
    await save(data);
  });

  return (
    <StyledHalalShopOrderForm {...restProps}>
      <FormProvider {...form}>
        <Main deliveryData={deliveryData} orderStatus={orderStatus} />

        <Sidebar
          onCancel={cancelHandler}
          orderStatus={orderStatus}
          onConfirm={confirmHandler}
          onSave={saveHandler}
          onShippingConfirm={shippingConfirmHandler}
        />
      </FormProvider>
    </StyledHalalShopOrderForm>
  );
};

export const HalalShopOrderForm = memo(_HalalShopOrderForm);
