import {
  StyledInvisibleInput,
  StyledUploadButton,
  StyledUploadButtonContainer,
} from './UploadButton.styles';

import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';

export type UploadButtonProps = PropsWithChildren<{
  className?: string;
  styles?: React.CSSProperties;

  accept?: string;

  onUpload?: (file: File, files: FileList) => void;
  multiple?: boolean;

  color?: 'white' | 'secondary';
}>;

export const UploadButton: FC<UploadButtonProps> = (props) => {
  const {
    children,
    onUpload,
    multiple,
    accept,
    color = 'white',
    ...restProps
  } = props;

  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);

  const onClickHandler = useCallback(() => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  }, []);

  const onChangeHandler:
    | React.ChangeEventHandler<HTMLInputElement>
    | undefined = useMemo(() => {
    return onUpload
      ? (event) => {
          const files = event.target.files;

          if (files) {
            const fileUploaded = files[0];
            onUpload(fileUploaded, files);
          }
        }
      : undefined;
  }, [onUpload]);

  return (
    <StyledUploadButtonContainer {...restProps}>
      <StyledUploadButton color={color} onClick={onClickHandler}>
        {children}
      </StyledUploadButton>
      <StyledInvisibleInput
        type="file"
        ref={hiddenFileInput}
        onChange={onChangeHandler}
        multiple={multiple}
        accept={accept}
      />
    </StyledUploadButtonContainer>
  );
};
