import {
  GetEstablishmentResponse,
  UpdateEstablishmentResponseRecord,
} from './types';

import { ApiRecordId } from '@features/api';
import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const ESTABLISHMENT_BASE_TAG = 'ESTABLISHMENT' as const;

export const DETAIL_TAG_ESTABLISHMENT = {
  type: ESTABLISHMENT_BASE_TAG,
  id: 'detail',
};

export const establishmentApi = createApi({
  reducerPath: 'establishmentApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'establishment' }),
  tagTypes: [ESTABLISHMENT_BASE_TAG],
  endpoints: (builder) => {
    return {
      getEstablishment: builder.query<GetEstablishmentResponse, {}>({
        providesTags: [DETAIL_TAG_ESTABLISHMENT],
        query: () => ({
          url: '/',
          method: 'GET',
        }),
      }),

      putEstablishment: builder.mutation<
        void,
        UpdateEstablishmentResponseRecord
      >({
        invalidatesTags: [DETAIL_TAG_ESTABLISHMENT],
        query: (data) => ({
          url: '/',
          method: 'PUT',
          body: data,
        }),
      }),

      putEstablishmentPhoto: builder.mutation<void, File | null>({
        invalidatesTags: [DETAIL_TAG_ESTABLISHMENT],
        query: (data: File | null) => {
          const formData = new FormData();

          if (data) {
            formData.append('photo', data);
          }

          return {
            url: '/photo/',
            method: 'PUT',
            body: formData,
          };
        },
      }),
      putEstablishmentLogo: builder.mutation<void, File | null>({
        invalidatesTags: [DETAIL_TAG_ESTABLISHMENT],
        query: (data: File | null) => {
          const formData = new FormData();
          if (data) {
            formData.append('logo', data);
          }

          return {
            url: '/logo/',
            method: 'PUT',
            body: formData,
          };
        },
      }),
      putEstablishmentPlacePhoto: builder.mutation<
        void,
        (File | ApiRecordId)[]
      >({
        invalidatesTags: [DETAIL_TAG_ESTABLISHMENT],
        query: (data) => {
          const formData = new FormData();
          data.forEach((photo) => {
            const value = photo instanceof File ? photo : photo.toString();
            formData.append('photos', value);
          });

          return {
            url: '/placePhotos/',
            method: 'PUT',
            body: formData,
          };
        },
      }),
    };
  },
});
