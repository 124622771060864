import { MainLayout } from '@layouts/MainLayout';
import { colors, Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledOrderSidebar = styled(MainLayout.Sidebar)``;

export const StyledSidebarContent = styled.div`
  border-top: 1px solid ${colors.gray2};
  padding-top: 13px;
`;

export const StyledBonusControl = styled.div`
  padding: 6.4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  min-height: 36.2px;

  border-bottom: 1px solid ${colors.gray1};
`;

export const StyledOrderItemsTitle = styled(Typography).attrs({
  size: 22,
  weight: 300,
})`
  padding-bottom: 13px;
  border-bottom: 1px solid ${colors.gray2};
`;

export const StyledInfoListItem = styled.div``;

export const StyledInfoList = styled.div`
  ${StyledInfoListItem}:not(:last-child) {
    border-bottom: 1px solid ${colors.gray2};
  }
`;

export const StyledInfoTextItem = styled(StyledInfoListItem)`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  gap: 8px;
`;

export const StyledInfoTextItemLabel = styled(Typography)``;
export const StyledInfoTextItemValue = styled(Typography)``;

export const StyledInfoSummaryItem = styled(StyledInfoListItem)`
  display: flex;
  padding: 12px 0;
  justify-content: space-between;
  gap: 8px;
`;

export const StyledInfoSummaryItemLabel = styled(Typography).attrs({
  weight: 700,
  size: 18,
})``;
export const StyledInfoSummaryItemValue = styled(Typography).attrs({
  weight: 700,
  size: 18,
})``;

export const StyledInfoOrderItem = styled(StyledInfoListItem)`
  padding: 15px 0;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;
