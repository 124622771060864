import {
  EditMenuPositionFormValidValues,
  EditMenuPositionFormValues,
} from './types';

import { fileValidation } from '@features/forms/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useUncontrolledForm,
  UseUncontrolledFormParams,
} from '@hooks/useUncontrolledForm';
import { useCallback } from 'react';
import * as yup from 'yup';

export const validationSchema: yup.SchemaOf<
  Omit<EditMenuPositionFormValues, 'image'>
> = yup
  .object({
    name: yup
      .string()
      .required('Заполните поле название блюда')
      .max(60, 'Максимальное кол-во символов - 60'),
    weight: yup.number().nullable().required('Заполните поле вес блюда · Гр'),
    cost: yup.number().nullable().required('Заполните поле стоимость · Рубли'),
    category: yup.number().nullable().required('Выберите категорию блюда'),
    ingredients: yup
      .string()
      .required('Заполните поле состав блюда')
      .max(200, 'Максимальное кол-во символов - 200'),
    image: fileValidation({
      required: true,
      requiredMessage: 'Необходимо загрузить фото блюда',
    }),
  })
  .required();

export type UseEditMenuPositionFormParams = {
  initValues: EditMenuPositionFormValues;
  onSubmit: (
    data: EditMenuPositionFormValidValues,
    isChangeImage: boolean,
  ) => Promise<void>;
};
export const useEditMenuPositionForm = (
  params: UseEditMenuPositionFormParams,
) => {
  const { initValues, onSubmit } = params;

  const submitHandler: UseUncontrolledFormParams<
    EditMenuPositionFormValues,
    EditMenuPositionFormValidValues
  >['onSubmit'] = useCallback(
    async (data, formState) => {
      const isEditedImage = Boolean(formState.dirtyFields.image);

      await onSubmit(data, isEditedImage);
    },
    [onSubmit],
  );

  return useUncontrolledForm<
    EditMenuPositionFormValues,
    EditMenuPositionFormValidValues
  >({
    defaultValues: initValues,
    onSubmit: submitHandler,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
};
