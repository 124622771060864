import {
  emailValidation,
  fileValidation,
  phoneValidation,
  urlValidation,
} from '@features/forms/validation';
import * as yup from 'yup';

export const establishmentFormSchema =
  // : yup.SchemaOf<
  //   Omit<EstablishmentEditFormValues, 'workTime' | 'logo' | 'mainPhoto'>
  // >
  yup
    .object({
      name: yup.string().required('Введите название заведения'),
      logo: fileValidation({
        // maxResolution: {
        //   height: 875,
        //   width: 650,
        // },
        mime: ['image/jpeg'],
      }),
      // shortDescription: yup.string().required(),
      // category: yup.string().nullable().optional(),
      // fullDescription: yup.string().required(),
      tel: phoneValidation().required('Введите номер'),
      mail: emailValidation(),
      site: urlValidation(),
      // email: yup
      //   .string()
      //   .email('Неверный формат email')
      //   .required('Заполните поле электронная почта'),

      // mainPhoto: yup
      //   .mixed()
      //   .nullable()
      //   .required('Необходимо выбрать основное фото'),
      // logo: yup.mixed().required('Необходимо выбрать логотип заведения'),
      // photoGallery: yup.array().of(yup.string().required()),
      // workTime: yup.object(),
      // isSmokingAllowed: yup.boolean().required(),
      // isWiFiAvailable: yup.boolean().required(),
      // isAlcoholPresent: yup.boolean().required(),
      // isPaymentByBankCard: yup.boolean().required(),
      // isParkingAvailable: yup.boolean().required(),
      // isTableReservation: yup.boolean().required(),
      // isHalalCertificate: yup.boolean().required(),
      // cuisines: yup.array().of(yup.string().required()),
      // averageCheck: yup.number().nullable().required(),
      // currency: yup.string().required(),
    })
    .required();
