import styled from 'styled-components';

export const StyledContentHeader = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 54px;
`;

export const StyledContentTitle = styled.h1`
  font-size: 28px;
  font-weight: 300;
`;

export const StyledRightContainer = styled.div``;
