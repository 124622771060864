import { Card } from '@components/Card/Card';
import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledInfoCard = styled(Card)`
  display: flex;
  gap: 16px;
  height: fit-content;
`;

export const StyledInfo = styled.div`
  flex: 1;

  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const StyledHead = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledMainValue = styled(Typography).attrs({
  size: 22,
  weight: 700,
  nowrap: true,
})``;

export const StyledDescription = styled(Typography).attrs({
  size: 14,
  weight: 400,
  ellipsis: true,
  lines: 3,
  maxLines: 2,
})`
  padding-right: 25px;
  min-height: 35px;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 36px;
`;
