import {
  StyledContent,
  StyledGraphWrapper,
  StyledSearchesAndRequestDynamicCard,
  StyledSummaryWrapper,
} from './SearchesAndRequestDynamicCard.styles';

import { LegendItem } from '../common/LegendItem';

import { Column, ColumnConfig } from '@ant-design/plots';
import { GetAnalyticsResponse } from '@features/analytics/store';
import { colors } from '@packages/uiKit';
import React, { FC, memo } from 'react';

export interface SearchesAndRequestDynamicCardProps {
  className?: string;
  style?: React.CSSProperties;

  analyticsData: GetAnalyticsResponse;
}

type DataItem = {
  period: string;
  value: number;
  type: 'allSearches' | 'unicSearches' | 'siteRequestions';
};

// const dataMock: DataItem[] = [
//   {
//     period: 'Ноя. 2021',
//     value: 50,
//     type: 'allSearches',
//   },
//   {
//     period: 'Ноя. 2021',
//     value: 20,
//     type: 'unicSearches',
//   },
//   {
//     period: 'Ноя. 2021',
//     value: 5,
//     type: 'siteRequestions',
//   },

//   {
//     period: 'Дек. 2021',
//     value: 100,
//     type: 'allSearches',
//   },
//   {
//     period: 'Дек. 2021',
//     value: 40,
//     type: 'unicSearches',
//   },
//   {
//     period: 'Дек. 2021',
//     value: 10,
//     type: 'siteRequestions',
//   },

//   {
//     period: 'Янв. 2022',
//     value: 80,
//     type: 'allSearches',
//   },
//   {
//     period: 'Янв. 2022',
//     value: 30,
//     type: 'unicSearches',
//   },
//   {
//     period: 'Янв. 2022',
//     value: 7,
//     type: 'siteRequestions',
//   },
// ];

const _SearchesAndRequestDynamicCard: FC<SearchesAndRequestDynamicCardProps> = (
  props,
) => {
  const { analyticsData, ...restProps } = props;

  const data = Array.from({ length: 1 })
    .map(() => {
      return [
        {
          period: 'Все время',
          value: analyticsData.total_hits,
          type: 'allSearches',
        },
        {
          period: 'Просмотров заведения на HalalGuide',
          value: analyticsData.web_hits,
          type: 'siteRequestions',
        },
        {
          period: 'Просмотрено через приложение',
          value: analyticsData.hits,
          type: 'unicSearches',
        },
        {
          period: 'Просмотров заведения в Instagram',
          value: analyticsData.instagram_hits,
          type: 'instagramSearches',
        },
      ];
    })
    .flat();

  const config: ColumnConfig = {
    data,
    isGroup: true,
    height: 250,
    color: [colors.primaryLight, colors.primaryDark3, colors.primary],
    xField: 'period',
    yField: 'value',
    seriesField: 'type',
    legend: false as const,
    meta: {
      type: {
        formatter: (value: DataItem['type']) =>
          value === 'allSearches'
            ? 'Всего просмотров'
            : value === 'unicSearches'
            ? 'Просмотры через моб. устройства'
            : 'Просмотры через сайт',
      },
    },
  };

  return (
    <StyledSearchesAndRequestDynamicCard
      title="Динамика просмотров и заявок"
      {...restProps}>
      <StyledContent>
        <StyledGraphWrapper>
          <Column {...config} />
        </StyledGraphWrapper>
        <StyledSummaryWrapper>
          <LegendItem
            value={analyticsData.total_hits}
            color={colors.primaryDark3}
            description="Всего просмотров"
          />
          <LegendItem
            value={analyticsData.hits}
            color={colors.primaryLight}
            description="Просмотры через моб. устройства"
          />
          <LegendItem
            value={analyticsData.web_hits}
            color={colors.primary}
            description="Просмотры через сайт"
          />
        </StyledSummaryWrapper>
      </StyledContent>
    </StyledSearchesAndRequestDynamicCard>
  );
};

export const SearchesAndRequestDynamicCard = memo(
  _SearchesAndRequestDynamicCard,
);
