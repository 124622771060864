import { MainLayout } from '@layouts/MainLayout';
import styled from 'styled-components';

export const StyledSidebarForm = styled(MainLayout.Sidebar)`
  min-width: 500px;
`;

export const StyledContent = styled.div``;
export const StyledSwitchListContainer = styled.div`
  margin-bottom: 32px;
`;
