import { ReactComponent as DeleteIcon } from '@packages/icons/assets/svgs/close.svg';
import { Typography } from '@packages/uiKit/Typography';

import { colors } from '@packages/uiKit/utils/colors';
import styled from 'styled-components';

export const StyledMultiselect = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 6px;
  border-bottom: 1px solid ${colors.gray1};
`;

export const StyledSelectedList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const StyledSelectItem = styled(Typography).attrs({
  size: 12,
  forwardedAs: 'div',
})`
  border-radius: 25px;
  font-size: 12px;
  padding: 2px 8px 2px 20px;
  border: 1px solid ${colors.gray3};
  display: flex;
  align-items: center;
`;

export const StyledSelectLabel = styled(Typography).attrs({ size: 12 })``;

export const StyledSelectedDelete = styled.span`
  padding-right: 5px;
  margin-top: 3.5px;
  margin-left: 15px;
  cursor: pointer;
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  width: 16px;
  height: 16px;
`;
