import React, { FC, memo, useState } from 'react';

import { citiesApi } from '@features/cities/store';
import { Modal } from '@packages/uiKit';
import { InputField } from '@packages/uiKit/fields/InputField';
import { StyledButton, StyledCitiesModal, StyledRadioGroup } from './styles';

export interface CitiesModalProps {
  className?: string;
  style?: React.CSSProperties;

  isOpen: boolean;
  onClose: () => void;
}

const _CitiesModal: FC<CitiesModalProps> = (props) => {
  const { isOpen, onClose, ...restProps } = props;

  const [text, setText] = useState('');

  const { data, isSuccess } = citiesApi.useGetCitiesQuery({
    limit: 12,
    name: text ?? undefined,
    country_id: 1,
  });

  console.log(data);

  const radioOptions =
    isSuccess &&
    data.map((item) => {
      return { value: item.id, label: item.name };
    });

  const [selectedCity, setSelectedCity] = useState<string | number>();

  return (
    <StyledCitiesModal {...restProps}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Выберите город"
        width={650}>
        <InputField
          value={text}
          onChange={setText}
          label="Введите название города"
        />
        <StyledRadioGroup
          options={radioOptions ? radioOptions : []}
          value={selectedCity}
          onChange={setSelectedCity}
          cols={3}
        />
        <StyledButton size="large" onClick={onClose}>
          Выбрать
        </StyledButton>
      </Modal>
    </StyledCitiesModal>
  );
};

export const CitiesModal = memo(_CitiesModal);
