import { menuCategoriesApi } from '@features/menuCategories';
import { SelectOption } from '@packages/uiKit';
import {
  SelectFieldAdapter,
  SelectFieldAdapterProps,
} from '@packages/uiKit/fieldAdapters/SelectFieldAdapter';
import { memo, useMemo } from 'react';
import { FieldValues, Path } from 'react-hook-form';

export interface MenuCategorySelectFieldAdapterProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> extends Omit<SelectFieldAdapterProps<TFieldValues, TName>, 'options'> {}

const _MenuCategorySelectFieldAdapter = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>(
  props: MenuCategorySelectFieldAdapterProps<TFieldValues, TName>,
) => {
  const { data } = menuCategoriesApi.useGetMenuCategoriesListQuery();

  const options: SelectOption<number>[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((certCenter) => ({
      value: certCenter.id,
      label: certCenter.name,
    }));
  }, [data]);

  return <SelectFieldAdapter options={options} {...props} />;
};

export const MenuCategorySelectFieldAdapter = memo(
  _MenuCategorySelectFieldAdapter,
) as typeof _MenuCategorySelectFieldAdapter;
