import { StyledSidebarHeader } from './SidebarHeader.styles';

import React, { FC, PropsWithChildren } from 'react';

export interface SidebarHeaderProps extends PropsWithChildren {
  className?: string;
  styles?: React.CSSProperties;
}

export const SidebarHeader: FC<SidebarHeaderProps> = (props) => {
  const { ...restProps } = props;

  return <StyledSidebarHeader {...restProps} />;
};
