import { Card } from '@components/Card/Card';
import { FireIcon, HandbagIcon, TargetIcon } from '@packages/icons';
import { Image, Typography, colors } from '@packages/uiKit';
import { UploadImageInputField } from '@packages/uiKit/fields/UploadImageInputField';
import styled, { css } from 'styled-components';

export const StyledStockPositionCard = styled(Card)`
  margin-top: 25px;
`;

export const StyledStockPositionCardWrapper = styled.div`
  margin-top: 15px;
`;

export const StyledActiveSwitchTitle = styled(Typography).attrs({
  size: 12,
  color: 'gray1',
})`
  margin-left: 10px;
`;

export const StyledSwitcher = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledUploadImageInputField = styled(UploadImageInputField)``;

export const StyledPreviewWrapper = styled.div`
  position: relative;
  min-height: 100%;
`;

export const StyledImage = styled(Image)``;

export const StyledPreviewImage = styled(Image)<{
  src: string;
}>`
  width: 100%;
  height: 180px;
  background-size: cover;
  border-radius: 34px;
  ${({ src }) =>
    src &&
    css`
      background-image: linear-gradient(
          81.47deg,
          rgba(23, 23, 26, 0.72) 4.43%,
          rgba(23, 23, 26, 0) 100.91%
        ),
        url(${src});
    `};
`;

export const StyledPreviewTextWrapped = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 21px 23px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledPreviewTitle = styled(Typography).attrs({
  size: 28,
  weight: 700,
  color: 'white',
  ellipsis: true,
})`
  min-height: 0;
`;

export const StyledPreviewTip = styled(Typography).attrs({
  size: 14,
  color: 'white',
  ellipsis: true,
  nowrap: true,
})`
  align-self: flex-start;
  width: fit-content;
  max-width: 100%;
  padding: 6px 8px;
  margin-top: 10px;
  gap: 18px;
  background-color: ${colors.primary};
  border-radius: 15px;
`;

export const StyledFireIcon = styled(FireIcon)`
  margin-right: 8px;
`;

export const StyledStatsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: fit-content;
  background-color: ${colors.rootBackground};
  gap: 11px;
  height: 100%;
  width: 100%;
`;

export const StyledTargetIcon = styled(TargetIcon)`
  font-size: 28px;
  color: ${colors.primary};
`;

export const StyledStatsCardAmount = styled(Typography).attrs({
  size: 32,
  weight: 700,
  ellipsis: true,
})``;

export const StyledStatsCardAmountInfo = styled(Typography).attrs({
  size: 14,
  ellipsis: true,
})`
  padding-bottom: 12px;
`;

export const StyledHandbagIcon = styled(HandbagIcon)`
  font-size: 28px;
  font-weight: 300;
  color: ${colors.primary};
`;
