import { StyledLight, StyledStrong } from './LegendItem.styles';

import {
  LegendItem as Li,
  LegendItemProps as LiProps,
} from '@features/analytics/components/common/LegendItem';
import { getNoun } from '@utils/getNoun';

import { FC, memo } from 'react';

export interface LegendItemProps
  extends Pick<LiProps, 'color' | 'className' | 'style'> {
  percent: number;
  amount: number;
  orderCount: number;
  description: string;
}

const _LegendItem: FC<LegendItemProps> = (props) => {
  const { percent, amount, orderCount, description, ...restProps } = props;

  return (
    <Li
      value={
        <>
          <StyledStrong>{percent * 100}% · </StyledStrong>
          <StyledLight>{amount.toLocaleString()} ₸</StyledLight>
        </>
      }
      description={
        <>
          <StyledLight>{description}</StyledLight>
          <StyledLight>
            · {orderCount} {getNoun(orderCount, 'заказ', 'заказа', 'заказов')}
          </StyledLight>
        </>
      }
      {...restProps}></Li>
  );
};

export const LegendItem = memo(_LegendItem);
