import { HalalShopOrderStatus } from '@features/halalShop/store/types';

const needPostCodeStatuses: HalalShopOrderStatus[] = [
  HalalShopOrderStatus.AwaitingShipment,
  HalalShopOrderStatus.Delivering,
  HalalShopOrderStatus.Delivered,
];

export const isNeedPostCode = (status: HalalShopOrderStatus) =>
  needPostCodeStatuses.includes(status);
