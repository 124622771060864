import { StyledControlContainer, StyledValueLabel } from './SwitchField.styles';

import { Switch, SwitchProps } from '@packages/uiKit';
import { Field } from '@packages/uiKit/Field';
import { FC } from 'react';

export interface SwitchFieldProps extends Omit<SwitchProps, 'error'> {
  label?: string;
  tip?: string;
  error?: string;
  valueLabel: string;
}

export const SwitchField: FC<SwitchFieldProps> = (props) => {
  const { label, styles, className, error, tip, valueLabel, ...restProps } =
    props;

  return (
    <Field
      style={styles}
      className={className}
      label={label}
      error={error}
      tip={tip}>
      <StyledControlContainer>
        <StyledValueLabel>{valueLabel}</StyledValueLabel>
        <Switch {...restProps} />
      </StyledControlContainer>
    </Field>
  );
};
