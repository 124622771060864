import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledReviewRatingCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const StyledRatingText = styled(Typography)`
  flex: 1;
  min-width: 0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
