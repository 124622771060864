import {
  InputPasswordField,
  InputPasswordFieldProps,
} from '@packages/uiKit/fields/InputPasswordField';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import { memo } from 'react';
import { Controller } from 'react-hook-form';

const _InputPasswordFieldAdapter: FieldAdapterFCComponent<
  InputPasswordFieldProps
> = (props) => {
  const {
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputPasswordField
            {...inputProps}
            {...field}
            error={error?.message}
          />
        );
      }}
    />
  );
};

export const InputPasswordFieldAdapter = memo(
  _InputPasswordFieldAdapter,
) as typeof _InputPasswordFieldAdapter;
