import { DotsThreeCirclesNoPadding } from '@packages/icons';
import { Button, colors, Image, Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledMenuPositionCard = styled.div`
  display: flex;
  gap: 19px;
  max-height: 86px;
`;

export const StyledImage = styled(Image).attrs({ size: 86 })``;

export const StyledRightContainer = styled.div`
  flex: 1;
  min-width: 0;
  border-top: 1px solid ${colors.gray1};
  padding-top: 10px;

  display: flex;
  gap: 19px;
`;

export const StyledMainInfoContainer = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;

export const StyledName = styled(Typography).attrs({ ellipsis: true })``;

export const StyledBottomContainer = styled.div`
  display: flex;
  gap: 9px;
`;

export const StyledParamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const StyledWeight = styled(Typography).attrs({
  ellipsis: true,
  nowrap: true,
  color: 'gray1',
  size: 12,
})``;

export const StyledCost = styled(Typography).attrs({
  ellipsis: true,
  nowrap: true,
  size: 16,
  weight: 700,
})``;

export const StyledActionsButton = styled(Button).attrs({
  icon: <DotsThreeCirclesNoPadding />,
  variant: 'text',
})`
  padding: 0;
`;

export const StyledActionButtonContainer = styled.div``;

export const StyledIconButton = styled(Button)`
  font-size: 24px;
  padding: 4px;
`;
