import { accountingApi } from '@features/accounting';
import { analyticsApi } from '@features/analytics/store';
import { authApi, authSlice } from '@features/auth';
import { bookingsApi } from '@features/booking';
import { certificateCentersApi } from '@features/certificateCenters';
import { citiesApi } from '@features/cities/store';
import { companyApi } from '@features/company';
import { cuisinesApi } from '@features/cuisines';
import { establishmentCategoriesApi } from '@features/establishmentCategories';
import { establishmentApi } from '@features/establishments';
import {
  greenBonusClientsApi,
  pushNotificationApi,
} from '@features/halalBonus/clients/store';
import { greenBonusComplaintsApi } from '@features/halalBonus/complaints';
import { greenBonusEstablishmentApi } from '@features/halalBonus/establishment';
import { greenBonusTransactionsApi } from '@features/halalBonus/transactions';
import { halalEdaEstablishmentApi } from '@features/halalEda';
import { halalEdaOrdersApi } from '@features/halalEda/orders';
import { halalShopOrdersApi } from '@features/halalShop/store/apiSerive';

import { halalTradingApi } from '@features/halalTrading/store/apiService';
import { menuApi } from '@features/menu';
import { menuCategoriesApi } from '@features/menuCategories';
import { reviewsApi } from '@features/reviews';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [citiesApi.reducerPath]: citiesApi.reducer,

    [analyticsApi.reducerPath]: analyticsApi.reducer,
    [greenBonusClientsApi.reducerPath]: greenBonusClientsApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [certificateCentersApi.reducerPath]: certificateCentersApi.reducer,
    [establishmentApi.reducerPath]: establishmentApi.reducer,
    [cuisinesApi.reducerPath]: cuisinesApi.reducer,
    [establishmentCategoriesApi.reducerPath]:
      establishmentCategoriesApi.reducer,
    [accountingApi.reducerPath]: accountingApi.reducer,
    [menuApi.reducerPath]: menuApi.reducer,
    [menuCategoriesApi.reducerPath]: menuCategoriesApi.reducer,
    [halalEdaOrdersApi.reducerPath]: halalEdaOrdersApi.reducer,
    [halalEdaEstablishmentApi.reducerPath]: halalEdaEstablishmentApi.reducer,
    [greenBonusTransactionsApi.reducerPath]: greenBonusTransactionsApi.reducer,
    [greenBonusComplaintsApi.reducerPath]: greenBonusComplaintsApi.reducer,
    [greenBonusEstablishmentApi.reducerPath]:
      greenBonusEstablishmentApi.reducer,
    [reviewsApi.reducerPath]: reviewsApi.reducer,
    [bookingsApi.reducerPath]: bookingsApi.reducer,
    [halalShopOrdersApi.reducerPath]: halalShopOrdersApi.reducer,
    [halalTradingApi.reducerPath]: halalTradingApi.reducer,
    [pushNotificationApi.reducerPath]: pushNotificationApi.reducer,

    auth: authSlice.reducer,
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      authApi.middleware,
      halalTradingApi.middleware,
      pushNotificationApi.middleware,
      citiesApi.middleware,
      halalShopOrdersApi.middleware,
      analyticsApi.middleware,
      greenBonusClientsApi.middleware,
      companyApi.middleware,
      certificateCentersApi.middleware,
      establishmentApi.middleware,
      cuisinesApi.middleware,
      establishmentCategoriesApi.middleware,
      accountingApi.middleware,
      menuApi.middleware,
      menuCategoriesApi.middleware,
      halalEdaOrdersApi.middleware,
      halalEdaEstablishmentApi.middleware,
      greenBonusTransactionsApi.middleware,
      greenBonusComplaintsApi.middleware,
      greenBonusEstablishmentApi.middleware,
      reviewsApi.middleware,
      bookingsApi.middleware,
    );
  },
});

setupListeners(store.dispatch);
