import {
  InputDateField,
  InputDateFieldProps,
} from '@packages/uiKit/fields/InputDateField';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import { memo } from 'react';
import { Controller } from 'react-hook-form';

const _InputDateFieldAdapter: FieldAdapterFCComponent<InputDateFieldProps> = (
  props,
) => {
  const {
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field: { ref, ...restField }, fieldState: { error } }) => {
        return (
          <InputDateField
            {...inputProps}
            {...restField}
            error={error?.message}
          />
        );
      }}
    />
  );
};

export const InputDateFieldAdapter = memo(
  _InputDateFieldAdapter,
) as typeof _InputDateFieldAdapter;
