import { NavbarNavs } from './NavbarNavs';

import {
  StyledContent,
  StyledContentArea,
  StyledFooterArea,
  StyledMainLayout,
  StyledNavBar,
  StyledNavBarArea,
  StyledSidebarArea,
} from './styles';

import { MainContactInfo } from '../components/MainContactInfo';

import { MainLogo } from '../components/MainLogo';

import { ReactComponent as BriefcaseIcon } from '@assets/svgs/briefcase.svg';
import { ReactComponent as ForkKnifeIcon } from '@assets/svgs/forkKnife.svg';
import { ReactComponent as HouseIcon } from '@assets/svgs/house.svg';
import { ReactComponent as SignOutIcon } from '@assets/svgs/signOut.svg';
import { ReactComponent as StackIcon } from '@assets/svgs/stack.svg';

import { logout } from '@features/auth';
import { useAppDispatch } from '@features/store';
import { Content } from '@layouts/MainLayout/Content';
import { Sidebar } from '@layouts/MainLayout/Sidebar';
import { MainLayoutOutletContext } from '@layouts/MainLayout/types';
import {
  ChartPieSliceIcon,
  ChatTeardropTextIcon,
  CoinIcon,
  ShoppingCartIcon,
  TruckIcon,
  UserIcon,
} from '@packages/icons';
import React, { useCallback, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

export const MainLayout: React.FC & {
  Content: typeof Content;
  Sidebar: typeof Sidebar;
} = () => {
  const dispatch = useAppDispatch();

  const [sidebar, setSidebar] = useState<React.ReactNode>(null);

  const outletContext: MainLayoutOutletContext = useMemo(() => {
    return {
      setSidebar,
    };
  }, []);

  const onLogoutClick = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <StyledMainLayout withSideBar={Boolean(sidebar)}>
      <StyledNavBarArea>
        <StyledNavBar>
          <MainLogo />
          <NavbarNavs>
            <NavbarNavs.Link
              icon={<HouseIcon />}
              label="Заведение"
              to="/establishment"
            />
            <NavbarNavs.Link
              icon={<BriefcaseIcon />}
              label="Компания"
              to="/company"
            />
            <NavbarNavs.Link
              icon={<ChartPieSliceIcon />}
              label="Аналитика"
              to="/analytics"
            />
            <NavbarNavs.Link
              icon={<StackIcon />}
              label="Отчётность"
              to="/accounting"
            />
            <NavbarNavs.Link
              icon={<ForkKnifeIcon />}
              label="HalalEda"
              to="/halalEda"
            />
            <NavbarNavs.Link
              icon={<ShoppingCartIcon />}
              label="HalalShop"
              to="/halalShop"
            />
            <NavbarNavs.Link
              icon={<TruckIcon />}
              label="HalalTrading"
              to="/halalTrading"
            />
            <NavbarNavs.Link
              icon={<CoinIcon />}
              label="HalalBonus "
              to="/halalBonus"
            />

            <NavbarNavs.Link
              icon={<UserIcon />}
              label="Бронирование"
              to="/booking"
            />
            <NavbarNavs.Link
              icon={<ChatTeardropTextIcon />}
              label="Отзывы"
              to="/reviews"
            />

            <NavbarNavs.Button
              onClick={onLogoutClick}
              icon={<SignOutIcon />}
              label="Выйти"
            />
          </NavbarNavs>
        </StyledNavBar>
      </StyledNavBarArea>

      <StyledContentArea>
        <StyledContent>
          <Outlet context={outletContext} />
        </StyledContent>
      </StyledContentArea>

      <StyledFooterArea>
        <MainContactInfo />
      </StyledFooterArea>

      {sidebar && <StyledSidebarArea>{sidebar}</StyledSidebarArea>}
    </StyledMainLayout>
  );
};

MainLayout.Content = Content;
MainLayout.Sidebar = Sidebar;
