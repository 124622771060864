import { StyledMenuPositionFormFields } from './MenuPositionFormFields.styles';

import { MenuPositionFormValues } from './types';

import { MenuCategorySelectFieldAdapter } from '@features/menuCategories';
import { useUncontrolledFormContext } from '@hooks/useUncontrolledFromContext';
import { InputFieldAdapter } from '@packages/uiKit/fieldAdapters/InputFieldAdapter';
import { InputNumberFieldAdapter } from '@packages/uiKit/fieldAdapters/InputNumberFieldAdapter';
import { TextAreaFieldAdapter } from '@packages/uiKit/fieldAdapters/TextAreaFieldAdapter';
import { UploadImageInputFieldAdapter } from '@packages/uiKit/fieldAdapters/UploadImageInputFieldAdapter';
import { Col, Row } from 'antd';
import { FC, memo } from 'react';

const _MenuPositionFormFields: FC = () => {
  const { control, watch } =
    useUncontrolledFormContext<MenuPositionFormValues>();

  const name = watch('name');

  const ingredients = watch('ingredients');

  const nameCharactersLeft = 60 - name.length;

  const ingredientsCharactersLeft = 200 - ingredients.length;

  const nameTip =
    nameCharactersLeft >= 0
      ? `Осталось ${nameCharactersLeft} символов`
      : undefined;

  const ingredientsTip =
    ingredientsCharactersLeft >= 0
      ? `Осталось ${ingredientsCharactersLeft} символов`
      : undefined;

  return (
    <StyledMenuPositionFormFields>
      <InputFieldAdapter
        control={control}
        name="name"
        label="Название блюда"
        tip={nameTip}
      />

      <Row gutter={19}>
        <Col span={8}>
          <MenuCategorySelectFieldAdapter
            control={control}
            name="category"
            label="Категория блюда"
          />
        </Col>

        <Col span={8}>
          <InputNumberFieldAdapter
            control={control}
            name="weight"
            label="Вес блюда · Гр"
          />
        </Col>
        <Col span={8}>
          <InputNumberFieldAdapter
            control={control}
            name="cost"
            label="Стоимость · Тенге"
          />
        </Col>
      </Row>

      <TextAreaFieldAdapter
        control={control}
        name="ingredients"
        label="Состав блюда"
        tip={ingredientsTip}
        isAutosize
      />

      <UploadImageInputFieldAdapter
        control={control}
        name="image"
        preview="icon"
        color="secondary"
        aspectRatio={50}
        isCropable>
        Загрузить фото блюда
      </UploadImageInputFieldAdapter>
    </StyledMenuPositionFormFields>
  );
};

export const MenuPositionFormFields = memo(_MenuPositionFormFields);
