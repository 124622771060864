import {
  StyledAccordionExpandable,
  StyledAccordionExpandableHeader,
  StyledCaretDownIcon,
  StyledContent,
  StyledTitle,
} from './styles';

import { AnimatePresence } from 'framer-motion';

import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  memo,
  useCallback,
  useState,
} from 'react';
import { IconButton } from '../IconButton';

export type AccordionSizeItem = 'default' | 'small';
export interface AccordionItemProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
  title?: string | ReactNode;
  customTitle?: ReactNode;

  initStateIsOpen?: boolean;
  titleVariant?: 'bold' | 'normal';
  size?: AccordionSizeItem;
  hideBottomBorder?: boolean;
}

const _AccordionExpandable: FC<AccordionItemProps> = (props) => {
  const {
    title,
    customTitle,
    children,
    size = 'default',
    initStateIsOpen = false,
    titleVariant = 'bold',
    hideBottomBorder,
    ...restProps
  } = props;

  const [isOpen, setIsOpen] = useState(initStateIsOpen);

  const toggleOpenHandler = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <StyledAccordionExpandable
      initial={false}
      $hideBottomBorder={hideBottomBorder}
      {...restProps}>
      <StyledAccordionExpandableHeader onClick={toggleOpenHandler} $size={size}>
        <StyledTitle $isOpen={isOpen} $titleVariant={titleVariant} $size={size}>
          {customTitle ? customTitle : title}
        </StyledTitle>
        <IconButton variant="text">
          <StyledCaretDownIcon $isOpen={isOpen} />
        </IconButton>
      </StyledAccordionExpandableHeader>
      <AnimatePresence initial={false}>
        {isOpen ? (
          <StyledContent
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto', marginBottom: '20px' },
              collapsed: { opacity: 0, height: 0, marginBottom: '0px' },
            }}
            transition={{ duration: 0.15, easeOut: 0.05 }}>
            {children}
          </StyledContent>
        ) : null}
      </AnimatePresence>
    </StyledAccordionExpandable>
  );
};

export const AccordionExpandable = memo(_AccordionExpandable);
