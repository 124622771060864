import React, { FC, memo, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { PushNotificationFormValues } from '../../PushModalContent/types';
import {
  StyledButton,
  StyledButtonContainer,
  StyledImage,
  StyledInAppPreview,
  StyledMainText,
  StyledTitle,
} from './styles';

import placeHolderImgGray from '@assets/imgs/placeholderImageGray.png';

export interface InAppPreviewProps {
  className?: string;
  style?: React.CSSProperties;
}

const _InAppPreview: FC<InAppPreviewProps> = (props) => {
  const { ...restProps } = props;

  const { watch } = useFormContext<PushNotificationFormValues>();

  const title = watch('title');
  const mainText = watch('mainText');

  const file = watch('file');

  const previewSrc = useMemo(() => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }
    return file;
  }, [file]);

  return (
    <StyledInAppPreview {...restProps}>
      <StyledImage src={previewSrc ? previewSrc : placeHolderImgGray} />
      <StyledTitle>{title}</StyledTitle>
      <StyledMainText>{mainText}</StyledMainText>
      <StyledButtonContainer>
        <StyledButton size="small" block>
          Узнать больше
        </StyledButton>
      </StyledButtonContainer>
    </StyledInAppPreview>
  );
};

export const InAppPreview = memo(_InAppPreview);
