import { InputNumber } from '@packages/uiKit/InputNumber';
import { Select } from '@packages/uiKit/Select';
import { Typography } from '@packages/uiKit/Typography';
import { colors } from '@packages/uiKit/utils/colors';
import styled from 'styled-components';

export const StyledWorkTime = styled.div``;

export const StyledHeader = styled.div`
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 1.2fr 2fr 1fr;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const StyledColumnTitle = styled(Typography).attrs({
  size: 12,
  color: 'gray1',
})``;

export const StyledDayTitle = styled(StyledColumnTitle)`
  width: 145px;
  min-width: 145px;
  flex: 1;
`;

export const StyledTimeTitle = styled(StyledColumnTitle)`
  flex: 2;
  white-space: nowrap;
`;

export const StyledBonusTitle = styled(StyledColumnTitle)`
  flex: 1;
  white-space: nowrap;
`;

export const StyledDayFullTitle = styled(Typography).attrs({
  size: 14,
})`
  height: fit-content;
`;

export const StyledRowList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 60px;
`;

export const StyledSubDayRow = styled.div`
  display: flex;
  gap: 25px;
`;

export const StyledDayCell = styled.div`
  display: flex;
  gap: 10px;
  padding: 8px 0;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray1};
  min-width: 145px;
  height: fit-content;
`;

export const StyledTimeCell = styled.div`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  margin-bottom: 15px;
`;

export const StyledTimeContainer = styled.div`
  width: 100%;
`;

export const StyledInputNumber = styled(InputNumber).attrs({})`
  width: 50%;
`;

export const StyledTimeSelect = styled(Select)`
  flex: 1;

  height: fit-content;
` as typeof Select;
