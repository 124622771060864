import { getCurrencySymbol } from '@features/currency/hooks/getCurrencySymbol';
import {
  StyledBottom,
  StyledBottomLeft,
  StyledCode,
  StyledCost,
  StyledDropdownIconButton,
  StyledHalalShopProductCard,
  StyledImage,
  StyledName,
  StyledRight,
  StyledTop,
} from './styles';

import { SwitchWithValueLabel } from '@components/SwitchWithValueLabel';
import { NotePencilIcon } from '@packages/icons';
import { Button } from '@packages/uiKit';
import React, { FC, memo, useState } from 'react';

export interface HalalShopProductCardProps {
  className?: string;
  style?: React.CSSProperties;
  image: string;
  name: string;
  id: number;
  cost: number;
  currency: string;

  isActive: boolean;
  onEditClick?: () => void;
}

const _HalalShopProductCard: FC<HalalShopProductCardProps> = (props) => {
  const {
    image,
    name,
    id,
    cost,
    currency,
    isActive,
    onEditClick,

    ...restProps
  } = props;

  const [isVisible, setIsVisible] = useState(false);

  const currencySymbol = getCurrencySymbol(currency);

  return (
    <StyledHalalShopProductCard {...restProps}>
      <StyledImage src={image} placeholderType="gray" onClick={onEditClick} />
      <StyledRight>
        <StyledTop>
          <StyledName>{name}</StyledName>
          <StyledCode>Код товара: {id}</StyledCode>
          <StyledDropdownIconButton
            dropdown={<h1>Hello</h1>}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          />
        </StyledTop>
        <StyledBottom>
          <StyledBottomLeft>
            <Button
              icon={<NotePencilIcon />}
              color="white"
              onClick={onEditClick}
            />
            <StyledCost>
              {cost.toLocaleString()} {currencySymbol}
            </StyledCost>
          </StyledBottomLeft>

          <SwitchWithValueLabel
            trueLabel="Продается"
            falseLabel="Не продается"
            value={isActive}
          />
        </StyledBottom>
      </StyledRight>
    </StyledHalalShopProductCard>
  );
};

export const HalalShopProductCard = memo(_HalalShopProductCard);
