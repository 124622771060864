import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledPushModalContent = styled.div`
  margin-top: 35px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  margin-top: 55px;
  margin-bottom: 15px;
`;

export const StyledPreviewAccordionTitle = styled(Typography).attrs({
  size: 14,
  weight: 700,
})``;
