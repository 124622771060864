import { AmountCell } from '@components/table';
import { ToDetailCell } from '@components/table/ToDetailCell';
import { TableColumn, Typography } from '@packages/uiKit';
import { HalalBonusEmployeesRecord } from './types';

export const columns: TableColumn<HalalBonusEmployeesRecord>[] = [
  {
    Header: 'ID',
    accessor: 'id',

    Cell: ({ value }: { value: number | null }) =>
      value ? (
        <Typography nowrap ellipsis>
          {value}
        </Typography>
      ) : null,
  },
  {
    Header: 'Имя',
    accessor: (data) => data.name,

    Cell: ({ value }: { value: string }) => (
      <Typography nowrap ellipsis title={value} weight={700}>
        {value}
      </Typography>
    ),
  },
  {
    Header: 'Скачиваний',
    accessor: (record) => record.downloads,

    Cell: ({ value }: { value: number | null }) =>
      value ? (
        <Typography nowrap ellipsis>
          {value}
        </Typography>
      ) : null,
  },
  {
    Header: 'Бонусы',
    accessor: (record) => record.points,

    Cell: ({ value }: { value: number | null }) =>
      value ? (
        <Typography nowrap ellipsis>
          {value}
        </Typography>
      ) : null,
  },
  {
    Header: 'Сумма транзакций',
    accessor: (record) => record.points,

    Cell: ({ value }: { value: number | null }) =>
      value ? <AmountCell amount={value} /> : null,
  },
  {
    Header: 'Должность',
    accessor: (record) => record.jobTitle,

    Cell: ({ value }: { value: number | null }) =>
      value ? <ToDetailCell>{value}</ToDetailCell> : null,
  },
];
