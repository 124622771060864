import {
  useCreateMenuPositionForm,
  UseCreateMenuPositionFormParams,
} from './hooks';
import { StyledCreateMenuPositionModal } from './styles';

import { MenuPositionFormFields } from '../MenuPositionFormFields';

import { menuApi } from '@features/menu';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { UncontrolledFormProvider } from '@hooks/useUncontrolledFromContext';
import { Button, Modal, ModalProps } from '@packages/uiKit';
import { Col, Row } from 'antd';
import React, { FC, memo, useCallback } from 'react';

export interface CreateMenuPositionModalProps
  extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  className?: string;
  style?: React.CSSProperties;
}

type CreateMenuPositionModalInnerProps = {
  onClose?: () => void;
};

const CreateMenuPositionModalInner: FC<CreateMenuPositionModalInnerProps> = ({
  onClose,
}) => {
  const createNotification = useNotification();

  const [postMenuItemM] = menuApi.usePostMenuItemMutation();

  const [putMenuItemImageM] = menuApi.usePutMenuItemImageMutation();

  const onSubmitHandler: UseCreateMenuPositionFormParams['onSubmit'] =
    useCallback(
      async ({ image, ...data }, isUploadImage) => {
        try {
          const resMenuItem = await postMenuItemM({
            ...data,
          }).unwrap();

          if (isUploadImage) {
            await putMenuItemImageM({
              id: resMenuItem.id,
              image: image as File | null,
            }).unwrap();
          }

          onClose?.();

          createNotification({
            message: `Блюдо "${data.name}" успешно создано`,
            type: 'success',
          });
        } catch {
          createNotification({
            message: `Произошла ошибка при создании блюда`,
            type: 'error',
          });
        }
      },
      [createNotification, onClose, postMenuItemM, putMenuItemImageM],
    );

  const form = useCreateMenuPositionForm({
    onSubmit: onSubmitHandler,
  });

  return (
    <StyledCreateMenuPositionModal>
      <UncontrolledFormProvider {...form}>
        <MenuPositionFormFields />
        <Row gutter={10}>
          <Col>
            <Button size="large" onClick={form.handleSubmit}>
              Сохранить блюдо
            </Button>
          </Col>
        </Row>
      </UncontrolledFormProvider>
    </StyledCreateMenuPositionModal>
  );
};

const _CreateMenuPositionModal: FC<CreateMenuPositionModalProps> = (props) => {
  const { ...restProps } = props;

  return (
    <Modal title="Добавить блюдо" {...restProps}>
      <CreateMenuPositionModalInner onClose={restProps.onClose} />
    </Modal>
  );
};

export const CreateMenuPositionModal = memo(_CreateMenuPositionModal);
