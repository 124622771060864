import {
  BooleanSelectField,
  BooleanSelectFieldProps,
} from '@packages/uiKit/fields/BooleanSelectField';
import { FieldAdapterFCComponent } from '@packages/uiKit/utils/fieldAdapterTypes';
import { Controller } from 'react-hook-form';

export const BooleanSelectFieldAdapter: FieldAdapterFCComponent<
  BooleanSelectFieldProps
> = (props) => {
  const {
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field: { onBlur, ...field }, fieldState: { error } }) => {
        return (
          <BooleanSelectField
            {...inputProps}
            {...field}
            error={error?.message}
          />
        );
      }}
    />
  );
};
