import React, { FC, memo } from 'react';

import { Tabs } from '@components';
import { useSearchParam } from '@hooks/useSearchParam';
import { tabs } from './constants';
import { ClientSection } from './sections/ClientSection';
import { ReturnSection } from './sections/ReturnSection';
import { StyledContentFormSection } from './styles';
import { ReturnDetailTab } from './types';

export interface ContentFormSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

const sectionsDist = {
  [ReturnDetailTab.Client]: <ClientSection />,
  [ReturnDetailTab.Return]: <ReturnSection />,
};

const _ContentFormSection: FC<ContentFormSectionProps> = (props) => {
  const { ...restProps } = props;

  const [searchTab, setSearchTab] = useSearchParam<ReturnDetailTab>({
    paramName: 'tab',
    defaultValue: ReturnDetailTab.Return,
  });

  return (
    <StyledContentFormSection {...restProps}>
      <Tabs<ReturnDetailTab>
        tabs={tabs}
        value={searchTab}
        onChange={setSearchTab}
      />
      {sectionsDist[searchTab]}
    </StyledContentFormSection>
  );
};

export const ContentFormSection = memo(_ContentFormSection);
