import { StockPositionCardFormValues } from './types';

export const stockPositionMockData: StockPositionCardFormValues[] = [
  {
    id: 1,
    title: 'Дарим скидку 30% на все блюда',
    stockVariantSelected: 1,
    stockVariantOptions: [
      { value: 1, label: 'Скидка при заказе от суммы' },
      { value: 2, label: 'Подарок при заказе от суммы' },
    ],
    fromSumOrder: 1500,
    discountPercentage: 30,
    statsOne: 1840,
    statsTwo: 1210,
    photo:
      'https://images.unsplash.com/photo-1511690656952-34342bb7c2f2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80',
  },
  {
    id: 2,
    title: 'Поке с сыром Тофу в подарок',
    stockVariantSelected: 1,
    stockVariantOptions: [
      { value: 1, label: 'Скидка при заказе от суммы' },
      { value: 2, label: 'Подарок при заказе от суммы' },
    ],
    fromSumOrder: 2000,
    discountPercentage: 25,
    statsOne: 1725,
    statsTwo: 2541,
    photo:
      'https://images.unsplash.com/photo-1511690656952-34342bb7c2f2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80',
  },
];
