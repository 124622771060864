import { colors } from '@packages/uiKit/utils/colors';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledMainLogo = styled(Link)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'logo company'
    'logo project';
  column-gap: 22px;
  row-gap: 4px;
  margin-bottom: 45px;
  color: inherit;

  :hover {
    color: inherit;
  }
`;

export const StyledLogoImg = styled.img`
  grid-area: logo;
`;

export const StyledCompanyName = styled.p`
  color: ${colors.primary};
  font-size: 12px;
`;

export const StyledProjectName = styled.p`
  font-size: 24px;
  font-weight: 700;
`;
