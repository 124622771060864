import { AlarmIcon, CheckCircleOutlinedIcon } from '@packages/icons';
import { Typography } from '@packages/uiKit/Typography';
import styled from 'styled-components';

export const StyledLabel = styled(Typography)`
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledPayIcon = styled(AlarmIcon).attrs({
  size: 22,
})``;

export const StyledPayedIcon = styled(CheckCircleOutlinedIcon).attrs({
  size: 22,
})``;

export const StyledStatusCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;
