import { API_URL } from '@features/api';
import { ACCESS_TOKEN_HEADER_KEY } from '@features/queryClient/constans';
import { AppRootState } from '@features/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ChangeProductDetailRequest,
  ChangeProductDetailResponse,
  GetHalalShopOrdersResponse,
  GetHalalShopProductsRequest,
  GetHalalShopProductsResponse,
  GetProductDetailRequest,
  GetProductDetailResponse,
  HalalShopOrderDetailResponse,
} from './types';

export const HALAL_SHOP_ORDERS_TAG = 'HALAL_SHOP_ORDERS_TAG' as const;
export const HALAL_SHOP_PRODUCTS_TAG = 'HALAL_SHOP_PRODUCTS_TAG' as const;
export const HALAL_SHOP_PRODUCT_DETAIL_TAG =
  'HALAL_SHOP_PRODUCT_DETAIL_TAG' as const;

export const halalShopOrdersApi = createApi({
  reducerPath: 'halalShopOrdersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/shop/`,
    prepareHeaders: (headers, { getState }) => {
      const authState = (getState() as AppRootState).auth;

      if (authState.isAuth) {
        headers.set(ACCESS_TOKEN_HEADER_KEY, `Bearer ${authState.token}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    HALAL_SHOP_ORDERS_TAG,
    HALAL_SHOP_PRODUCTS_TAG,
    HALAL_SHOP_PRODUCT_DETAIL_TAG,
  ],
  endpoints: () => ({}),
});

export const shopOrdersEndpoints = halalShopOrdersApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getOrders: builder.query<
        GetHalalShopOrdersResponse,
        { limit?: number; offset?: number }
      >({
        providesTags: (response) => [HALAL_SHOP_ORDERS_TAG],
        query: (params) => ({
          url: `/orders/`,
          method: 'GET',
          params,
        }),
      }),
      getOrderDetail: builder.query<
        HalalShopOrderDetailResponse,
        { id: number }
      >({
        query: (params) => ({
          url: `/orders/${params.id}`,
          method: 'POST',
          ...params,
        }),
      }),
    };
  },
});

export const shopProductsEndpoints = halalShopOrdersApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getProducts: builder.query<
        GetHalalShopProductsResponse,
        GetHalalShopProductsRequest
      >({
        providesTags: (response) => [HALAL_SHOP_PRODUCTS_TAG],
        query: (params) => ({
          url: `products/`,
          method: 'GET',
          params,
        }),
      }),
      getProductDetail: builder.query<
        GetProductDetailResponse,
        GetProductDetailRequest
      >({
        providesTags: (response) => [HALAL_SHOP_PRODUCT_DETAIL_TAG],
        query: ({ currency, ...params }) => ({
          url: `products/${params.id}/`,
          method: 'GET',
          headers: { Currency: currency },
        }),
      }),
      changeProductDetail: builder.mutation<
        ChangeProductDetailResponse,
        ChangeProductDetailRequest
      >({
        invalidatesTags: () => [HALAL_SHOP_PRODUCT_DETAIL_TAG],
        query: (data) => ({
          url: `products/${data.id}/`,
          method: 'PUT',
          body: data,
        }),
      }),
      postProductImage: builder.mutation<
        void,
        { image: File | null; product_id: number; is_main: boolean }
      >({
        invalidatesTags: () => [HALAL_SHOP_PRODUCT_DETAIL_TAG],
        query: (data) => {
          const formData = new FormData();

          if (data.image) {
            formData.append('image', data.image);
          }
          if (data.product_id) {
            formData.append('product_id', data.product_id.toString());
          }
          if (data.is_main) {
            formData.append('is_main', data.is_main.toString());
          }

          return {
            url: `product-photo/`,
            method: 'POST',
            body: formData,
          };
        },
      }),
      putProductImage: builder.mutation<
        void,
        { image: File | null; id: number; is_main: boolean }
      >({
        invalidatesTags: () => [HALAL_SHOP_PRODUCT_DETAIL_TAG],
        query: (data) => {
          const formData = new FormData();

          if (data.image) {
            formData.append('image', data.image);
          }
          if (data.id) {
            formData.append('id', data.id.toString());
          }
          if (data.is_main) {
            formData.append('is_main', data.is_main.toString());
          }

          return {
            url: `product-photo/${data.id}/`,
            method: 'PUT',
            body: formData,
          };
        },
      }),
      deleteProductImage: builder.mutation<
        void,
        { id: number; is_main: boolean }
      >({
        invalidatesTags: () => [HALAL_SHOP_PRODUCTS_TAG],
        query: (params) => ({
          url: `product-photo/${params.id}/`,
          method: 'DELETE',
          params,
        }),
      }),
    };
  },
});
