import { StyledTable } from './OrderTable.styles';

import { getColumns } from './utils';

import {
  HalalEdaOrderRecord,
  HalalEdaOrderStatus,
} from '@features/halalEda/orders';
import { TableProps } from '@packages/uiKit/Table';
import { HalalEdaPageSection } from '@pages/HalalEdaPage/types';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

export interface OrderTableProps {
  data: HalalEdaOrderRecord[];
  hideHeader?: boolean;
  onChangeStatus: (
    record: HalalEdaOrderRecord,
    newStatus: HalalEdaOrderStatus,
  ) => void;
}

const getRowId: TableProps<HalalEdaOrderRecord>['getRowId'] = (item) =>
  item.id.toString();

export const OrderTable: FC<OrderTableProps> = (props) => {
  const { data, hideHeader, onChangeStatus } = props;

  const navigate = useNavigate();

  const getRowProps: TableProps<HalalEdaOrderRecord>['getRowProps'] =
    useCallback(
      (row: Row<HalalEdaOrderRecord>) => {
        const { id, status } = row.original;

        const onClickHandler = () => {
          navigate(`${HalalEdaPageSection.Orders}/detail/${id}`);
        };

        if (
          status === HalalEdaOrderStatus.Completed ||
          status === HalalEdaOrderStatus.Cancelled
        ) {
          return {
            className: 'row row--disabled',
            onClick: onClickHandler,
          };
        }
        return {
          className: 'row',
          onClick: onClickHandler,
        };
      },

      [navigate],
    );

  const columns = useMemo(
    () => getColumns({ onChangeStatus }),
    [onChangeStatus],
  );

  return (
    <StyledTable
      columns={columns}
      data={data}
      getRowId={getRowId}
      getRowProps={getRowProps}
      hideHeader={hideHeader}
      scroll={false}
    />
  );
};
