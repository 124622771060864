import {
  StyledCameraIcon,
  StyledLabel,
  StyledUploadButtonContent,
} from './UploadImageButton.styles';

import { UploadButton, UploadButtonProps } from '@packages/uiKit/UploadButton';
import { FC } from 'react';

export type UploadImageButtonProps = UploadButtonProps;

export const UploadImageButton: FC<UploadImageButtonProps> = (props) => {
  const { children, ...restProps } = props;

  return (
    <UploadButton accept="image/jpeg, image/png, image/svg+xml" {...restProps}>
      <StyledUploadButtonContent>
        <StyledLabel>{children}</StyledLabel>

        <StyledCameraIcon />
      </StyledUploadButtonContent>
    </UploadButton>
  );
};
