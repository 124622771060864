import { EstablishmentForm, EstablishmentFormProps } from './EstablishmentForm';

import { StyledEstablishmentSection } from './EstablishmentSection.styles';

import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';

import { halalEdaEstablishmentApi } from '@features/halalEda';
import { useNotification } from '@features/notifications/hooks/useNotification';
import React, { FC, useCallback } from 'react';

export interface EstablishmentSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

export const EstablishmentSection: FC<EstablishmentSectionProps> = (props) => {
  const { ...restProps } = props;

  const createNotification = useNotification();

  const { data, isSuccess, isLoading } =
    halalEdaEstablishmentApi.useGetEstablishmentQuery();

  const [putEstablishmentM] =
    halalEdaEstablishmentApi.usePutEstablishmentMutation();

  const submitHandler: EstablishmentFormProps['onSubmit'] = useCallback(
    async (data) => {
      try {
        await putEstablishmentM(data).unwrap();
        createNotification({
          message: 'HalalEda заведение успешно изменено',
          type: 'success',
        });
      } catch {
        createNotification({
          message: 'При изменении заведения произошла ошибка',
          type: 'error',
        });
      }
    },
    [createNotification, putEstablishmentM],
  );

  return (
    <StyledEstablishmentSection {...restProps}>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess && (
          <EstablishmentForm initValues={data} onSubmit={submitHandler} />
        )
      )}
      {!isSuccess && !isLoading && <TextPlaceholder />}
    </StyledEstablishmentSection>
  );
};
