import {
  GetMenuIncludedCategoriesResponse,
  GetMenuItemListParams,
  GetMenuItemListResponse,
  PostMenuItemRequest,
  PostMenuItemResponse,
  PutMenuItemImageRequest,
  PutMenuItemRequest,
} from './types';

import { ApiRecordId } from '@features/api';
import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const MENU_TAG = 'MENU' as const;
export const INCLUDED_CATEGORIES_TAG = 'INCLUDED_CATEGORIES' as const;

const MENU_LIST_TAG = { type: MENU_TAG, id: 'list' };

const getDetailTag = (id: ApiRecordId) => ({
  type: MENU_TAG,
  id: `detail_${id}}`,
});

export const menuApi = createApi({
  reducerPath: 'menuApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'menu' }),
  tagTypes: [MENU_TAG, INCLUDED_CATEGORIES_TAG],
  endpoints: (builder) => {
    return {
      getMenuItems: builder.query<
        GetMenuItemListResponse,
        GetMenuItemListParams
      >({
        providesTags: [MENU_LIST_TAG],
        query: (params) => ({
          url: '/',
          method: 'GET',
          params,
        }),
      }),
      getMenuIncludedCategories: builder.query<
        GetMenuIncludedCategoriesResponse,
        void
      >({
        providesTags: [INCLUDED_CATEGORIES_TAG],
        query: () => ({
          url: '/included-categories/',
          method: 'GET',
        }),
      }),

      postMenuItem: builder.mutation<PostMenuItemResponse, PostMenuItemRequest>(
        {
          invalidatesTags: [MENU_LIST_TAG, INCLUDED_CATEGORIES_TAG],
          query: (request) => {
            return {
              url: '/',
              method: 'POST',

              body: request,
            };
          },
        },
      ),

      putMenuItem: builder.mutation<void, PutMenuItemRequest>({
        invalidatesTags: [MENU_LIST_TAG, INCLUDED_CATEGORIES_TAG],
        query: (request) => ({
          url: `/${request.id}/`,
          method: 'PUT',
          body: request.put,
        }),
      }),

      putMenuItemImage: builder.mutation<void, PutMenuItemImageRequest>({
        invalidatesTags: [MENU_LIST_TAG],
        query: (request) => {
          const formData = new FormData();
          if (request.image) {
            formData.append('image', request.image);
          }
          return {
            url: `/${request.id}/image/`,
            method: 'PUT',
            body: formData,
          };
        },
      }),
      deleteMenuItem: builder.mutation<void, { id: ApiRecordId }>({
        invalidatesTags: (_, _2, { id }) => [
          MENU_LIST_TAG,
          INCLUDED_CATEGORIES_TAG,
          getDetailTag(id),
        ],
        query: (request) => {
          return {
            url: `/${request.id}/`,
            method: 'DELETE',
          };
        },
      }),
    };
  },
});
