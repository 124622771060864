import { StyledHalalEdaCommissionCard } from './HalalEdaCommissionCard.styles';

import { InfoCardProps } from '../common/InfoCard';

import { ReceiptIcon } from '@packages/icons';
import React, { FC, memo } from 'react';

export interface OrdersDeliveredCardProps extends Pick<InfoCardProps, 'value'> {
  className?: string;
  style?: React.CSSProperties;
}

const _HalalEdaCommissionCard: FC<OrdersDeliveredCardProps> = (props) => {
  const { value, ...restProps } = props;

  const valueWithCurrency = `${value.toLocaleString()} ₸`;

  return (
    <StyledHalalEdaCommissionCard
      {...restProps}
      value={valueWithCurrency}
      icon={<ReceiptIcon />}
      description={'Комиссия HalalEda'}
      color="primary"
    />
  );
};

export const HalalEdaCommissionCard = memo(_HalalEdaCommissionCard);
