import { StatusCell } from './StatusCell';

import { AmountCell } from '@components/table/AmountCell';
import { BoldTextCell } from '@components/table/BoldTextCell';
import { DateCell } from '@components/table/DateCell';
import { FileCell } from '@components/table/FileCell';
import { AccountingItemRecord } from '@features/accounting';
import { parseServerDate } from '@utils/parseServerDate';
import { Column } from 'react-table';

export const columns: Column<AccountingItemRecord>[] = [
  {
    Header: 'Статус',
    accessor: 'paid',
    width: 200,
    Cell: ({ value }) => <StatusCell paid={value} />,
  },
  {
    Header: 'Проект',
    accessor: 'app_name',
    width: 150,
    Cell: ({ value }) => <BoldTextCell>{value}</BoldTextCell>,
  },
  {
    Header: 'Сумма',
    accessor: 'cost',
    width: 150,
    Cell: ({ value }) => <AmountCell amount={value} />,
  },
  {
    Header: 'Номер',
    accessor: 'id',
    width: 90,
    Cell: ({ value }) => <>#{value}</>,
  },
  {
    Header: 'Дата',
    accessor: 'created_at',
    width: 150,
    Cell: ({ value }) =>
      value ? <DateCell date={parseServerDate(value)} format="date" /> : null,
  },

  {
    Header: 'Файл счета',
    width: 170,
    accessor: 'invoice',

    Cell: ({
      value,
      row: {
        original: { id },
      },
    }) => <FileCell href={value}>Счет #{id}</FileCell>,
  },
  {
    Header: 'Файл акта',
    id: 'file2',
    width: 170,
    accessor: 'akt',
    Cell: ({
      value,
      row: {
        original: { id },
      },
    }) => (value ? <FileCell href={value}>Акт #{id}</FileCell> : null),
  },
  {
    Header: 'Файл отчета',
    id: 'file3',
    width: 170,
    accessor: 'report',
    Cell: ({
      value,
      row: {
        original: { id },
      },
    }) => (value ? <FileCell href={value}>Отчет #{id}</FileCell> : null),
  },
  {
    Header: 'Начало',
    accessor: 'date_start',
    width: 150,
    Cell: ({ value }) =>
      value ? <DateCell date={parseServerDate(value)} format="date" /> : null,
  },
  {
    Header: 'Конец',
    accessor: 'date_end',
    width: 150,
    Cell: ({ value }) =>
      value ? <DateCell date={parseServerDate(value)} format="date" /> : null,
  },
];
