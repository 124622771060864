import { StyledFormError } from './styles';

import React, { FC, memo } from 'react';

export interface FormErrorProps {
  className?: string;
  style?: React.CSSProperties;
  message?: string | null;
}

const _FormError: FC<FormErrorProps> = (props) => {
  const { message, ...restProps } = props;

  if (!message) {
    return null;
  }

  return <StyledFormError {...restProps}>{message}</StyledFormError>;
};

export const FormError = memo(_FormError);
