import { OrderTable, OrderTableProps } from './OrderTable';

import { Loader } from '@components/Loader';

import { TextPlaceholder } from '@components/TextPlaceholder';

import { TablesScrollX } from '@components/TablesScrollX';
import { halalEdaOrdersApi } from '@features/halalEda/orders';
import { Pagination } from '@packages/uiKit/Pagination';
import React, { FC, useCallback, useState } from 'react';
import { StyledOrdersSection } from './OrdersSection.styles';

export interface OrdersSectionProps {
  className?: string;
  style?: React.CSSProperties;
}

export const OrdersSection: FC<OrdersSectionProps> = (props) => {
  const { ...restProps } = props;

  const [page, setPage] = useState(1);

  const { data, isLoading, isSuccess } = halalEdaOrdersApi.useGetOrderPageQuery(
    {
      page,
      perPage: 12,
    },
  );

  const [patchOrderM] = halalEdaOrdersApi.usePatchOrderMutation();

  const changeStatusHandler: OrderTableProps['onChangeStatus'] = useCallback(
    (record, newStatus) => {
      patchOrderM({ id: record.id, patch: { status: newStatus } });
    },
    [patchOrderM],
  );

  return (
    <StyledOrdersSection {...restProps}>
      {isLoading ? (
        <Loader />
      ) : (
        isSuccess && (
          <>
            {data?.result.length === 0 ? (
              <TextPlaceholder variant="noData" />
            ) : (
              <>
                <TablesScrollX>
                  <OrderTable
                    data={data?.result ?? []}
                    onChangeStatus={changeStatusHandler}
                  />
                </TablesScrollX>
                <Pagination
                  pageIndex={page}
                  onChangePage={setPage}
                  pageCount={data?.pagination.pageCount ?? 0}
                />
              </>
            )}
          </>
        )
      )}
      {!isSuccess && !isLoading && <TextPlaceholder variant="error" />}
    </StyledOrdersSection>
  );
};
