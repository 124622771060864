import React, { FC, memo, useCallback } from 'react';

import { GetClientsListRecord } from '@features/halalBonus/clients/store';
import { TableProps } from '@packages/uiKit';
import { HalalBonusPageTab } from '@pages/HalalBonusPage/types';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { StyledClientsTable, StyledTable } from './styles';
import { getColumns } from './utils';

export interface ClientsTableProps {
  className?: string;
  style?: React.CSSProperties;
  data: GetClientsListRecord[];
}

const getRowId: TableProps<GetClientsListRecord>['getRowId'] = (item) =>
  item.id.toString();

const _ClientsTable: FC<ClientsTableProps> = (props) => {
  const { data, ...restProps } = props;

  const columns = getColumns();

  const navigate = useNavigate();

  const getRowProps: TableProps<GetClientsListRecord>['getRowProps'] =
    useCallback(
      (row: Row<GetClientsListRecord>) => {
        const { id } = row.original;

        const onClickHandler = () => {
          navigate(`${HalalBonusPageTab.Clients}/detail/${id}`);
        };

        return {
          className: 'row',
          onClick: onClickHandler,
        };
      },

      [navigate],
    );

  return (
    <StyledClientsTable {...restProps}>
      <StyledTable
        columns={columns}
        data={data}
        getRowId={getRowId}
        getRowProps={getRowProps}
        scroll={false}
      />
    </StyledClientsTable>
  );
};

export const ClientsTable = memo(_ClientsTable);
