import { Typography } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledSwitchWithValueLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const StyledValueLabel = styled(Typography).attrs({
  size: 10,
  color: 'gray1',
  ellipsis: true,
  lines: 1,
})`
  width: 35px;
`;
