import { useLoginForm, UseLoginFormParams } from './hooks';

import { LoginForm } from './LoginForm';
import { StyledLoginPage } from './LoginPage.styles';

import { login } from '@features/auth';
import { useAppDispatch } from '@features/store';
import { UncontrolledFormProvider } from '@hooks/useUncontrolledFromContext';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoginPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit: UseLoginFormParams['onSubmit'] = useCallback(
    async (data) => {
      setIsLoading(true);
      //console.log('submit login form with data: ', data);
      try {
        await dispatch(login(data)).unwrap();
      } catch (e) {
        const error = e as FetchBaseQueryError;
        if (error.status === 403) {
          throw new Error('Неправильный логин или пароль');
        }

        throw e;
      } finally {
        setIsLoading(false);
      }

      navigate('/');
    },
    [dispatch, navigate],
  );

  const form = useLoginForm({
    onSubmit,
  });

  return (
    <StyledLoginPage>
      <UncontrolledFormProvider {...form}>
        <LoginForm isLoading={isLoading} />
      </UncontrolledFormProvider>
    </StyledLoginPage>
  );
};
