import { LoginFormValues } from './types';

import { yupResolver } from '@hookform/resolvers/yup';
import { useUncontrolledForm } from '@hooks/useUncontrolledForm';
import * as yup from 'yup';

const defaultValues: LoginFormValues = {
  login: '',
  password: '',
};

export const validationSchema: yup.SchemaOf<LoginFormValues> = yup
  .object({
    login: yup.string().required('Заполните поле логин'),
    password: yup.string().required('Заполните поле пароль'),
  })
  .required();

export type UseLoginFormParams = {
  onSubmit: (data: LoginFormValues) => Promise<void>;
};
export const useLoginForm = (params: UseLoginFormParams) => {
  const { onSubmit } = params;

  return useUncontrolledForm<LoginFormValues>({
    defaultValues,
    onSubmit,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
};
