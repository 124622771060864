import { Typography, colors } from '@packages/uiKit';
import styled from 'styled-components';

export const StyledSidebarSection = styled.div``;

export const StyledInfoTextItem = styled.div`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  gap: 8px;

  border-top: 1px solid ${colors.gray7};
`;

export const StyledInfoTextItemLabel = styled(Typography)``;
export const StyledInfoTextItemValue = styled(Typography)``;

export const StyledInfoSummaryItemLabel = styled(Typography).attrs({
  weight: 700,
  size: 18,
})``;

export const StyledInfoSummaryItemValue = styled(Typography).attrs({
  weight: 700,
  size: 18,
})``;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 45px;
`;
