import { OperationCheckCell } from './OperationCheckCell';

import { DateCell, SelectCellWithIcon } from '@components/table';
import { QrCodeCell } from '@components/table/QrCodeCell';
import { SelectCellWithIconConfig } from '@components/table/SelectCellWithIcon/types';
import {
  HalalBonusComplaintRecord,
  HalalBonusComplaintStatus,
} from '@features/halalBonus/complaints';
import {
  AlarmIcon,
  CheckCircleOutlinedIcon,
  ProhibitIcon,
} from '@packages/icons';
import { TableColumn, Typography, colors } from '@packages/uiKit';

export const statusConfig: SelectCellWithIconConfig<HalalBonusComplaintStatus> =
  [
    {
      value: HalalBonusComplaintStatus.New,
      label: 'В обработке',
      bgColor: colors.danger,
      color: colors.white,
      icon: <AlarmIcon />,
    },
    {
      value: HalalBonusComplaintStatus.Confirmed,
      label: 'Подтвержден',
      bgColor: colors.primary,
      color: colors.white,
      icon: <CheckCircleOutlinedIcon />,
    },
    {
      value: HalalBonusComplaintStatus.Canceled,
      label: 'Отказ',
      bgColor: colors.gray2,
      color: colors.white,
      icon: <ProhibitIcon />,
    },
  ];

export type GetColumnsParams = {
  onChangeStatus: (
    record: HalalBonusComplaintRecord,
    status: HalalBonusComplaintStatus,
  ) => void;
};
export const getColumns = (
  params: GetColumnsParams,
): TableColumn<HalalBonusComplaintRecord>[] => [
  {
    Header: 'Статус',
    accessor: 'status',

    width: 220,
    overflow: false,
    Cell: ({ value, row: { original } }) => {
      const changeHandler = (status: HalalBonusComplaintStatus) => {
        params.onChangeStatus(original, status);
      };
      return (
        <SelectCellWithIcon
          value={value}
          config={statusConfig}
          onChange={changeHandler}
        />
      );
    },
  },
  {
    Header: 'Клиент',
    accessor: (record) => record.user.full_name,
    minWidth: 180,
    Cell: ({ value }: { value: string }) => (
      <Typography nowrap ellipsis title={value} weight={700}>
        {value}
      </Typography>
    ),
  },
  {
    Header: 'Номер телефона',
    accessor: (record) => record.user.tel,
    minWidth: 180,

    Cell: ({ value }: { value: number | null }) =>
      value ? (
        <Typography nowrap ellipsis>
          {value}
        </Typography>
      ) : null,
  },
  {
    Header: 'Сумма',
    accessor: 'amount',
    Cell: ({ value }) => (
      <Typography nowrap ellipsis weight={700}>
        {value} ₸
      </Typography>
    ),
  },

  {
    Header: 'Сумма со штрафом',
    accessor: 'amount_inc',
    Cell: ({ value }) => (
      <Typography nowrap ellipsis weight={700} color="danger">
        {value} ₸
      </Typography>
    ),
  },

  {
    Header: 'Время',
    accessor: 'created_at',
    minWidth: 150,

    Cell: ({ value }) => <DateCell date={value} format="dateTime" />,
  },
  {
    Header: 'Номер QR-кода',
    id: 'qr',
    width: 200,
    Cell: () => <QrCodeCell>9794916670058</QrCodeCell>,
  },
  {
    Header: 'Чек операции',
    accessor: 'photo',
    minWidth: 150,
    Cell: ({ value }) => <OperationCheckCell photo={value} number="9791857" />,
  },
];
