import {
  SelectCellWithIcon,
  SelectCellWithIconConfig,
} from '@components/table';
import {
  AlarmIcon,
  CheckCircleOutlinedIcon,
  ForkKnifeIcon,
  ProhibitIcon,
} from '@packages/icons';
import { colors } from '@packages/uiKit/utils/colors';
import { FC, memo, useCallback } from 'react';
import { HalalShopReturnStatus } from '../../types';

const config: SelectCellWithIconConfig<HalalShopReturnStatus> = [
  {
    value: HalalShopReturnStatus.Processed,
    label: HalalShopReturnStatus.Processed, // "Новый"
    icon: <AlarmIcon />,
    bgColor: colors.danger,
    color: colors.white,
  },
  {
    value: HalalShopReturnStatus.Accepted,
    label: HalalShopReturnStatus.Accepted,
    icon: <ForkKnifeIcon />,
    bgColor: colors.warning,
    color: colors.dark1,
  },

  {
    value: HalalShopReturnStatus.Rejected,
    label: HalalShopReturnStatus.Rejected,
    icon: <ProhibitIcon />,
    bgColor: colors.gray1,
    color: colors.white,
  },
  {
    value: HalalShopReturnStatus.Delivered,
    label: HalalShopReturnStatus.Delivered,
    icon: <CheckCircleOutlinedIcon />,
    bgColor: 'transparent',
    color: colors.gray1,
    borderColor: colors.gray1,
  },
];

export interface HalalShopReturnStatusCellProps {
  status: HalalShopReturnStatus;
  onChange: (value: HalalShopReturnStatus) => void;
}

const _HalalShopReturnStatusCell: FC<HalalShopReturnStatusCellProps> = (
  props,
) => {
  const { status, onChange } = props;

  const clickHandler: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
    },
    [],
  );

  return (
    <SelectCellWithIcon<HalalShopReturnStatus>
      value={status}
      config={config}
      onClick={clickHandler}
      onChange={onChange}
    />
  );
};

export const HalalShopReturnStatusCell = memo(_HalalShopReturnStatusCell);
