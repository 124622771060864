import { getBooleanOptions } from './utils';

import { Select, SelectProps } from '../Select';

import { forwardRef, memo, useCallback, useMemo } from 'react';

export interface BooleanSelectProps
  extends Omit<SelectProps<number>, 'value' | 'onChange' | 'options'> {
  value?: boolean | null;
  onChange?: (value: boolean) => void;
  trueLabel?: string;
  falseLabel?: string;
}

const _BooleanSelect = forwardRef<HTMLDivElement, BooleanSelectProps>(
  (props, ref) => {
    const { value, onChange, trueLabel, falseLabel, ...restProps } = props;

    const selectValue = typeof value === 'boolean' ? (value ? 1 : 0) : value;

    const changeHandler = useCallback(
      (newSelectValue: number) => {
        onChange?.(Boolean(newSelectValue));
      },
      [onChange],
    );

    const options = useMemo(
      () => getBooleanOptions({ trueLabel, falseLabel }),
      [falseLabel, trueLabel],
    );

    return (
      <Select<number>
        ref={ref}
        value={selectValue}
        onChange={changeHandler}
        options={options}
        {...restProps}
      />
    );
  },
);

export const BooleanSelect = memo(_BooleanSelect);
