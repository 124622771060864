import { StyledContent, StyledFormSection, StyledTitle } from './styles';

import React, { FC, PropsWithChildren } from 'react';

export interface FormSectionProps extends PropsWithChildren {
  className?: string;
  style?: React.CSSProperties;
  title?: string;
}

export const FormSection: FC<FormSectionProps> = (props) => {
  const { title, children, ...restProps } = props;

  return (
    <StyledFormSection {...restProps}>
      <StyledTitle>{title}</StyledTitle>
      <StyledContent>{children}</StyledContent>
    </StyledFormSection>
  );
};
