import { BookingPageTab } from './types';

import { TabsTabItem } from '@components';
import { BookingStatus } from '@features/booking';

export const tabs: TabsTabItem<BookingPageTab>[] = [
  {
    value: BookingPageTab.All,
    label: 'Все',
  },
  {
    value: BookingPageTab.Today,
    label: 'На сегодня',
  },
  {
    value: BookingPageTab.Tomorrow,
    label: 'На завтра',
  },

  {
    value: BookingPageTab.Handling,
    label: 'Обработка',
  },
];

export const tabFiltersDict: Record<
  BookingPageTab,
  { time?: 'today' | 'tomorrow'; status?: BookingStatus }
> = {
  all: {},
  Handling: {
    status: BookingStatus.New,
  },
  today: {
    time: 'today',
  },
  tomorrow: {
    time: 'tomorrow',
  },
};
