import { defaultParams } from './constants';
import {
  StyledStatusCell,
  StyledStatusIconContainer,
  StyledStatusLabel,
} from './styles';

import { StatusCellConfig } from './types';

import React, { memo, useMemo } from 'react';

export interface StatusCellProps<ValueType extends string | number> {
  className?: string;
  style?: React.CSSProperties;
  config: StatusCellConfig<ValueType>;
  value: ValueType;
}

const _StatusCell = <ValueType extends string | number>(
  props: StatusCellProps<ValueType>,
) => {
  const { config, value, ...restProps } = props;

  const { bgColor, color, icon, borderColor, label } = useMemo(() => {
    const confItem = config.find((confItem) => confItem.value === value);
    if (confItem) {
      const { bgColor, color, icon, borderColor, label } = confItem;
      return {
        bgColor,
        color,
        icon,
        borderColor: borderColor ?? bgColor,
        label,
      };
    }
    return defaultParams;
  }, [config, value]);

  return (
    <StyledStatusCell {...restProps}>
      <StyledStatusLabel>{label}</StyledStatusLabel>
      <StyledStatusIconContainer
        style={{ backgroundColor: bgColor, color, borderColor }}>
        {icon}
      </StyledStatusIconContainer>
    </StyledStatusCell>
  );
};

export const StatusCell = memo(_StatusCell) as typeof _StatusCell;
