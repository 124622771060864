import { StyledExternalLink } from './ExternalLink.styles';

import { FC, PropsWithChildren } from 'react';

export interface ExternalLinkProps {
  href: string;
  newFolder?: boolean;
}

export const ExternalLink: FC<PropsWithChildren<ExternalLinkProps>> = (
  props,
) => {
  const { href, newFolder, children } = props;

  return (
    <StyledExternalLink href={href} target={newFolder ? '_blank' : undefined}>
      {children}
    </StyledExternalLink>
  );
};
