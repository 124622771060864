import { colors } from '@packages/uiKit/utils/colors';

import styled, { css } from 'styled-components';

export type StyledMainLayoutProps = {
  withSideBar: boolean;
};

export const StyledMainLayout = styled.div<StyledMainLayoutProps>`
  background-color: ${colors.rootBackground};
  min-height: 100vh;

  display: grid;

  & > * {
    min-width: 0px;
  }

  ${({ withSideBar }) =>
    withSideBar
      ? css`
          grid-template-columns: auto 1fr auto;
          grid-template-rows: 1fr auto;
          grid-template-areas:
            'navBar content sideBar'
            'navBar footer sideBar';
        `
      : css`
          grid-template-columns: auto 1fr;
          grid-template-rows: 1fr auto;
          grid-template-areas:
            'navBar content'
            'navBar footer';
        `}
`;

export const StyledNavBarArea = styled.div`
  grid-area: navBar;
`;

export const StyledNavBar = styled.div`
  height: 100%;
  background-color: white;
  padding: 40px 25px;
`;

export const StyledContentArea = styled.main`
  grid-area: content;
  min-width: 0;
`;

export const StyledContent = styled.div`
  height: 100%;
  padding: 32px 60px;
`;

export const StyledFooterArea = styled.footer`
  grid-area: footer;
  padding: 40px 60px;
`;

export const StyledSidebarArea = styled.aside`
  grid-area: sideBar;
`;
