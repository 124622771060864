import { GetCompanyResponse, PatchCompanyRequest } from './types';

import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

const COMPANY_BASE_TAG = 'COMPANY' as const;

export const DETAIL_TAG_COMPANY = { type: COMPANY_BASE_TAG, id: 'detail' };

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'company' }),
  tagTypes: [COMPANY_BASE_TAG],
  endpoints: (builder) => {
    return {
      getCompany: builder.query<GetCompanyResponse, void>({
        providesTags: [DETAIL_TAG_COMPANY],
        query: () => ({
          url: '/',
          method: 'GET',
        }),
      }),
      putCompany: builder.mutation<void, PatchCompanyRequest>({
        invalidatesTags: [DETAIL_TAG_COMPANY],
        query: (data) => {
          return {
            url: '/',
            method: 'PUT',
            body: data,
          };
        },
      }),

      putCompanyScan: builder.mutation<void, File | null>({
        invalidatesTags: [DETAIL_TAG_COMPANY],
        query: (file) => {
          const formData = new FormData();
          if (file) {
            formData.append('scan', file);
          }

          return {
            url: '/scan',
            method: 'PUT',
            body: formData,
          };
        },
      }),
    };
  },
});
