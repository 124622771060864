import { StyledTransactionsCard } from './TransactionsCard.styles';

import { InfoCardProps } from '../common/InfoCard';

import { QrCodeIcon } from '@packages/icons';
import React, { FC } from 'react';

export interface TransactionsCardProps
  extends Pick<InfoCardProps, 'value' | 'percent'> {
  className?: string;
  style?: React.CSSProperties;
}

export const TransactionsCard: FC<TransactionsCardProps> = (props) => {
  const { ...restProps } = props;

  return (
    <StyledTransactionsCard
      {...restProps}
      icon={<QrCodeIcon size={36} />}
      description={'Транзакций'}
      color="primary"
    />
  );
};
