import React, { FC, memo, useEffect, useState } from 'react';

import { Grid } from '@components/Grid';
import { Loader } from '@components/Loader';
import { Space } from '@components/Space';
import { TextPlaceholder } from '@components/TextPlaceholder';
import { HalalShopProductCard } from '@features/halalShop/orders/components/HalalShopProductCard';
import { shopProductsEndpoints } from '@features/halalShop/store/apiSerive';
import { ShopOrdersProduct } from '@features/halalShop/store/types';
import { PaginationLimitOffsetAdapter } from '@packages/uiKit/PaginationOffset';
import { HalalShopPageSection } from '@pages/HalalShopPage/types';
import { CATALOG_ITEMS_PER_PAGE } from '@utils/globalContants';
import { useNavigate } from 'react-router-dom';
import { StyledProductCardsGrid } from './styles';

export interface ProductCardsGridProps {
  className?: string;
  style?: React.CSSProperties;
}

const _ProductCardsGrid: FC<ProductCardsGridProps> = (props) => {
  const { ...restProps } = props;

  const navigate = useNavigate();

  const [data, setData] = useState<ShopOrdersProduct[] | null>();

  const [offset, setOffset] = useState<number>(0);

  const [elementsCount, setElementsCount] = useState<number>(0);

  const [getProductsQ, { isSuccess, isLoading }] =
    shopProductsEndpoints.useLazyGetProductsQuery();

  useEffect(() => {
    const fn = async () => {
      const res = await getProductsQ({
        limit: CATALOG_ITEMS_PER_PAGE,
        offset: offset,
      }).unwrap();

      setElementsCount(res.count);
      setData(res.results);
    };

    fn();
  }, [getProductsQ, offset]);

  const isEmpty = data && data.length === 0;

  return (
    <StyledProductCardsGrid {...restProps}>
      {isLoading && <Loader />}
      {isSuccess && isEmpty && !isLoading && (
        <TextPlaceholder variant="noData" />
      )}
      {data && (
        <Grid cols={4} border="none" columnGap={25} rowGap={25}>
          {data.map((item, index) => {
            return (
              <HalalShopProductCard
                key={item.id}
                name={item.name}
                id={item.id}
                image={item.image}
                currency={item.price.currency}
                cost={item.price.value}
                isActive={true}
                onEditClick={() => {
                  navigate(`${HalalShopPageSection.Catalog}/${item.id}`);
                }}
              />
            );
          })}
        </Grid>
      )}
      <Space size={40} />
      <PaginationLimitOffsetAdapter
        offset={offset}
        onChangeOffset={setOffset}
        totalElementsCount={elementsCount}
        perPage={CATALOG_ITEMS_PER_PAGE}
      />
    </StyledProductCardsGrid>
  );
};

export const ProductCardsGrid = memo(_ProductCardsGrid);
