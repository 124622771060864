import { StyledTabs, StyledTabsArea } from './BookingPage.styles';
import { BookingTable, BookingTableProps } from './BookingTable';
import { tabFiltersDict, tabs } from './constants';
import { BookingPageTab } from './types';

import { Loader } from '@components/Loader';

import { bookingsApi, CreateBookingModal } from '@features/booking';
import { EditBookingModal } from '@features/booking/components/EditBookingModal';
import { BookingRecord } from '@features/booking/store/types';
import { useAuthProtection } from '@hooks/useAuthProtection';
import { useModalState } from '@hooks/useModalState';
import { useSearchParam } from '@hooks/useSearchParam';
import { MainLayout } from '@layouts/MainLayout';
import { Button } from '@packages/uiKit';
import { FC, useCallback, useState } from 'react';

const PAGE_SIZE = 13;
export const BookingPage: FC = () => {
  useAuthProtection();

  const [patchBookingM] = bookingsApi.usePatchBookingMutation();

  const [statusSearch, setStatusSearch] = useSearchParam<BookingPageTab>({
    paramName: 'status',
    defaultValue: BookingPageTab.All,
  });

  const [page, setPage] = useState(1);

  const { data, isLoading } = bookingsApi.useGetBookingPageQuery({
    page,
    perPage: PAGE_SIZE,
    ...tabFiltersDict[statusSearch],
  });

  const [createBookingModalState, createBookingModalActions] = useModalState();

  const [editBookingModalState, editBookingModalActions] = useModalState<{
    bookingItem: BookingRecord;
  }>();

  const editClickHandler: BookingTableProps['onEditClick'] = useCallback(
    (bookingItem) => {
      editBookingModalActions.openHandler({ bookingItem });
    },
    [editBookingModalActions],
  );

  const editStatusHandler: BookingTableProps['onEditStatus'] = useCallback(
    async (bookingItem, status) => {
      await patchBookingM({
        id: bookingItem.id,
        patch: {
          status,
        },
      });
    },
    [patchBookingM],
  );

  return (
    <MainLayout.Content>
      <MainLayout.Content.Header>Бронирование</MainLayout.Content.Header>

      <StyledTabsArea>
        <StyledTabs
          tabs={tabs}
          value={statusSearch}
          onChange={setStatusSearch}
        />
        <Button onClick={createBookingModalActions.openHandler}>
          Создать бронь
        </Button>
      </StyledTabsArea>

      {isLoading ? (
        <Loader />
      ) : (
        <BookingTable
          data={data?.result ?? []}
          pagination={{
            pageIndex: page,
            onChangePage: setPage,
            pageCount: data?.pagination.pageCount ?? 0,
          }}
          onEditClick={editClickHandler}
          onEditStatus={editStatusHandler}
        />
      )}

      <CreateBookingModal
        {...createBookingModalState}
        onClose={createBookingModalActions.closeHandler}
      />
      <EditBookingModal
        {...editBookingModalState}
        onClose={editBookingModalActions.closeHandler}
      />
    </MainLayout.Content>
  );
};
