import React, { FC, memo, useCallback, useState } from 'react';

import { halalTradingEndpoints } from '@features/halalTrading/store/apiService';
import { useNotification } from '@features/notifications/hooks/useNotification';
import { Button } from '@packages/uiKit';
import { FormProvider, useForm } from 'react-hook-form';

import { TabsTabItem } from '@components';
import { HalalTradingProductMutationRequest } from '@features/halalTrading/store/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row } from 'antd';
import { ProductHalalTradingForm } from '../ProductHalalTradingForm';
import { ProductHalalTradingValues, validationSchema } from '../types';
import { StyledCreateNewProductPosition } from './styles';

export interface ProductPositionModalProps {
  className?: string;
  style?: React.CSSProperties;

  onClose: () => void;

  initValues: HalalTradingProductMutationRequest;
  productId: number;
  categoriesTabs: TabsTabItem<string>[];
}

const _EditProductPositionModal: FC<ProductPositionModalProps> = (props) => {
  const { onClose, initValues, productId, categoriesTabs, ...restProps } =
    props;

  const createNotification = useNotification();

  const [editProduct] = halalTradingEndpoints.useEditProductDetailMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (data: ProductHalalTradingValues) => {
      setIsLoading(true);
      try {
        await editProduct({ data, id: productId }).unwrap();

        createNotification({
          message: `Товар "${data.name}" успешно изменен`,
          type: 'success',
        });

        onClose();
      } catch (error) {
        createNotification({
          message: `Произошла ошибка при изменении товара`,
          type: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [editProduct, createNotification, onClose, productId],
  );

  const form = useForm<ProductHalalTradingValues>({
    defaultValues: initValues,
    resolver: yupResolver(validationSchema),
  });

  return (
    <StyledCreateNewProductPosition {...restProps}>
      <FormProvider {...form}>
        <ProductHalalTradingForm categoriesTabs={categoriesTabs} />
        <Row gutter={[15, 15]}>
          <Col span={12}>
            <Button
              onClick={form.handleSubmit(onSubmit)}
              block
              size="large"
              disabled={isLoading}>
              Сохранить изменения
            </Button>
          </Col>
          <Col span={12}>
            <Button onClick={onClose} variant="text" size="large">
              Отменить
            </Button>
          </Col>
        </Row>
      </FormProvider>
    </StyledCreateNewProductPosition>
  );
};

export const EditProductPositionModal = memo(_EditProductPositionModal);
