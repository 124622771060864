import { GetCitiesRequest, GetCitiesResponse } from './types';

import { ApiRecordId } from '@features/api';
import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

const CITIES_TAG = 'CITIES_TAG' as const;

const getCityItemTag = (id: ApiRecordId) => ({
  type: CITIES_TAG,
  id,
});

export const citiesApi = createApi({
  reducerPath: 'citiesApi',
  baseQuery: getQueryWithNoRefresh({
    baseUrl: 'https://data.halalguide.me/api/shop_api/cities',
  }),
  tagTypes: [CITIES_TAG],

  endpoints: (builder) => {
    return {
      getCities: builder.query<GetCitiesResponse, GetCitiesRequest>({
        providesTags: (response) => [
          CITIES_TAG,
          ...(response?.map((item) => getCityItemTag(item.id)) ?? []),
        ],
        query: (params) => ({
          url: '/',
          method: 'GET',
          params,
        }),
      }),
    };
  },
});
