import {
  AddMenuCategoryListRequest,
  AddMenuCategoryListResponse,
  GetMenuCategoryListResponse,
} from './types';

import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const MENU_CATEGORIES_TAG = 'MENU_CATEGORIES_TAG' as const;

const LIST_TAG = { type: MENU_CATEGORIES_TAG, id: 'list' };

export const menuCategoriesApi = createApi({
  reducerPath: 'menuCategoriesApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'menu-categories' }),
  tagTypes: [MENU_CATEGORIES_TAG],
  endpoints: (builder) => {
    return {
      getMenuCategoriesList: builder.query<GetMenuCategoryListResponse, void>({
        providesTags: [LIST_TAG],
        query: () => ({
          url: '/',
          method: 'GET',
        }),
      }),
      addCategory: builder.mutation<
        AddMenuCategoryListResponse,
        AddMenuCategoryListRequest
      >({
        query: (body) => ({
          url: '/',
          method: 'POST',
          body,
        }),
        invalidatesTags: [LIST_TAG],
      }),
    };
  },
});
