import { StyledButton } from './Button.styles';

import { Loader } from '@components/Loader';
import React, { forwardRef, memo, PropsWithChildren, ReactNode } from 'react';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'medium' | 'large';
  variant?: 'filled' | 'outlined' | 'text';
  block?: boolean;
  icon?: ReactNode;
  color?: 'primary' | 'white' | 'secondary';
  disabled?: boolean;
  loading?: boolean;
  width?: number | null;
}

const _Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (props, ref) => {
    const {
      size = 'medium',
      variant = 'filled',
      block = false,
      icon,
      color = 'primary',
      loading,
      width,
      disabled,
      children,
      ...restProps
    } = props;

    return (
      <StyledButton
        ref={ref}
        size={size}
        variant={variant}
        block={block}
        hasIcon={Boolean(icon)}
        hasLoader={Boolean(loading)}
        color={color}
        width={width}
        onlyIcon={Boolean(icon) && !Boolean(children)}
        disabled={disabled}
        {...restProps}>
        {children}
        {icon && icon}
        {loading && <Loader loaderVariant="bar" size={4} />}
      </StyledButton>
    );
  },
);

export const Button = memo(_Button);
