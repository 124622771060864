import { Field } from '@packages/uiKit/Field';
import { Select, SelectProps, SelectValue } from '@packages/uiKit/Select';
import React, { forwardRef } from 'react';

export interface SelectFieldProps<ValueType extends SelectValue = SelectValue>
  extends Omit<SelectProps<ValueType>, 'error'> {
  className?: string;
  style?: React.CSSProperties;

  label?: string;
  tip?: string;
  error?: string;
}

export const SelectField = forwardRef<HTMLDivElement, SelectFieldProps>(
  (props, ref) => {
    const { label, style, className, error, tip, ...restProps } = props;

    return (
      <Field label={label} error={error} tip={tip}>
        <Select ref={ref} {...restProps} variant="default" />
      </Field>
    );
  },
);

SelectField.displayName = 'SelectField';
