import { ReactComponent as CaretLeftSvg } from '@assets/svgs/caretLeft.svg';
import { Button } from '@packages/uiKit/Button';
import styled from 'styled-components';

export const StyledPagination = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const StyledCaretLeftIcon = styled(CaretLeftSvg)`
  width: 20px;
  height: 20px;
`;

export const StyledPrevButton = styled(Button).attrs({
  variant: 'filled',
  color: 'white',
  icon: <StyledCaretLeftIcon />,
})`
  padding: 8px;
`;

export const StyledPageButton = styled(Button).attrs({
  color: 'white',
})`
  width: 38px;
  height: 38px;
  padding: 4px;
`;

export const StyledPagesContainer = styled.div`
  display: flex;
  gap: 10px;
`;
