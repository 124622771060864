import { BookingFormValues } from './types';

import { phoneValidation } from '@features/forms/validation';
import * as yup from 'yup';

export const bookingFormSchema: yup.SchemaOf<BookingFormValues> = yup
  .object({
    name: yup.string().nullable().required('Заполните поле имя клиента'),
    time: yup.date().nullable().required('Выберете время брони'),
    phone: phoneValidation().required('Заполните поле номер телефона'),
    note: yup.string().nullable().optional(),
    seats: yup.number().nullable().required('Заполните поле кол-во мест'),
  })
  .required();
