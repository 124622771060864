import { Outlet } from 'react-router-dom';
import { Content } from './Content';
import { Sidebar } from './Sidebar';
import { StyledOrderDetailLayout } from './styles';

export const OrderDetailLayout: React.FC & {
  Content: typeof Content;
  Sidebar: typeof Sidebar;
} = (props) => {
  const { ...restProps } = props;

  return (
    <StyledOrderDetailLayout {...restProps}>
      <Outlet />
    </StyledOrderDetailLayout>
  );
};

OrderDetailLayout.Sidebar = Sidebar;
OrderDetailLayout.Content = Content;
