import { tabs, tabsFilterDict } from './constants';
import {
  StyledTitleAverageRate,
  StyledTitleRateDynamic,
} from './ReviewsPage.styles';

import { ReviewTable, ReviewTableProps } from './ReviewTable';
import { ReviewsPageTabs } from './types';

import { Tabs } from '@components';
import { Loader } from '@components/Loader';
import { TextPlaceholder } from '@components/TextPlaceholder';
import { reviewsApi } from '@features/reviews';
import { ReviewAnswerModal } from '@features/reviews/components/ReviewAnswerModal';
import { ReviewRecord } from '@features/reviews/store/types';
import { useAuthProtection } from '@hooks/useAuthProtection';
import { useModalState } from '@hooks/useModalState';
import { useSearchParam } from '@hooks/useSearchParam';
import { MainLayout } from '@layouts/MainLayout';
import { FC, useCallback, useState } from 'react';

export const ReviewsPage: FC = () => {
  useAuthProtection();

  const [statusSearch, setStatusSearch] = useSearchParam<ReviewsPageTabs>({
    paramName: 'status',
    defaultValue: ReviewsPageTabs.All,
  });

  const [page, setPage] = useState(1);

  const { data, isLoading, isSuccess } = reviewsApi.useGetReviewPageQuery({
    page,
    perPage: 12,
    ...tabsFilterDict[statusSearch],
  });

  const [reviewAnswerModalState, reviewModalActions] = useModalState<{
    reviewItem: ReviewRecord;
  }>();

  const onClickMakeAnswerHandler: ReviewTableProps['onClickMakeAnswer'] =
    useCallback(
      (reviewItem) => {
        reviewModalActions.openHandler({ reviewItem });
      },
      [reviewModalActions],
    );

  const arrowType =
    data?.is_positive === null ? '' : data?.is_positive === true ? '↑' : '↓';

  return (
    <MainLayout.Content>
      <MainLayout.Content.Header>
        Отзывы клиентов · Средняя{' '}
        {isLoading ? (
          <>· · ·</>
        ) : !isSuccess ? (
          <>-</>
        ) : (
          <>
            <StyledTitleAverageRate>{data?.rating}</StyledTitleAverageRate>{' '}
            <StyledTitleRateDynamic
              color={
                arrowType === '↓'
                  ? 'danger'
                  : arrowType === '↑'
                  ? 'primary'
                  : 'blueGray'
              }>
              {arrowType}
            </StyledTitleRateDynamic>
          </>
        )}
      </MainLayout.Content.Header>

      <Tabs tabs={tabs} value={statusSearch} onChange={setStatusSearch} />
      {isLoading ? (
        <Loader />
      ) : !isSuccess ? (
        <TextPlaceholder />
      ) : (
        <>
          <ReviewTable
            data={data?.result ?? []}
            pagination={{
              pageIndex: page,
              onChangePage: setPage,
              pageCount: data?.pagination.pageCount ?? 0,
            }}
            onClickMakeAnswer={onClickMakeAnswerHandler}
          />

          <ReviewAnswerModal
            onClose={reviewModalActions.closeHandler}
            {...reviewAnswerModalState}
          />
        </>
      )}
    </MainLayout.Content>
  );
};
