import { Button } from '../Button';
import { Image } from '../Image';

import { UploadImageButton } from '@packages/uiKit/UploadImageButton';
import styled, { css } from 'styled-components';

export const StyledUploadImageInput = styled.div<{ preview?: 'icon' | 'card' }>`
  display: flex;
  gap: 8px;
  flex-direction: column;

  ${({ preview }) =>
    preview === 'card' &&
    css`
      max-width: 219px;
    `}
`;

export const StyledHorizontalContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledCropButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
`;

export const StyledUploadImageButton = styled(UploadImageButton)`
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  height: 52px;
  min-height: 52px;
`;

export const StyledPreview = styled(Image).attrs({ size: 52 })`
  cursor: pointer;
`;
