import {
  GetHalalBonusEstablishmentResponse,
  PutHalalBonusEstablishmentRequest,
} from './types';

import { getQueryWithNoRefresh } from '@features/queryClient/queryWithNoRefresh';
import { createApi } from '@reduxjs/toolkit/query/react';

export const GREEN_BONUS_ESTABLISHMENT_TAG =
  'GREEN_BONUS_ESTABLISHMENT_TAG' as const;

export const greenBonusEstablishmentApi = createApi({
  reducerPath: 'greenBonusEstablishmentApi',
  baseQuery: getQueryWithNoRefresh({ subpath: 'green-bonus/establishment' }),
  tagTypes: [GREEN_BONUS_ESTABLISHMENT_TAG],
  endpoints: (builder) => {
    return {
      getEstablishment: builder.query<GetHalalBonusEstablishmentResponse, void>(
        {
          providesTags: [GREEN_BONUS_ESTABLISHMENT_TAG],
          query: () => ({
            url: '/',
            method: 'GET',
          }),
        },
      ),

      putEstablishment: builder.mutation<
        void,
        PutHalalBonusEstablishmentRequest
      >({
        invalidatesTags: [GREEN_BONUS_ESTABLISHMENT_TAG],
        query: (request) => ({
          url: '/',
          method: 'PUT',
          body: request,
        }),
      }),
    };
  },
});
